import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/groups';
import RehearsalForm from './rehearsal-form';
import { Modal } from 'react-bootstrap';

class RehearsalList extends Component {
  
   constructor(props, context) {
        super(props, context);
        this.state = {
            showModal: false,
            selectedItem: {}
        };

        this.removeItem = this.removeItem.bind(this);
        this.addItem = this.addItem.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }

removeItem(item) {
        if (window.confirm(`Remove rehearsal: ${item.meetingDescription}`)) {
            this.props.removeRehearsal(item._id,this.props.group._id);
        }
    }

  addItem(item) {
   const rehearsal = JSON.parse(JSON.stringify(item));

   rehearsal.eventTitle = `${this.props.group.shortName} Rehearsal`;
   rehearsal.eventDescription = `${this.props.group.name} Rehearsal`;
   rehearsal.groups = [this.props.group._id];
   rehearsal.description = `On ${rehearsal.dayOfWeek} at ${rehearsal.startTime} to ${rehearsal.endTime}`;
   rehearsal.localTimeZone = new Date().getTimezoneOffset();
   this.props.addRehearsal(rehearsal, this.props.group._id);
   this.setState({ showModal: false });
  }

  selectItem(selectedItem) {
      this.setState({ selectedItem, showModal: true });
  }

  close() {
        this.setState({ showModal: false });
    }

  open() {
        this.setState({ showModal: true });
    }

  renderList() {
    if (!this.props.group) {
      return null;
    }

    return this.props.group.meetings.map((rehearsal, index) => {
      return (
        <li key={index} className="list-group-item">
            <div className="row">
                <div className="col-xs-9 small">{rehearsal.meetingDescription}</div>
                <div className="col-xs-1" onClick={() => this.selectItem(rehearsal)}><i className="fa fa-pencil-square-o">&nbsp;</i></div>
                <div className="col-xs-1" onClick={() => this.removeItem(rehearsal)}><i className="fa fa-times">&nbsp;</i></div>
            </div>
        </li>
      );
    });
  }

  render() {
    
    return (
    <div className="clearfix">
        <div className="panel panel-default">
            <div className="panel-body">
                <div className="row">
                    <div className="col-sm-6">Group Rehearsals</div>
                    <div className=" col-sm-6 text-right">
                        <button type="button" onClick={() => this.selectItem({ startHours: '7', endHours: '8', startMinutes: '00', endMinutes: '00', startAmPm: 'AM', endAmPm: 'AM' })} className="btn btn-default btn-xs rounded">Add Rehearsal</button>
                    </div>
                </div>
                 <ul className="list-group">
                    {this.renderList()}
                </ul>
          </div>
    </div>

        <Modal show={this.state.showModal} onHide={this.close}>
            <Modal.Header className="modal-header primary" closeButton>
                <Modal.Title>Rehearsals</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RehearsalForm onSubmit={this.addItem} initialValues={this.state.selectedItem} venues={this.props.venues} />
            </Modal.Body>
           
        </Modal>
    </div>
    );
  }
}

RehearsalList.propTypes = {
    group: PropTypes.object.isRequired,
    rehearsals: PropTypes.array,
    venues: PropTypes.array.isRequired,
    addRehearsal: PropTypes.func.isRequired,
    removeRehearsal: PropTypes.func.isRequired
};

export default connect(null, actions)(RehearsalList);
