import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/instruments';
import ReactDataGrid from 'react-data-grid';
import { Data } from 'react-data-grid-addons';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Instrument from './instrument-page';
import AddInstrumentForm from './add-instrument-form';
import EmptyToolbar from '../common/grid-toolbar';

const Selectors = Data.Selectors;

class InstrumentListPage extends Component {
    
     constructor(props) {
        super(props);

        this.state = {
            selectedInstruments: [],
            rows: [],
            filters : {},
            sortColumn: null, 
            sortDirection: null,
            showAddInstrument: false,
            showInstrument: false
        };

        this.rowGetter = this.rowGetter.bind(this);
        this.onRowsSelected = this.onRowsSelected.bind(this);
        this.onRowsDeselected = this.onRowsDeselected.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.getRows = this.getRows.bind(this);
        this.getSize = this.getSize.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.onClearFilters = this.onClearFilters.bind(this);
        this.handleGridSort = this.handleGridSort.bind(this);
        this.exportInstruments = this.exportInstruments.bind(this);
        this.openAddInstrument = this.openAddInstrument.bind(this);
        this.closeAddInstrument = this.closeAddInstrument.bind(this);
        this.closeInstrument = this.closeInstrument.bind(this);
        this.addInstrument = this.addInstrument.bind(this);
    }

     componentDidMount() {
         if (!this.props.userSetting.schoolId) {
          this.props.reload(this.props.location.pathname, this.props.history);
        } else {
          this.props.getInstruments(this.props.userSetting.schoolId, true);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.instruments !== this.props.instruments) {
          this.setState({rows: nextProps.instruments.slice(0)});
        }
     }

    exportInstruments() {
        let exportList = [];
        if (this.state.selectedInstruments.length === 0) { 
            exportList = this.state.rows.map(row => row._id);
        } else {
            exportList = this.state.selectedInstruments;
        }
        this.props.exportInstruments(exportList, this.props.userSetting.schoolId);
    }

    openAddInstrument() {
        this.setState({ showAddInstrument: true });
    }

    closeAddInstrument() {
        this.setState({ showAddInstrument: false });
    }

    closeInstrument() {
        this.setState({ showInstrument: false });
    }

    addInstrument(instrument) {
             this.props.addInstrument(instrument, this.props.userSetting.schoolId);
             this.closeAddInstrument();
        }

    rowGetter(i) {
        const rows = this.getRows();
        return rows[i];
    }

    onRowsSelected(rows) {
        this.setState({ selectedInstruments: this.state.selectedInstruments.concat(rows.map(r => r.row._id)) });
    }

    onRowsDeselected(rows) {
        let ids = rows.map(r => r.row._id);
        this.setState({ selectedInstruments: this.state.selectedInstruments.filter(item => !ids.some(id => id === item) )});
    }

    onRowClick(rowIdx, row) {
        if (row) {
            this.props.getInstrument(row._id, () => this.setState({ showInstrument: true }));
        }
    }

    getRows() {
         return Selectors.getRows(this.state);
     }

    getSize() {
        return this.getRows().length;
    }

    handleFilterChange(filter) {
        const newFilters = Object.assign({}, this.state.filters);
        if (filter.filterTerm) {
        newFilters[filter.column.key] = filter;
        } else {
        delete newFilters[filter.column.key];
        }
        this.setState({filters: newFilters});
    }

    onClearFilters() {
        //all filters removed
        this.setState({filters: {} });
    }

     handleGridSort(sortColumn, sortDirection) {
        const state = Object.assign({}, this.state, {sortColumn: sortColumn, sortDirection: sortDirection});
        this.setState(state);
    }
  
    render() {

        if (!this.props.instruments) {
            return <div>loading...</div>;
        }

        const columns = [
            {
                key: 'instrument',
                name: 'Instrument',
                sortable : true,
                filterable : true
            },
            {
                key: 'manufacturer',
                name: 'Make',
                sortable : true,
                filterable : true
            },
            {
                key: 'model',
                name: 'Model',
                sortable : true,
                filterable : true
            },
             {
                key: 'serialNo',
                name: 'Serial No',
                sortable : true,
                filterable : true,
                width: 115
            },
             {
                key: 'schoolNo',
                name: 'School No',
                sortable : true,
                filterable : true,
                width: 115
            },
             {
                key: 'instrumentStatus',
                name: 'Status',
                sortable : true,
                filterable : true,
                width: 100
            },
             {
                key: 'hiredBy',
                name: 'Hired By',
                sortable : true,
                filterable : true
            }
            ];

        return (
            <div>
                <div className="pageheader">
                    <h2>Instruments</h2>
                </div>
                <section id="main-content">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="pt-button-group .modifier">
                                                <div className="pt-button pt-intent-primary pt-active" role="button">Instruments</div>
                                                <Link to="/hired" className="pt-button pt-intent-primary">Hired</Link>
                                                <Link to="/service" className="pt-button pt-intent-primary">In Service</Link>
                                                <Link to="/instrument-archive" className="pt-button pt-intent-primary">Archive</Link>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="text-right">
                                                <button type="button" className="btn btn-xs btn-primary rounded" onClick={() => this.openAddInstrument()} >Add Instrument</button>&nbsp;&nbsp;
                                                <button type="button" className="btn btn-xs btn-default rounded" onClick={() => this.exportInstruments()} >Export</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12">
                                        <div>
                                        <ReactDataGrid
                                                rowKey="_id"
                                                onGridSort={this.handleGridSort}
                                                columns={columns}
                                                rowGetter={this.rowGetter}
                                                rowsCount={this.getSize()}
                                                minHeight={this.getSize() * 35 + 85}
                                                onRowClick={this.onRowClick}
                                                toolbar={<EmptyToolbar />}
                                                onAddFilter={this.handleFilterChange}
                                                onClearFilters={this.onClearFilters}
                                                rowSelection={{
                                                    showCheckbox: true,
                                                    enableShiftSelect: true,
                                                    onRowsSelected: this.onRowsSelected,
                                                    onRowsDeselected: this.onRowsDeselected,
                                                    selectBy: {
                                                        keys: { rowKey: '_id', values: this.state.selectedInstruments}
                                                    }
                                                }} />
                                            </div>
                                            <div className="admin-label text-right margin-top-quarter"><i className="blueBG"> { (this.getSize() === this.state.rows.length) ? `${this.state.rows.length} Instruments` : `Showing ${this.getSize()} of ${this.state.rows.length} Instruments`} </i></div> 
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                 <Modal show={this.state.showAddInstrument} onHide={this.closeaddInstrument}>
                    <Modal.Header className="modal-header primary" closeButton>
                        <Modal.Title>Add Instrument</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-padding">
                            <AddInstrumentForm onSubmit={this.addInstrument} onClose={this.closeAddInstrument} />
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showInstrument} dialogClassName="fullSize" onHide={this.closeInstrument}>
                    <Modal.Body>
                        <Instrument onClose={this.closeInstrument} />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

InstrumentListPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  instruments: PropTypes.array.isRequired,
  getInstruments: PropTypes.func.isRequired,
  getInstrument: PropTypes.func.isRequired,
  exportInstruments: PropTypes.func.isRequired,
  addInstrument: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return { 
      userSetting: state.usersetting,
      instruments: state.instruments.all 
    };
}

export default connect(mapStateToProps, actions)(InstrumentListPage);
