import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import { DateInput } from "@blueprintjs/datetime";
import moment from 'moment';

const repeatNumbers = [];
let item = {};
for (let i = 1; i < 31; i++) { 
    item = { value: i, label: i };
    repeatNumbers.push(item);
}

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
  <div>
      <DropdownList {...input} {...rest} onBlur={() => input.change} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderDatePicker = ({ input, minDate, meta: { touched, error } }) => (
  <div>
      <DateInput value={input.value} onChange={input.onChange} format={'DD MMM YYYY'} minDate={minDate} maxDate={moment(minDate).add(1, 'year').toDate()} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const CloneWeekForm = (props) => {
    const { handleSubmit, submitting, onClose, initialValues } = props;

    return (
        <div className="basic-form">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                      <div className="row">
                      <div className="col-sm-3">
                          <label>Clone to Week</label>
                      </div>
                      <div className="col-sm-7">
                        <Field name="cloneDate" type="text"component={renderDatePicker} minDate={initialValues.cloneDate} />
                      </div>
                  </div>
              </div>

              <div className="form-group">
                  <div className="row">
                      <div className="col-sm-3">
                          <label>Recurring</label>
                      </div>
                      <div className="col-sm-4">
                          <Field name="frequency" type="text" simpleValue={true} component={renderDropdownList} options={[{ value: 7, label: 'Weekly'}, { value: 14, label: 'Fortnightly'}]} />
                      </div>
                      <div className="col-sm-3">
                          <Field name="repeat" type="text" simpleValue={true} component={renderDropdownList} options={repeatNumbers} />
                      </div>
                  </div>
              </div>
                <hr />
                <div className="row">
                  <div className="col-sm-12">
                    <button type="submit" className="btn btn-primary rounded" disabled={submitting}>Clone Week</button>&nbsp;
                    <button type="button" className="btn btn-default rounded" onClick={() => onClose()}>Cancel</button>
                  </div>
                </div>
        </form>
    </div>
    );
};

CloneWeekForm.propTypes = {
    ...propTypes
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

renderDatePicker.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  minDate: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'cloneWeekForm'
})(CloneWeekForm);