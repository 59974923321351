import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import ImportUpload from './upload-import';
import ImportList from './import-list';

class ImportPage extends Component {

    constructor(props, context) {
        super(props, context);
                
        this.uploadFile = this.uploadFile.bind(this);
    }

    uploadFile(file) {
        this.props.uploadImport(file, this.props.userSetting.schoolId);
    }

     render() {
        return(
            <div>
                <div className="pageheader"><h2>Import</h2></div>
                <section id="main-content">
                        <div className="row">
                            <div className="col-md-12 col-lg-11">
                                <div className="panel">
                                    <div className="panel-body">
                                        <ImportUpload onDrop={this.uploadFile} />
                                        <ImportList />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
        );
    }
}

ImportPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  uploadImport: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        userSetting: state.usersetting
    };
}

export default connect(mapStateToProps, actions)(ImportPage);
