import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { DropdownList } from 'react-widgets';
import { required } from '../common/validation';

const instrumentType = [ 'Brass', 'Other', 'Percussion', 'Stringed', 'Wind' ];

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
  <div>
    <DropdownList className="form-control space-5" {...input} {...rest}/>
    {touched && error && <h5><span className="label label-warning pull-right">{error}</span></h5>}
  </div>);

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
      <input className="form-control space-5" {...input} type={type} placeholder={label}/>
      {touched && error && <h5><span className="label label-warning pull-right">{error}</span></h5>}
  </div>
);

const InstrumentForm = (props) => {
    
    const { handleSubmit, initialValues, submitting } = props;
   
    return (
        <div className="basic-form">
          <form onSubmit={handleSubmit}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-4">
                      <label>Instrument</label>
                  </div>
                  <div className="col-sm-6">
                      <Field name="label" type="text" component={renderField} label="Instrument" validate={required}/>
                  </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                  <div className="col-sm-4">
                      <label>Instrument Type</label>
                  </div>
                  <div className="col-sm-5">
                      <Field name="instrumentType" type="text" component={renderDropdownList} data={instrumentType} validate={required} />
                  </div>
              </div>
            </div>
              
            <hr />
            <button type="submit" disabled={submitting} className="btn btn-primary">{(initialValues.label) ? 'Save' : 'Add'}</button>
          </form>
        </div>
    );
};

InstrumentForm.propTypes = {
    ...propTypes
};

renderDropdownList.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

renderField.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'instrumentList'
})(InstrumentForm);