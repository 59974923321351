import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';

const renderField = ({ input, label, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type="text" placeholder={label} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const FolderForm = (props) => {
    const { handleSubmit, submitting, initialValues, closeFolder, removeFolder } = props;

    return (
      <div className="basic-form">
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <div className="row">
                    <div className="col-sm-2">
                        <label>Folder</label>
                    </div>
                    <div className="col-sm-10">
                        <Field name="label" type="text" component={renderField} label="Folder"/>
                    </div>
                </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-8">
                  <button type="submit" className="btn btn-sm btn-primary rounded" disabled={submitting}>{(initialValues.label) ? 'Update' : 'Add'}</button>&nbsp;&nbsp;
                  <button type="button" className="btn btn-sm btn-default rounded" onClick={() => closeFolder()}>Cancel</button>
              </div>
              <div className="col-sm-4 text-right">
                  {(initialValues.label && initialValues.childNodes.length === 0) && <button type="button" className="btn btn-sm btn-warning rounded" onClick={() => removeFolder(initialValues.id)}>Remove</button>}
              </div>
            </div>
        </form>
      </div>
    );
};

FolderForm.propTypes = {
    ...propTypes
};

renderField.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'folderForm'
})(FolderForm);