import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { Multiselect } from 'react-widgets';
import { Checkbox } from 'react-icheck';
import FormEditor from '../editor/FormEditor';
import { required } from '../common/validation';

const renderEditor = ({ input, onQuestionText }) => (
  <FormEditor
    value={input.value}
    handleOnChange={input.onChange}
    variables={[
      '[Parent_First]',
      '[Parent_Last]',
      '[Student_First]',
      '[Student_Last]',
      '[Student_Grade]',
      '[Student_Instruments]',
      '[Student_Groups]',
      '[School_No]',
      '[Model]',
      '[Make]',
      '[Serial_No]',
      '[Current_Value]',
    ]}
    onBlur={onQuestionText(input.value)}
  />
);
const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    {label ? <label>{label}</label> : null}
    <div className='margin-top-half'>
      <input className='form-control' {...input} type={type} placeholder={label} />
      {touched && error && (
        <h5>
          <span className='label label-warning pull-right'>{error}</span>
        </h5>
      )}
    </div>
  </div>
);

const renderCheckbox = ({ input, name }) => (
  <Checkbox
    checkboxClass='icheckbox_square-blue'
    increaseArea='20%'
    name={name}
    onChange={input.onChange}
    defaultChecked={input.value ? true : false}
  />
);

const renderMultiselect = ({ input, meta: { touched, error }, ...rest }) => (
  <div>
    <Multiselect
      {...input}
      onBlur={() => input.onBlur()}
      onCreate={(tag) => {
        input.onChange(input.value ? input.value.concat(tag) : [tag]);
      }}
      value={input.value || []} // requires value to be an array
      className='form-multiselect'
      {...rest}
    />
    {touched && error && (
      <h5>
        <span className='label label-warning pull-right'>{error}</span>
      </h5>
    )}
  </div>
);

const question = () => (
  <div className='col-md-12'>
    <div className='form-group'>
      <Field name='questionText' type='text' component={renderField} label='Question' validate={required} />
    </div>
    <div className='form-group'>
      <Field name='required' component={renderCheckbox} />
      &nbsp; <span>An answer to this question is required</span>
    </div>
  </div>
);

const checkox = () => (
  <div className='col-md-12'>
    <div className='row'>
      <div className='col-sm-9 padding-top-half text-right'>
        <label>Grid Header</label>
      </div>
      <div className='col-sm-3'>
        <Field name='commentText' type='text' component={renderField} />
      </div>
    </div>
    <div className='row'>
      <div className='col-md-12'>
        <div className='form-group'>
          <Field
            name='questionText'
            type='text'
            component={renderField}
            label='Text displayed next to checkbox'
            validate={required}
          />
        </div>
        <div className='form-group'>
          <Field name='required' component={renderCheckbox} />
          &nbsp; <span>Checkbox must be checked</span>
        </div>
      </div>
    </div>
  </div>
);

const header = () => (
  <div className='col-md-12'>
    <div className='form-group'>
      <Field name='questionText' type='text' component={renderField} label='Header' validate={required} />
    </div>
    <div className='form-group'>
      <Field name='required' component={renderCheckbox} />
      &nbsp; <span>Place line under header</span>
    </div>
  </div>
);

const infoText = (onQuestionText) => (
  <div>
    <div className='col-md-12'>
      <label>Info Text</label>
    </div>
    <div className='col-md-12 padding-top-one'>
      <Field name='questionText' type='textarea' onQuestionText={onQuestionText} component={renderEditor} />
    </div>
    <div className='padding-top-one'>&nbsp;</div>
  </div>
);

const selectionList = () => (
  <div className='col-md-12'>
    <div className='form-group'>
      <Field name='questionText' type='text' component={renderField} label='Question' validate={required} />
    </div>
    <div className='form-group'>
      <label>Answer Options</label>
      <span className='text-muted small'> (Enter each option followed by 'Enter')</span>
      <div className='margin-top-half'>
        <Field name='answers' component={renderMultiselect} validate={required} />
      </div>
      <div className='form-group'>
        <Field name='multiOptions' component={renderCheckbox} />
        &nbsp; <span>Allow muliple options to selected</span>
      </div>
      <div className='form-group'>
        <Field name='required' component={renderCheckbox} />
        &nbsp; <span>An answer to this question is required</span>
      </div>
      <hr />
      <div className='form-group'>
        <Field name='commentAllowed' component={renderCheckbox} />
        &nbsp; <span>Allow comments for this question</span>
      </div>

      <div className='form-group'>
        <Field name='commentText' type='text' component={renderField} label='Comment Instructions' />
      </div>
    </div>
  </div>
);

const FormItemForm = (props) => {
  const { handleSubmit, submitting, formType, onQuestionText, onClose, removeItem, initialValues } = props;

  return (
    <div className='form-padding'>
      <form onSubmit={handleSubmit}>
        <div className='row'>
          {formType === 'Question' && question()}
          {formType === 'Text' && infoText(onQuestionText)}
          {formType === 'Selection' && selectionList()}
          {formType === 'Checkbox' && checkox()}
          {formType === 'Heading' && header()}
        </div>
        <hr />
        <div className='row'>
          <div className='col-sm-8'>
            <button type='submit' className='btn btn-primary rounded' disabled={submitting}>
              Save
            </button>
            &nbsp;
            <button type='button' className='btn btn-default rounded' onClick={() => onClose()}>
              Close
            </button>
          </div>
          <div className='col-sm-4 text-right'>
            {initialValues._id && (
              <button type='button' className='btn btn-warning rounded' onClick={() => removeItem(initialValues._id)}>
                Remove
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

FormItemForm.propTypes = {
  ...propTypes,
};

renderField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

renderMultiselect.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
  name: PropTypes.string,
};

renderEditor.propTypes = {
  input: PropTypes.object.isRequired,
  onQuestionText: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'FormItemForm',
})(FormItemForm);
