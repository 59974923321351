import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import * as actions from '../../actions';
import { Modal } from 'react-bootstrap';
import EmailForm from './email-form';
import EmailList from './email-list';
import EmailTemplates from './template-list';
import AttachDocument from './document-tabs';
import Preview from './preview';
import SaveTemplate from './save-template-modal';
import { ROOT_URL } from '../../actions/types';

class SendEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedStudents: this.creatEmailList(),
      emailBody: '',
      emailStatus: 'Send Email',
      modalHeading: '',
      showModal: false,
      showPreview: false,
      showAttachment: false,
      showTemplate: false,
      email: { emailBody: '' },
    };

    this.sendEmails = this.sendEmails.bind(this);
    this.setEmailBody = this.setEmailBody.bind(this);
    this.updateList = this.updateList.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openPreview = this.openPreview.bind(this);
    this.closePreview = this.closePreview.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.selectTemplate = this.selectTemplate.bind(this);
  }

  componentDidMount() {
    this.props.clearEmail();
    if (!this.props.userSetting.schoolId) {
      this.props.reload('/students');
    } else {
      if (!this.props.contacts.length) this.props.getContacts(this.props.userSetting.schoolId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.email.emailStatus !== this.props.email.emailStatus) {
      this.setState({ emailStatus: nextProps.email.emailStatus });
    }
  }

  sendEmails(values) {
    this.setState({ emailStatus: 'Sending...' });
    const email = {
      emailFrom: values.fromEmail,
      emailSubject: values.subject,
      emailBody: values.emailBody,
      attachments: this.props.email.attachments,
      emailList: this.state.selectedStudents,
      bccList: values.bccList,
    };
    this.props.SendEmail(email, this.props.userSetting.schoolId);
  }

  setEmailBody(emailBody) {
    this.setState({ emailBody });
    this.props.resetEmailButton();
  }

  openModal(modalHeading) {
    this.setState({ showModal: true, modalHeading });
  }

  closeModal() {
    this.setState({ showModal: false, modalHeading: '', uploaded: 0 });
  }

  openPreview() {
    this.setState({ showPreview: true });
  }

  closePreview() {
    this.setState({ showPreview: false });
  }

  // uploadFile(file) {
  //     this.props.uploadFile(file, this.props.userSetting.schoolId);
  //     this.closeModal();
  // }

  deleteAttachment(index) {
    this.props.removeAttachment(index);
  }

  selectTemplate(template) {
    this.props.clearAttachment();
    template.attachments.forEach((file) => this.props.addAttachment({ data: { _id: file.id, label: file.label } }));
    this.setState({ email: template, emailBody: template.emailBody });
  }

  creatEmailList() {
    const _emailList = [];
    for (let item of this.props.emailList) {
      _emailList.push(item._id);
    }
    return _emailList;
  }

  updateList(values) {
    if (values.status) {
      this.setState({ selectedStudents: this.state.selectedStudents.concat(values.rows.map((r) => r.row._id)) });
    } else {
      let rows = values.rows.map((r) => r.row._id);
      this.setState({ selectedStudents: this.state.selectedStudents.filter((i) => rows.indexOf(i) === -1) });
    }
  }

  uploadFile(files, rejected) {
    if (rejected.length > 0) {
      this.props.displayWarning('Maxium size per file is: 10Mb');
    } else {
      const config = {
        headers: { authorization: localStorage.getItem('token'), 'content-type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const uploaded = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          this.setState({ uploaded });
          if (uploaded === 100) {
            setTimeout(() => this.closeModal(), 2000);
          }
        },
      };

      const data = new FormData();
      files.forEach((file) => {
        data.append('file', file);
        data.append('name', this.props.userSetting.schoolId);
        data.append('label', 'Attachments');
      });
      axios.post(`${ROOT_URL}/upload-file/${this.props.userSetting.schoolId}`, data, config).then((response) => {
        this.props.addAttachment(response);
      });
    }
  }

  render() {
    return (
      <div>
        <div className='pageheader'>
          <h2>Send Emails</h2>
        </div>
        <section id='main-content'>
          <div className='row'>
            <div className='col-md-12 col-lg-12'>
              <div className='panel panel-success'>
                <div className='panel-heading'>Email Details</div>
                <div className='panel-body'>
                  <EmailForm
                    onSubmit={this.sendEmails}
                    fromEmails={this.props.fromEmails}
                    onEmailBody={this.setEmailBody}
                    initialValues={this.state.email}
                    attachments={this.props.email.attachments}
                    openModal={this.openModal}
                    closeModal={this.closeModal}
                    openPreview={this.openPreview}
                    onRemoveAttachment={this.deleteAttachment}
                    onTemplate={this.openTemplate}
                    emailStatus={this.state.emailStatus}
                    emailNumber={this.state.selectedStudents.length}
                    contactList={this.props.contacts}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12 col-lg-12'>
              <div className='panel panel-primary'>
                <div className='panel-heading'>Send Email To</div>
                <div className='panel-body'>
                  <EmailList selectedStudents={this.updateList} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header className='modal-header primary' closeButton>
            <Modal.Title>{this.state.modalHeading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.modalHeading === 'Attachments' && (
              <AttachDocument
                uploadFile={this.uploadFile}
                closeModal={this.closeModal}
                uploadProgress={this.state.uploaded}
              />
            )}
            {this.state.modalHeading === 'Templates' && (
              <EmailTemplates onSelect={this.selectTemplate} closeModal={this.closeModal} />
            )}
            {this.state.modalHeading === 'Save as Template' && (
              <SaveTemplate
                onSubmit={this.saveTemplate}
                closeModal={this.closeModal}
                initialValues={{
                  emailBody: this.state.emailBody,
                  subject:
                    this.props.emailForm && this.props.emailForm.values.subject
                      ? this.props.emailForm.values.subject
                      : '',
                }}
              />
            )}
          </Modal.Body>
        </Modal>

        <Modal show={this.state.showPreview} bsSize='lg' onHide={this.closePreview}>
          <Modal.Header className='modal-header primary' closeButton>
            <Modal.Title>Email Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Preview
              sendPreview={this.sendPreview}
              studentId={this.props.emailList[0]._id}
              email={this.state.emailBody}
              contactList={this.props.contacts}
              closeModal={this.closePreview}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

SendEmail.propTypes = {
  userSetting: PropTypes.object.isRequired,
  fromEmails: PropTypes.array.isRequired,
  emailForm: PropTypes.object,
  email: PropTypes.object.isRequired,
  SendEmail: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  removeAttachment: PropTypes.func.isRequired,
  emailList: PropTypes.array.isRequired,
  contacts: PropTypes.array.isRequired,
  reload: PropTypes.func.isRequired,
  clearEmail: PropTypes.func.isRequired,
  getContacts: PropTypes.func.isRequired,
  saveTemplate: PropTypes.func.isRequired,
  displayWarning: PropTypes.func.isRequired,
  addAttachment: PropTypes.func.isRequired,
  clearAttachment: PropTypes.func.isRequired,
  resetEmailButton: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    userSetting: state.usersetting,
    fromEmails: state.usersetting.profile.fromEmails,
    email: state.email,
    emailList: state.students.emailList,
    contacts: state.contacts.all,
    emailForm: state.form.emailForm,
  };
}

export default connect(mapStateToProps, actions)(SendEmail);
