import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/instruments';
import ReactDataGrid from 'react-data-grid';
import moment from 'moment';
import { Data } from 'react-data-grid-addons';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Instrument from './instrument-page';
import ServiceCentres from './service-centre-page';
import EmptyToolbar from '../common/grid-toolbar';

const Selectors = Data.Selectors;

class InstrumentServicePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndexes: [],
      rows: this.props.instruments
        .filter((item) => item.instrumentStatus === 'In-Service')
        .map((item) => {
          const instrument = _.pick(item, ['_id', 'instrument', 'manufacturer', 'serialNo', 'schoolNo']);
          instrument.dateSent = moment(item.serviceDetails.dateSent).format('ll');
          instrument.serviceCentre = item.serviceDetails.serviceCentre;
          return instrument;
        }),
      filters: {},
      sortColumn: null,
      sortDirection: null,
      showModal: false,
      modalHeader: '',
      showInstrument: false,
    };

    this.rowGetter = this.rowGetter.bind(this);
    this.onRowsSelected = this.onRowsSelected.bind(this);
    this.onRowsDeselected = this.onRowsDeselected.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.getRows = this.getRows.bind(this);
    this.getSize = this.getSize.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.onClearFilters = this.onClearFilters.bind(this);
    this.handleGridSort = this.handleGridSort.bind(this);
    this.exportInstruments = this.exportInstruments.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeInstrument = this.closeInstrument.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.instruments !== this.props.instruments) {
      this.setState({
        rows: nextProps.instruments
          .filter((item) => item.instrumentStatus === 'In-Service')
          .map((item) => {
            const instrument = _.pick(item, ['_id', 'instrument', 'manufacturer', 'serialNo', 'schoolNo']);
            instrument.dateSent = moment(item.serviceDetails.dateSent).format('ll');
            instrument.serviceCentre = item.serviceDetails.serviceCentre;
            return instrument;
          }),
      });
    }
  }

  exportInstruments() {
    //     let exportList = [];
    //     if (this.state.selectedIndexes.length === 0) {
    //         exportList = this.state.rows.map(row => row._id);
    //     } else {
    //         exportList = this.state.selectedIndexes.map(index => this.state.rows[index]._id);
    //     }
    //   //  this.props.exportInstruments(exportList, this.props.userSetting.schoolId);
  }

  openModal(modalHeader) {
    this.setState({ showModal: true, modalHeader });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  rowGetter(i) {
    const rows = this.getRows();
    return rows[i];
  }

  onRowsSelected(rows) {
    this.setState({ selectedIndexes: this.state.selectedIndexes.concat(rows.map((r) => r.rowIdx)) });
  }

  onRowsDeselected(rows) {
    let rowIndexes = rows.map((r) => r.rowIdx);
    this.setState({ selectedIndexes: this.state.selectedIndexes.filter((i) => rowIndexes.indexOf(i) === -1) });
  }

  onRowClick(rowIdx, row) {
    if (row) {
      this.props.getInstrument(row._id, () => this.setState({ showInstrument: true }));
    }
  }

  closeInstrument() {
    this.setState({ showInstrument: false });
  }

  getRows() {
    return Selectors.getRows(this.state);
  }

  getSize() {
    return this.getRows().length;
  }

  handleFilterChange(filter) {
    const newFilters = Object.assign({}, this.state.filters);
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
    this.setState({ filters: newFilters });
  }

  onClearFilters() {
    //all filters removed
    this.setState({ filters: {} });
  }

  handleGridSort(sortColumn, sortDirection) {
    const state = Object.assign({}, this.state, { sortColumn: sortColumn, sortDirection: sortDirection });
    this.setState(state);
  }

  render() {
    const columns = [
      {
        key: 'instrument',
        name: 'Instrument',
        sortable: true,
        filterable: true,
      },
      {
        key: 'manufacturer',
        name: 'Make',
        sortable: true,
        filterable: true,
      },
      {
        key: 'serialNo',
        name: 'Serial No',
        sortable: true,
        filterable: true,
      },
      {
        key: 'schoolNo',
        name: 'School No',
        sortable: true,
        filterable: true,
      },
      {
        key: 'dateSent',
        name: 'Date Sent',
        sortable: true,
        filterable: true,
      },
      {
        key: 'serviceCentre',
        name: 'Service Centre',
        sortable: true,
        filterable: true,
      },
    ];

    return (
      <div>
        <div className='pageheader'>
          <h2>Instruments</h2>
        </div>
        <section id='main-content'>
          <div className='row'>
            <div className='col-md-12 col-lg-12'>
              <div className='panel panel-default'>
                <div className='panel-body'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='pt-button-group'>
                        <Link to='/instruments' className='pt-button pt-intent-primary'>
                          Instruments
                        </Link>
                        <Link to='/hired' className='pt-button pt-intent-primary'>
                          Hired
                        </Link>
                        <div className='pt-button pt-intent-primary pt-active' role='button'>
                          In Service
                        </div>
                        <Link to='/instrument-archive' className='pt-button pt-intent-primary'>
                          Archive
                        </Link>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='text-right'>
                        <button
                          type='button'
                          className='btn btn-xs btn-success rounded'
                          onClick={() => this.openModal('Service Centres')}
                        >
                          Service Centres
                        </button>
                        &nbsp;&nbsp;
                        <button
                          type='button'
                          className='btn btn-xs btn-default rounded'
                          onClick={() => this.openModal('Export')}
                        >
                          Export
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='col-xs-12'>
                    <div>
                      <ReactDataGrid
                        rowKey='_id'
                        onGridSort={this.handleGridSort}
                        columns={columns}
                        rowGetter={this.rowGetter}
                        rowsCount={this.getSize()}
                        minHeight={this.getSize() * 35 + 85}
                        onRowClick={this.onRowClick}
                        toolbar={<EmptyToolbar />}
                        onAddFilter={this.handleFilterChange}
                        onClearFilters={this.onClearFilters}
                        rowSelection={{
                          showCheckbox: true,
                          enableShiftSelect: true,
                          onRowsSelected: this.onRowsSelected,
                          onRowsDeselected: this.onRowsDeselected,
                          selectBy: {
                            indexes: this.state.selectedIndexes,
                          },
                        }}
                      />
                    </div>
                    <div className='admin-label text-right margin-top-quarter'>
                      <i className='blueBG'>
                        {' '}
                        {this.getSize() === this.state.rows.length
                          ? `${this.state.rows.length} Instruments`
                          : `Showing ${this.getSize()} of ${this.state.rows.length} Instruments`}{' '}
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header className='modal-header primary' closeButton>
            <Modal.Title>{this.state.modalHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-padding'>
              {this.state.modalHeader === 'Service Centres' && <ServiceCentres closeModal={this.closeModal} />}
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.showInstrument} dialogClassName='fullSize' onHide={this.closeInstrument}>
          <Modal.Body>
            <Instrument onClose={this.closeInstrument} openTab='service' />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

InstrumentServicePage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  instruments: PropTypes.array.isRequired,
  getInstrument: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    userSetting: state.usersetting,
    instruments: state.instruments.all,
  };
}

export default connect(mapStateToProps, actions)(InstrumentServicePage);
