import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import { Checkbox } from '@blueprintjs/core';
import FormEditor from '../editor/FormEditor';

const renderCheckbox = ({ input }) => (
  <label className='pt-control pt-checkbox pt-large'>
    <Checkbox checked={input.value} onChange={input.onChange} />
  </label>
);

const renderEditor = ({ input, onEnrolmentText }) => (
  <FormEditor value={input.value} handleOnChange={input.onChange} onBlur={onEnrolmentText(input.value)} />
);

const renderDropdownList = ({ input, ...rest }) => <DropdownList {...input} {...rest} onBlur={() => input.change} />;

const EnrolmentForm = (props) => {
  const { handleSubmit, submitting, groupList, onEnrolmentText } = props;

  return (
    <div className='advance-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <div className='col-sm-3'>
            <label className='text-right'>Groups</label>
          </div>
          <div className='col-sm-9'>
            <div className='form-group'>
              <Field
                name='ensembleList'
                type='text'
                component={renderDropdownList}
                simpleValue={true}
                multi={true}
                options={groupList}
                labelKey='name'
                valueKey='_id'
              />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='col-sm-3'>
            <label className='text-right'>Enrolment Info</label>
          </div>
          <div className='col-sm-9'>
            <Field name='enrolmentText' type='textarea' onEnrolmentText={onEnrolmentText} component={renderEditor} />
          </div>
          <div className='clearfix'>&nbsp;</div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label className='text-right'>Activate Form</label>
            </div>
            <div className='col-sm-5 margin-top-quarter'>
              <Field name='enrolmentActive' component={renderCheckbox} />
            </div>
            <div className='col-sm-4 text-right'>
              <button type='submit' disabled={submitting} className='btn btn-primary rounded'>
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

EnrolmentForm.propTypes = {
  ...propTypes,
};

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
};

renderEditor.propTypes = {
  input: PropTypes.object.isRequired,
  onEnrolmentText: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'EnrolmentForm',
})(EnrolmentForm);
