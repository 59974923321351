import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import * as actions from '../../actions/forms';
import Enrolment from './enrolment-form';

class EnrolmentPage extends Component {

    constructor(props, context) {
        super(props, context);

         this.state = {
            enrolmentText: (this.props.enrolment) ? this.props.enrolment.enrolmentText : ''
        };

        this.saveEnrolment = this.saveEnrolment.bind(this);
        this.setEnrolmentText = this.setEnrolmentText.bind(this);
    }

    componentDidMount() {
      this.props.getForms(this.props.userSetting.schoolId);
    }

    saveEnrolment(values) {
      const enrolmentForm = _.pick(values, ['ensembleList', 'enrolmentActive']);
      enrolmentForm.enrolmentText = this.state.enrolmentText;
      this.props.saveEnrolment(enrolmentForm, this.props.userSetting.schoolId);
    }

    setEnrolmentText(enrolmentText) {
      this.setState({enrolmentText});
    }
   
    render() {
      const { groups, enrolment } = this.props;

        return (
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-default">
                    <div className="panel-body">
                      <Enrolment
                          onSubmit={this.saveEnrolment} 
                          groupList={groups} 
                          initialValues={enrolment}
                          onEnrolmentText={this.setEnrolmentText} />
                    </div>
                  </div>
                </div>
            </div>
        );
    }
}

EnrolmentPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  getForms: PropTypes.func.isRequired,
  saveEnrolment: PropTypes.func.isRequired,
  enrolment: PropTypes.object,
  groups: PropTypes.array.isRequired,
};



function mapStateToProps(state) {

    return {
      userSetting: state.usersetting,
      groups: state.school.groups,
      enrolment: state.forms.enrolment
    };
}

export default connect(mapStateToProps, actions)(EnrolmentPage);