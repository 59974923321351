import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import DatePicker from 'react-datepicker';
import { Checkbox } from 'react-icheck';
import moment from 'moment';
import { required } from '../common/validation';
import 'react-datepicker/dist/react-datepicker.css';

const statusTypes = [
  { label: 'Available' },
  { label: 'For Sale' },
  { label: 'Hired', disabled: true },
  { label: 'In-Service', disabled: true },
  { label: 'In-Store' },
  { label: 'Missing' },
  { label: 'Retired' },
  { label: 'Needs Repair' },
  { label: 'Clean & Service' },
  { label: 'School' },
  { label: 'Scrap' },
  { label: 'Spare' },
  { label: 'Sold' },
  { label: 'Lost / Stolen' },
  { label: 'On Loan' },
  { label: 'Unknown' },
];

const renderCheckbox = ({ input }) => (
  <Checkbox
    checkboxClass='icheckbox_square-blue'
    increaseArea='20%'
    onChange={input.onChange}
    defaultChecked={input.value ? true : false}
  />
);

const renderDropdownList = ({ input, ...rest }) => <DropdownList {...input} {...rest} onBlur={() => input.change} />;

const renderDatePicker = ({ input: { onChange, value } }) => (
  <DatePicker
    className='form-control'
    selected={!value ? null : moment(value)}
    dateFormat='DD MMM YYYY'
    onChange={onChange}
  />
);

const renderField = ({ input, label, type, tabIndex, meta: { touched, error } }) => (
  <div>
    <input className='form-control' {...input} type={type} placeholder={label} tabIndex={tabIndex} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderCurrencyField = ({ input, label, type, meta: { touched, error } }) => (
  <div className='input-group'>
    <span className='input-group-addon'>$</span>
    <input className='form-control' {...input} type={type} placeholder={label} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const InstrumentForm = (props) => {
  const { handleSubmit, submitting, initialValues, isHired, inService, studentList, onClose } = props;

  return (
    <div className='basic-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>Instrument</label>
              </div>
              <div className='col-sm-8'>
                <Field name='instrument' type='text' component={renderField} validate={required} />
              </div>
            </div>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>Status</label>
              </div>
              <div className='col-sm-8'>
                <Field
                  name='instrumentStatus'
                  type='text'
                  simpleValue={true}
                  valueKey='label'
                  clearable={false}
                  disabled={isHired || inService}
                  component={renderDropdownList}
                  options={statusTypes}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>Manufacturer</label>
              </div>
              <div className='col-sm-8'>
                <Field name='manufacturer' type='text' component={renderField} />
              </div>
            </div>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>Model</label>
              </div>
              <div className='col-sm-8'>
                <Field name='model' type='text' component={renderField} />
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>Serial No</label>
              </div>
              <div className='col-sm-8'>
                <Field name='serialNo' type='text' component={renderField} validate={required} />
              </div>
            </div>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>School No</label>
              </div>
              <div className='col-sm-8'>
                <Field name='schoolNo' type='text' component={renderField} />
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>Other Details</label>
              </div>
              <div className='col-sm-8'>
                <Field name='accessories' type='text' component={renderField} />
              </div>
            </div>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>Location</label>
              </div>
              <div className='col-sm-8'>
                <Field name='location' type='text' component={renderField} />
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>Bought From</label>
              </div>
              <div className='col-sm-8'>
                <Field name='purchaseDetails.purchasedFrom' type='text' component={renderField} />
              </div>
            </div>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>Purchase Date</label>
              </div>
              <div className='col-sm-8'>
                <Field name='purchaseDetails.purchasedDate' type='text' component={renderDatePicker} />
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>Purchase Price</label>
              </div>
              <div className='col-sm-8'>
                <Field name='purchaseDetails.purchasedPrice' type='text' component={renderCurrencyField} />
              </div>
            </div>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>Warranty</label>
              </div>
              <div className='col-sm-8'>
                <Field name='purchaseDetails.warranty' type='text' component={renderField} />
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>Current Value</label>
              </div>
              <div className='col-sm-8'>
                <Field name='currentValue' type='text' component={renderCurrencyField} />
              </div>
            </div>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>First Hired</label>
              </div>
              <div className='col-sm-8'>
                <Field name='firstHired' type='text' component={renderDatePicker} />
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>Last Hired</label>
              </div>
              <div className='col-sm-8'>
                <Field name='lastHireDate' type='text' component={renderDatePicker} />
              </div>
            </div>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>Reserved For</label>
              </div>
              <div className='col-sm-8'>
                <Field
                  name='reservedFor'
                  type='text'
                  valueKey='studentId'
                  component={renderDropdownList}
                  options={studentList}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>Last Serviced</label>
              </div>
              <div className='col-sm-8'>
                <Field name='lastServiceDate' type='text' component={renderDatePicker} />
              </div>
            </div>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-5'>
                <label>Needs Service</label>
              </div>
              <div className='col-sm-7'>
                <Field name='requireService' component={renderCheckbox} />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className='row'>
          <div className='col-sm-6'>
            <button type='submit' className='btn btn-primary rounded' disabled={submitting}>
              {initialValues ? 'Save' : 'Add'}
            </button>
            &nbsp;&nbsp;
            <button type='button' className='btn btn-trans rounded' onClick={onClose}>
              Close
            </button>
          </div>
          <div className='col-sm-6 text-right'>
            {!initialValues.active && (
              <button type='button' className='btn btn-danger rounded' onClick={props.onDelete}>
                Delete
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

InstrumentForm.propTypes = {
  ...propTypes,
};

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
};

renderField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  tabIndex: PropTypes.string,
  meta: PropTypes.object.isRequired,
};

renderCurrencyField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
};

renderDatePicker.propTypes = {
  input: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'InstrumentForm',
  enableReinitialize: true,
})(InstrumentForm);
