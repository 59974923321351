import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer  } from 'react-big-calendar'
import { Modal } from 'react-bootstrap';
import * as actions from '../../actions/events';
import moment from 'moment';
import { connect } from 'react-redux';
import EventForm from './event-form';
import UpdateAll from './update-all-form';
import RemoveAll from './remove-all-form';
import '../../styles/css/react-big-calendar.css';

const localizer = momentLocalizer(moment)

class CalendarPage extends Component {
 
    constructor(props, context) {
        super(props, context);
        this.state = {
            showEvent: false,
            showRemove: false,
            showUpdate: false,
            updateEvent: {},
            displayEvents: []
        };

        this.openEvent = this.openEvent.bind(this);
        this.closeEvent = this.closeEvent.bind(this);
        this.addEventDates = this.addEventDates.bind(this);
        this.saveEvent = this.saveEvent.bind(this);
        this.openRemove = this.openRemove.bind(this);
        this.closeRemove = this.closeRemove.bind(this);
        this.closeUpdate = this.closeUpdate.bind(this);
        this.removeEvent = this.removeEvent.bind(this);
        this.updateEvent = this.updateEvent.bind(this);
    }

    componentDidMount() {
        if (!this.props.userSetting.schoolId) {
            this.props.reload(this.props.location.pathname, this.props.history);
        } else {
            this.props.getEvents(this.props.userSetting.schoolId, this.props.history);
        }
  }

    openEvent(event) {
            this.props.selectEvent(event);
            this.setState({ showEvent: true });
   }

    closeEvent() {
        this.setState({ showEvent: false });
    }

    addEventDates(slotInfo) {
            const event = { 
                eventStart: slotInfo.start,
                eventEnd: slotInfo.end,
                isNew: true
            };
            this.openEvent(event);
    }

    saveEvent(event) {
        const eventDetails = (!event.groups || event.groups.length === 0)
            ? Object.assign({}, event, {groups: this.props.groups.map(item => item._id).toString()})
            : event;
        if (eventDetails._id) {
            this.closeEvent();

            if (eventDetails.recurring) {
                this.setState({ showUpdate: true, updateEvent: eventDetails });
            } else {
                this.props.updateEvent(eventDetails, false);
            }
            
        } else {
            this.props.addEvent(eventDetails, this.props.userSetting.schoolId);
            this.closeEvent();
        }
    }

   openRemove() {
        this.closeEvent();
        this.setState({ showRemove: true });
   }

   closeRemove() {
        this.setState({ showRemove: false });
    }

   closeUpdate() {
        this.setState({ showUpdate: false });
    }


   updateEvent(option) {
       this.props.updateEvent(this.state.updateEvent, option.updateAll);
       this.closeUpdate();

   }

   removeEvent(option) {
       this.props.removeEvent(this.props.events.selectedEvent._id, option.removeAll);
       this.closeRemove();
   }

    render() {
        const { events, groups } = this.props;

        return (
            <div>
                <div className="pageheader">
                <h2>Events</h2>
                
            </div>
            <section id="main-content">
                <div className="row">
                    <div className="col-md-12 col-lg-12">
                        <div className="panel panel-default">
                            <div className="panel-body ng-binding">
                                    <div className="bigCal veiwCal">
                                        <Calendar
                                            localizer={localizer}
                                            selectable
                                            startAccessor="eventStart"
                                            endAccessor="eventEnd"
                                            titleAccessor="eventTitle"
                                            events={events.all}
                                            min={new Date(2015, 10, 19, 6, 0, 0)}
                                            max={new Date(2015, 10, 19, 21, 0, 0)}
                                            defaultView="month"
                                            components={{event: this.eventFormat}}
                                            onSelectEvent={event => this.openEvent(event)}
                                            onSelectSlot={(slotInfo) => this.addEventDates(slotInfo)}
                                            eventPropGetter={(event) => ({style: { backgroundColor: event.eventColour }})}
                                        />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                <Modal show={this.state.showEvent} onHide={this.closeEvent}>
                    <Modal.Header className="modal-header primary" closeButton>
                        <Modal.Title>Calendar Event</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-padding">
                            <EventForm 
                                onSubmit={this.saveEvent} 
                                initialValues={events.selectedEvent} 
                                onClose={this.closeEvent} 
                                groups={groups} 
                                isNew={(events.selectedEvent && events.selectedEvent.isNew) ? true : false} 
                                removeEvent={this.openRemove} />
                        </div>
                    </Modal.Body>
                </Modal>

                 <Modal show={this.state.showRemove} onHide={this.closeRemove}>
                    <Modal.Header className="modal-header primary" closeButton>
                        <Modal.Title>Remove Calendar Event</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-padding">
                           <RemoveAll onSubmit={this.removeEvent} initialValues={{removeAll: 'false'}}  onClose={this.closeRemove}  />
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showUpdate} onHide={this.closeUpdate}>
                    <Modal.Header className="modal-header primary" closeButton>
                        <Modal.Title>Update Calendar Event</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-padding">
                           <UpdateAll onSubmit={this.updateEvent} onClose={this.closeUpdate} initialValues={{updateAll: 'false'}} />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        );
    }
}

CalendarPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  events: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  selectEvent: PropTypes.func.isRequired,
  addEvent: PropTypes.func.isRequired,
  getEvents: PropTypes.func.isRequired,
  removeEvent: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired
};

function mapStateToProps(state) {

    return {
        userSetting: state.usersetting,
        events: state.events,
        groups: state.school.groups
    };
}

export default connect(mapStateToProps, actions)(CalendarPage);