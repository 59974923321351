import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { Multiselect } from 'react-widgets';
import { Checkbox } from 'react-icheck';

const renderCheckbox = ({ input, name }) => (
  <Checkbox
    checkboxClass='icheckbox_square-blue'
    increaseArea='20%'
    name={name}
    onChange={input.onChange}
    defaultChecked={input.value ? true : false}
  />
);

const renderMultiselect = ({ input, ...rest }) => (
  <Multiselect
    {...input}
    onBlur={() => input.onBlur()}
    onCreate={(tag) => {
      input.onChange(input.value ? input.value.concat(tag) : [tag]);
    }}
    value={input.value || []} // requires value to be an array
    className='form-multiselect'
    {...rest}
  />
);

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <label>{label}</label>
    <div className='margin-top-half'>
      <input className='form-control' {...input} type={type} placeholder={label} />
      {touched && error && (
        <h5>
          <span className='label label-warning pull-right'>{error}</span>
        </h5>
      )}
    </div>
  </div>
);

const isQuestion = (initialValues, onDelete) => {
  if (initialValues)
    return (
      <div className='col-sm-6 text-right'>
        <button type='button' className='btn btn-danger rounded' onClick={() => onDelete(initialValues)}>
          Remove Question
        </button>
      </div>
    );
};

const RegistrationQuestion = (props) => {
  const { handleSubmit, initialValues, onClose, onDelete } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className='form-group'>
        <Field name='questionText' type='text' component={renderField} label='Question' />
      </div>
      <div className='form-group'>
        <label>Answer Options</label>
        <span className='text-muted small'> (Enter each option followed by 'Enter')</span>
        <div className='margin-top-half'>
          <Field name='answers' component={renderMultiselect} />
        </div>
        <div className='form-group'>
          <Field name='multiOptions' component={renderCheckbox} />
          &nbsp; <span>Allow multiple answers to be selected</span>
        </div>
        <div className='form-group'>
          <Field name='required' component={renderCheckbox} />
          &nbsp; <span>An answer to this question is required</span>
        </div>
        <hr />
        <div className='form-group'>
          <Field name='commentAllowed' component={renderCheckbox} />
          &nbsp; <span>Allow comments for this question</span>
        </div>

        <div className='form-group'>
          <Field name='commentText' type='text' component={renderField} label='Comment Instructions' />
        </div>
      </div>
      <hr />
      <div className='row'>
        <div className='col-sm-6'>
          <button type='submit' className='btn btn-primary rounded'>
            {initialValues._id ? 'Save' : 'Add'}
          </button>
          &nbsp;&nbsp;
          <button type='button' className='btn btn-default rounded' onClick={() => onClose()}>
            Cancel
          </button>
        </div>
        {isQuestion(initialValues._id, onDelete)}
      </div>
    </form>
  );
};

RegistrationQuestion.propTypes = {
  ...propTypes,
};

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
  name: PropTypes.string,
};

renderMultiselect.propTypes = {
  input: PropTypes.object.isRequired,
};

renderField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'registrationQuestion',
})(RegistrationQuestion);
