import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "../../actions/invoice";
import moment from "moment";
import { Tab, Col, Row, Nav, NavItem } from "react-bootstrap";
import InvoiceDetailsForm from "./invoice-details-form";
import PaymentForm from "./payment-form";
import Payments from "./invoice-payment";

class InvoiceDetailsPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.removePayment = this.removePayment.bind(this);
    this.addPayment = this.addPayment.bind(this);
    this.updateInvoice = this.updateInvoice.bind(this);
  }

  addPayment(payment) {
    this.props.addPayment(payment, this.props.invoice._id);
    this.props.closeModal();
  }

  removePayment(paymentId) {
    this.props.removePayment(paymentId, this.props.invoice._id);
  }

  updateInvoice(values) {
    const invoice = _.pick(values, ["invoiceItems", "invoiceNumber"]);
    this.props.updateInvoice(invoice, this.props.invoice._id);
  }

  render() {
    const { invoice, accounts, closeModal, viewInvoice } = this.props;

    return (
      <div>
        <Tab.Container id='invoice-tabs' defaultActiveKey='payments'>
          <Row className='clearfix'>
            <Col sm={12}>
              <Nav className='nav nav-tabs'>
                <NavItem eventKey='payments'>Payment Details</NavItem>
                <NavItem eventKey='invoice'>Invoice Details</NavItem>
              </Nav>

              <Tab.Content animation>
                <Tab.Pane eventKey='payments'>
                  <br />
                  Payments
                  <Payments payments={invoice.payments} removePayment={this.removePayment} />
                  <hr />
                  <PaymentForm
                    onSubmit={this.addPayment}
                    closeModal={closeModal}
                    viewInvoice={viewInvoice}
                    initialValues={{ paymentDate: moment().toDate(), amount: invoice.amount }}
                  />
                </Tab.Pane>

                <Tab.Pane eventKey='invoice'>
                  <br />
                  <InvoiceDetailsForm onSubmit={this.updateInvoice} initialValues={invoice} accounts={accounts} closeModal={closeModal} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    );
  }
}

InvoiceDetailsPage.propTypes = {
  accounts: PropTypes.array.isRequired,
  invoice: PropTypes.object.isRequired,
  removePayment: PropTypes.func.isRequired,
  addPayment: PropTypes.func.isRequired,
  updateInvoice: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  viewInvoice: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    accounts: state.school.selectors.accounts,
  };
}

export default connect(mapStateToProps, actions)(InvoiceDetailsPage);
