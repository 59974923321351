import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/auth';
import ResetPasswordForm from './reset-password-form';
import { Link } from "react-router-dom";

class ResetPasswordPage extends Component {

    constructor(props, context) {
        super(props, context);

        this.resetPassword = this.resetPassword.bind(this);

  }

  resetPassword(email) {
      this.props.resetPassword(email);
  }

  render() {
 
      return (
           <div>
                <div className="user-access-page">
                    <div className="row">
                        <div className="col-sm-offset-3 col-sm-6 col-md-offset-4 col-md-4">
                            <div className="login-widget">
                                <div className="login-box">
                                    <h3 className="orangeBG">Reset Password</h3>
                                    <div className="forget-pass">
                                        <ResetPasswordForm onSubmit={this.resetPassword} />
                                    </div>
                                </div>
                                <div className="register">
                                    <p>Know your password? <Link to="signin">Sign In</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
           </div>
        );
  }
}

ResetPasswordPage.propTypes = {
  resetPassword: PropTypes.func.isRequired
};

export default connect(null, actions)(ResetPasswordPage);

