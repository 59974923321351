import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { required } from '../common/validation';

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <label className="col-sm-4 control-label">{label}</label>
    <div className="col-sm-6">
         <input className="form-control" {...input} placeholder={label} type={type}/>
            { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
  </div>
);

 const UpdatePasswordForm = (props) => {
    const { handleSubmit, submitting, closeModal } = props;

    return (
        <form onSubmit={handleSubmit} className="form-horizontal">
            <div className="form-group">
                <Field name="password" type="password" component={renderField} label="New Password" validate={required} />
            </div>
           
          
            <hr />
             <div className="form-actions clearfix">
                <div className="col-sm-6">
                    <button type="submit" className="btn btn-primary rounded" disabled={submitting}>Save</button>&nbsp;&nbsp;
                    <button type="button" className="btn btn-default rounded" onClick={closeModal}>Close</button>
                </div>
           </div>  
        </form>
    );
};

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired
};

UpdatePasswordForm.propTypes = {
  ...propTypes
};

export default reduxForm({
    form: 'PasswordForm'
})(UpdatePasswordForm);