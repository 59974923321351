import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/forms';
import Select from 'react-select';
import { Modal } from 'react-bootstrap';
import FormItemFrom from './form-item-form';
import Preview from './preview-form';
import FormDetails from './form-details-form';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';

const SortableItem = SortableElement(({question, openItem}) => <li className="list-group-item">
    <div onClick={() => openItem(question)}>
      <div className="row">
        <div className="col-sm-2 text-muted"><strong>{question.type}:</strong></div>
        <div className="col-sm-10">{question.questionText.replace(/<[^>]+>/g, '')}</div>
      </div>
    </div>
  </li>);

const SortableList = SortableContainer(({questions, openItem}) => {
  return (
    <ul className="list-group rule-list">
      {questions.map((question, index) => (
        <SortableItem key={`item-${index}`} index={index} question={question} openItem={openItem} />
      ))}
    </ul>
  );
});

class FormDetailsPage extends Component {

 constructor(props, context) {
    super(props, context);
    this.state = {
      questions: this.props.form.questions,
      showModal: false,
      modalHeading: '',
      selectedItem: '',
      questionText: '',
      item: {}
    };

    this.selectItem = this.selectItem.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addItem = this.addItem.bind(this);
    this.setQuestionText = this.setQuestionText.bind(this);
    this.openItem = this.openItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.removeForm = this.removeForm.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.form.questions !== this.props.form.questions) {
          this.setState({ questions: nextProps.form.questions });
        }
    }

    setQuestionText(questionText) {
      this.setState({questionText});
      }

    selectItem(selectedItem) {
      this.setState({ selectedItem, showModal: true, item: {} });
    }

    openItem(item) {
      this.setState({ item, showModal: true, selectedItem: item.type });
    }

    addItem(values) {
      const formItem = JSON.parse(JSON.stringify(values));
      formItem.type = this.state.selectedItem;
      if (this.state.selectedItem === 'Text') formItem.questionText = this.state.questionText;
      
      this.props.saveCustomQuestion(formItem, this.props.form._id);
      this.closeModal();
    }

    updateForm(values) {
      this.props.saveCustomForm(values, this.props.userSetting.schoolId);
    }

    removeForm() {
      if (window.confirm(`Confirm that you would like to delete this from`)) { 
        this.props.removeCustomForm(this.props.form._id);
        this.props.onButtonClicked('forms');
    } 
    }

    onSortEnd({oldIndex, newIndex}) {
      const questions = arrayMove(this.state.questions, oldIndex, newIndex);
      this.setState({ questions });
      this.props.updateCustomQuestionOrder(this.props.form._id, questions);
    }

    removeItem(id) {
      this.props.removeCustomQuestion(id);
      this.closeModal();
    }

    closeModal() {
      this.setState({ showModal: false });
    }

    // renderItems(items) {
    //   if (!items)  return null;

    //   return items.map((item, index) => {
    //     return (
    //       <li key={index} className="list-group-item" onClick={() => this.openItem(item)}>{item.questionText.replace(/<[^>]+>/g, '')}</li>
    //     );
    //   });
    // }

     render() {
      const { form, onButtonClicked } = this.props;
      const options = [ { value: 'Heading', label: 'Add a Heading' }, { value: 'Text', label: 'Add Text Block'}, { value: 'Selection', label: 'Add a Selection List Question' }, { value: 'Question', label: 'Add a Comment Question'}, { value: 'Checkbox', label: 'Add a Checkbox Question' }];
        return(
           <div> 
            <div>
              <div className="row">
                  <div className="col-sm-1">
                  <button type="button" className="btn btn-default btn-xs rounded" onClick={() => onButtonClicked('formResponse')}><i className="fa fa-angle-left"></i></button>
                  </div>
                  <div className="col-sm-11">
                    <FormDetails initialValues={{ formTitle: form.formTitle, formActive: form.formActive, id: form._id }} onSubmit={this.updateForm} onRemove={this.removeForm}  />
                  </div>
                </div>
              <hr />
              <div className="basic-form">
                <div className="form-group">
                  <div className="col-sm-8">
                    <div className="row">
                      <div className="col-sm-4">
                        <label>Add form item</label>
                      </div>
                      <div className="col-sm-8">
                        <Select name="addItem" options={options} simpleValue onChange={this.selectItem} />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 text-right">
                  <button type="button" className="btn btn-info btn-sm rounded" onClick={() => this.selectItem('Preview')}>Preview</button>
                  </div>
                </div>
              </div>
              <hr />
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-12">
                  <SortableList 
                    questions={this.state.questions} 
                    onSortEnd={this.onSortEnd} 
                    openItem={this.openItem}
                    pressDelay={200} />
                  </div>
                </div>
              </div>
            </div>

          <Modal show={this.state.showModal} bsSize="lg" onHide={this.closeModal}>
          <Modal.Header className="modal-header primary" closeButton>
              <Modal.Title>{this.state.selectedItem}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {(this.state.selectedItem === 'Preview') 
              ? <Preview initialValues={{ questions: this.state.questions }} onClose={this.closeModal} />
              : <FormItemFrom 
                  onSubmit={this.addItem} 
                  removeItem={this.removeItem}
                  formType={this.state.selectedItem} 
                  initialValues={this.state.item} 
                  onQuestionText={this.setQuestionText}
                  onClose={this.closeModal} />}
          </Modal.Body>

          </Modal>
        </div>
        );
    }
}

FormDetailsPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  saveCustomQuestion: PropTypes.func.isRequired,
  removeCustomQuestion: PropTypes.func.isRequired,
  updateCustomQuestionOrder: PropTypes.func.isRequired,
  onButtonClicked: PropTypes.func.isRequired,
  saveCustomForm: PropTypes.func.isRequired,
  removeCustomForm: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        userSetting: state.usersetting
    };
}

export default connect(mapStateToProps, actions)(FormDetailsPage);
