import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import * as actions from '../../actions/auth';

class Signout extends Component {
 
 componentWillMount() {

   this.props.signoutUser(this.props.userSetting);
 }

  render() {
    return (
        
        <section className="container animated fadeInUp">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3">
                        <div id="login-wrapper">
                            
                            <div className="panel panel-primary">
                                <div className="panel-heading">
                                    <h3 className="panel-title">Signed Out</h3>
                                </div>
                                <div className="panel-body">
                                   <p className="text-center">You have been signed out My School Music Admin</p>
                                   <p className="text-center"><strong><Link to="/">Sign back in</Link></strong></p>
                                   <br /><br />
                                    <div className="text-right small">
                                        <a className="text-muted" onClick={() => localStorage.removeItem('token')}>Forget Password</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
  }
}

Signout.propTypes = {
  userSetting: PropTypes.object.isRequired,
  signoutUser: PropTypes.func.isRequired
};

function mapStateToProps(state) {

  return {
    userSetting: state.usersetting
  };
}

export default connect(mapStateToProps, actions)(Signout);