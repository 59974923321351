import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { DateInput } from '@blueprintjs/datetime';
import DropdownList from 'react-select';
import { Checkbox } from '@blueprintjs/core';
import moment from 'moment';

const relationship = [{ label: 'Father' }, { label: 'Mother' }, { label: 'Grandparent' }, { label: 'Guardian' }, { label: 'Other' }];
const title = [{ label: 'Mr' }, { label: 'Mrs' }, { label: 'Miss' }, { label: 'Dr' }, { label: 'Ms' }, { label: 'Prof' }, { label: 'Rev' }];

const renderDropdownList = ({ input, ...rest }) => <DropdownList {...input} {...rest} onBlur={() => input.change} />;

const renderCheckbox = ({ input }) => (
  <label className='pt-control pt-checkbox pt-large'>
    <Checkbox checked={input.value} onChange={input.onChange} />
  </label>
);

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <input className='form-control' {...input} type={type} placeholder={label} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderDatePicker = ({ input, max, min, meta: { touched, error } }) => (
  <div>
    <DateInput
      value={input.value}
      onChange={input.onChange}
      format={'DD MMM YYYY'}
      minDate={moment().add(min, 'years').toDate()}
      maxDate={moment().add(max, 'years').toDate()}
    />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const StudentContactForm = (props) => {
  const { handleSubmit, submitting, initialValues, onClose } = props;

  return (
    <div className='basic-form clearfix'>
      <form onSubmit={handleSubmit}>
        <h4 className='blueColor'>First Contact</h4>

        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-7'>
              <div className='col-sm-4'>
                <label>First Name</label>
              </div>
              <div className='col-sm-8 d-flex'>
                <div className='pr-1 w120'>
                  <Field
                    name='contact1.title'
                    type='text'
                    valueKey='label'
                    simpleValue={true}
                    clearable={false}
                    component={renderDropdownList}
                    options={title}
                    placeholder='Title'
                  />
                </div>
                <div className='fullWidth'>
                  <Field name='contact1.firstName' type='text' component={renderField} />
                </div>
              </div>
            </div>
            <div className='col-xs-12 col-sm-5'>
              <div className='col-sm-4'>
                <label>Last Name</label>
              </div>
              <div className='col-sm-8'>
                <Field name='contact1.lastName' type='text' component={renderField} />
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-7'>
              <div className='col-sm-4'>
                <label>Email</label>
              </div>
              <div className='col-sm-8'>
                <Field name='contact1.email' type='text' component={renderField} />
              </div>
            </div>
            <div className='col-xs-12 col-sm-5'>
              <div className='col-sm-4'>
                <label>Mobile</label>
              </div>
              <div className='col-sm-8'>
                <Field name='contact1.mobile' type='text' component={renderField} />
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-7'>
              <div className='col-sm-4'>
                <label>Address</label>
              </div>
              <div className='col-sm-8'>
                <Field name='contact1.address' type='text' component={renderField} />
              </div>
            </div>
            <div className='col-xs-12 col-sm-5'>
              <div className='col-sm-4'>
                <label>Relationship</label>
              </div>
              <div className='col-sm-8'>
                <Field
                  name='contact1.relationship'
                  type='text'
                  valueKey='label'
                  simpleValue={true}
                  clearable={false}
                  component={renderDropdownList}
                  options={relationship}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-7'>
              <div className='col-sm-4'>
                <label>WWC</label>
              </div>
              <div className='col-sm-8 d-flex'>
                <div className='w250 pr-2'>
                  <Field
                    name='contact1.wwcType'
                    type='text'
                    labelKey='value'
                    simpleValue={true}
                    component={renderDropdownList}
                    options={[{ value: 'Volunteer' }, { value: 'Full' }]}
                    placeholder='Type'
                  />
                </div>
                <div className='w250 pr-2 d-flex'>
                  <div className='pr-1'>No:</div>
                  <Field name='contact1.wwcNumber' type='text' component={renderField} label='Number' />
                </div>
              </div>
            </div>
            <div className='col-sm-5 d-flex'>
              <div className='w200 pr-2 d-flex'>
                <div className='pr-1'>Exp:</div>
                <Field name='contact1.wwcExp' type='text' component={renderDatePicker} min={-1} max={10} />
              </div>
              <div className='w200 d-flex'>
                <div className='pr-1'>DOB:</div>
                <Field name='contact1.wwcDOB' type='text' component={renderDatePicker} min={-80} max={-15} />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <h4 className='blueColor'>Second Contact</h4>

        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-7'>
              <div className='col-sm-4'>
                <label>First Name</label>
              </div>
              <div className='col-sm-8 d-flex'>
                <div className='pr-1 w120'>
                  <Field
                    name='contact2.title'
                    type='text'
                    valueKey='label'
                    simpleValue={true}
                    clearable={false}
                    component={renderDropdownList}
                    options={title}
                    placeholder='Title'
                  />
                </div>
                <div className='fullWidth'>
                  <Field name='contact2.firstName' type='text' component={renderField} />
                </div>
              </div>
            </div>
            <div className='col-xs-12 col-sm-5'>
              <div className='col-sm-4'>
                <label>Last Name</label>
              </div>
              <div className='col-sm-8'>
                <Field name='contact2.lastName' type='text' component={renderField} />
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-7'>
              <div className='col-sm-4'>
                <label>Email</label>
              </div>
              <div className='col-sm-8'>
                <Field name='contact2.email' type='text' component={renderField} />
              </div>
            </div>
            <div className='col-xs-12 col-sm-5'>
              <div className='col-sm-4'>
                <label>Mobile</label>
              </div>
              <div className='col-sm-8'>
                <Field name='contact2.mobile' type='text' component={renderField} />
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-7'>
              <div className='col-sm-4'>
                <label>Address</label>
              </div>
              <div className='col-sm-8'>
                <Field name='contact2.address' type='text' component={renderField} />
              </div>
            </div>
            <div className='col-xs-12 col-sm-5'>
              <div className='col-sm-4'>
                <label>Relationship</label>
              </div>
              <div className='col-sm-8'>
                <Field
                  name='contact2.relationship'
                  type='text'
                  valueKey='label'
                  simpleValue={true}
                  clearable={false}
                  component={renderDropdownList}
                  options={relationship}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-7'>
              <div className='col-sm-4'>
                <label>WWC</label>
              </div>
              <div className='col-sm-8 d-flex'>
                <div className='w250 pr-2'>
                  <Field
                    name='contact2.wwcType'
                    type='text'
                    labelKey='value'
                    simpleValue={true}
                    component={renderDropdownList}
                    options={[{ value: 'Volunteer' }, { value: 'Full' }]}
                    placeholder='Type'
                  />
                </div>
                <div className='w250 pr-2 d-flex'>
                  <div className='pr-1'>No:</div>
                  <Field name='contact2.wwcNumber' type='text' component={renderField} label='Number' />
                </div>
              </div>
            </div>
            <div className='col-sm-5 d-flex'>
              <div className='w200 pr-2 d-flex'>
                <div className='pr-1'>Exp:</div>
                <Field name='contact2.wwcExp' type='text' component={renderDatePicker} min={-1} max={10} />
              </div>
              <div className='w200 d-flex'>
                <div className='pr-1'>DOB:</div>
                <Field name='contact2.wwcDOB' type='text' component={renderDatePicker} min={-80} max={-15} />
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='col-sm-1 col-sm-offset-3'>
                <Field name='contact2.sendEmail' component={renderCheckbox} />
              </div>
              <div className='col-sm-8'>Send Emails to first and Second contacts</div>
            </div>
          </div>
        </div>
        <hr />

        <div className='d-flex'>
          <h4 className='blueColor w250'>Emergency Contact</h4>
          <div className='w300 pr-2 d-flex'>
            <div className='pr-1'>Name:</div>
            <Field name='emergency.name' type='text' component={renderField} />
          </div>
          <div className='w300 pr-2 d-flex'>
            <div className='pr-1'>Relationship:</div>
            <Field name='emergency.relationship' type='text' component={renderField} />
          </div>
          <div className='w300 d-flex'>
            <div className='pr-1'>Mobile:</div>
            <Field name='emergency.mobile' type='text' component={renderField} />
          </div>
        </div>
        <hr />

        <div className='margin-top-one'>
          <div className='col-sm-7'>
            <button type='submit' disabled={submitting} className='btn btn-primary rounded'>
              Save
            </button>
            &nbsp;&nbsp;
            <button type='button' className='btn btn-trans rounded' onClick={onClose}>
              Close
            </button>
          </div>
          <div className='col-sm-5 text-right padding-top-half'>
            <span style={{ color: '#aaa' }}>Registered on Portal: {initialValues && initialValues.registered ? 'Yes' : 'No'}</span>
          </div>
        </div>
      </form>
    </div>
  );
};

StudentContactForm.propTypes = {
  ...propTypes,
};

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
};

renderField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

renderDatePicker.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'studentContactForm',
  enableReinitialize: true,
})(StudentContactForm);
