import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import DisplayMsg from './components/common/display-message';
import Header from './components/common/header';
import Sidebar from './components/common/side-bar';

import AddContact from './components/contacts/add-contact-page';
import AddGroup from './components/groups/add-group-page';
import AddPermission from './components/contacts/add-permission-page';
import AddStudent from './components/students/add-student-page';
import Archive from './components/students/archive-list-page';
import Contact from './components/contacts/contact-page';
import Contacts from './components/contacts/contacts-page';
import Dashboard from './components/dashboard/dashboardPage';
import EventCalendar from './components/events/calendar-page';
import GroupView from './components/groups/group-page';
import Password from './components/auth/reset-password-page';
import Profile from './components/profile/profile-page';
import SendEmail from './components/email/send-email-page';
import SendInvoice from './components/email/send-invoice-page';
import SendRoster from './components/email/send-roster-page';
import SendContactEmail from './components/email/send-email-contacts-page';
import Selectors from './components/settings/selectors-page';
import Import from './components/import/import-page';
import SignIn from './components/auth/signin-page';
import SignOut from './components/auth/signout';
import Student from './components/students/student-page';
import Students from './components/students/students-list-page';
import Documents from './components/library/documents-page';
import Templates from './components/library/templates-page';
import Forms from './components/forms/forms-page';
import UpdatePassword from './components/auth/update-password-page';
import newRegistrations from './components/students-registration/new-student-page';
import ReEnrolments from './components/students-registration/re-enrole-student-page';
import Enrolments from './components/students-registration/enrolment-student-page';
import studentSearch from './components/students/student-search-page';
import instruments from './components/instruments/instrument-list-page';
import InstrumentArchive from './components/instruments/instrument-archive';
import InstrumentService from './components/instruments/instrument-service-page';
import Hired from './components/instruments/hired-list-page';
import instrument from './components/instruments/instrument-page';
import Logs from './components/dashboard/fullLogsPage';
import LogDetails from './components/dashboard/email-log-page';
import ParentPortal from './components/portal/school-details-tabs';
import NewLogin from './components/auth/new-login-page';
import Reload from './components/auth/reload';
import InvoiceList from './components/invoices/invoice-list-page';
import AddInvoice from './components/invoices/add-invoice-page';
import AccountSetup from './components/invoices/invoice-settings-page';
import TuitionList from './components/tuition/tuition-list-page';
import TuitionCalendar from './components/tuition/tuition-calendar-page';
import ChangeSchool from './components/common/change-school';
import Testing from './components/invoices/testing';

const menuItem = <Switch>
    <Route path="/add-contact" exact component={AddContact} />
    <Route path="/add-group" exact component={AddGroup} />
    <Route path="/contact-permission/:id" exact component={AddPermission} />
    <Route path="/add-student" exact component={AddStudent} />
    <Route path="/archive" exact component={Archive} />
    <Route path="/contacts" exact component={Contacts} />
    <Route path="/contact" exact component={Contact} />
    <Route path="/events" exact component={EventCalendar} />
    <Route path="/group/:id" exact component={GroupView} />
    <Route path="/password" exact component={Password} />
    <Route path="/profile" exact component={Profile} />
    <Route path="/documents" exact component={Documents} />
    <Route path="/templates" exact component={Templates} />
    <Route path="/parent-portal" exact component={ParentPortal} />
    <Route path="/send-email" exact component={SendEmail} />
    <Route path="/send-invoice" exact component={SendInvoice} />
    <Route path="/send-contact-email" exact component={SendContactEmail} />
    <Route path="/send-roster/:id/:studentId/:term" exact component={SendRoster} />
    <Route path="/settings-selectors" exact component={Selectors} />
    <Route path="/settings-import" exact component={Import} />
    <Route path="/signin" exact component={SignIn} />
    <Route path="/signout" exact component={SignOut} />
    <Route path="/reload" exact component={Reload} />
    <Route path="/student/:id(/:new)" exact component={Student} />
    <Route path="/students" exact component={Students} />
    <Route path="/forms" exact component={Forms} />
    <Route path="/logs" exact component={Logs} />
    <Route path="/log-details" exact component={LogDetails} />
    <Route path="/instruments" exact component={instruments} />
    <Route path="/hired" exact component={Hired} />
    <Route path="/instrument-archive" exact component={InstrumentArchive} />
    <Route path="/instrument" exact component={instrument} />
    <Route path="/service" exact component={InstrumentService} />
    <Route path="/new-registrations" exact component={newRegistrations} />
    <Route path="/re-enrolments" exact component={ReEnrolments} />
    <Route path="/enrolments" exact component={Enrolments} />
    <Route path="/student-search" exact component={studentSearch} />
    <Route path="/password/:id" exact component={UpdatePassword} />
    <Route path="/new-login/:id" exact component={NewLogin} />
    <Route path="/accounts" exact component={InvoiceList} />
    <Route path="/add-invoice" exact component={AddInvoice} />
    <Route path="/invoice-settings" exact component={AccountSetup} />
    <Route path="/tuition-list" exact component={TuitionList} />
    <Route path="/tuition-calendar" exact component={TuitionCalendar} />
    <Route path="/change-school" exact component={ChangeSchool} />
    <Route path="/testing" exact component={Testing} />
    <Route path="/dashboard" exact component={Dashboard} />
    <Route path="/" exact component={SignIn} />
    <Redirect to="/" />
</Switch>

class App extends Component {

  render() {
    if (!this.props.userSetting.signin) {
        return(
          <div id="app">
            <div id="main-wrapper">
                <DisplayMsg />
                <section className="main-content-wrapper">
                  {menuItem}
                </section>
            </div>
          </div>
        );
    }

    return (
      <div id="app">
        <div id="main-wrapper" className={(this.props.userSetting.menu) ? 'theme-blue-full sidebar-mini' : 'theme-blue-full sidebar-opened'}>
            <Header history={this.props.history} />
            <Sidebar/>
            <section className="main-content-wrapper">
              <DisplayMsg />
                {menuItem}
            </section>
        </div>
      </div>
    );
    }
}

function mapStateToProps(state) {
  return {
    userSetting: state.usersetting
  };
}

export default withRouter(connect(mapStateToProps)(App));
