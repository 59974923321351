import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/forms';
import moment from 'moment';
import ReactDataGrid from 'react-data-grid';
import { Modal } from 'react-bootstrap';
import { Data } from 'react-data-grid-addons';
import FormAddStudents from '../common/select-students';
import EmptyToolbar from '../common/grid-toolbar';
import DisplayResponse from './display-response';
import SendEmail from '../email/email-component';

const Selectors = Data.Selectors;

class FormResponse extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      rows: this.shapedData(this.props.form.students),
      showModal: false,
      selectedStudents: [],
      filters: {},
      sortColumn: null,
      sortDirection: null,
      response: [],
      showEmail: false,
      questions: this.props.form.questions.filter((item) => item.type !== 'Heading'),
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addStudents = this.addStudents.bind(this);
    this.removeStudents = this.removeStudents.bind(this);
    this.clearStudents = this.clearStudents.bind(this);
    this.onRowsSelected = this.onRowsSelected.bind(this);
    this.onRowsDeselected = this.onRowsDeselected.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.rowGetter = this.rowGetter.bind(this);
    this.getRows = this.getRows.bind(this);
    this.getSize = this.getSize.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.onClearFilters = this.onClearFilters.bind(this);
    this.handleGridSort = this.handleGridSort.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.exportCustomForm = this.exportCustomForm.bind(this);
    this.toggleEmail = this.toggleEmail.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.forms !== this.state.rows) {
      this.setState({ rows: this.shapedData(nextProps.form.students.slice(0)) });
    }
  }

  shapedData(data) {
    return data.map((item) => {
      const _data = {
        studentId: item.studentId,
        studentName: item.studentName,
        grade: item.grade,
        completedDate: item.completedDate,
        completed: item.completed,
        response: item.responses,
      };
      if (item.responses) {
        for (let i = 0; i < item.responses.length; i++) {
          _data[`question${i}`] = item.responses[i].response || item.responses[i].questionText;
        }
      }
      return _data;
    });
  }

  openModal(header) {
    this.setState({ showModal: true, header });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  toggleEmail() {
    this.setState({ showEmail: !this.state.showEmail });
  }

  addStudents(students) {
    const studentIds = students.map((student) => student._id);
    const addStudents = students.map((student) => ({
      studentId: student._id,
      studentName: `${student.firstName} ${student.lastName}`,
      grade: student.grade,
      class: student.class,
      groups: student.groupSummary,
      instruments: student.instruments,
    }));

    const formStudents = _.sortBy(this.props.form.students.concat(addStudents), ['studentName']);
    this.props.updateStudentsCustomForm(this.props.form._id, formStudents, studentIds, 'add');
    this.closeModal();
  }

  removeStudents() {
    const studentIds = this.state.selectedStudents.map((student) => student.studentId);
    const formStudents = _.pullAllBy(this.props.form.students.slice(0), this.state.selectedStudents, 'studentId');

    this.props.updateStudentsCustomForm(this.props.form._id, formStudents, studentIds, 'remove');
  }

  clearStudents(students) {
    const studentIds = students.map((student) => student._id);
    this.props.clearStudentsCustomForm(this.props.form._id, studentIds);
  }

  sendEmail() {
    const studetList = this.state.selectedStudents;

    this.props.emailList(
      studetList.map((student) => ({
        _id: student.studentId,
        firstName: student.firstName,
        lastName: student.lastName,
        grade: student.grade,
      }))
    );
    if (studetList.length) this.props.history.push('/send-email');
  }

  exportCustomForm() {
    this.props.exportCustomForm(
      this.state.selectedStudents.map((item) => item.studentId),
      this.props.form.formTitle,
      this.props.form._id
    );
  }

  getRows() {
    return Selectors.getRows(this.state);
  }

  rowGetter(i) {
    const rows = this.getRows();
    return rows[i];
  }

  getSize() {
    return this.getRows().length;
  }

  onRowsSelected(rows) {
    this.setState({ selectedStudents: this.state.selectedStudents.concat(rows.map((r) => r.row)) });
  }

  onRowsDeselected(rows) {
    let ids = rows.map((r) => r.row.studentId);
    this.setState({
      selectedStudents: this.state.selectedStudents.filter((student) => !ids.some((id) => id === student.studentId)),
    });
  }

  onRowClick(rowIdx, row) {
    if (row) {
      this.setState({ showModal: true, header: 'Form Response', response: row.response });
    }
  }

  handleFilterChange(filter) {
    const newFilters = Object.assign({}, this.state.filters);
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
    this.setState({ filters: newFilters });
  }

  onClearFilters() {
    //all filters removed
    this.setState({ filters: {} });
  }

  handleGridSort(sortColumn, sortDirection) {
    const state = Object.assign({}, this.state, { sortColumn: sortColumn, sortDirection: sortDirection });
    this.setState(state);
  }

  displyCheck(value) {
    return (
      <div>
        <i className={value.value || value.value === 'true' ? 'fa fa-check' : ''} aria-hidden='true'></i>
      </div>
    );
  }

  completedDate(value) {
    return <div>{value.value ? moment(value.value).format('lll') : ''}</div>;
  }

  render() {
    const { form, onButtonClicked } = this.props;
    const columns = form.questions
      .filter((item) => item.type !== 'Heading')
      .reduce(
        (items, item, index) => {
          if (item.type === 'Checkbox' && item.commentText && item.commentText.length > 0) {
            items.push({
              key: `question${index}`,
              name: item.commentText,
              width: 100,
              sortable: true,
              filterable: true,
              formatter: this.displyCheck,
              getRowMetaData: (row) => row,
            });
          }
          return items;
        },
        [
          {
            key: 'studentName',
            name: 'Student',
            sortable: true,
            filterable: true,
          },
          {
            key: 'grade',
            name: 'Grade',
            width: 130,
            sortable: true,
            filterable: true,
          },
          {
            key: 'completed',
            name: 'Completed',
            width: 100,
            sortable: true,
            filterable: true,
            formatter: this.displyCheck,
            getRowMetaData: (row) => row,
          },
        ]
      );

    columns.push({
      key: 'completedDate',
      name: 'Date',
      width: 180,
      sortable: true,
      filterable: true,
      formatter: this.completedDate,
      getRowMetaData: (row) => row,
    });

    return (
      <div>
        <div className='row'>
          <div className='col-sm-1'>
            <button type='button' className='btn btn-default btn-xs rounded' onClick={() => onButtonClicked('forms')}>
              <i className='fa fa-angle-left'></i>
            </button>
          </div>
          <div className='col-sm-11'>
            <h3>{form.formTitle}</h3>
          </div>
        </div>
        <hr />
        <div className='row'>
          <div className='col-md-12 text-right'>
            <button type='button' className='btn btn-success btn-xs rounded' onClick={() => this.toggleEmail()}>
              Email
            </button>
            &nbsp;&nbsp;
            <button
              type='button'
              className='btn btn-primary btn-xs rounded'
              onClick={() => this.openModal('Add Students to Form')}
            >
              Add
            </button>
            &nbsp;&nbsp;
            <button type='button' className='btn btn-warning btn-xs rounded' onClick={() => this.removeStudents()}>
              Remove
            </button>
            &nbsp;&nbsp;
            {false && (
              <button
                type='button'
                className='btn btn-info btn-xs rounded'
                onClick={() => onButtonClicked('formDetails')}
              >
                Clear
              </button>
            )}
            {form.completed === 0 ? (
              <button
                type='button'
                className='btn btn-default btn-xs rounded'
                onClick={() => onButtonClicked('formDetails')}
              >
                Edit Form
              </button>
            ) : (
              <button type='button' className='btn btn-default btn-xs rounded' onClick={() => this.exportCustomForm()}>
                Export
              </button>
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            {this.state.showEmail && (
              <SendEmail
                studentList={this.state.selectedStudents.map((item) => item.studentId)}
                onClose={this.toggleEmail}
              />
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div>
              <div>
                <ReactDataGrid
                  rowKey='studentId'
                  columns={columns}
                  onGridSort={this.handleGridSort}
                  rowGetter={this.rowGetter}
                  rowsCount={this.getSize()}
                  minHeight={this.getSize() * 35 + 85}
                  onRowClick={this.onRowClick}
                  toolbar={<EmptyToolbar />}
                  onAddFilter={this.handleFilterChange}
                  onClearFilters={this.onClearFilters}
                  rowSelection={{
                    showCheckbox: true,
                    enableShiftSelect: true,
                    onRowsSelected: this.onRowsSelected,
                    onRowsDeselected: this.onRowsDeselected,
                    selectBy: {
                      keys: { rowKey: 'studentId', values: this.state.selectedStudents.map((row) => row.studentId) },
                    },
                  }}
                />
              </div>
              <div className='admin-label text-right margin-top-quarter'>
                <i className='blueBG'>
                  {' '}
                  {this.getSize() === this.state.rows.length
                    ? `${this.state.rows.length} Students`
                    : `Showing ${this.getSize()} of ${this.state.rows.length} Students`}{' '}
                </i>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.showModal} bsSize='lg' onHide={this.closeModal}>
          <Modal.Header className='modal-header primary' closeButton>
            <Modal.Title>{this.state.header}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.header === 'Add Students to Form' && (
              <FormAddStudents
                onAdd={this.addStudents}
                excludeStudents={this.props.form.students.map((student) => student.studentId)}
                onClose={this.closeModal}
              />
            )}
            {this.state.header === 'Form Response' && (
              <DisplayResponse
                questions={this.state.questions}
                response={this.state.response}
                onClose={this.closeModal}
              />
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

FormResponse.propTypes = {
  form: PropTypes.object.isRequired,
  onButtonClicked: PropTypes.func.isRequired,
  updateStudentsCustomForm: PropTypes.func.isRequired,
  clearStudentsCustomForm: PropTypes.func.isRequired,
  emailList: PropTypes.func.isRequired,
  exportCustomForm: PropTypes.func.isRequired,
};

export default connect(null, actions)(FormResponse);
