import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/groups';
import ContactForm from './contact-form';
import { Modal } from 'react-bootstrap';

class RehearsalList extends Component {
  
   constructor(props, context) {
        super(props, context);
        this.state = {
            showModal: false,
            selectedItem: {}
        };

        this.removeItem = this.removeItem.bind(this);
        this.addItem = this.addItem.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }


removeItem(item) {
        if (window.confirm(`Remove ${item.name} as contact from this group`)) {
            this.props.removeGroupContact(item, this.props.group._id);
        }
    }

  addItem(item) {
    this.setState({ showModal: false });
    this.props.addGroupContact(item, this.props.group._id);
  }

  selectItem(selectedItem) {
      this.setState({ selectedItem, showModal: true });
  }

  close() {
        this.setState({ showModal: false });
    }

  open() {
        this.setState({ showModal: true });
    }

  renderList() {
    if (!this.props.group.contacts) {
      return null;
    }
    return this.props.group.contacts.map((contact, index) => {
      return (
        <li key={index} className="list-group-item">
            <div className="row">
                <div className="col-sm-10 small">{`${contact.name} (${contact.role})`}</div>
                <div className="col-sm-1" onClick={() => this.removeItem(contact)}><i className="fa fa-times">&nbsp;</i></div>
            </div>
        </li>
      );
    });
  }

  render() {
    return (
    <div className="clearfix">
        <div className="panel panel-default">
            <div className="panel-body">
                <div className="row">
                    <div className="col-sm-6">Group Contacts</div>
                    <div className=" col-sm-6 text-right">
                        <button type="button" onClick={() => this.selectItem({})} className="btn btn-default btn-xs rounded">Add Group Contact</button>
                    </div>
                </div>
                <ul className="list-group">
                    {this.renderList()}
                </ul>
            </div>
        </div>


        <Modal show={this.state.showModal} onHide={this.close}>
            <Modal.Header className="modal-header primary" closeButton>
                <Modal.Title>Group Contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ContactForm onSubmit={this.addItem}  />
            </Modal.Body>
           
        </Modal>
    </div>
    );
  }
}

RehearsalList.propTypes = {
    group: PropTypes.object.isRequired,
    contacts: PropTypes.array,
    addGroupContact: PropTypes.func.isRequired,
    removeGroupContact: PropTypes.func.isRequired
};

export default connect(null, actions)(RehearsalList);
