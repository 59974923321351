import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';

const renderDropdownList = ({ input, ...rest }) => <DropdownList {...input} {...rest} onBlur={() => input.change} />;

const tagForm = (props) => {
    const { handleSubmit, tags } = props;

    return (
        <div className="clearfix form-padding">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                  <div className="row">
                      <div className="col-sm-1">
                          <label>Tags</label>
                      </div>
                      <div className="col-sm-5">
                        <Field name="studentTag" type="text" simpleValue={true} valueKey="tag" labelKey="tag" component={renderDropdownList} options={tags}  />
                      </div>
                      <div className="col-sm-4">
                        <Field name="action" type="text" simpleValue={true} labelKey="value" component={renderDropdownList} options={[{ value: 'Add' }, { value: 'Remove' }]}  />
                      </div>
                      <div className="col-sm-2 text-right">
                        <button type="submit" className="btn btn-sm btn-primary rounded">Go</button>
                      </div>
                  </div>
              </div>
        </form>
    </div>
    );
};

tagForm.propTypes = {
    ...propTypes
};

renderDropdownList.propTypes = {
    input: PropTypes.object.isRequired
};


export default reduxForm({
    form: 'tagForm'
})(tagForm);