import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SketchPicker } from 'react-color';
import * as actions from '../../actions';


class SchoolColour extends Component {

    constructor(props, context) {
        super(props, context);

         this.state = {
            colour: this.props.school.schoolColour
        };

     this.changeColour = this.changeColour.bind(this);
     this.saveColour = this.saveColour.bind(this);
    }

    changeColour(colour) {
      this.setState({ colour });
    }

    saveColour() {
      this.props.updateSchool({ schoolColour: this.state.colour.hex }, this.props.school._id);
    }

   
    render() {

        return (
            <div className="col-xs-6 col-xs-offset-1 text-center">
              <div className="padding-top-two">
                <SketchPicker color={this.state.colour} onChangeComplete={this.changeColour}  />
              </div>

              <div className="padding-top-two">
                  <button type="button" className="btn btn-primary rounded" onClick={this.saveColour}>Save Colour</button>
              </div>
            </div>

        );
    }
}

SchoolColour.propTypes = {
  school: PropTypes.object.isRequired,
  updateSchool: PropTypes.func.isRequired
};



function mapStateToProps(state) {

    return {
         school: state.school
    };
}

export default connect(mapStateToProps, actions)(SchoolColour);