export const contacts = [
            {
                key: 'first_name',
                name: 'First Name',
                sortable : true
            },
            {
                key: 'last_name',
                name: 'Last Name',
                sortable : true
            },
             {
                key: 'email',
                name: 'Email',
                sortable : true
            },
             {
                key: 'mobile',
                name: 'Mobile',
                sortable : true
            },
             {
                key: 'role',
                name: 'Role',
                sortable : true
            },
             {
                key: 'wwc_number',
                name: 'WWC No',
                sortable : true
             },
            {
                key: 'wwc_exp_date',
                name: 'WWC Exp',
                sortable : true
            },
            {
                key: 'wwc_dob',
                name: 'WWC DOB',
                sortable : true
            }
            ];

export const students = [
            {
                key: 'first_name',
                name: 'First Name',
                width: 120,
                sortable : true
            },
            {
                key: 'last_name',
                name: 'Last Name',
                width: 120,
                sortable : true
            },
            {
                key: 'gender',
                name: 'Gender',
                width: 90,
                sortable : true
            },
             {
                key: 'grade',
                name: 'Grade',
                width: 90,
                sortable : true
            },
             {
                key: 'class',
                name: 'Class',
                width: 90,
                sortable : true
            },
            {
                key: 'DOB',
                name: 'DOB',
                width: 90,
                sortable : true
            },
             {
                key: 'group',
                name: 'Group',
                width: 150,
                sortable : true,
             },
            {
                key: 'instrument',
                name: 'Instrument',
                width: 150,
                sortable : true,
            },
            {
                key: 'contact1_title',
                name: 'Title',
                width: 90,
                sortable : true
            },
             {
                key: 'contact1_first_name',
                name: 'Contact 1 - First Name',
                width: 200,
                sortable : true,
            },
            {
                key: 'contact1_last_name',
                name: 'Last Name',
                width: 150,
                sortable : true,
            },
            {
                key: 'contact1_email',
                name: 'Email',
                width: 200,
                sortable : true,
            },
            {
                key: 'contact1_mobile',
                name: 'Mobile',
                width: 120,
                sortable : true,
            },
            {
                key: 'contact1_relationship',
                name: 'Relationship',
                width: 120,
                sortable : true,
            },
            {
                key: 'contact1_address',
                name: 'Address',
                width: 200,
                sortable : true,
            },
            {
                key: 'contact2_title',
                name: 'Title',
                width: 90,
                sortable : true
            },
             {
                key: 'contact2_first_name',
                name: 'Contact 2 - First Name',
                width: 200,
                sortable : true,
            },
            {
                key: 'contact2_last_name',
                name: 'Last Name',
                width: 150,
                sortable : true,
            },
            {
                key: 'contact2_email',
                name: 'Email',
                width: 200,
                sortable : true,
            },
            {
                key: 'contact2_mobile',
                name: 'Mobile',
                width: 120,
                sortable : true,
            },
            {
                key: 'contact2_relationship',
                name: 'Relationship',
                width: 120,
                sortable : true,
            },
            {
                key: 'contact2_address',
                name: 'Address',
                width: 200,
                sortable : true,
            },
            ];

export const instruments = [
            {
                key: 'instrument',
                name: 'Instrument',
                sortable : true
            },
            {
                key: 'manufacturer',
                name: 'Manufacturer',
                sortable : true
            },
             {
                key: 'model',
                name: 'Model',
                sortable : true
            },
             {
                key: 'serialNo',
                name: 'Serial No',
                sortable : true
            },
             {
                key: 'schoolNo',
                name: 'School No',
                sortable : true,
             },
             {
                key: 'purchasedFrom',
                name: 'Bought From',
                sortable : true,
             },
             {
                key: 'purchasedPrice',
                name: 'Price',
                sortable : true,
             },
             {
                key: 'purchasedDate',
                name: 'Date',
                sortable : true,
             },
             {
                key: 'currentValue',
                name: 'Current Value',
                sortable : true,
             },
             {
                key: 'lastServiceDate',
                name: 'Last Serviced',
                sortable : true,
             },
             {
                key: 'lastHireDate',
                name: 'Last Hired',
                sortable : true,
             },
             {
                key: 'firstHired',
                name: 'First Hired',
                sortable : true,
             },
             {
                key: 'accessories',
                name: 'Accessories',
                sortable : true,
             }
            ];