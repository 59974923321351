import _ from 'lodash';
import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/instruments';
import InstrumentForm from './instrument-form';

class InstrumentDetailsPage extends Component {

     constructor(props, context) {
        super(props, context);

        this.saveForm = this.saveForm.bind(this);
        this.removeInstrument = this.removeInstrument.bind(this);
    }

    saveForm(values) {
        this.props.updateInstrument(values, this.props.instrument._id, this.props.onClose);
        }

    removeInstrument() {
       this.props.removeInstrument(this.props.instrument._id);
        }

   
    render() {
    
      const { instrument, studentList, onClose } = this.props;

        return(
            <div>
                <InstrumentForm 
                  onSubmit={this.saveForm} 
                  initialValues={instrument} 
                  isHired={instrument.isHired}
                  inService={instrument.inService}
                  studentList={_.map(studentList, student => ({ value: student._id, label: `${student.firstName} ${student.lastName}`}))} 
                  onDelete={this.removeInstrument}
                  onClose={onClose} />
            </div>
        );
    }
}

InstrumentDetailsPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  instrument: PropTypes.object.isRequired,
  studentList: PropTypes.object.isRequired,
  updateInstrument: PropTypes.func.isRequired,
  removeInstrument: PropTypes.func.isRequired
};


function mapStateToProps(state) {
  return {
        userSetting: state.usersetting,
        instrument : state.instruments.selectedInstrument,
        studentList: state.students.all
  };
}

export default connect(mapStateToProps, actions)(InstrumentDetailsPage);
