import React from 'react';
import { Tab, Nav, NavItem, Row, Col } from 'react-bootstrap';
import Registration from './registration-page';
import Renrolment from './reenrolment-page';
import Enrolment from './enrolment-page';
import SchoolDetails from './school-details-page';
import ThemeColour from './school-colour';
import ThemeLogo from './upload-logo';

const SchoolDetailsTabs = () => (
        <div>
            <div className="pageheader"><h2>School Details</h2></div>
            <section id="main-content">
                    <div className="row">
                        <div className="col-md-12 col-lg-11">
                            <div className="panel">
                                <div className="panel-body">
                                    <Tab.Container id="contact-tabs" defaultActiveKey="schoolDetails">
                                        <Row className="clearfix">
                                            <Col sm={12}>
                                                <Nav className="nav nav-tabs info-tab">
                                                    <NavItem eventKey="schoolDetails">
                                                        Parent Portal
                                                    </NavItem>
                                                    <NavItem eventKey="theme">
                                                        Portal Theme
                                                    </NavItem>
                                                    <NavItem eventKey="registration">
                                                        Registration Form
                                                    </NavItem>
                                                    <NavItem eventKey="renrolment">
                                                        Re-enrolment Form
                                                    </NavItem>
                                                    <NavItem eventKey="enrolment">
                                                        Enrolment Form
                                                    </NavItem>
                                                </Nav>

                                                <Tab.Content animation>
                                                    <Tab.Pane eventKey="schoolDetails">
                                                        <br />
                                                        <SchoolDetails />
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="theme" mountOnEnter={true}>
                                                        <br />
                                                        <div className="row">
                                                          <div className="col-sm-6">
                                                            <div className="panel panel-default">
                                                              <div className="panel-heading">Set School Colour</div>
                                                              <div className="panel-body">
                                                                <ThemeColour />
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="col-sm-6">
                                                             <div className="panel panel-default">
                                                              <div className="panel-heading">School Logo</div>
                                                              <div className="panel-body">
                                                                <ThemeLogo />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="registration" mountOnEnter={true}>
                                                        <br />
                                                        <Registration />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="renrolment" mountOnEnter={true}>
                                                        <br />
                                                        <Renrolment />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="enrolment" mountOnEnter={true}>
                                                        <br />
                                                        <Enrolment />
                                                    </Tab.Pane>
                                                </Tab.Content>
                                              </Col>
                                        </Row>
                                    </Tab.Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );

export default SchoolDetailsTabs;
