import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import moment from 'moment';
import { required } from '../common/validation';

const dow = [{ label: 'Sunday'}, { label: 'Monday'}, { label: 'Tuesday'}, { label: 'Wednesday'}, { label: 'Thursday'}, { label: 'Friday'}, { label: 'Saturday'}];
const selectColour = [
    { value: '#0066ff', label: 'Blue'}, 
    { value: '#996633', label: 'Brown'},
    { value: '#cc9900', label: 'Dark Yellow'},
    { value: '#737373', label: 'Grey'}, 
    { value: '#008000', label: 'Green'},
    { value: '#00b8e6', label: 'Light Blue'},
    { value: '#339966', label: 'Light Green'},
    { value: '#800000', label: 'Maroon'},
    { value: '#ff6600', label: 'Orange'},
    { value: '#55552b', label: 'Olive'},
    { value: '#ff0080', label: 'Pink'},
    { value: '#ac00e6', label: 'Purple'},
    { value: '#e60000', label: 'Red'},
    { value: '#336699', label: 'Steel Blue'},
    { value: '#ffcc00', label: 'Yellow'}];  

const renderDropdownList = ({ input, ...rest }) =>
  <DropdownList {...input} {...rest} onBlur={() => input.change} />;

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type={type} placeholder={label}/>
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderTimeField = ({ input, meta: { touched, error } }) => (
    <div>
        <input className="form-control" {...input} type="text" onBlur={() => input.value && input.onChange(moment(input.value, ['HH:mm', 'hh:mm a', 'h a', 'HH']).format('h:mm a'))}/>
        { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
  );

const RehearsalForm = (props) => {
    const { handleSubmit, submitting, venues } = props;

    return (
        <div className="basic-form">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Day</label>
                        </div>
                        <div className="col-sm-7">
                            <Field name="dayOfWeek" type="text" component={renderDropdownList} options={dow} valueKey="label" simpleValue={true} placeholder="Day of week for Rehearsal" />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Start Time</label>
                        </div>
                        <div className="col-sm-7">
                            <Field name="startTime" type="text" component={renderTimeField} validate={required} />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>End Time</label>
                        </div>
                        <div className="col-sm-7">
                            <Field name="endTime" type="text" component={renderTimeField} validate={required} />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                <div className="row">
                    <div className="col-sm-3">
                        <label>Venue</label>
                    </div>
                    <div className="col-sm-7">
                        <Field name="eventVenue" type="text" component={renderDropdownList} options={venues} valueKey="label" simpleValue={true} placeholder="Rehearsal Venue" />   
                    </div>
                </div>
                </div>

                <div className="form-group">
                <div className="row">
                    <div className="col-sm-3">
                        <label>Colour</label>
                    </div>
                    <div className="col-sm-7">
                        <Field name="eventColour" type="text" simpleValue={true} component={renderDropdownList} options={selectColour} placeholder="Select Rehearsal Calendar Colour"  />
                    </div>
                </div>
                </div>
            <hr />
            <button type="submit" className="btn btn-primary rounded" disabled={submitting}>Save</button>
        </form>
    </div>
    );
};

RehearsalForm.propTypes = {
    ...propTypes
};

renderDropdownList.propTypes = {
    input: PropTypes.object.isRequired
};

renderTimeField.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

renderField.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'rehearsalForm'
})(RehearsalForm);