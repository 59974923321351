import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/tuition';
import ReactDataGrid from 'react-data-grid';
import { Data } from 'react-data-grid-addons';

const Selectors = Data.Selectors;

class GroupStudentsPage extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            rows: this.props.students,
            selectedRows: []
        };
      
        this.rowGetter = this.rowGetter.bind(this);
        this.getSize = this.getSize.bind(this);
        this.onRowsSelected = this.onRowsSelected.bind(this);
        this.onRowsDeselected = this.onRowsDeselected.bind(this);
        this.removeStudent = this.removeStudent.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }

    removeStudent() {
      const { rows, selectedRows } = this.state;

      this.props.removeSelectedStudents(selectedRows, this.props.tGroupId);
      this.setState({ rows: rows.filter(item => !selectedRows.some(row => row === item._id)), selectedRows: [] });
    }

    rowGetter(i) {
      const rows = this.getRows();
      return rows[i];
      }

    getRows() {
        return Selectors.getRows(this.state);
      }

    getSize() {
           return this.state.rows.length;
       }

    onRowsSelected(rows) {
        this.setState({ selectedRows: this.state.selectedRows.concat(rows.map(r => r.row._id)) });
    }

    onRowsDeselected(rows) {
        this.setState({ selectedRows: this.state.selectedRows.filter(item => !rows.some(row => row.row._id === item))});
    }

    sendEmail() {
        if (this.state.selectedRows.length === 0) {
            this.props.displayWarning('Select one or more students');
        } else {
            this.props.onSendEmail(this.state.selectedRows);
        }
    }
   
    render() {

      const { userSetting, modalView, onClose } = this.props;

      const adminButtons = (
        <span>
          <button type="button" className="btn btn-xs btn-primary rounded" onClick={() => modalView('add')}>Add Students</button>&nbsp;
          <button type="button" className="btn btn-xs btn-warning rounded" onClick={() => this.removeStudent()}>Remove Students</button>&nbsp;
          <button type="button" className="btn btn-xs btn-info rounded" onClick={() => modalView('setup')}>Settings</button>&nbsp;
        </span>);

      const columns = [
        {
            key: 'firstName',
            name: 'First Name',
        },
        {
            key: 'lastName',
            name: 'Last Name',
        },
         {
            key: 'instruments',
            name: 'Instruments',
        },
        {
            key: 'grade',
            name: 'Grade',
            width: 120
        },
          {
            key: 'class',
            name: 'Class',
            width: 120
        }
        ];

        return (
            <div>
                <div className="text-right">
                    <button type="button" className="btn btn-xs btn-success rounded" onClick={() => this.sendEmail()}>Email</button>&nbsp;
                    {userSetting.fullAdmin && adminButtons}
                    <button type="button" className="btn btn-default btn-xs rounded" onClick={() => onClose()}>Close</button>
                </div>

                <div className="padding-top-one">
                  <ReactDataGrid
                    rowKey="_id"
                    columns={columns}
                    enableCellSelect={true}
                    rowGetter={this.rowGetter}
                    rowsCount={this.getSize()}
                    minHeight={this.getSize() * 35 + 85}
                    onGridRowsUpdated={this.handleGridRowsUpdated}
                    rowSelection={{
                          showCheckbox: true,
                          enableShiftSelect: true,
                          onRowsSelected: this.onRowsSelected,
                          onRowsDeselected: this.onRowsDeselected,
                          selectBy: {
                              keys: { rowKey: '_id', values: this.state.selectedRows}
                          }
                      }} />
                </div> 
               
            </div>
        );
    }
}

GroupStudentsPage.propTypes = {
    tGroupId: PropTypes.string.isRequired,
    students: PropTypes.array.isRequired,
    userSetting: PropTypes.object.isRequired,
    modalView: PropTypes.func.isRequired,
    removeSelectedStudents: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSendEmail: PropTypes.func.isRequired,
    displayWarning: PropTypes.func.isRequired
};


function mapStateToProps(state) {
    return {
        userSetting: state.usersetting
    };
}

export default connect(mapStateToProps, actions)(GroupStudentsPage);