import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { Link } from 'react-router-dom';
import { Checkbox } from 'react-icheck';


const renderCheckbox = ({ input }) => (
  <Checkbox 
    checkboxClass="icheckbox_square-blue" 
    increaseArea="20%"
    name={input.name}
    onChange={input.onChange}
    />
);

const GroupContacts = (contacts) => {
      let contactList;
      contacts.map((contact) => {
          (contactList) ?  contactList += ', ' + `${contact.name} ${(contact.role) ? ` (${contact.role})` : ''}` : contactList = `${contact.name} (${contact.role})`;
     });
      return contactList;
    };

const GroupListRow = (groups) => {

        return groups.map((group, index) => {
            return (
                <li key={index} className="clearfix">
                    <Link to={'/group/' + group._id}>
                            <div className="user-info pull-left">
                                <div className="member-name col-xs-12 col-sm-12 col-md-12 col-lg-12"><Field name={'selectedGroups.' + group._id} component={renderCheckbox} />&nbsp;&nbsp;&nbsp; <strong>{group.name}</strong></div>
                                <div className="member-name col-xs-12 col-sm-12 col-md-12 col-lg-12"><span className="member-online-time">{group.contacts && GroupContacts(group.contacts)}</span></div>
                            </div>
                            <div className="pull-right">
                                <span className="admin-label">
                                    <i className="blueBG">{group.noStudent}</i>
                                </span>
                            </div>
                        </Link>
                </li>
            );
        });
};

const GroupList = (props) => {

     const { handleSubmit, groups, addGroup, totalStudents } = props;

    return (
        <form onSubmit={handleSubmit} >
            <div className="panel panel-primary">
                <div className="panel-heading">Groups</div>
                <div className="panel-body">
                    <div className="text-right">
                        <button className="btn btn-warning btn-xs rounded" type="submit">Email</button>&nbsp;
                        <button className="btn btn-primary btn-xs rounded" type="button" onClick={() => addGroup()}>Add Group</button>
                    </div>
                    <div className="member-list">
                        <ul className="list-unstyled">
                            {GroupListRow(groups)}
                        </ul>
                    </div>
                    <div className="text-right text-muted small">
                       Students: {totalStudents} 
                    </div>
                </div>
            </div>
        </form>
    );
};


GroupList.propTypes = {
    ...propTypes,
    groups: PropTypes.array.isRequired
};

renderCheckbox.propTypes = {
    input: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'groupEmail'
})(GroupList);