import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/students';
import TagForm from './tag-form';
import Group from './move-group-form';

class BulkUpdateModal extends Component {

     constructor(props, context) {
        super(props, context);

        this.updateTags = this.updateTags.bind(this);
        this.updateGroups = this.updateGroups.bind(this);
        this.archiveStudents = this.archiveStudents.bind(this);
        this.updateGrades = this.updateGrades.bind(this);

    }

    updateTags(values) {
        this.props.updateTags(this.props.selectedStudents.map(student => student._id), this.props.userSetting.schoolId, values);
        this.props.onClose();
    }

    updateGroups(values) {
        this.props.updateGroups(this.props.selectedStudents.map(student => student._id), this.props.userSetting.schoolId, values);
        this.props.onClose();
    }

    archiveStudents() {
      this.props.archiveStudents(this.props.selectedStudents.map(student => student._id), this.props.userSetting.schoolId);
      this.props.onClose();
    }

    updateGrades() {
        if (window.confirm(`Confirm that you would like to update all student grades to the next year`)) { 
            this.props.moveToNextGrade(this.props.userSetting.schoolId);
            this.props.onClose(); 
        } 
        
    }
    

    render() {
        return (
          <form>
             <p className="text-center">Select and action to perform on selected students</p> 
             <hr />
             <div className="row">   
                <div className="col-sm-12">
                    <TagForm onSubmit={this.updateTags} tags={this.props.selectors.studentTags} />
                    <Group onSubmit={this.updateGroups} groups={this.props.groups} />
                    <hr />
                    <div className="row form-padding">
                        <div className="col-sm-10">
                            Move selected students to archive list
                        </div>
                        <div className="col-sm-2 text-right">
                            <button type="button" className="btn btn-sm btn-primary rounded" onClick={this.archiveStudents}>Go</button>
                        </div>
                    </div>
                    <hr />
                    <div className="row form-padding">
                        <div className="col-sm-10">
                            Move <b>ALL</b> students to next grade
                        </div>
                        <div className="col-sm-2 text-right">
                            <button type="button" className="btn btn-sm btn-primary rounded" onClick={this.updateGrades}>Go</button>
                        </div>
                    </div>
                    
                </div>
             </div>
             <hr />
             <div className="row">   
                <div className="col-sm-12 text-right">
                <button type="button" className="btn btn-sm btn-default rounded" onClick={() => this.props.onClose()}>Close</button>
                </div>
             </div>
        </form>        
        );
    }
}

BulkUpdateModal.propTypes = {
  userSetting: PropTypes.object.isRequired,
  selectedStudents: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  selectors: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  updateTags: PropTypes.func.isRequired,
  updateGroups: PropTypes.func.isRequired,
  archiveStudents: PropTypes.func.isRequired,
  moveToNextGrade: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        userSetting: state.usersetting,
        selectors: state.school.selectors,
        groups: state.school.groups
    };
}

export default connect(mapStateToProps, actions)(BulkUpdateModal);