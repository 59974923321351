import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/forms';
import ReEnrolmentForm from './re-enrolment-form';
import Questions from '../common/questions';

class ReenrolmentPage extends Component {

    constructor(props, context) {
        super(props, context);

         this.state = {
            reEnrolInfo: this.props.forms.reEnrolment.reEnrolmentInfo
        };

        this.saveReEnrole = this.saveReEnrole.bind(this);
        this.setReEnroleInfo = this.setReEnroleInfo.bind(this);
        this.saveEnroleQuestion = this.saveEnroleQuestion.bind(this);
        this.removeEnroleQuestion = this.removeEnroleQuestion.bind(this);
    }

    saveReEnrole(enrole) {
      const enroleForm = Object.assign({}, enrole, { reEnrolmentInfo: this.state.reEnrolInfo });
      this.props.saveReEnrolment(enroleForm, this.props.userSetting.schoolId);
    }

    saveEnroleQuestion(question) {
        this.props.addEnroleQuestion(question, this.props.userSetting.schoolId);
    }

    removeEnroleQuestion(question) {
        this.props.removeEnroleQuestion(question, this.props.userSetting.schoolId);
    }

    setReEnroleInfo(reEnrolInfo) {
        this.setState({reEnrolInfo});
        }

    render() {

        const { forms } = this.props;

        return (
          <div className="row">
              <div className="col-md-12">
                <div className="panel panel-default">
                    <div className="panel-body">
                      <ReEnrolmentForm onSubmit={this.saveReEnrole} initialValues={forms.reEnrolment} onInfoField={this.setReEnroleInfo} />
                        <h4 className="blueColor">Registration Questions</h4>
                        <Questions 
                            questions={forms.reEnrolment.questions} 
                            saveQuestion={this.saveEnroleQuestion}
                            removeQuestion={this.removeEnroleQuestion} />
                    </div>
                  </div>
                </div>
            </div>
        );
    }
}

ReenrolmentPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  forms: PropTypes.object.isRequired,
  saveReEnrolment: PropTypes.func.isRequired,
  addEnroleQuestion: PropTypes.func.isRequired,
  removeEnroleQuestion: PropTypes.func.isRequired
};



function mapStateToProps(state) {
    return {
      userSetting: state.usersetting,
      forms: state.forms
    };
}

export default connect(mapStateToProps, actions)(ReenrolmentPage);