import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tab, Nav, NavItem, Row, Col } from 'react-bootstrap';
import * as actions from '../../actions/students';
import RegDetails from './new-student-reg-details';
import RegForm from './new-student-reg-form';
import StudentForm from './new-student-detail-form';
import StudentContactForm from './new-student-contact-form';

class NewStudentModal extends Component {

     constructor(props, context) {
        super(props, context);

        this.setGroup = this.setGroup.bind(this);
        this.updateStudent = this.updateStudent.bind(this);
        this.updateContact = this.updateContact.bind(this);
        this.deleteNewStudent = this.deleteNewStudent.bind(this);
    }

    setGroup(group) {
        this.props.setGroup({group}, this.props.student._id);
        if (group.setToActive) {
            this.props.updateAlert(this.props.noStudents - 1);
        }
        this.props.closeStudent();
    }

    updateStudent(student) {
        this.props.updateStudent(student, this.props.student._id, true);

    }

    updateContact(contact) {
        this.props.updateStudentContact(contact, this.props.student._id);
    }

    deleteNewStudent() {
        if (window.confirm(`Confirm you would like to delete student: ${this.props.student.firstName} ${this.props.student.lastName}`)) { 
         this.props.deleteNewStudent(this.props.student._id);
         this.props.closeStudent();
        }

    }

    render() {

        const { student, selectors, groups, closeStudent } = this.props;

        return (
                                                 
            <Tab.Container id="contact-tabs" defaultActiveKey="registration">
                <Row className="clearfix">
                    <Col sm={12}>
                        <Nav bsStyle="nav nav-tabs">
                             <NavItem eventKey="registration">
                                Registration
                            </NavItem>
                            <NavItem eventKey="student">
                                Student
                            </NavItem>
                            <NavItem eventKey="contacts">
                                Contacts
                            </NavItem>
                        </Nav>

                        <Tab.Content animation>
                            <Tab.Pane eventKey="registration">
                                <br />
                                <div>
                                    <RegForm selectorsValues={selectors} selectGroups={groups} initialValues={student.groups[0]} onSubmit={this.setGroup} closeStudent={closeStudent} deleteNewStudent={this.deleteNewStudent} />
                                </div>
                                <div className="clearfix">
                                    <RegDetails regDetails={student.registration}/>
                                </div>

                            </Tab.Pane>
                            <Tab.Pane eventKey="student">
                                <br />
                                <StudentForm selectors={selectors} initialValues={student} onSubmit={this.updateStudent} closeStudent={closeStudent} />
                                
                            </Tab.Pane>

                            <Tab.Pane eventKey="contacts">
                                <br />
                                <StudentContactForm initialValues={student.studentContact} onSubmit={this.updateContact} closeStudent={closeStudent}  />
                               
                            </Tab.Pane>

                        </Tab.Content>
                        </Col>
                </Row>
            </Tab.Container>
                                   
        );
    }
}

NewStudentModal.propTypes = {
  userSetting: PropTypes.object.isRequired,
  student: PropTypes.object.isRequired,
  selectors: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  setGroup: PropTypes.func.isRequired,
  closeStudent: PropTypes.func.isRequired,
  updateStudentContact: PropTypes.func.isRequired,
  updateStudent: PropTypes.func.isRequired,
  deleteNewStudent: PropTypes.func.isRequired,
  noStudents: PropTypes.number.isRequired
};

function mapStateToProps(state) {
    return {
        userSetting: state.usersetting,
        selectors: state.school.selectors,
        groups: state.school.groups,
    };
}

export default connect(mapStateToProps, actions)(NewStudentModal);