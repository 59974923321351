import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import { required, number } from '../common/validation';

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
  <div>
      <DropdownList {...input} {...rest} onBlur={() => input.change} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderField = ({ input, label, type, tabIndex, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type={type} tabIndex={tabIndex} placeholder={label}/>
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const StudentAccountForm = (props) => {
    const { handleSubmit, submitting, DOB } = props;

    return (
      <div className="panel panel-default">
        <div className="panel-body">
            <h4>Student Account</h4>
            <div className="basic-form">
                <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12">
                            <div className="col-sm-2">
                                <label>Voucher</label>
                            </div>
                            <div className="col-sm-2">
                                <Field name="voucher" type="text" component={renderField} tabIndex="5" />
                            </div>
                            <div className="col-sm-2">
                                <label>MediCare Name</label>
                            </div>
                            <div className="col-sm-3">
                                <Field name="mediCareName" type="text" component={renderField} tabIndex="6" />
                            </div>
                            <div className="col-sm-2">
                            <label>
                                DOB: {DOB && moment(DOB).format('DD MMM YY')}
                            </label>
                            </div> 
                        </div>
                    </div>
                </div>
                <button type="submit" disabled={submitting} className="btn btn-primary">Save</button>
            </form>
        </div>
      </div>
    </div>
    );
};

StudentAccountForm.propTypes = {
    ...propTypes
};

renderField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  tabIndex: PropTypes.string
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};


export default reduxForm({
    form: 'studentAccountForm'
})(StudentAccountForm);