import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import * as actions from '../../actions/tuition';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AddtGroup from './add-group-form';
import AddtPrivate from './add-private-form';
import ReactDataGrid from 'react-data-grid';
import { Data } from 'react-data-grid-addons';
import TGroupModal from './tuition-group-modal';
import EmptyToolbar from '../common/grid-toolbar';
import EmailModal from './email-modal';

const Selectors = Data.Selectors;

class TuitionListPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      rows: [],
      selectedTGroups: [],
      filters: {},
      sortColumn: null,
      sortDirection: null,
      showModal: false,
      showLargeModal: false,
      modalHeading: '',
      selectedTGroup: {},
      rowIdx: 0,
      studentList: [],
    };

    this.onRowsSelected = this.onRowsSelected.bind(this);
    this.onRowsDeselected = this.onRowsDeselected.bind(this);
    this.rowGetter = this.rowGetter.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.getRows = this.getRows.bind(this);
    this.getSize = this.getSize.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.onClearFilters = this.onClearFilters.bind(this);
    this.handleGridSort = this.handleGridSort.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openLargeModal = this.openLargeModal.bind(this);
    this.closeLargeModal = this.closeLargeModal.bind(this);
    this.exportTGroups = this.exportTGroups.bind(this);
    this.addTGroup = this.addTGroup.bind(this);
    this.addTPrivate = this.addTPrivate.bind(this);
    this.onSendEmail = this.onSendEmail.bind(this);
    this.onSendGroupEmail = this.onSendGroupEmail.bind(this);
  }

  componentDidMount() {
    this.props.getTuitionGroups(this.props.userSetting.schoolId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tGroups !== this.props.tGroups) {
      this.setState({ rows: nextProps.tGroups.slice(0) });
    }
  }

  openModal(modalHeading) {
    this.setState({ showModal: true, modalHeading });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  openLargeModal(modalHeading) {
    this.setState({ showLargeModal: true, modalHeading });
  }

  closeLargeModal() {
    this.setState({ showLargeModal: false });
  }

  exportTGroups() {
    this.props.exportTGroups(
      this.state.selectedTGroups.length === 0 ? this.state.rows.map((item) => item._id) : this.state.selectedTGroups
    );
  }

  addTGroup(group) {
    const tGroup = _.pick(group, [
      'groupName',
      'instruments',
      'defaultDay',
      'defaultTime',
      'defaultDuration',
      'defaultVenue',
      'defaultColour',
    ]);
    tGroup.tutorId = group.tutor._id;
    tGroup.tutorName = group.tutor.name;
    tGroup.schoolId = this.props.userSetting.schoolId;
    tGroup.type = 'Group';
    if (!tGroup.defaultDay) tGroup.defaultDay = 'Mon';
    if (!tGroup.defaultTime) tGroup.defaultTime = '8:00 am';
    if (!tGroup.defaultDuration) tGroup.defaultDuration = '30';
    this.props.addTGroup(tGroup);
    this.closeModal();
  }

  addTPrivate(group) {
    const tGroup = _.pick(group, [
      'groupName',
      'instruments',
      'defaultDay',
      'defaultTime',
      'defaultDuration',
      'defaultVenue',
    ]);
    const student = this.props.studentList[tGroup.groupName.value];
    tGroup.groupName = tGroup.groupName.label;
    tGroup.tutorId = group.tutor._id;
    tGroup.tutorName = group.tutor.name;
    tGroup.schoolId = this.props.userSetting.schoolId;
    tGroup.type = 'Private';
    tGroup.defaultColour = '#333';
    tGroup.students = [student._id];
    if (!tGroup.defaultDay) tGroup.defaultDay = 'Mon';
    if (!tGroup.defaultTime) tGroup.defaultTime = '8:00 am';
    if (!tGroup.defaultDuration) tGroup.defaultDuration = '30';

    this.props.addTGroup(tGroup);
    this.closeModal();
  }

  onSendEmail(modalHeading) {
    if (this.state.selectedTGroups.length === 0) {
      this.props.displayWarning('Select one or more Groups');
    } else {
      const studentList = this.state.rows.reduce((list, item) => {
        if (this.state.selectedTGroups.includes(item._id)) {
          item.students.forEach((student) => {
            if (!list.includes(student._id)) list.push(student._id);
          });
        }
        return list;
      }, []);

      this.setState({ showLargeModal: true, modalHeading, studentList });
    }
  }

  onSendGroupEmail(studentList) {
    this.setState({ showLargeModal: true, modalHeading: 'Send Email', studentList });
  }

  instrumentTags(instruments) {
    if (!instruments.value) return null;

    return (
      <div>
        {instruments.value.split(',').map((instrument, index) => {
          return (
            <span key={index} className='label label-default label-padding'>
              {instrument}
            </span>
          );
        })}
      </div>
    );
  }

  rowGetter(i) {
    const rows = this.getRows();
    return rows[i];
  }

  onRowsSelected(rows) {
    this.setState({ selectedTGroups: this.state.selectedTGroups.concat(rows.map((r) => r.row._id)) });
  }

  onRowsDeselected(rows) {
    this.setState({
      selectedTGroups: this.state.selectedTGroups.filter((item) => !rows.some((row) => row.row._id === item)),
    });
  }

  onRowClick(rowIdx, row) {
    if (row) {
      this.setState({ showLargeModal: true, selectedTGroup: row, modalHeading: row.groupName });
    }
  }

  getRows() {
    return Selectors.getRows(this.state);
  }

  getSize() {
    return this.getRows().length;
  }

  handleFilterChange(filter) {
    const newFilters = Object.assign({}, this.state.filters);
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
    this.setState({ filters: newFilters });
  }

  onClearFilters() {
    //all filters removed
    this.setState({ filters: {} });
  }

  handleGridSort(sortColumn, sortDirection) {
    const comparer = (a, b) => {
      if (sortDirection === 'ASC') {
        return a[sortColumn].toLowerCase() > b[sortColumn].toLowerCase() ? 1 : -1;
      } else if (sortDirection === 'DESC') {
        return a[sortColumn].toLowerCase() < b[sortColumn].toLowerCase() ? 1 : -1;
      }
    };

    const dateComparer = (a, b) => {
      const momentA = moment(`01/01/2018 ${a[sortColumn]}`);
      const momentB = moment(`01/01/2018 ${b[sortColumn]}`);
      if (sortDirection === 'ASC') {
        return momentA.isAfter(momentB) ? 1 : -1;
      } else if (sortDirection === 'DESC') {
        return momentA.isBefore(momentB) ? 1 : -1;
      }
    };

    const rows =
      sortDirection === 'NONE'
        ? this.props.tGroups.slice(0)
        : this.state.rows.sort(sortColumn === 'defaultTime' ? dateComparer : comparer);
    this.setState({ rows });
  }

  render() {
    const columns = [
      {
        key: 'groupName',
        name: 'Group Name',
        sortable: true,
        filterable: true,
      },
      {
        key: 'tutorName',
        name: 'Tutor',
        sortable: true,
        filterable: true,
      },
      {
        key: 'instruments',
        name: 'Instruments',
        sortable: true,
        filterable: true,
        width: 180,
        formatter: this.instrumentTags,
      },
      {
        key: 'defaultDay',
        name: 'Day',
        width: 80,
        sortable: true,
        filterable: true,
      },
      {
        key: 'defaultTime',
        name: 'Time',
        width: 80,
        sortable: true,
        filterable: true,
      },
      {
        key: 'defaultVenue',
        name: 'Venue',
        width: 120,
        sortable: true,
        filterable: true,
      },
      {
        key: 'noStudents',
        name: 'Students',
        headerRenderer: <i className='fa fa-users' />,
        width: 50,
      },
    ];

    return (
      <div>
        <div className='pageheader'>
          <h2>Tuition Groups</h2>
        </div>
        <section id='main-content'>
          <div className='row'>
            <div className='col-md-12 col-lg-12'>
              <div className='panel panel-default'>
                <div className='panel-body'>
                  <div className='row'>
                    <div className='col-xs-12 col-sm-6'>
                      <div className='pt-button-group .modifier'>
                        <div className='pt-button pt-intent-primary pt-active' role='button'>
                          Tuition List
                        </div>
                        <Link to='/tuition-calendar' className='pt-button pt-intent-primary'>
                          Tuition Calendar
                        </Link>
                      </div>
                    </div>
                    <div className='col-xs-12 col-sm-6'>
                      <div className='text-right'>
                        <button
                          type='button'
                          className='btn btn-warning btn-trans btn-xs rounded'
                          onClick={() => this.onSendEmail('Send Email')}
                        >
                          Email
                        </button>
                        &nbsp;
                        {this.props.userSetting.fullAdmin && (
                          <button
                            type='button'
                            className='btn btn-info btn-trans btn-xs rounded'
                            onClick={() => this.openModal('Add Group')}
                          >
                            Add Group
                          </button>
                        )}
                        &nbsp;
                        {this.props.userSetting.fullAdmin && (
                          <button
                            type='button'
                            className='btn btn-primary btn-trans btn-xs rounded'
                            onClick={() => this.openModal('Add Private')}
                          >
                            Add Private
                          </button>
                        )}
                        &nbsp;
                        <button
                          type='button'
                          className='btn btn-xs btn-default rounded'
                          onClick={() => this.exportTGroups()}
                        >
                          Export
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='col-xs-12'>
                    <div>
                      <div>
                        <ReactDataGrid
                          rowKey='_id'
                          onGridSort={this.handleGridSort}
                          columns={columns}
                          rowGetter={this.rowGetter}
                          rowsCount={this.getSize()}
                          minHeight={this.getSize() * 35 + 85}
                          onRowClick={this.onRowClick}
                          toolbar={<EmptyToolbar />}
                          onAddFilter={this.handleFilterChange}
                          onClearFilters={this.onClearFilters}
                          getValidFilterValues={this.getValidFilterValues}
                          rowSelection={{
                            showCheckbox: true,
                            enableShiftSelect: true,
                            onRowsSelected: this.onRowsSelected,
                            onRowsDeselected: this.onRowsDeselected,
                            selectBy: {
                              keys: { rowKey: '_id', values: this.state.selectedTGroups },
                            },
                          }}
                        />
                      </div>
                      <div className='admin-label text-right margin-top-quarter'>
                        <i className='blueBG'>
                          {' '}
                          {this.getSize() === this.state.rows.length
                            ? `${this.state.rows.length} Tuition Groups`
                            : `Showing ${this.getSize()} of ${this.state.rows.length} Students`}{' '}
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header bsClass='modal-header primary' closeButton>
            <Modal.Title>{this.state.modalHeading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-padding'>
              {this.state.modalHeading === 'Add Group' && (
                <AddtGroup onSubmit={this.addTGroup} selectors={this.props.selectors} onClose={this.closeModal} />
              )}
              {this.state.modalHeading === 'Add Private' && (
                <AddtPrivate
                  onSubmit={this.addTPrivate}
                  selectors={this.props.selectors}
                  studentList={_.map(this.props.studentList, (student) => ({
                    value: student._id,
                    label: `${student.firstName} ${student.lastName}`,
                    instrument: student.groups.length ? student.groups[0].instrument : '',
                    tutor: student.groups.length && student.groups[0].tutor ? student.groups[0].tutor : '',
                  }))}
                  onClose={this.closeModal}
                />
              )}
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.showLargeModal} bsSize='lg' onHide={this.closeLargeModal}>
          <Modal.Header bsClass='modal-header primary' closeButton>
            <Modal.Title>{this.state.modalHeading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-padding'>
              {this.state.modalHeading === 'Send Email' ? (
                <EmailModal
                  tGroup={this.state.selectedTGroup}
                  onClose={this.closeLargeModal}
                  studentList={this.state.studentList}
                />
              ) : (
                <TGroupModal
                  tGroup={this.state.selectedTGroup}
                  onClose={this.closeLargeModal}
                  onSendEmail={this.onSendGroupEmail}
                />
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

TuitionListPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  tGroups: PropTypes.array.isRequired,
  studentList: PropTypes.object.isRequired,
  selectors: PropTypes.object.isRequired,
  addTGroup: PropTypes.func.isRequired,
  exportTGroups: PropTypes.func.isRequired,
  getTuitionGroups: PropTypes.func.isRequired,
  emailList: PropTypes.func.isRequired,
  displayWarning: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    userSetting: state.usersetting,
    selectors: state.school.selectors,
    tGroups: state.tuition,
    studentList: state.students.all,
  };
}

export default connect(mapStateToProps, actions)(TuitionListPage);
