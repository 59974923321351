import _ from 'lodash';
import { GET_STUDENTS, GET_STUDENT, STUDENT_COMMENT_ADD, GET_STUDENTS_SEARCH, UPDATE_STUDENTS,
    STUDENT_COMMENT_DEL, SET_EMAIL_LIST, GET_STUDENT_LIST, UPDATE_STUDENT, REMOVE_STUDENT_LIST,
    SEARCH_TEXT, UPDATE_STUDENT_LIST, GET_ARCHIVE_STUDENTS, UPDATE_STUDENT_IN_LIST, NEW_STUDENT_LIST, 
    ARCHIVE_STUDENTS, RESTORE_STUDENT } from '../actions/types';
import initialState from './initial-state';

export default function(state = initialState.students, action) {
    switch(action.type) {

        case GET_STUDENTS:
            return { ...state, all: _.mapKeys(action.payload, '_id'), totalStudents: action.payload.length };

        case UPDATE_STUDENT:
            return { ...state, all: {...state.all, [action.id]: action.payload} };

        case UPDATE_STUDENTS:
            return { ...state, all: {...state.all,  ..._.mapKeys(action.payload, '_id')} };

        case ARCHIVE_STUDENTS:
            return { ...state, all: _.omit(state.all, action.payload) };

        case RESTORE_STUDENT:
            return { ...state, studentArchive: _.omit(state.studentArchive, action.payload) };

        case GET_STUDENT:
            return { ...state, student: action.payload  };

        case STUDENT_COMMENT_ADD:
            return { ...state, all: {...state.all, [action.id]: { ...state.all[action.id],  noComments: state.all[action.id]['noComments'] + 1,
            comments: [action.payload, ...state.all[action.id]['comments'].slice()] } } };

        case STUDENT_COMMENT_DEL:
            return { ...state, all: {...state.all, [action.id]: { ...state.all[action.id], noComments: state.all[action.id]['noComments'] - 1,
                        comments: [...state.all[action.id]['comments'].slice(0, action.payload), ...state.all[action.id]['comments'].slice(action.payload + 1) ] } } };

         case SET_EMAIL_LIST:
            return { ...state, emailList: action.payload };

        case GET_STUDENT_LIST:
            return { ...state, studentList: _.mapKeys(action.payload, '_id') };

        case NEW_STUDENT_LIST:
            return { ...state, studentList: action.payload };
        
        case UPDATE_STUDENT_LIST:
            return { ...state, studentList: {...state.studentList, [action.id]: action.payload} };

        case REMOVE_STUDENT_LIST:
            return { ...state, studentList: _.omit(state.studentList, action.payload) };

        case UPDATE_STUDENT_IN_LIST:
            return { ...state, studentList: {...state.studentList, [action.id]: action.payload} };

        case GET_STUDENTS_SEARCH:
            return { ...state, studentSearch: action.payload };

        case SEARCH_TEXT:
            return { ...state, searchText: action.payload  };

        case GET_ARCHIVE_STUDENTS:
            return { ...state, studentArchive: _.mapKeys(action.payload, '_id') };

        default:
            return state;
    }
}


