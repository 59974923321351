export default {
    school: { 
        logs: [],
        alerts: { newStudents: 0 }
    },
    contacts: { 
        all: [], 
        contact: {  
            loginActive: false, 
            fullAdmin: false 
        },
        emailList: []
    },
    events: {
        selectedEvent: {},
        all: []
    },
    instruments: {
        selectedInstrument: {},
        all: [],
        archive: []
    },
    students: { 
        all: {}, 
        emailList: [],
        studentList: {},
        studentSearch: [],
        studentArchive: {}
    },
    contactList: [],
    logs: {
        logItems: [],
        emailLog: {}
    },
    forms: { 
        newReg: { 
            questions: [], 
            selectedInfo: {} 
        },
        reEnrolment: {
            questions: []
        },
        customForms: {}
    },
    notifications: {},
    selectors: { 
        selectedEmail: {},
        importList: []},
    userSettings: { 
        schoolName: '', 
        schoolId: '', 
        menu: false, 
        signin: false, 
        remember: false, 
        resetPassword: false,
        menuPermissions: {}, 
        profile: {},
        currentURL: '',
        lastUpdate: { students: null, instruments: null } },
    email: {
        subject: '',
        emailBody: '',
        attachments: [],
        emailStatus: 'Send Email'
    },
    library: {
        uploaded: 100,
        folders: []
    },
    invoices: {
        selectedInvoices: []
    },
    roster: {},
    tuition: []
};