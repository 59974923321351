import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';

const dropdownData = (data) => {
   let newArry = data.map(function(obj) {
    return { value: obj._id, label: obj.name };
    });
    return newArry;
};

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
    <div>
        <DropdownList {...input} {...rest} onBlur={() => input.change} />
        { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
);

const AddStudentsForm = (props) => {
    const { handleSubmit, submitting, groupList } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label>Select groups required to re-enrole</label>
                <Field name="groupList" type="text" simpleValue={true} component={renderDropdownList} multi={true} options={dropdownData(groupList)} textField="groupName"  />
            </div>
            <br /><br />
            <div className="text-center"><button type="submit" disabled={submitting} className="btn btn-primary rounded">Add Students</button></div>
        </form>
    );
};

AddStudentsForm.propTypes = {
    ...propTypes
};

renderDropdownList.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'AddStudentsForm'
})(AddStudentsForm);