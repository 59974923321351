import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import FormEditor from '../editor/FormEditor';
import { Checkbox } from '@blueprintjs/core';

const renderEditor = ({ input, onInfoField }) => (
  <FormEditor value={input.value} handleOnChange={input.onChange} onBlur={onInfoField(input.value)} />
);

const renderCheckbox = ({ input }) => (
  <label className='pt-control pt-checkbox pt-large'>
    <Checkbox checked={input.value} onChange={input.onChange} />
  </label>
);

const renderField = ({ input, type, meta: { touched, error } }) => (
  <div>
    <input className='form-control' {...input} type={type} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const ReEnrolmentForm = (props) => {
  const { handleSubmit, submitting, onInfoField } = props;

  return (
    <div className='clearfix basic-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>Title</label>
            </div>
            <div className='col-sm-8'>
              <Field name='reEnrolmentTitle' type='text' component={renderField} />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>Enroling Text</label>
            </div>
            <div className='col-sm-8'>
              <Field name='reEnrolmentText' type='text' component={renderField} />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>Enroling Info</label>
            </div>
            <div className='col-sm-8'>
              <Field name='reEnrolmentInfo' type='textarea' onInfoField={onInfoField} component={renderEditor} />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>Activate Form</label>
            </div>
            <div className='col-sm-5 margin-top-quarter'>
              <Field name='reEnrolmentActive' component={renderCheckbox} />
            </div>
            <div className='col-sm-3 text-right'>
              <button type='submit' disabled={submitting} className='btn btn-primary rounded'>
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

ReEnrolmentForm.propTypes = {
  ...propTypes,
};

renderField.propTypes = {
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
};

renderEditor.propTypes = {
  input: PropTypes.object.isRequired,
  onInfoField: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'reEnrolmentForm',
  enableReinitialize: true,
})(ReEnrolmentForm);
