// User Settings
export const TOGGLE_MENU = 'toggle_menu';
export const SIGN_IN = 'sign_in';
export const SET_SCHOOL = 'set_school';
export const UPDATE_PROFILE = 'update_profile';
export const PWD_TOKEN = 'pwd_token';
export const UPDATE_FROM_EMAIL = 'update_from_email';
export const NOTHING = 'nothing';
export const LASTUPDATE_STUDENT = 'lastupdate_student';

// School
export const GET_SCHOOL = 'get_school';
export const UPDATE_INSTRUMENT_LIST = 'update_instrument_list';
export const UPDATE_STUDENT_STATUS_LIST = 'update_student_status_list';
export const UPDATE_CONTACT_CATEGORIES_LIST = 'update_contact_categories_list';
export const UPDATE_STUDENT_GRADE_LIST = 'update_student_grade_list';
export const UPDATE_MEETING_TIMES_LIST = 'update_meeting_times_list';
export const UPDATE_VENUE_LIST = 'update_venue_list';
export const SCHOOL_GROUP = 'school_group';

// Contacts
export const GET_CONTACTS = 'get_contacts';
export const GET_CONTACT = 'get_contact';
export const ADD_CONTACT = 'add_contact';
export const UPDATE_CONTACT = 'update_contact';
export const CONTACT_COMMENT_ADD = 'contact_comment_add';
export const CONTACT_COMMENT_DEL = 'contact_comment_del';
export const TOGGLE_PERMISSION = 'toggle_permission';

// Students
export const GET_STUDENTS = 'get_students';
export const GET_ARCHIVE_STUDENTS = 'get_archive_students';
export const GET_STUDENT = 'get_student';
export const ADD_STUDENT = 'add_student';
export const UPDATE_STUDENT = 'update_student';
export const UPDATE_STUDENT_LIST = 'update_student_list';
export const UPDATE_STUDENTS = 'update_students';
export const ARCHIVE_STUDENTS = 'archive_students';
export const UPDATE_STUDENT_CONTACT = 'update_student_contact';
export const STUDENT_COMMENT_ADD = 'student_comment_add';
export const STUDENT_COMMENT_DEL = 'student_comment_del';
export const GET_NEW_STUDENTS = 'get_new_students';
export const SEARCH_TEXT = 'search_text';
export const GET_STUDENTS_SEARCH = 'get_students_search';
export const GET_STUDENT_LIST = 'get_student_search';
export const REMOVE_STUDENT_LIST = 'remove_student_list';
export const UPDATE_STUDENT_IN_LIST = 'update_student_in_list';
export const NEW_STUDENT_LIST = 'update_student_list';
export const UPDATE_ALERT = 'update_alert';
export const RESTORE_STUDENT = 'restore_students';

// Settings
export const SETTING_SELECT_STUDENT_STATUS = 'setting_select_student_status';
export const SETTING_SELECT_CONTACT_CATEGORY = 'setting_select_contact_category';
export const SETTING_SELECT_FROM_EMAIL = 'setting_select_from_email';
export const SETTING_SELECT_VENUE = 'setting_select_venues';
export const IMPORT_LIST = 'import_list';
export const IMPORT_NOW = 'import_now';
export const SELECT_HOLIDAY = 'select_holiday';
export const UPDATE_SELECTORS = 'update_selectors';

// Instruments
export const UPDATE_INSTRUMENT = 'update_instrument';
export const GET_INSTRUMENTS = 'get_instruments';
export const GET_INSTRUMENT = 'get_instrument';
export const ADD_INSTRUMENT = 'add_instrument';
export const UPDATE_INSTRUMENT_HIRE = 'update_instrument_hire';
export const INSTRUMENT_COMMENT_ADD = 'instrument_comment_add';
export const INSTRUMENT_COMMENT_DEL = 'instrument_comment_del';
export const GET_INSTRUMENTS_ARCHIVE = 'get_instrument_archive';
export const UPDATE_INSTRUMENTS = 'update_instruments';

// Email
export const SET_EMAIL_LIST = 'set_email_list';
export const SET_EMAIL_CONTACT_LIST = 'set_email_contact_list';
export const ADD_ATTACHMENT = 'add_attachment';
export const REMOVE_ATTACHMENT = 'remove_attachment';
export const CLEAR_ATTACHMENT = 'clear_attachment';
export const EMAIL_STATUS = 'email_status';
export const CLEAR_EMAIL = 'clear_email';

// Forms
export const GET_FORMS = 'get_forms';
export const UPDATE_REG_QUESTIONS = 'update_reg_questions';
export const UPDATE_NEW_REGISTRATION = 'update_new_registration';
export const UPDATE_ENROLE_QUESTIONS = 'update_enrole_questions';
export const GET_CUSTOM_FORMS = 'get_custom_forms';
export const UPDATE_STUDENTS_CUSTOM_FORMS = 'update_students_custom_forms';
export const REMOVE_CUSTOM_FORM = 'remove_custom_form';

// Events
export const GET_EVENTS = 'get_events';
export const SELECT_EVENT = 'select_event';

// Logs
export const GET_LOGS = 'get_logs';
export const GET_LOG = 'get_log';

// Other
export const NO_ACTION = 'No_Action';
export const CURRENT_URL = 'Current_Url';

// Library
export const GET_DOCUMENTS = 'get_documents';
export const GET_TEMPLATES = 'get_templates';
export const GET_FOLDERS = 'get_folders';

// Invoices
export const UPDATE_INVOICE = 'update_invoice';
export const GET_INVOICES = 'get_invoices';
export const REMOVE_INVOICES = 'remove_invoices';
export const UPDATE_INV = 'update_inv';
export const SELECTED_INVOICES = 'selected_invoices';

// Roster
export const GET_ROSTER = 'get_roster';
export const GET_PARENTS = 'get_parents';
export const ROSTER_EMAIL = 'roster_email';

// Tuition
export const GET_TUITION = 'get_tuition';
export const ADD_TUITION = 'add_tuition';
export const UPDATE_TUITION = 'update_tuition';

// API URL
export const ROOT_URL = 'https://api.msmusic.com.au/api';
// export const ROOT_URL = 'http://localhost:3090/api';
