import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import * as actions from '../../actions';

class SideBar extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
           selectedMenu: 'dashboard',
           selectedSubMenu: '',
           pagesCollapsed: false,
           studentCollapsed: false,
           settingsCollapsed: false
        };
    }

    // {menuItem('forms', 'Forms', 'wpforms', menuPermissions.forms, fullAdmin)}  

    menuClicked(item) {
         switch(item) {

            case 'dashboard':
               // this.props.getSchool(this.props.userSettings.schoolId);
                this.setState({ selectedMenu: 'dashboard' });
                this.props.history.push('/dashboard');
                break;
            
             case 'active-students':
             // this.props.getStudents(this.props.userSettings.schoolId);
              this.setState({ selectedMenu: 'students', selectedSubMenu: 'all-students' });
              this.props.history.push('/students');
              break;

             case 'archive-students':
             // this.props.getStudents(this.props.userSettings.schoolId);
              this.setState({ selectedMenu: 'students', selectedSubMenu: 'archive-students' });
              this.props.history.push('/archive');
              break;

             case 'contacts':
                //this.props.getContacts(this.props.userSettings.schoolId);
                this.setState({ selectedMenu: 'contacts' });
                this.props.history.push('/contacts');
                break;

            case 'events':
               // this.props.getEvents(this.props.userSettings.schoolId);
                this.setState({ selectedMenu: 'events' });
                this.props.history.push('/events');
                break;

            case 'selector-options':
                //this.props.getSettings(this.props.userSettings.schoolId);
                this.setState({ selectedMenu: 'selector-options' });
                this.props.history.push('/settings-selectors');
                break;

            case 'invoice-setup':
                //this.props.getSettings(this.props.userSettings.schoolId);
                this.setState({ selectedMenu: 'invoice-setup' });
                this.props.history.push('/invoice-settings');
                break;

            case 'parent-portal':
                //this.props.getSettings(this.props.userSettings.schoolId);
                this.setState({ selectedMenu: 'parent-portal' });
                this.props.history.push('/parent-portal');
                break;

            case 'import':
             // this.props.getStudents(this.props.userSettings.schoolId);
              this.setState({ selectedMenu: 'import', selectedSubMenu: 'import' });
              this.props.history.push('/settings-import');
              break;

            case 'forms':
                //this.props.getSettings(this.props.userSettings.schoolId);
                this.setState({ selectedMenu: 'forms' });
                this.props.history.push('/forms');
                break;

            case 'instruments':
                //this.props.getSettings(this.props.userSettings.schoolId);
                this.setState({ selectedMenu: 'instruments' });
                this.props.history.push('/instruments');
                break;

            case 'students':
                //this.props.getSettings(this.props.userSettings.schoolId);
                this.setState({ selectedMenu: 'students' });
                this.props.history.push('/students');
                break;

            case 'accounts':
                //this.props.getSettings(this.props.userSettings.schoolId);
                this.setState({ selectedMenu: 'accounts' });
                this.props.history.push('/accounts');
                break;

            case 'tuition':
                //this.props.getSettings(this.props.userSettings.schoolId);
                this.setState({ selectedMenu: 'tuition' });
                this.props.history.push('/tuition-list');
                break;
            }
        }

    // activeRoute(getPath) {
    //     console.log(getPath, this.props)
    //     getPath = Array.isArray(getPath) ? getPath : [getPath];
    //     for (let i in getPath) {
    //         if (this.context.router.isActive(getPath[i]) === true)
    //             return true;
    //         }
    //     return false; 
    // }

    hasLogo(id) { 
        return (
            <div className="avatar">
                <img className="profile-image" src={`https://portal.msmusic.com.au/${id}.png`} alt="logo" />
            </div>
        );
    }

    render() {
        
        const { school, userSettings: { menuPermissions, fullAdmin } } = this.props;

        const menuItem = (item, label, icon, display, fullAdmin) => {
            if (!fullAdmin && !display) return;
            const menuIcon = "fa fa-fw fa-" + icon;
            return (
                <li className={(this.state.selectedMenu === item) ? 'active' : ''}>
                    <a onClick={() => this.menuClicked(item)}>
                        <i className={menuIcon}>&nbsp;</i>
                        <span>{label}</span>
                    </a>
                </li>
                );
            };

            const libraryMenu = () => {
                
             return (
                <li >
                    <a href="javascript:void(0)" onClick={() => { this.setState({ pagesCollapsed: !this.state.pagesCollapsed }); return false; }}>

                        <i className="fa fa-book fa-fw">&nbsp;</i>
                        <span>Library</span>
                    </a>
                    <ul className={classNames({ 'nav-sub': true, 'collapse': !this.state.pagesCollapsed })}>
                        <li>
                            <Link title="Documents" to="/documents" >
                                <span>Documents</span>
                            </Link>
                        </li>

                        <li>
                            <Link title="Templates" to="/templates" >
                                <span>Email Templates</span>
                            </Link>
                        </li>
                    </ul>
                </li>);
             };

             const settingsMenu = () => {
               
            return (
                <li className={(this.state.selectedMenu === 'settings') ? 'active' : ''}>
                    <a href="javascript:void(0)" onClick={() => { this.setState({ settingsCollapsed: !this.state.settingsCollapsed }); return false; }}>
                        <i className="fa fa-fw fa-cogs">&nbsp;</i>
                        <span>Settings</span>
                    </a>
                    <ul className={classNames({ 'nav-sub': true, 'collapse': !this.state.settingsCollapsed })}>
                        <li className={(this.state.selectedSubMenu === 'selector-options') ? 'active' : ''}>
                            <a onClick={() => this.menuClicked('parent-portal')}>
                                <span>Parent Portal</span>
                            </a>
                        </li>
                        <li className={(this.state.selectedSubMenu === 'selector-options') ? 'active' : ''}>
                            <a onClick={() => this.menuClicked('selector-options')}>
                                <span>Selector Options</span>
                            </a>
                        </li>
                        <li className={(this.state.selectedSubMenu === 'invoice-setup') ? 'active' : ''}>
                            <a onClick={() => this.menuClicked('invoice-setup')}>
                                <span>Invoice Setup</span>
                            </a>
                        </li>
                        <li className={(this.state.selectedSubMenu === 'import') ? 'active' : ''}>
                            <a onClick={() => this.menuClicked('import')}>
                                <span>Import</span>
                            </a>
                        </li>
                    </ul>
                </li>
                );
            };




        return(
            <aside className="sidebar sidebar-left">
                 <div className="sidebar-profile">
                    {school.hasLogo && this.hasLogo(school._id)}
                    <div className="img-circle profile-body">
                        <h4>{this.props.userSettings.schoolName}</h4>
                        <small className="title">School Music Admin</small>
                    </div>
                </div>
                <nav className="sidebarNav">
                    <ul className="nav nav-pills nav-stacked">
                        
                        {menuItem('dashboard', 'Dashboard', 'tachometer', menuPermissions.dashboard, fullAdmin)}
                        {menuItem('students', 'Students', 'child', menuPermissions.students, fullAdmin)}
                        {menuItem('instruments', 'Instruments', 'music ', menuPermissions.instruments, fullAdmin)}
                        {menuItem('contacts', 'Contacts', 'users', menuPermissions.contacts, fullAdmin)} 
                        {menuItem('events', 'Events', 'calendar', menuPermissions.events, fullAdmin)}
                        {menuItem('tuition', 'Tuition', 'graduation-cap', menuPermissions.tuition, fullAdmin)}
                        {menuItem('accounts', 'Accounts', 'bar-chart', menuPermissions.accounts, fullAdmin)}
                        {menuItem('forms', 'Forms', 'wpforms', menuPermissions.forms, fullAdmin)}
                        {(fullAdmin || menuPermissions.library) && libraryMenu()}
                        {(fullAdmin || menuPermissions.settings) && settingsMenu()}

                    </ul>
                </nav>
            </aside>

        );
    }
}

SideBar.propTypes = {
  userSettings: PropTypes.object.isRequired,
  school: PropTypes.object.isRequired
  
};

SideBar.contextTypes = {
    router: PropTypes.object
};

function mapStateToProps(state) {
    return {
       userSettings: state.usersetting,
       school: state.school
    };
}

export default withRouter(connect(mapStateToProps, actions)(SideBar));