import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import { Checkbox } from 'react-icheck';


const relationship = [ { label: 'Father'}, { label: 'Mother'}, { label: 'Grandparent'}, { label: 'Guardian'}, { label: 'Other'} ];

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
    <div>
        <DropdownList {...input} {...rest} onBlur={() => input.change} />
        { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
);

const renderCheckbox = ({ input }) => (
  <Checkbox 
    checkboxClass="icheckbox_square-blue" 
    increaseArea="20%"
    onChange={input.onChange}
    defaultChecked = {input.value ? true : false}
    />
);

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type={type} placeholder={label}/>
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const NewStudentContactForm = (props) => {
    const { handleSubmit, submitting, closeStudent } = props;

    return (
        <div className="clearfix basic-form">
            <form onSubmit={handleSubmit}>
                <h4 className="blueColor">First Contact</h4>
                <div className="row">
                    <div className="col-sm-12 col-md-5">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-3 col-md-4">
                                    <label>First Name</label>
                                </div>
                                <div className="col-sm-8 col-md-8">
                                    <Field name="contact1.firstName" type="text" component={renderField} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-3 col-md-4">
                                    <label>Last Name</label>
                                </div>
                                <div className="col-sm-8 col-md-8">
                                    <Field name="contact1.lastName" type="text" component={renderField} label="Last Name"/>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-3 col-md-4">
                                    <label>Mobile</label>
                                </div>
                                <div className="col-sm-8 col-md-8">
                                    <Field name="contact1.mobile" type="text" component={renderField} label="Mobile"/>
                                </div> 
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                         <div className="form-group">
                            <div className="row">
                                <div className="col-sm-3 col-md-4">
                                    <label>Email</label>
                                </div>
                                <div className="col-sm-8 col-md-8">
                                    <Field name="contact1.email" type="text" component={renderField} label="Email"/>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-3 col-md-4">
                                    <label>Relationship</label>
                                </div>
                                <div className="col-sm-8 col-md-8">
                                    <Field name="contact1.relationship" type="text" valueKey="label" simpleValue={true} clearable={false} component={renderDropdownList} options={relationship} />
                                </div> 
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-3 col-md-4">
                                    <label>Address</label>
                                </div>
                                <div className="col-sm-8 col-md-8">
                                    <Field name="contact1.address" type="text" component={renderField} label="Address"/>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <h4 className="blueColor">Second Contact</h4>
                <div className="row">
                    <div className="col-sm-12 col-md-5">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-3 col-md-4">
                                    <label>First Name</label>
                                </div>
                                <div className="col-sm-8 col-md-8">
                                    <Field name="contact2.firstName" type="text" component={renderField} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-3 col-md-4">
                                    <label>Last Name</label>
                                </div>
                                <div className="col-sm-8 col-md-8">
                                    <Field name="contact2.lastName" type="text" component={renderField} label="Last Name"/>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-3 col-md-4">
                                    <label>Mobile</label>
                                </div>
                                <div className="col-sm-8 col-md-8">
                                    <Field name="contact2.mobile" type="text" component={renderField} label="Mobile"/>
                                </div> 
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                         <div className="form-group">
                            <div className="row">
                                <div className="col-sm-3 col-md-4">
                                    <label>Email</label>
                                </div>
                                <div className="col-sm-8 col-md-8">
                                    <Field name="contact2.email" type="text" component={renderField} label="Email"/>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-3 col-md-4">
                                    <label>Relationship</label>
                                </div>
                                <div className="col-sm-8 col-md-8">
                                    <Field name="contact2.relationship" type="text" valueKey="label" simpleValue={true} clearable={false} component={renderDropdownList} options={relationship} />
                                </div> 
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-3 col-md-4">
                                    <label>Address</label>
                                </div>
                                <div className="col-sm-8 col-md-8">
                                    <Field name="contact2.address" type="text" component={renderField} label="Address"/>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-12 col-md-9 col-md-offset-3">
                            <Field name="contact2.sendEmail" component={renderCheckbox} />&nbsp; Send Emails to first and Second contacts
                        </div> 
                    </div>
                </div>
                <hr />
                <div className="margin-top-one">
                    <div className="col-xs-6">
                        <button type="submit" disabled={submitting} className="btn btn-primary rounded">Save</button>
                     </div>
                     <div className="col-xs-6 text-right">   
                        <button type="button" className="btn btn-sm btn-default rounded" onClick={() => closeStudent()}>Close</button>
                    </div>
                </div>
        </form>
    </div>
    );
};

NewStudentContactForm.propTypes = {
  ...propTypes
  
};

renderCheckbox.propTypes = {
    input: PropTypes.object.isRequired
};

renderField.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

renderDropdownList.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};


export default reduxForm({
    form: 'newStudentContactForm'
})(NewStudentContactForm);