import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import * as actions from '../../actions/students';
import { Link } from 'react-router-dom';
import ReactDataGrid from 'react-data-grid';
import { Data } from 'react-data-grid-addons';
import ExportForm from '../students/export-form';
import ExportRoll from './export-roll';
import EmptyToolbar from '../common/grid-toolbar';
import Student from '../students/student-page';

const Selectors = Data.Selectors;

class StudentsListPage extends Component {
    
     constructor(props) {
        super(props);

        this.state = {
            selectedStudents: [],
            rows: [],
            filters: {},
            showExport: false,
            sortColumn: null, 
            sortDirection: null,
            exportType: '',
            showStudent: false
        };

        this.rowGetter = this.rowGetter.bind(this);
        this.onRowsSelected = this.onRowsSelected.bind(this);
        this.onRowsDeselected = this.onRowsDeselected.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.getRows = this.getRows.bind(this);
        this.getSize = this.getSize.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.onClearFilters = this.onClearFilters.bind(this);
        this.handleGridSort = this.handleGridSort.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.closeExport = this.closeExport.bind(this);
        this.closeStudent = this.closeStudent.bind(this);
        this.openExport = this.openExport.bind(this);
        this.exportStudents = this.exportStudents.bind(this);
        this.exportRoll = this.exportRoll.bind(this);
    }

    componentWillMount() {
        //this.props.getGroupStudents(this.props.group_id);
        const groupList = _.filter(this.props.students, student => _.some(student.groups, ['groupId', this.props.group_id]));
        const rows = _.map(groupList, groupStudent => {
           const index = _.findIndex(groupStudent.groups, ['groupId', this.props.group_id]);
           if (index > -1)  {
               return { ...groupStudent, instrument: groupStudent.groups[index].instrument, status: groupStudent.groups[index].status};
        }});
        this.setState({rows});
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.students !== this.props.students) {
            const groupList = _.filter(nextProps.students, student => _.some(student.groups, ['groupId', this.props.group_id]));
            const rows = _.map(groupList, groupStudent => {
               const index = _.findIndex(groupStudent.groups, ['groupId', this.props.group_id]);
               if (index > -1)  {
                   return { ...groupStudent, instrument: groupStudent.groups[index].instrument, status: groupStudent.groups[index].status};
            }});
            this.setState({rows});
        }
     }

    //  componentWillReceiveProps(nextProps) {
    //     if (nextProps.students !== this.props.students) {
    //       this.setState({rows: nextProps.students.slice(0)});
    //     }
    //  }

    rowGetter(i) {
        const rows = this.getRows();
        return rows[i];
    }

     onRowsSelected(rows) {
        this.setState({selectedStudents: this.state.selectedStudents.concat(rows.map(r => r.row))});
    }

    onRowsDeselected(rows) {
        let ids = rows.map(r => r.row._id);
        this.setState({selectedStudents: this.state.selectedStudents.filter(student => !ids.some(id => id === student._id) )});
    }

    onRowClick(rowIdx, row) {
        if (row) {
            this.setState({ showStudent: true, id: row._id })
        }
    }

    getRows() {
         return Selectors.getRows(this.state);
     }

    getSize() {
        return this.getRows().length;
    }

    handleFilterChange(filter) {
        const newFilters = Object.assign({}, this.state.filters);
        if (filter.filterTerm) {
        newFilters[filter.column.key] = filter;
        } else {
        delete newFilters[filter.column.key];
        }
        this.setState({filters: newFilters});
    }

    onClearFilters() {
        //all filters removed
        this.setState({filters: {} });
    }

     handleGridSort(sortColumn, sortDirection) {
        let state = Object.assign({}, this.state, {sortColumn: sortColumn, sortDirection: sortDirection});
        this.setState(state);
    }

     sendEmail() {
        const studetList = this.state.selectedStudents;
        if (studetList.length) {
            this.props.emailList(studetList);
        } else {
            this.props.emailList(this.state.rows);
        }
        this.props.history.push('/send-email');
    }

     openExport(exportType) {
        this.setState({ showExport: true, exportType });
    }

    closeExport() {
        this.setState({ showExport: false, exportType: '' });
    }

    closeStudent() {
        this.setState({ showStudent: false });
    }

    exportStudents(exportOptions) {

        let exportList = [];
        if (this.state.selectedStudents.length === 0) { 
            exportList = this.state.rows.map(row => row._id);
        } else {
            exportList = this.state.selectedStudents.map(student => student._id);
        }
        
        this.props.exportStudents(exportList, exportOptions, this.props.userSetting.schoolId);
        this.closeExport();
    }

    exportRoll(exportOptions) {
        this.props.exportRoll(this.props.group_id, this.props.userSetting.schoolId, exportOptions);
        this.closeExport();
    }

    studentTags(tags) {
        if (tags.value.length === 0) return null;
        return (
            <div>
               {tags.value.map((tag, index) => {
                   return <span key={index} className="label label-info label-padding">{tag}</span>;
                   })}
            </div>
        );
    }
       
    render() {

        const columns = [
            {
                key: 'firstName',
                name: 'First Name',
                sortable : true,
                filterable : true,
                width: 150
            },
            {
                key: 'lastName',
                name: 'Last Name',
                sortable : true,
                filterable : true,
                width: 150
            },
             {
                key: 'grade',
                name: 'Grade',
                sortable : true,
                filterable : true,
                width: 70
            },
             {
                key: 'class',
                name: 'Class',
                sortable : true,
                filterable : true,
                width: 80
            },
            {
                key: 'instrument',
                name: 'Instrument',
                sortable : true,
                filterable : true,
            },
            {
                key: 'studentTags',
                name: 'Tags',
                sortable : true,
                filterable : true,
                formatter: this.studentTags
            },
            {
                key: 'status',
                name: 'Status',
                sortable : true,
                filterable : true,
                width: 100
            }
            ];


        return (
         <div>   
            <div>
                <div className="text-right">
                    <button className="btn btn-warning btn-xs rounded" type="button" onClick={() => this.sendEmail()}>Email Group</button>&nbsp;&nbsp;
                    <Link to="/add-student" className="btn btn-primary btn-trans btn-xs rounded">Add Student</Link>&nbsp;&nbsp;
                    <button type="button" className="btn btn-xs btn-info rounded" onClick={() => this.openExport('roll')} >Roll</button>&nbsp;&nbsp;
                    <button type="button" className="btn btn-xs btn-default rounded" onClick={() => this.openExport('export')} >Export</button>
                </div>
                <ReactDataGrid
                        rowKey="_id"
                        onGridSort={this.handleGridSort}
                        columns={columns}
                        rowGetter={this.rowGetter}
                        rowsCount={this.getSize()}
                        minHeight={this.getSize() * 35 + 85}
                        onRowClick={this.onRowClick}
                        toolbar={<EmptyToolbar />}
                        onAddFilter={this.handleFilterChange}
                        onClearFilters={this.onClearFilters}
                        rowSelection={{
                            showCheckbox: true,
                            enableShiftSelect: true,
                            onRowsSelected: this.onRowsSelected,
                            onRowsDeselected: this.onRowsDeselected,
                             selectBy: {
                                keys: { rowKey: '_id', values: this.state.selectedStudents.map(row => row._id)}
                            }
                        }} />
            </div> 
            <div className="admin-label text-right margin-top-quarter"><i className="blueBG"> { (this.getSize() === this.state.rows.length) ? `${this.state.rows.length} Students` : `Showing ${this.getSize()} of ${this.state.rows.length} Students`} </i></div><br />
            
            <Modal show={this.state.showExport} onHide={this.closeExport}>
                <Modal.Header bsClass="modal-header primary" closeButton>
                    <Modal.Title>{(this.state.exportType === 'export') ? 'Student Export' : 'Create Student Roll'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-padding">
                        {(this.state.exportType === 'export') && <ExportForm onSubmit={this.exportStudents} />}
                        {(this.state.exportType === 'roll') && <ExportRoll onSubmit={this.exportRoll} />}
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={this.state.showStudent} dialogClassName="fullSize" onHide={this.closeStudent}>
                <Modal.Body>
                    <Student id={this.state.id} onClose={this.closeStudent} />
                </Modal.Body>
            </Modal>
        </div>                 
        );
    }
}

StudentsListPage.propTypes = {
  students: PropTypes.object.isRequired,
  userSetting: PropTypes.object.isRequired,
  getStudent: PropTypes.func.isRequired,
  group_id: PropTypes.string.isRequired,
  emailList: PropTypes.func.isRequired,
  exportStudents: PropTypes.func.isRequired,
  exportRoll: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
      userSetting: state.usersetting,
      students: state.students.all
    };
}

export default connect(mapStateToProps, actions)(StudentsListPage);
