import axios from 'axios';
import Notifications from 'react-notification-system-redux';
// import {reset} from 'redux-form';
// import { browserHistory } from 'react-router';
import fileDownload from 'js-file-download';
import { GET_DOCUMENTS, GET_TEMPLATES, NO_ACTION, ADD_ATTACHMENT, GET_FOLDERS, ROOT_URL  } from './types';

function treeData(nodes) {
    const treeNodes = nodes.map(item => ({
        hasCaret: true,
        iconName: "folder-close",
        label: item.label,
        id: item._id,
        childNodes: item.folderContents.map(file => ({ id: file._id, iconName: file.iconName, label: file.label, secondaryLabel: file.share.active ? file.secondaryLabel + ' | *' : file.secondaryLabel, share : file.share })) 
    }));
    return treeNodes;
}

function templateTreeData(nodes) {
    const treeNodes = nodes.map(item => ({
        hasCaret: true,
        iconName: "folder-close",
        label: item.label,
        id: item._id,
        childNodes: item.folderContents.map(template => ({ 
          id: template._id,
          iconName: template.iconName,
          label: template.label,
          subject: template.subject,
          emailBody: template.emailBody,
          attachments: template.attachments }))
    }));
    return treeNodes;
}

export function getTemplateFolders(schoolId) {
  return function(dispatch) {
    axios.get(`${ROOT_URL}/email-folders/${schoolId}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        dispatch({ type: GET_FOLDERS, payload: response.data });
      });
  };
}

export function getDocuments(schoolId) {

  return function(dispatch) {
    axios.get(`${ROOT_URL}/library/file/${schoolId}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        const treeNodes = treeData(response.data);
        const fileList = response.data.reduce((files, folders) => {
          if (folders.label !== 'Attachments') {
            return files.concat(folders.folderContents.map(item => ({ id: item._id, listView: `${folders.label} - ${item.label}`, label: item.label })));
          } else {
            return files;
          }
    }, []);
        dispatch({ type: GET_DOCUMENTS, payload: treeNodes, fileList });
      });
  };
}

export function addFolder(folderDetails, schoolId) {
   return function(dispatch) {
    axios.post(`${ROOT_URL}/library/file/folder/${schoolId}`, {folderDetails},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
        const treeNodes = treeData(response.data);

        dispatch(Notifications.success({ title: 'Folder Added' }));
        dispatch({
          type: GET_DOCUMENTS,
          payload: treeNodes
        });
      });
  };
}

export function updateFolder(folderDetails, id) {
   return function(dispatch) {

    axios.put(`${ROOT_URL}/library/file/folder/${id}`, {folderDetails},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
   .then(response => {
        const treeNodes = treeData(response.data);

        dispatch(Notifications.success({ title: 'Folder Updated' }));
        dispatch({
          type: GET_DOCUMENTS,
          payload: treeNodes
        });
      });
  };
}

export function removeFolder(id) {
   return function(dispatch) {
    axios.delete(`${ROOT_URL}/library/file/folder/${id}`, 
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
        const treeNodes = treeData(response.data);

        dispatch(Notifications.error({ title: 'Folder Removed' }));
        dispatch({
          type: GET_DOCUMENTS,
          payload: treeNodes
        });
      });
  };
}

// export function uploadFile(file, id) {
//   return function(dispatch) {
//     if (file.length === 0) {
//       dispatch(Notifications.error({ title: 'Invalid File', message: 'Max size 1 mb, File type csv' }));
//     } else {
//       const data = new FormData();
//       data.append('file', file[0]);

//        axios.post(`${ROOT_URL}/library/file/${id}`, data,
//         { headers: { authorization: localStorage.getItem('token') } 
//         })
//         .then(response => {
//         const treeNodes = treeData(response.data);

//         dispatch({
//           type: GET_DOCUMENTS,
//           payload: treeNodes
//         });
//       });
//     }
//   };
// }

export function addFile(files) {
  const treeNodes = treeData(files);

  return {
    type: GET_DOCUMENTS,
    payload: treeNodes
  };

 }

export function removeFile(id) {
   return function(dispatch) {
    axios.delete(`${ROOT_URL}/library/file/${id}`, 
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
        const treeNodes = treeData(response.data);

        dispatch(Notifications.error({ title: 'File Removed' }));
        dispatch({
          type: GET_DOCUMENTS,
          payload: treeNodes
        });
      });
  };
}

export function fileSharing(file) {
   return function(dispatch) {
    axios.post(`${ROOT_URL}/library/file/share`, {file},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
        const treeNodes = treeData(response.data);

        dispatch(Notifications.success({ title: 'File Sharing Updated' }));
        dispatch({
          type: GET_DOCUMENTS,
          payload: treeNodes
        });
      });
  };
}

export function downloadFile(file) {

    axios.get(`${ROOT_URL}/library/download/${file.id}`,
    { headers: { authorization: localStorage.getItem('token') }, responseType: 'arraybuffer'})
    .then(response => {
        fileDownload(response.data, file.label);
        });

  return {
    type: NO_ACTION,
    payload: ''
  };
}

export function moveFile(folder, file) {
   return function(dispatch) {
    axios.put(`${ROOT_URL}/library/file/move`, {file, folder},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
        const treeNodes = treeData(response.data);

        dispatch(Notifications.success({ title: `File Moved to ${folder.label}` }));
        dispatch({
          type: GET_DOCUMENTS,
          payload: treeNodes
        });
      });
  };
}


export function getTemplates(schoolId) {

  return function(dispatch) {
    axios.get(`${ROOT_URL}/library/template/${schoolId}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        const treeNodes = templateTreeData(response.data);

        dispatch({
          type: GET_TEMPLATES,
          payload: treeNodes
        });
      });
  };
}

export function addTemplateFolder(folderDetails, schoolId) {
   return function(dispatch) {
    axios.post(`${ROOT_URL}/library/template/folder/${schoolId}`, {folderDetails},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
        const treeNodes = templateTreeData(response.data);

        dispatch(Notifications.success({ title: 'Template Folder Added' }));
        dispatch({
          type: GET_TEMPLATES,
          payload: treeNodes
        });
      });
  };
}

export function updateTemplateFolder(folderDetails, id) {
   return function(dispatch) {

    axios.put(`${ROOT_URL}/library/template/folder/${id}`, {folderDetails},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
   .then(response => {
        const treeNodes = templateTreeData(response.data);

        dispatch(Notifications.success({ title: 'Template Folder Updated' }));
        dispatch({
          type: GET_TEMPLATES,
          payload: treeNodes
        });
      });
  };
}

export function removeTemplateFolder(id) {
   return function(dispatch) {
    axios.delete(`${ROOT_URL}/library/template/folder/${id}`, 
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
        const treeNodes = templateTreeData(response.data);

        dispatch(Notifications.error({ title: 'Template Folder Removed' }));
        dispatch({
          type: GET_TEMPLATES,
          payload: treeNodes
        });
      });
  };
}

export function removeTemplate(id) {
   return function(dispatch) {
    axios.delete(`${ROOT_URL}/library/template/${id}`, 
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
        const treeNodes = templateTreeData(response.data);

        dispatch(Notifications.error({ title: 'Email Template Removed' }));
        dispatch({
          type: GET_TEMPLATES,
          payload: treeNodes
        });
      });
  };
}

export function saveTemplate(template, id) {
   return function(dispatch) {
    axios.post(`${ROOT_URL}/library/template/${id}`, {template},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
        const treeNodes = templateTreeData(response.data);

        dispatch(Notifications.success({ title: 'Email Template Saved' }));
        dispatch({
          type: GET_TEMPLATES,
          payload: treeNodes
        });
      });
  };
}

export function moveTemplate(folder, template) {
   return function(dispatch) {
    axios.put(`${ROOT_URL}/library/template/move`, {folder, template},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
        const treeNodes = templateTreeData(response.data);

        dispatch(Notifications.success({ title: `Template Moved to ${folder.label}` }));
        dispatch({
          type: GET_TEMPLATES,
          payload: treeNodes
        });
      });
  };
}

export function addAttachment(attachment) {

  return {
    type: ADD_ATTACHMENT,
    payload: attachment
  };

 }

 export function displayWarning(message) {
  return Notifications.error({ title: message });
}