import { GET_INSTRUMENTS, GET_INSTRUMENT, ADD_INSTRUMENT, GET_INSTRUMENTS_ARCHIVE,
    INSTRUMENT_COMMENT_ADD, INSTRUMENT_COMMENT_DEL, UPDATE_INSTRUMENTS } from '../actions/types';
import initialState from './initial-state';

export default function(state = initialState.instruments, action) {
    switch(action.type) {

        case GET_INSTRUMENTS:
            return { ...state, all: action.payload };
        
        case GET_INSTRUMENTS_ARCHIVE:
            return { ...state, archive: action.payload };

        case ADD_INSTRUMENT:
            return { ...state, all: [action.payload, ...state.all] };

        case UPDATE_INSTRUMENTS:
            return { ...state, all: state.all.map(inst => inst._id === action.payload._id ? action.payload : inst) };

        case GET_INSTRUMENT:
            return { ...state, selectedInstrument: action.payload };

        case INSTRUMENT_COMMENT_ADD:
            return { ...state, selectedInstrument: {...state.selectedInstrument, noComments: state.selectedInstrument.noComments + 1,
            comments: [action.payload, ...state.selectedInstrument.comments.slice()] } };

        case INSTRUMENT_COMMENT_DEL:
            return { ...state, selectedInstrument: {...state.selectedInstrument, noComments: state.selectedInstrument.noComments - 1,
                        comments: [...state.selectedInstrument.comments.slice(0, action.payload), ...state.selectedInstrument.comments.slice(action.payload + 1) ] } };


        default:
            return state;
    }
}