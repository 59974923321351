import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data } from 'react-data-grid-addons';

const Selectors = Data.Selectors;

class RecipientsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: this.props.recipients,
      filters: {},
      sortColumn: null,
      sortDirection: null,
    };

    this.rowGetter = this.rowGetter.bind(this);
    this.getRows = this.getRows.bind(this);
    this.getSize = this.getSize.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.onClearFilters = this.onClearFilters.bind(this);
    this.handleGridSort = this.handleGridSort.bind(this);
    this.emailOpened = this.emailOpened.bind(this);
    this.emailStatus = this.emailStatus.bind(this);
  }

  rowGetter(i) {
    const rows = this.getRows();
    return rows[i];
  }

  getRows() {
    return Selectors.getRows(this.state);
  }

  getSize() {
    return this.getRows().length;
  }

  handleFilterChange(filter) {
    const newFilters = Object.assign({}, this.state.filters);
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
    this.setState({ filters: newFilters });
  }

  onClearFilters() {
    //all filters removed
    this.setState({ filters: {} });
  }

  handleGridSort(sortColumn, sortDirection) {
    const state = Object.assign({}, this.state, { sortColumn: sortColumn, sortDirection: sortDirection });
    this.setState(state);
  }

  emailOpened(value) {
    return (
      <div>
        <i className={value.value ? 'fa fa-check' : ''} aria-hidden='true'></i>
      </div>
    );
  }

  emailStatus(value) {
    let classValue = 'blueBG';
    if (value.value === 'delivered' || value.value === 'Delivered') {
      classValue = 'greenBG';
    } else if (value.value === 'Delayed') {
      classValue = 'orangeBG';
    } else if (
      value.value === 'Failed' ||
      value.value === 'dropped' ||
      value.value === 'bounce' ||
      value.value === 'spam report'
    ) {
      classValue = 'redBG';
    }
    classValue += ' capitalize';

    return (
      <div>
        <div className='admin-label'>
          <i className={classValue}>{value.value}</i>
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.recipients) {
      return <div>loading...</div>;
    }

    const columns = [
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        filterable: true,
      },
      {
        key: 'email',
        name: 'Email',
        sortable: true,
        filterable: true,
      },
      {
        key: 'status',
        name: 'Status',
        sortable: true,
        filterable: true,
        width: 120,
        formatter: this.emailStatus,
        getRowMetaData: (row) => row,
      },
      {
        key: 'opened',
        name: 'Opened',
        sortable: true,
        width: 80,
        formatter: this.emailOpened,
        getRowMetaData: (row) => row,
      },
    ];

    return (
      <div>
        <ReactDataGrid
          rowKey='_id'
          onGridSort={this.handleGridSort}
          columns={columns}
          gridWidth='100%'
          rowGetter={this.rowGetter}
          rowsCount={this.getSize()}
          minHeight={this.getSize() * 35 + 85}
          onRowClick={this.onRowClick}
          toolbar={<Toolbar enableFilter={true} />}
          onAddFilter={this.handleFilterChange}
          onClearFilters={this.onClearFilters}
        />
      </div>
    );
  }
}

RecipientsPage.propTypes = {
  recipients: PropTypes.array.isRequired,
  onComment: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    userSetting: state.usersetting,
    recipients: state.logs.emailLog.recipients,
  };
}

export default connect(mapStateToProps)(RecipientsPage);
