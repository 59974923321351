import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import EmailEditor from '../editor/EmailEditor';
import DropdownList from 'react-select';
import { required } from '../common/validation';

const renderField = ({ input, label, meta: { touched, error } }) => (
  <div>
    <input className='form-control' {...input} type='text' placeholder={label} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
  <div>
    <DropdownList {...input} {...rest} onBlur={() => input.change} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderEditor = ({ input, onEmailBody }) => (
  <EmailEditor value={input.value} handleOnChange={input.onChange} onBlur={onEmailBody(input.value)} />
);

const TemplateForm = (props) => {
  const {
    handleSubmit,
    submitting,
    onEmailBody,
    initialValues,
    closeTemplate,
    removeTemplate,
    moveTemplate,
    fileList,
  } = props;

  return (
    <div className='basic-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-2'>
              <label>Template Label</label>
            </div>
            <div className='col-sm-9'>
              <Field name='label' type='text' component={renderField} label='Template Label' validate={required} />
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-2'>
              <label>Subject</label>
            </div>
            <div className='col-sm-9'>
              <Field name='subject' type='text' component={renderField} label='Subject' />
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-2'>
              <label>Attachments</label>
            </div>
            <div className='col-sm-9'>
              <Field
                name='attachments'
                type='text'
                valueKey='id'
                labelKey='listView'
                component={renderDropdownList}
                multi={true}
                options={fileList}
                valueRenderer={(option) => option.label}
              />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-12'>
              <Field name='emailBody' type='textarea' onEmailBody={onEmailBody} component={renderEditor} />
            </div>
            <div className='clearfix'>&nbsp;</div>
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-8'>
            <button type='submit' className='btn btn-primary rounded' disabled={submitting}>
              Save
            </button>
            &nbsp;&nbsp;
            {initialValues.label && (
              <button type='button' className='btn btn-info' onClick={() => moveTemplate(initialValues.id)}>
                Move
              </button>
            )}
            &nbsp;&nbsp;
            <button type='button' className='btn btn-default rounded' onClick={() => closeTemplate()}>
              Close
            </button>
          </div>
          <div className='col-sm-4 text-right'>
            {initialValues.label && (
              <button
                type='button'
                className='btn btn-sm btn-warning rounded'
                onClick={() => removeTemplate(initialValues.id)}
              >
                Remove
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

TemplateForm.propTypes = {
  ...propTypes,
};

renderField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

renderEditor.propTypes = {
  input: PropTypes.object.isRequired,
  onEmailBody: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'templateForm',
})(TemplateForm);
