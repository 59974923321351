import React from 'react';
import PropTypes from 'prop-types';

const regQuestions = (questions) => (
    <ul className="list-unstyled">
        {questions.map((question, index) =>
            <li key={index}>
                <div className="list-detail-2">
                    <span className="box-heading-2">{question.question}</span>
                    <span className="box-description-2">Response: {question.answer}</span>
                    <span className="box-time-2">{question.comment}</span>
                </div>
            </li>
        )}
    </ul>
);

const ReEnroleDetails = (props) => {
    const { regDetails } = props;

    return (
            <div className="clearfix">
                <div className="col-sm-12">
                    <h4>Registration Questions</h4>
                    <div className="our-clients-2 admin-widget-2">
                        <div className="content-list-2">
                                { regDetails && regQuestions(regDetails.questions) }
                        </div>
                    </div>
                </div>
          </div>   
      
    );
};

ReEnroleDetails.propTypes = {
  regDetails: PropTypes.object.isRequired
};

export default ReEnroleDetails;