import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm, propTypes } from 'redux-form';
import { Link } from 'react-router-dom';
import DropdownList from 'react-select';
import { DateInput } from "@blueprintjs/datetime";
import { Checkbox } from "@blueprintjs/core";
import moment from 'moment';

const renderDropdownList = ({ input, change, index, meta: { touched, error }, ...rest }) => (
    <div>
        <DropdownList {...input} {...rest} 
            onChange={(value) => {
                change(`invoiceItems[${index}].description`, value.label);
                input.onChange(value.value);
                }} 
            onBlur={() => input.change} />
        { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
);

const renderField = ({ input, label, type, tabIndex, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type={type} tabIndex={tabIndex} placeholder={label}/>
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderCurrencyField = ({ input, label, type, meta: { touched, error } }) => (
  <div className="input-group">
      <span className="input-group-addon">$</span>
      <input className="form-control" {...input} type={type} placeholder={label}/>
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderDatePicker = ({ input, meta: { touched, error } }) => (
  <div>
      <DateInput value={input.value} onChange={input.onChange} format={'DD MMM YYYY'} minDate={moment().add(-1, 'years').toDate()} maxDate={moment().add(6, 'months').toDate()} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderCheckbox = ({ input }) => (
  <label className="pt-control pt-checkbox pt-large">
      <Checkbox checked={input.value} onChange={input.onChange} />
  </label>
);

const renderItems = ({ fields, accounts, change }) => (
    <div>
        <div className="row">
            <div className="col-sm-6 col-sm-offset-6"><DropdownList options={accounts} placeholder="Add Invoice Item" onChange={(item) => fields.push({ account: item.value, description: item.label })} /></div>
        </div>
        <table className="table table-bordered margin-top-one">
        <thead>
        <tr>
            <th width="20%">Account</th>
            <th width="45%">Description</th>
            <th width="25%">Amount</th>
            <th width="10%"><i className="fa fa-trash" aria-hidden="true"></i></th>
        </tr>
        </thead>
        <tbody>
            {fields.map((invoiceItems, index) => {
              return (<tr key={index}>
                    <td><Field name={`${invoiceItems}.account`} type="text" labelKey="value" clearable={false} component={renderDropdownList} options={accounts} change={change} index={index} /></td>
                    <td><Field name={`${invoiceItems}.description`} type="text" component={renderField} /></td>
                    <td><Field name={`${invoiceItems}.amount`} type="text" component={renderCurrencyField} /></td>
                    <td><div className="top-5" onClick={() => fields.remove(index)}><i className="fa fa-times">&nbsp;</i></div></td>
                </tr>);
            }
        )}
        </tbody>
        </table>
    </div>
  
);


const InvoiceForm = (props) => {
    const { handleSubmit, submitting, accounts, change } = props;
    return (
        <div className="basic-form">
            <form onSubmit={handleSubmit}>

            <div className="row">
              <div className="col-sm-12 col-md-5 form-divider">
                <div className="col-xs-12 col-sm-6 col-md-12">
                    <div className="row">
                        <div className="col-xs-6">
                            <label>Invoice Date</label>
                        </div>
                        <div className="col-xs-6">
                        <Field name="invoiceDate" type="text" component={renderDatePicker} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6">
                            <label>Due Date</label>
                        </div>
                        <div className="col-xs-6">
                        <Field name="dueDate" type="text" component={renderDatePicker} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6">
                            <label>Invoice Comment</label>
                        </div>
                        <div className="col-xs-6">
                        <Field name="invoiceComment" type="text" component="textarea" className="form-control" placeholder="Optional comment displayed on invoice" />
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                    <div className="row">
                        <div className="col-xs-6">
                            <label>Apply Inst. Hire</label>
                        </div>
                        <div className="col-xs-6">
                        <Field name="instrumentHire" component={renderCheckbox} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-6">
                            <label>Apply Discount</label>
                        </div>
                        <div className="col-xs-6">
                        <Field name="discount" component={renderCheckbox} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-7">
                <FieldArray name="invoiceItems" component={renderItems} accounts={accounts} change={change} />
            </div>
          </div>
          <hr />
          <div className="row">
                <div className="col-xs-12">
                    <button type="submit" className="btn btn-primary btn-sm rounded" disabled={submitting}>Create Invoices</button>&nbsp;&nbsp;
                    <Link to="accounts" className="btn btn-trans btn-default btn-sm rounded">Cancel</Link>
                </div>
            </div>
        </form>
    </div>
    );
};

InvoiceForm.propTypes = {
    ...propTypes,
};

renderItems.propTypes = {
    fields: PropTypes.object.isRequired,
    accounts: PropTypes.array.isRequired,
    change: PropTypes.func.isRequired,
};

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired
};

renderDropdownList.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
};

renderCurrencyField.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  tabIndex: PropTypes.string
};

renderField.propTypes = {
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    tabIndex: PropTypes.string
};

renderDatePicker.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
};

export default reduxForm({
    form: 'invoiceForm'
})(InvoiceForm);