import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/invoice';
import { Link } from 'react-router-dom';
import AddInvoiceForm from './add-invoice-form';
import moment from 'moment';
import StudentList from './student-list';
import DropdownList from 'react-select';

class AddInvoicePage extends Component {

     constructor(props, context) {
        super(props, context);

        this.state = {
            students: [],
            newStudents: false
        };

        this.createInvoices = this.createInvoices.bind(this);
        this.selectedStudents = this.selectedStudents.bind(this);
    }

    componentDidMount() {
        this.props.getNewStudents(this.props.userSetting.schoolId);
    }

    createInvoices(values) {
        this.props.createInvoices(values, this.state.students, this.props.userSetting.schoolId);
    }

    selectedStudents(values) {
        const students = values.map(student => student._id);
        this.setState({ students });
      }

    render() {
      const { students } = this.props;

        return(
            <div>
                <div className="pageheader">
                    <Link to="accounts">Invoice List</Link> / Add Invoice
                </div>
                <section id="main-content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel panel-success">
                                    <div className="panel-heading">Invoice Details</div>
                                    <div className="panel-body">
                                        <AddInvoiceForm 
                                            onSubmit={this.createInvoices} 
                                            accounts={this.props.accounts} 
                                            initialValues={{ invoiceDate: moment().toDate(), dueDate: moment().add(1, 'month').toDate() }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel panel-primary">
                                    <div className="panel-heading">Create Inoices For</div>
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-xs-5 col-xs-offset-7 col-sm-4 col-sm-offset-8 col-md-3 col-md-offset-9">
                                                <DropdownList simple={true} value={this.state.newStudents} onChange={(input) => this.setState({ newStudents: input.value, students: (input.value) ? this.props.students.studentList : this.props.students.all })} options={[{ value: false, label: 'Active students'}, { value: true, label: 'New Students' }]} />
                                            </div>
                                        </div>
                                        <StudentList students={(this.state.newStudents) ? students.studentList : students.all} selectedStudents={this.selectedStudents} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
            </div>
        );
    }
}

AddInvoicePage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  students: PropTypes.object.isRequired,
  accounts: PropTypes.array.isRequired,
  createInvoices: PropTypes.func.isRequired,
  getNewStudents: PropTypes.func.isRequired
};


function mapStateToProps(state) {
  return {
        userSetting: state.usersetting,
        accounts: state.school.selectors.accounts,
        students: state.students 
  };
}

export default connect(mapStateToProps, actions)(AddInvoicePage);
