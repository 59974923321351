import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import { Checkbox } from 'react-icheck';

const renderField = ({ input, label, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type="text" placeholder={label} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderCheckbox = ({ input }) => (
  <Checkbox 
    checkboxClass="icheckbox_square-blue" 
    increaseArea="20%"
    name={input.name}
    onChange={input.onChange}
    defaultChecked = {input.value ? true : false}
    />
);

const renderDropdownList = ({ input, ...rest }) =>
  <DropdownList {...input} {...rest} onBlur={() => input.change} />;

const FileShareForm = (props) => {
    const { handleSubmit, submitting, initialValues, groups, closeOption } = props;

    return (
      <div className="basic-form">
        <form onSubmit={handleSubmit}>

            <div className="form-group">
                <div className="row">
                    <div className="col-sm-3">
                        <label>Groups</label>
                    </div>
                    <div className="col-sm-8">
                        <Field name="share.groups" type="text" component={renderDropdownList} multi={true} options={groups} valueKey="_id" labelKey="name" simpleValue={true} placeholder="Assign document to one or more groups" />
                    </div>
                </div>
            </div>

            <div className="form-group">
                <div className="row">
                    <div className="col-sm-3">
                        <label>Comment</label>
                    </div>
                    <div className="col-sm-8">
                        <Field name="share.comment" type="text" component={renderField} label="Optional comment display with document"/>
                    </div>
                </div>
            </div>

            <div className="form-group">
                <div className="row">
                    <div className="col-sm-3">
                        <label>Share</label>
                    </div>
                    <div className="col-sm-8">
                        <Field name="share.active" component={renderCheckbox} />
                    </div>
                </div>
            </div>

            <hr />
            <div className="row">
              <div className="col-sm-12">
                  <button type="submit" className="btn btn-sm btn-primary rounded" disabled={submitting}>{(initialValues.share.active) ? 'Update' : 'Share'}</button>&nbsp;&nbsp;
                  <button type="button" className="btn btn-sm btn-default rounded" onClick={() => closeOption()}>Close</button>
              </div>
            </div>
        </form>
      </div>
    );
};

FileShareForm.propTypes = {
    ...propTypes
};

renderField.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

renderDropdownList.propTypes = {
    input: PropTypes.object.isRequired
};

renderCheckbox.propTypes = {
    input: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'fileShareForm'
})(FileShareForm);