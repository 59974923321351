import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tab, Nav, NavItem, Row, Col } from 'react-bootstrap';
import * as actions from '../../actions/settings';
import ListInstruments from './list-instruments';
import ListStudentStatus from './list-student-status';
import ListStudentTags from './list-student-tags';
import ListContactCategories from './list-contact-categories';
import ListVenues from './list-venues';
import FormTermDates from './form-term-dates';
import HolidayDates from './list-holidays';
import ListAccounts from './list-accounts';

class SelectorsPage extends Component {

       constructor(props, context) {
        super(props, context);

         this.saveTermDates = this.saveTermDates.bind(this);
    }

    saveTermDates(termDates) {
        this.props.updateTermDates(termDates, this.props.userSetting.schoolId);

  }

    render() {

        return(
            <div>
                <div className="pageheader"><h2>Settings</h2></div>
                <section id="main-content">
                        <div className="row">
                            <div className="col-md-12 col-lg-11">
                                <div className="panel">
                                    <div className="panel-body">
                                        <Tab.Container id="contact-tabs" defaultActiveKey="categories">
                                            <Row className="clearfix">
                                                <Col sm={12}>
                                                    <Nav className="nav nav-tabs info-tab">
                                                        <NavItem eventKey="categories">
                                                            Contact Categories
                                                        </NavItem>
                                                        <NavItem eventKey="instruments">
                                                            Instruments
                                                        </NavItem>
                                                        <NavItem eventKey="studentStatus">
                                                            Student Status
                                                        </NavItem>
                                                        <NavItem eventKey="studentTags">
                                                            Student Tags 
                                                        </NavItem>
                                                        <NavItem eventKey="venues">
                                                            Venues 
                                                        </NavItem>
                                                         <NavItem eventKey="termDates">
                                                            Term Dates 
                                                        </NavItem>
                                                        <NavItem eventKey="accounts">
                                                            Accounts
                                                        </NavItem>

                                                    </Nav>

                                                    <Tab.Content animation>

                                                        <Tab.Pane eventKey="categories">
                                                            <br />
                                                            <ListContactCategories />
                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="instruments">
                                                            <br />
                                                            <ListInstruments />
                                                          
                                                        </Tab.Pane>
                                                      
                                                        <Tab.Pane eventKey="studentStatus">
                                                            <br />
                                                            <ListStudentStatus />
                                                            
                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="studentTags">
                                                            <br />
                                                            <ListStudentTags />
                                                            
                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="venues">
                                                            <br />
                                                            <ListVenues />
                                                            
                                                        </Tab.Pane>

                                                         <Tab.Pane eventKey="termDates">
                                                            <br />
                                                            <div className="clearfix">
                                                                <div className="col-md-6">
                                                                    <div className="panel panel-default">
                                                                        <div className="panel-heading">Term Dates</div>
                                                                        <div className="panel-body">
                                                                            <FormTermDates onSubmit={this.saveTermDates} initialValues={this.props.selectors.termDates}  />
                                                                         </div>
                                                                    </div>
                                                                </div>

                                                                 <div className="col-md-6">
                                                                    <div className="panel panel-default">
                                                                        <div className="panel-heading">Public and Other Holidays</div>
                                                                        <div className="panel-body">
                                                                            <HolidayDates />
                                                                         </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="accounts">
                                                            <br />
                                                            <ListAccounts />
                                                          
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                 </Col>
                                            </Row>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
        );
    }
}

SelectorsPage.propTypes = {
    userSetting: PropTypes.object.isRequired,
    selectors: PropTypes.object.isRequired,
    updateTermDates: PropTypes.func.isRequired
};


function mapStateToProps(state) {
    return {
        userSetting: state.usersetting,
        selectors: state.school.selectors
    };
}

export default connect(mapStateToProps, actions)(SelectorsPage);
