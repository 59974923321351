import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "../../actions/students";
import Select from "react-select";

class Preview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: false,
      sendTo: [],
    };
    this.sendPreview = this.sendPreview.bind(this);
    this.sendToChanged = this.sendToChanged.bind(this);
  }

  componentDidMount() {
    this.props.getStudent(this.props.studentId);
  }

  sendToChanged(sendTo) {
    this.setState({ sendTo });
  }

  sendPreview(emailBody) {
    const email = _.pick(this.props.emailForm, ["fromEmail", "subject"]);
    email.body = emailBody;
    email.sendTo = this.state.sendTo;
    this.props.sendEmailPreview(email, this.props.student.schoolId);
  }

  render() {
    const { student, email, contactList, closeModal, invoice } = this.props;

    if (!student || !student.studentContact) return null;

    const inv = invoice ? invoice : {};
    const emailTags = email
      .replace(/\[Parent_First]/g, student.studentContact.contact1.firstName)
      .replace(/\[Parent_Last]/g, student.studentContact.contact1.lastName)
      .replace(/\[Student_First]/g, student.firstName)
      .replace(/\[Student_Last]/g, student.lastName)
      .replace(/\[Student_Groups]/g, student.groupSummary)
      .replace(/\[Student_Instruments]/g, student.instruments)
      .replace("[INVOICE_TO]", inv.invoiceTo || "")
      .replace("[DUE_DATE]", inv.dueDate || "")
      .replace("[AMOUNT]", (inv.amount * 1).toFixed(2) || "")
      .replace("[INVOICE_ID]", inv._id || "");

    return (
      <div>
        <div className='row'>
          <div className='col-xs-12 text-right'>
            <button type='button' className='btn btn-default btn-xs' onClick={() => this.setState({ tags: !this.state.tags })}>
              Test Personalisation Tags
            </button>
          </div>
          <div className='col-xs-12 margin-top-half'>
            <div className='panel panel-default'>
              <div className='panel-body'>
                <div dangerouslySetInnerHTML={{ __html: this.state.tags ? emailTags : email }} />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-3 text-right'>Send preview email to:</div>
          <div className='col-xs-5'>
            <Select
              name='sendTo'
              multi
              simpleValue
              value={this.state.sendTo}
              options={contactList.map((contact) => ({ value: contact.email, label: `${contact.firstName} ${contact.lastName}` }))}
              onChange={this.sendToChanged}
            />
          </div>
          <div className='col-xs-4'>
            <button type='button' className='btn btn-success btn-sm' onClick={() => this.sendPreview(emailTags)}>
              Send
            </button>
            &nbsp;&nbsp;
            <button type='button' className='btn btn-default btn-sm' onClick={() => closeModal()}>
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Preview.propTypes = {
  studentId: PropTypes.string.isRequired,
  student: PropTypes.object.isRequired,
  invoice: PropTypes.object,
  emailForm: PropTypes.object.isRequired,
  contactList: PropTypes.array.isRequired,
  email: PropTypes.string,
  sendEmailPreview: PropTypes.func.isRequired,
  getStudent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    student: state.students.all[ownProps.studentId],
    emailForm: state.form.emailForm.values,
  };
}

export default connect(mapStateToProps, actions)(Preview);
