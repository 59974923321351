import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import ToggleButton from 'react-toggle-button';

const borderRadiusStyle = { borderRadius: 2 };
const renderToggleButton = ({ input }) => (
    <ToggleButton
        value={input.value || false}
        onToggle={(value) => input.onChange(!value)}
        thumbStyle={borderRadiusStyle}
        trackStyle={borderRadiusStyle}
    />
);

const ExportRosterForm = (props) => {
    const { handleSubmit, submitting } = props;

    return (
        <div className="clearfix form-padding">
            <form onSubmit={handleSubmit}>
                <h5>Include in Roster export:</h5>
                <div className="admin-widgets">
                    <div className="detail-list">
                        <ul className="clearfix">
                            <li className="pro-name">Contact details ( Email / Mobile)</li>
                            <li className="project-member"><Field name="contactDetails" component={renderToggleButton} /></li>
                        </ul>
                    </div>
                </div>
                <div className="text-center padding-top-one"><button type="submit" disabled={submitting} className="btn btn-primary">Download Roster</button></div>
        </form>
    </div>
    );
};

ExportRosterForm.propTypes = {
    ...propTypes
};

renderToggleButton.propTypes = {
    input: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'exportRosterForm'
})(ExportRosterForm);