import _ from 'lodash';
import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/instruments';
import InstrumentHireForm from './instrument-hire-form';
import HireHistory from './hire-history';
import InstrumentSummary from './instrument-summary';

class InstrumentHirePage extends Component {

     constructor(props, context) {
        super(props, context);

        this.saveHire = this.saveHire.bind(this);
        this.checkReserved = this.checkReserved.bind(this);
    }

    checkReserved(value) {
           this.props.checkReserved(this.props.instrument.reservedFor, value.label);
   
        }


    saveHire(value) {
        if (value.isHired) {
            this.props.saveHireDetails(value.hireDetails, this.props.instrument._id, this.props.onClose);
        } else {
        this.props.instrumentHire(value.hireDetails, this.props.instrument._id, this.props.onClose);
        }
    }
   
    render() {
    
        const { instrument, studentList, openModal } = this.props;

        return(
            <div>
               <InstrumentSummary instrument={instrument} />
               <hr />
               <InstrumentHireForm 
                  onSubmit={this.saveHire} 
                  returnInst={openModal} 
                  initialValues={instrument} 
                  isHired={instrument.isHired}
                  studentList={_.map(studentList, student => ({ value: student._id, label: `${student.firstName} ${student.lastName}`}))} 
                  checkReserved={this.checkReserved} />
               <HireHistory hireHistory={instrument.hireHistory} openModal={openModal} />
            </div>
        );
    }
}

InstrumentHirePage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  instrument: PropTypes.object.isRequired,
  studentList: PropTypes.object.isRequired,
  checkReserved: PropTypes.func.isRequired,
  instrumentHire: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  saveHireDetails: PropTypes.func.isRequired
};


function mapStateToProps(state) {
  return {
        userSetting: state.usersetting,
        instrument : state.instruments.selectedInstrument,
        studentList: state.students.all 
  };
}

export default connect(mapStateToProps, actions)(InstrumentHirePage);
