import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { required } from '../common/validation';

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <label>{label}</label>
    <div className="space-10">
      <input className="form-control space-5" {...input} type={type} placeholder={label}/>
      {touched && error && <h5><span className="label label-warning pull-right">{error}</span></h5>}
    </div>
  </div>
);

const StudentTagForm = (props) => {
    
    const { handleSubmit } = props;
   
    return (
        <form onSubmit={handleSubmit}>
            <Field name="studentTag" type="text" component={renderField} label="Student Tag" validate={required}/>
            <br />
            <button type="submit" className="btn btn-primary">Add</button>
        </form>
    );
};

StudentTagForm.propTypes = {
    ...propTypes
};

renderField.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'studentTags'
})(StudentTagForm);