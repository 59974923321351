import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuDivider, MenuItem } from "@blueprintjs/core";

const FileOptions = (props) => {
    const { onMove, onShare, onDownload, onDelete } = props;

    return (
     <Menu>
          <MenuItem iconName="cloud-download" text="Download" onClick={() => onDownload()} />
          <MenuItem iconName="circle-arrow-right" text="Move" onClick={() => onMove(false)} />
          <MenuItem iconName="people" text="Share on Portal" onClick={() => onShare(true)} />
          <MenuDivider />
          <MenuItem iconName="trash" className="pt-intent-danger" text="Delete" onClick={() => onDelete()} />
      </Menu>
    );
};

FileOptions.propTypes = {
  onMove: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default FileOptions;