import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/auth';
import UpdatePasswordForm from './update-password-form';
import { Link } from 'react-router-dom';

class UpdatePasswordPage extends Component {

    constructor(props, context) {
        super(props, context);

        this.updatePassword = this.updatePassword.bind(this);

  }

  componentWillMount() {
        if (!this.props.userSetting.signin) {
            this.props.checkPwdtoken(this.props.match.params.id);
        }
        
    }    

  updatePassword(pwd){
      this.props.updatePassword(pwd, this.props.match.params.id, this.props.history);
  }

  render() {

      return(
            <div>
            <div className="user-access-page">
                <div className="row">
                    <div className="col-sm-offset-3 col-sm-6 col-md-offset-4 col-md-4">
                        <div className="login-widget">
                            <div className="login-box">
                                <h3 className="blueBG">Enter a New Password</h3>
                                    <UpdatePasswordForm onSubmit={this.updatePassword} pwdExpired={!this.props.userSetting.resetPassword} />

                                <div className="login-footer">
                                    <Link className="grey-text small" to={this.props.userSetting.resetPassword ? 'signin' : 'password'}>{this.props.userSetting.resetPassword ? 'Sigin' : 'Forgot Password'}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <div className="copyrights">© Copyrights 2016 <a href="http://www.myschoolmusic.com.au/" target="_blank">My School Music</a> All Rights Reserved.
                </div>
            </footer>
        </div>
      );
  }
}

UpdatePasswordPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  updatePassword: PropTypes.func.isRequired,
  checkPwdtoken: PropTypes.func.isRequired
};

function mapStateToProps(state) {

   return {
       userSetting: state.usersetting
    };
    
}

export default connect(mapStateToProps, actions)(UpdatePasswordPage);

