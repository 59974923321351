import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/invoice';
import moment from 'moment';
import ReactToPrint from "react-to-print";
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import InvoiceList from './invoice-list';
import ExportForm from './export-form';
import InvoiceDetails from './invoice-details-page';
import DisplayInvoice from './display-invoice';
import DateRange from  './date-range';

class InvoiceListPage extends Component {

     constructor(props, context) {
        super(props, context);

        this.state = {
            invoices: _.map(this.props.invoices.invoiceList, invoice => invoice),
            selectedInvoices: [],
            selectedInvoice: '',
            showModal: false,
            showLargeModal: false,
            modalHeading: '',
            listType: 'all'
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.exportInvoices = this.exportInvoices.bind(this);
        this.deleteInvoices = this.deleteInvoices.bind(this);
        this.selectedInvoices = this.selectedInvoices.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.updateRange = this.updateRange.bind(this);
        this.viewUnpaid = this.viewUnpaid.bind(this);
        this.viewPaid = this.viewPaid.bind(this);
        this.viewAll = this.viewAll.bind(this);
        this.switchView = this.switchView.bind(this);
    }

    componentWillMount() {
        this.props.getInvoices(this.props.userSetting.schoolId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.invoices.invoiceList !== this.props.invoices.invoiceList) {
          this.setState({ invoices: _.map(nextProps.invoices.invoiceList, invoice => invoice)});
        }
     }

    exportInvoices(values) {
        this.props.exportInvoices(this.state.selectedInvoices, values.exportFormat);
    }

    deleteInvoices() {
        if (window.confirm(`Confirm you would like to delete the selected invoices, ${this.state.selectedInvoices.length} invoices will be deleted. DELETED Invoices cannot be restored`)) { 
            this.props.deleteInvoices(this.props.userSetting.schoolId, this.state.selectedInvoices);
        } 
    }

    sendInvoice() {
       this.props.selectedInvoices(this.state.selectedInvoices);
       if (this.state.selectedInvoices.length) this.props.history.push('/send-invoice');
    }

    openModal(modalHeading) {
        this.setState({ showModal: true, modalHeading });
    }

    closeModal() {
        this.setState({ showModal: false, showLargeModal: false });
    }

    viewAll() {
        this.setState({ invoices: _.map(this.props.invoices.invoiceList, invoice => invoice), listType: 'all' });
    }

    viewUnpaid() {
        const invoices = _.filter(this.props.invoices.invoiceList, (item) => item.balance > 0);
        this.setState({ invoices, listType: 'unpaid' });
    }

    viewPaid() {
        const invoices = _.filter(this.props.invoices.invoiceList, (item) => item.balance === 0);
        this.setState({ invoices, listType: 'paid' });
    }
    
    switchView() {
        this.setState({ showModal: false, showLargeModal: true });
    }

    selectedInvoices(values) {
        if (values.status) {
             this.setState({ selectedInvoices: this.state.selectedInvoices.concat(values.rows.map(r => r.row._id)) });
        } else {
            const ids = values.rows.map(r => r.row._id);
            this.setState({ selectedInvoices: this.state.selectedInvoices.filter(invoice => !ids.some(id => id === invoice)) });
        }
    }
   
    onRowClick(rowIdx, invoice) {
        if (invoice) {
            this.setState({ selectedInvoice: invoice._id }); 
            this.openModal('Invoice Details');
        }
    }

    updateRange(values) {
        const invoiceFrom = values.invoiceFrom.startOf('day');
        const invoiceTo = values.invoiceTo.endOf('day');

        if (invoiceFrom < this.props.invoices.dateFrom || invoiceTo > this.props.invoices.dateTo) {
            this.props.getInvoices(this.props.userSetting.schoolId, invoiceFrom, invoiceTo);
        } else {
            const invoices = _.filter(this.props.invoices.invoiceList, (item) => {
               const invDate = moment(item.invoiceDate, 'D MMM YY').startOf('day');
               return invDate >= invoiceFrom && invDate <= invoiceTo;
            });
            this.props.filterInvoices(invoices, invoiceFrom, invoiceTo);
        }
    }

    
    // filterByGroup(groupId) {
    //     const studentIds = this.props.students.map(student => student.groups.includes(groupId));
    //     this.setState({ invoices: this.state.invoices.filter(invoice => studentIds.includes(invoice.studentId))})
    // }
        

    render() {
        const { invoices } = this.props;

        if (!invoices.invoiceList) {
            return <div>loading...</div>;
        }
    
        return(
            <div>
                <div className="pageheader">
                    <h2>Invoice List</h2>
                </div>
                <section id="main-content">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="panel panel-primary">
                                <div className="panel-heading">Invoices</div>
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-3 col-md-3 padding-top-half">
                                            <div className="pt-button-group">
                                                <div className={(this.state.listType === 'all') ? 'pt-button pt-intent-primary pt-active' : 'pt-button pt-intent-primary'} role="button" onClick={() => this.viewAll()}>All</div>
                                                <div className={(this.state.listType === 'paid') ? 'pt-button pt-intent-primary pt-active' : 'pt-button pt-intent-primary'} role="button" onClick={() => this.viewPaid()}>Paid</div>
                                                <div className={(this.state.listType === 'unpaid') ? 'pt-button pt-intent-primary pt-active' : 'pt-button pt-intent-primary'} role="button" onClick={() => this.viewUnpaid()}>Unpaid</div>
                                            </div>
                                        </div>
                                        <div className="col-xs-4 col-sm-4 col-md-3 padding-top-half">
                                            <DateRange updateRange={this.updateRange} dateFrom={invoices.dateFrom} dateTo={invoices.dateTo} />
                                        </div>
                                        <div className="col-xs-8 col-sm-5 col-md-6 padding-top-half text-right">
                                                <button type="button" className="btn btn-warning btn-trans btn-xs rounded" onClick={() => this.sendInvoice()} >Email</button>&nbsp;
                                                <Link to="/add-invoice" className="btn btn-success btn-trans btn-xs rounded">Add</Link>&nbsp;
                                                <button type="button" className="btn btn-xs btn-danger rounded" onClick={() => this.deleteInvoices()}>Delete</button>&nbsp;
                                                <button type="button" className="btn btn-xs btn-primary rounded" onClick={() => this.openModal('Export')}>Export</button>
                                        </div>
                                    </div>
                                    
                                        <div className="col-xs-12">
                                            <div>
                                                <InvoiceList invoices={this.state.invoices} selectedInvoices={this.selectedInvoices} onRowClick={this.onRowClick} />
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </section>
                <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <Modal.Header bsClass="modal-header primary" closeButton>
                        <Modal.Title>{this.state.modalHeading}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-padding">
                            {(this.state.modalHeading === 'Invoice Details') && <InvoiceDetails onSubmit={this.receivePayment} closeModal={this.closeModal} viewInvoice={this.switchView} invoice={invoices.invoiceList[this.state.selectedInvoice]} />}
                            {(this.state.modalHeading === 'Export') && <ExportForm onSubmit={this.exportInvoices} closeModal={this.closeModal} />}
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showLargeModal} bsSize="lg" onHide={this.close}>
                    <Modal.Body>
                        <div>
                            <div className="row invoicePadding">
                                <div className="col-sm-6">
                                    <ReactToPrint
                                        trigger={() => <button type="button" className="btn btn-primary btn-sm rounded">Print</button>}
                                        content={() => this.componentRef}
                                    />
                                </div>
                                <div className="col-sm-6 text-right">
                                    <button type="button" className="btn btn-default btn-sm rounded" onClick={this.closeModal}>Close</button>
                                </div>
                            </div>
                            <DisplayInvoice ref={el => (this.componentRef = el)} schoolInv={this.props.schoolInv} studentInv={invoices.invoiceList[this.state.selectedInvoice]} />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

InvoiceListPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  invoices: PropTypes.object.isRequired,
  schoolInv: PropTypes.object.isRequired,
  students: PropTypes.array.isRequired,
  getInvoices: PropTypes.func.isRequired,
  deleteInvoices: PropTypes.func.isRequired,
  filterInvoices: PropTypes.func.isRequired,
  selectedInvoices: PropTypes.func.isRequired,
  exportInvoices: PropTypes.func.isRequired
};


function mapStateToProps(state) {
  return {
        userSetting: state.usersetting,
        invoices: state.invoices,
        students: state.students,
        schoolInv: state.school.invoices
  };
}

export default connect(mapStateToProps, actions)(InvoiceListPage);