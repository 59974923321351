import { GET_TUITION, ADD_TUITION, UPDATE_TUITION } from '../actions/types';
import initialState from './initial-state';

export default function(state = initialState.tuition, action) {
    switch(action.type) {

        case GET_TUITION:
             return action.payload;

        case ADD_TUITION:
            return [action.payload, ...state];

        case UPDATE_TUITION:
            {
                const groups = state.slice(0);
                groups.splice(action.idx, 1, action.payload);
                return groups;
            }

        default:
            return state;
    }
}