import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer  } from 'react-big-calendar'
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as actions from '../../actions/tuition';
import moment from 'moment';
import { connect } from 'react-redux';
import TuitionEventForm from './tuition-event-form';
import CloneWeek from './clone-week-form';
import EventDetails from './event-details';
import '../../styles/css/react-big-calendar.css';

const localizer = momentLocalizer(moment)

class TuitionCalendarPage extends Component {
 
    constructor(props, context) {
        super(props, context);
        this.state = {
            showModal: false,
            modalHeading: '',
            event: {},
            view: 'week',
            selectedDate: moment().format('DDMMYY')
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.saveTEvent = this.saveTEvent.bind(this);
        this.groupValues = this.groupValues.bind(this);
        this.setView = this.setView.bind(this);
        this.removeWeek = this.removeWeek.bind(this);
        this.selectedDate = this.selectedDate.bind(this);
        this.cloneWeek = this.cloneWeek.bind(this);
        this.addModal = this.addModal.bind(this);
        this.openEvent = this.openEvent.bind(this);
        this.removeTEvent = this.removeTEvent.bind(this);
    }

    addModal(slotInfo) {
        const event = { 
          startDate: slotInfo.start,
          isNew: true
      };
        this.setState({ showModal: true, modalHeading: 'Add Tuition Event', event });
    }

    openModal(modalHeading) {
        this.setState({ showModal: true, modalHeading });
    }

    openEvent(event) {
        this.setState({ showModal: true, modalHeading: 'Tuition Details', event });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    setView(view) {
      this.setState({ view });
    }

    selectedDate(selectedDate) {
        this.setState({ selectedDate: moment(selectedDate).format('DDMMYY') });
    }

    saveTEvent(event) {
       const idx = this.props.tGroups.findIndex(item => item._id === event.tGroup);
       this.props.saveTEvent(event, idx);
       this.closeModal();
    }

    removeTEvent(id, eventId) {
        const idx = this.props.tGroups.findIndex(item => item._id === id);
        this.props.removeTEvent(id, eventId, idx);
        this.closeModal();
     }

    removeWeek() {
        if (window.confirm('Confirm removing all tutition events for the current week')) {
            this.props.removeWeek(this.props.userSetting.schoolId, this.state.selectedDate);
        }
     }

    cloneWeek(values) {
        this.props.cloneWeek(this.props.userSetting.schoolId, this.state.selectedDate, values);
        this.closeModal();
     }

    groupValues(id) {
      const selectedGroup = this.props.tGroups.filter(item => item._id === id);
      const defaultValues = {
        tGroup: id,
        duration: selectedGroup[0].defaultDuration,
        eventVenue: selectedGroup[0].defaultVenue,
        eventColour: selectedGroup[0].defaultColour
      };
      this.setState({ event: { ...this.state.event, ...defaultValues} });
    }


    render() {
        const { selectors, tGroups } = this.props;
        const eventList = tGroups.reduce((events, group) => {
            return events.concat(group.groupDates.map(item => (
            { 
              groupId: group._id, 
              eventId: item._id,
              title: group.groupName, 
              startDate: moment(item.startDate).toDate(),
              endDate: moment(item.endDate).toDate(),
              eventColour: item.eventColour,
              eventVenue: item.eventVenue
            })));
        }, []);

        return (
            <div>
                <div className="pageheader">
                <h2>Tuition Calendar</h2>
            </div>
            <section id="main-content">
              <div className="col-xs-12 col-sm-6">
                <div className="pt-button-group .modifier">
                    <Link to="/tuition-list" className="pt-button pt-intent-primary">Tuition List</Link>
                    <div className="pt-button pt-intent-primary pt-active" role="button">Tuition Calendar</div>
                    
                </div>
                </div>
                <div className="col-xs-12 col-sm-6">
                    {(this.state.view === 'week') && <div className="text-right">
                        <button type="button" className="btn btn-primary btn-trans btn-xs rounded" onClick={() => this.openModal('Clone Week')} >Clone Week</button>&nbsp;
                        <button type="button" className="btn btn-warning btn-trans btn-xs rounded" onClick={() => this.removeWeek()} >Clear Week</button>
                    </div>}
                </div>
          
                  <div className="row">
                      <div className="col-xs-12">
                          <div className="panel panel-default">
                              <div className="panel-body ng-binding">
                                      <div className="bigCal veiwCal">
                                          <Calendar
                                              localizer={localizer}
                                              style={{height: 500}}
                                              selectable
                                              culture="en-US"
                                              startAccessor="startDate"
                                              endAccessor="endDate"
                                              min={new Date(2015, 10, 19, 6, 0, 0)}
                                              max={new Date(2015, 10, 19, 21, 0, 0)}
                                              events={eventList}
                                              defaultView="week"
                                              onView={(view) => this.setView(view)}
                                              onNavigate={(selectedDate) => this.selectedDate(selectedDate)}
                                              onSelectEvent={event => this.openEvent(event)}
                                              onSelectSlot={(slotInfo) => this.addModal(slotInfo)}
                                              eventPropGetter={(event) => ({style: { backgroundColor: event.eventColour }})}
                                          />
                                      </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </section>
                <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <Modal.Header className="modal-header primary" closeButton>
                        <Modal.Title>{this.state.modalHeading}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-padding">
                            {(this.state.modalHeading === 'Add Tuition Event') && 
                                <TuitionEventForm 
                                    onSubmit={this.saveTEvent} 
                                    initialValues={this.state.event} 
                                    onClose={this.closeModal}
                                    selectors={selectors}
                                    groupValues={this.groupValues}
                                    tGroups={tGroups.map(item =>({ _id: item._id, label: item.groupName }))}
                                    />}
                            {(this.state.modalHeading === 'Clone Week') && <CloneWeek onSubmit={this.cloneWeek} onClose={this.closeModal} initialValues={{ cloneDate: moment(this.state.selectedDate, 'DDMMYY').add(7, 'days').startOf('week').toDate() }} />}
                            {(this.state.modalHeading === 'Tuition Details') && <EventDetails event={this.state.event} tGroups={tGroups} onClose={this.closeModal} removeEvent={this.removeTEvent} />}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        );
    }
}

TuitionCalendarPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  tGroups: PropTypes.array.isRequired,
  selectors: PropTypes.object.isRequired,
  saveTEvent: PropTypes.func.isRequired,
  removeWeek: PropTypes.func.isRequired,
  cloneWeek: PropTypes.func.isRequired,
  removeTEvent: PropTypes.func.isRequired
};

function mapStateToProps(state) {

    return {
      userSetting: state.usersetting,
      selectors: state.school.selectors,
      tGroups: state.tuition
    };
}

export default connect(mapStateToProps, actions)(TuitionCalendarPage);