import axios from 'axios';
import Notifications from 'react-notification-system-redux';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { GET_FORMS,
  GET_CUSTOM_FORMS,
  UPDATE_NEW_REGISTRATION,
  UPDATE_ENROLE_QUESTIONS,
  UPDATE_STUDENTS_CUSTOM_FORMS,
  SET_EMAIL_LIST,
  REMOVE_CUSTOM_FORM,
  NO_ACTION,
  ROOT_URL  } from './types';

export function getForms(id) {
  return function(dispatch) {
    axios.get(`${ROOT_URL}/forms/${id}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        dispatch({ type: GET_FORMS, form: response.data });
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function getCustomForms(id) {
  return function(dispatch) {
    axios.get(`${ROOT_URL}/custom-forms/${id}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        dispatch({ type: GET_CUSTOM_FORMS, payload: response.data });
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function addRegistrationQuestion(question, id) {
   return function(dispatch) {
    axios.post(`${ROOT_URL}/forms/registration/question/${id}`, question,
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
            dispatch( {type: UPDATE_NEW_REGISTRATION, payload: response.data.newReg } );
            dispatch(Notifications.success({ title: 'Question Saved' }));
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function removeRegQuestion(questionId, schoolID) {

   return function(dispatch) {
    axios.delete(`${ROOT_URL}/forms/registration/question/${schoolID}/${questionId}`,
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
       dispatch( {type: UPDATE_NEW_REGISTRATION, payload: response.data.newReg } );
       dispatch(Notifications.error({ title: 'Question Deleted' }));
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

// export function selectInfo(selected, info) {
// console.log(info)
//   const selectedInfo = {};
//   if (selected === 'infoMusic') {
//     selectedInfo.infoText = info.musicProgramInfo;
//     selectedInfo.type = 'infoMusic';
//   } else  {
//     selectedInfo.infoText = info.parentAgreement;
//     selectedInfo.acceptAgreementText = info.acceptAgreementText;
//     selectedInfo.type = 'parentAgreement';
//   } 
//   return {
//     type: FORMS_SELECT_INFO,
//     payload: selectedInfo
//   };

//  }

 export function saveNewRegistrationInfo(info, id) {

   return function(dispatch) {
    axios.post(`${ROOT_URL}/forms/registration/info/${id}`, info,
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
            dispatch( {type: UPDATE_NEW_REGISTRATION, payload: response.data.newReg } );
            dispatch(Notifications.success({ title: 'Updates Saved' }));
      }).catch(error => {dispatch(Notifications.error({ title: error.response.data.error }));throw(error);
      });
  };
}

export function saveNewRegistration(info, id) {

   return function(dispatch) {
    axios.post(`${ROOT_URL}/forms/registration/${id}`, info,
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
            dispatch( {type: UPDATE_NEW_REGISTRATION, payload: response.data } );
            dispatch(Notifications.success({ title: 'Updates Saved' }));
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function saveReEnrolment(reEnrolment, id) {

   return function(dispatch) {
    axios.post(`${ROOT_URL}/forms/re-enrolment/${id}`, {reEnrolment},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(() => {
            dispatch(Notifications.success({ title: 'Updates Saved' }));
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}


export function addEnroleQuestion(question, id) {

   return function(dispatch) {
    axios.post(`${ROOT_URL}/forms/re-enrolment/question/${id}`, {question},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
            dispatch( {type: UPDATE_ENROLE_QUESTIONS, payload: response.data.reEnrolment.questions } );
            dispatch(Notifications.success({ title: 'Question Saved' }));
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function removeEnroleQuestion(questionId, schoolID) {

   return function(dispatch) {
    axios.delete(`${ROOT_URL}/forms/re-enrolment/question/${schoolID}/${questionId}`,
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
       dispatch( {type: UPDATE_ENROLE_QUESTIONS, payload: response.data.reEnrolment.questions } );
       dispatch(Notifications.error({ title: 'Question Deleted' }));
      }).catch(error => {
        dispatch(Notifications.error({ title: 'Processing Error', message: error.response.data }));
      });
  };
}

export function saveEnrolment(Enrolment, id) {
     return function(dispatch) {
      axios.post(`${ROOT_URL}/forms/enrolment/${id}`, Enrolment,
      { headers: { authorization: localStorage.getItem('token') } 
      } )
      .then(() => {
              dispatch(Notifications.success({ title: 'Updates Saved' }));
        }).catch(error => {
          dispatch(Notifications.error({ title: 'Processing Error', message: error.response.data }));
        });
    };
  }

  export function saveCustomForm(form, id) {
      return function(dispatch) {
      axios.post(`${ROOT_URL}/custom-forms/${id}`, form,
      { headers: { authorization: localStorage.getItem('token') } 
      } )
      .then(response => {
          dispatch({ type: GET_CUSTOM_FORMS, payload: response.data });
          dispatch(Notifications.success({ title: 'Form Saved' }));
        }).catch(error => {
          dispatch(Notifications.error({ title: 'Processing Error', message: error.response.data }));
        });
    };
  }

  export function removeCustomForm(formId) {
    return function(dispatch) {
    axios.delete(`${ROOT_URL}/custom-form/${formId}`,
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(() => {
            dispatch(Notifications.warning({ title: 'Form Deleted' }));
            dispatch({ type: REMOVE_CUSTOM_FORM, payload: formId });
      }).catch(error => {
        dispatch(Notifications.error({ title: 'Processing Error', message: error.response.data }));
      });
  };
}

 export function saveCustomQuestion(question, id) {
    return function(dispatch) {
    axios.post(`${ROOT_URL}/custom-question/${id}`, question,
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then((response) => {
            dispatch(Notifications.success({ title: 'Question Saved' }));
            dispatch({ type: GET_CUSTOM_FORMS, payload: response.data });
      }).catch(error => {
        dispatch(Notifications.error({ title: 'Processing Error', message: error.response.data }));
      });
  };
  }

  export function removeCustomQuestion(id) {
    return function(dispatch) {
    axios.delete(`${ROOT_URL}/custom-question/${id}`,
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then((response) => {
            dispatch(Notifications.warning({ title: 'Question Deleted' }));
            dispatch({ type: GET_CUSTOM_FORMS, payload: response.data });
      }).catch(error => {
        dispatch(Notifications.error({ title: 'Processing Error', message: error.response.data }));
      });
  };
  }

  export function updateCustomQuestionOrder(id, questions) {
    return function(dispatch) {
    axios.put(`${ROOT_URL}/custom-question/${id}`, questions,
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then((response) => {
            dispatch({ type: GET_CUSTOM_FORMS, payload: response.data });
      }).catch(error => {
        dispatch(Notifications.error({ title: 'Processing Error', message: error.response.data }));
      });
  };
  }

  export function updateStudentsCustomForm(id, students, studentIds, action) {
    return function(dispatch) {
    axios.post(`${ROOT_URL}/custom-students/${id}`, { students, studentIds, action },
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(() => {
            dispatch({ type: UPDATE_STUDENTS_CUSTOM_FORMS, id, payload: students });
      }).catch(error => {
        dispatch(Notifications.error({ title: 'Processing Error', message: error.response.data }));
      });
  };
  }

  export function clearStudentsCustomForm(id, studentIds) {
    return function(dispatch) {
    axios.put(`${ROOT_URL}/custom-students/${id}`, studentIds,
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then((response) => {
            dispatch({ type: UPDATE_STUDENTS_CUSTOM_FORMS, id, payload: response.data });
      }).catch(error => {
        dispatch(Notifications.error({ title: 'Processing Error', message: error.response.data }));
      });
  };
  }

  export function emailList(emailList) {
    if (!emailList.length) {
      return function(dispatch) {
        dispatch(Notifications.error({ title: 'Select at least one student' }));
      };
    } else {
      return { type: SET_EMAIL_LIST, payload: emailList };
    }
}

export function exportCustomForm(exportList, formTitle, formId) {
      axios.post(`${ROOT_URL}/utils/export-form/${formId}`, exportList,
      { headers: { authorization: localStorage.getItem('token') } 
      })
      .then(response => {
          fileDownload(response.data, `${formTitle}-${moment().format('D-MMM-YYYY')}.csv`);
          });
  
    return {
      type: NO_ACTION,
      payload: ''
    };
  }


  