import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';

const renderDropdownList = ({ input, placeHolder, ...rest }) => <DropdownList {...input} {...rest} onBlur={() => input.change} placeholder={placeHolder} />;

const MoveGroup = (props) => {
    const { handleSubmit, groups } = props;

    return (
        <div className="clearfix form-padding">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                  <div className="row">
                      <div className="col-sm-1">
                          <label>Group</label>
                      </div>
                      <div className="col-sm-5">
                        <Field name="oldGroup" type="text" valueKey="_id" labelKey="name" component={renderDropdownList} options={groups} placeHolder="Move from"  />
                      </div>
                      <div className="col-sm-4">
                        <Field name="newGroup" type="text" valueKey="_id" labelKey="name" component={renderDropdownList} options={groups} placeHolder="Move to" />
                      </div>
                      <div className="col-sm-2 text-right">
                        <button type="submit" className="btn btn-sm btn-primary rounded">Go</button>
                      </div>
                  </div>
              </div>
        </form>
    </div>
    );
};

MoveGroup.propTypes = {
    ...propTypes
};

renderDropdownList.propTypes = {
    input: PropTypes.object.isRequired,
    placeHolder: PropTypes.string
};


export default reduxForm({
    form: 'moveGroup'
})(MoveGroup);