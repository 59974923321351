import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/instruments';
import ServiceCentreList from './service-centre-list';
import ServiceCentreForm from './service-centre-form';

class ServiceCentrePage extends Component {

     constructor(props, context) {
        super(props, context);

        this.state = {
            displayForm: false,
            serviceCenter: {}
          };

        this.saveServiceCentre = this.saveServiceCentre.bind(this);
        this.removeServiceCentre = this.removeServiceCentre.bind(this);
        this.closeForm = this.closeForm.bind(this);
        this.editServiceCentre = this.editServiceCentre.bind(this);
    }

    saveServiceCentre(values) {
        this.props.saveServiceCentre(values, this.props.userSetting.schoolId);
        this.closeForm();
    }

    removeServiceCentre(id) {
        if (window.confirm(`Are you sure you want to delete this service centre`)) {
            this.props.removeServiceCentre(id, this.props.userSetting.schoolId);
            this.closeForm();
        }
    }

    editServiceCentre(serviceCenter) {
        this.setState({ displayForm: true, serviceCenter });
      }

    closeForm() {
        this.setState({ displayForm: false });
    }



    render() {

        return(
            <div>
                <div className="text-right">
                    <button type="button" className="btn btn-xs btn-primary rounded" onClick={() => this.setState({ displayForm: true, serviceCenter: {} })}>Add</button>&nbsp;&nbsp;
                    <button type="button" className="btn btn-xs btn-default rounded" onClick={() => this.props.closeModal()}>Close</button>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-12">
                        {(this.state.displayForm) ? 
                            <ServiceCentreForm 
                                onSubmit={this.saveServiceCentre} 
                                closeForm={this.closeForm} 
                                initialValues={this.state.serviceCenter} 
                                removeSC={this.removeServiceCentre} /> : 
                            <ServiceCentreList 
                                serviceCentres={this.props.serviceCentres} 
                                editCS={this.editServiceCentre}
                                />}
                    </div>
                </div>
            </div>
        );
    }
}

ServiceCentrePage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  serviceCentres: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  saveServiceCentre: PropTypes.func.isRequired,
  removeServiceCentre: PropTypes.func.isRequired
};


function mapStateToProps(state) {
  return {
        userSetting: state.usersetting,
        serviceCentres: state.school.selectors.serviceCentres
  };
}

export default connect(mapStateToProps, actions)(ServiceCentrePage);