import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import { Checkbox } from 'react-icheck';

const dropdownData = (data) => {
   let newArry = data.map(function(obj) {
    return { _id: obj._id, name: obj.name, shortName: obj.shortName };
    });

    return newArry;
};

const renderCheckbox = ({ input, name }) => (
  <Checkbox 
    checkboxClass="icheckbox_square-blue" 
    increaseArea="20%"
    name={name}
    onChange={input.onChange}
    defaultChecked = {input.value ? true : false}
    />
);

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
    <div>
        <DropdownList {...input} {...rest} onBlur={() => input.change} />
        { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
);

const newStudentReg = (props) => {
    
    const { handleSubmit, selectorsValues, selectGroups, closeStudent, deleteNewStudent } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <div className="row">
                    <div className="col-sm-6">
                        <label className="col-sm-4">Group</label>
                        <div className="col-sm-8">
                            <Field name="groupId" type="text" valueKey="_id" labelKey="name" simpleValue={true} clearable={false} component={renderDropdownList} options={dropdownData(selectGroups)} />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <label className="col-sm-4">Tutor</label>
                        <div className="col-sm-8">
                            <Field name="tutor" valueKey="_id" labelKey="name" simpleValue={true} clearable={false} component={renderDropdownList} options={selectorsValues.tutors} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-sm-6">
                        <label className="col-sm-4">Instrument</label>
                        <div className="col-sm-8">
                            <Field name="instrument" type="text" valueKey="label" simpleValue={true} clearable={false} component={renderDropdownList} options={selectorsValues.instrumentList} />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <label className="col-sm-4">Status</label>
                        <div className="col-sm-8">
                            <Field name="status" type="text" valueKey="label" simpleValue={true} clearable={false} component={renderDropdownList} options={selectorsValues.studentStatus}/>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="col-md-7 col-sm-12">
                    <div className="form-group">
                        <Field name="setToActive" component={renderCheckbox} />&nbsp; <label>Move student to active students list</label>
                    </div>
                </div>
                <div className="col-md-5 col-sm-12 text-right">
                    <div>
                        <button type="submit" className="btn btn-sm btn-primary rounded">Save</button>&nbsp;&nbsp;
                        <button type="button" className="btn btn-sm btn-danger rounded" onClick={() => deleteNewStudent()}>Delete</button>&nbsp;&nbsp;
                        <button type="button" className="btn btn-sm btn-default rounded" onClick={() => closeStudent()}>Close</button>
                    </div>
                </div>
            </div>
        </form>
    );
};

newStudentReg.propTypes = {
    ...propTypes
};

renderDropdownList.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

renderCheckbox.propTypes = {
    name: PropTypes.string,
    input: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'newStudentReg'
})(newStudentReg);