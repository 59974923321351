import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { Checkbox } from 'react-icheck';

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type={type} placeholder={label}/>
       {touched && error && <h5><span className="label label-warning pull-right">{error}</span></h5>}
  </div>
);

const renderCheckbox = ({ input, name }) => (
  <Checkbox 
    checkboxClass="icheckbox_square-blue" 
    increaseArea="20%"
    name={name}
    onChange={input.onChange}
    defaultChecked = {input.value ? true : false}
    />
);

const FormDetails = (props) => {
    
    const { handleSubmit, onRemove } = props;
   
    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-8">
                <div className="form-group">
                    <Field name="formTitle" type="text" component={renderField} label="Form Name"/>
                </div>
              </div>
              <div className="col-sm-4 text-right">
                <button type="submit" className="btn btn-xs btn-primary rounded">Save</button>&nbsp;&nbsp;
                <button type="button" className="btn btn-danger btn-xs rounded" onClick={() => onRemove()}>Delete</button>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                    <Field name="formActive" component={renderCheckbox} />&nbsp; <span>Form is active on portal</span>
                </div>
              </div>
            </div>
        </form>
    );
};

FormDetails.propTypes = {
    ...propTypes
};

renderField.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
  name: PropTypes.string
};

export default reduxForm({
    form: 'formDetails'
})(FormDetails);