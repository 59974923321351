import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/contacts';
import { Modal } from 'react-bootstrap';
import ProfileForm from './profile-form';
import FromEmailList from './from-email-list';
import UpdatePassword from './update-password-form';

class ProfilePage extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = { showModal: false };

        this.saveProfile = this.saveProfile.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    saveProfile(values) {
        this.props.updateProfile(values);
        }

    updatePassword(values) {
            this.props.updatePassword(values, this.closeModal);
            }

    openModal() {
        this.setState({ showModal: true });
    }

    closeModal() {
        this.setState({ showModal: false });
    }
   
    render() {

        return (
            <div>
                <div className="pageheader"><h2>Profile</h2></div>
                <section id="main-content">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="panel panel-primary">
                                <div className="panel-heading">My Profile</div>
                                <div className="panel-body">
                                    <ProfileForm onSubmit={this.saveProfile} initialValues={this.props.profile} openModal={this.openModal} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="panel panel-success">
                                <div className="panel-heading">Send Emails From</div>
                                <div className="panel-body">
                                    <FromEmailList fromEmails={this.props.profile.fromEmails} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <Modal.Header bsClass="modal-header primary" closeButton>
                        <Modal.Title>Update Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <UpdatePassword onSubmit={this.updatePassword} closeModal={this.closeModal} />
                    </Modal.Body>
                </Modal>

            </div>

        );
    }
}

ProfilePage.propTypes = {
  profile: PropTypes.object.isRequired,
  updateProfile: PropTypes.func.isRequired
};



function mapStateToProps(state) {

    return {
        profile: state.usersetting.profile
    };
}

export default connect(mapStateToProps, actions)(ProfilePage);