import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AvatarEditor from 'react-avatar-editor';
import { Slider } from "@blueprintjs/core";
import * as actions from '../../actions/invoice';


class UploadImage extends Component {

    constructor(props, context) {
        super(props, context);

         this.state = {
            scale: 1,
            fileName: 'Choose image...'
        };

     this.handleNewImage = this.handleNewImage.bind(this);
     this.setEditorRef = this.setEditorRef.bind(this);
     this.handleScale = this.handleScale.bind(this);
     this.onUploadImage = this.onUploadImage.bind(this);
     this.removeImage = this.removeImage.bind(this);
    }

    handleNewImage(e) {
      this.setState({ image: e.target.files[0], fileName: e.target.files[0].name });
    }

     handleScale(scale) {
      this.setState({ scale });
    }

    setEditorRef(editor) {
      if (editor) this.editor = editor;
    }

    onUploadImage() {
      this.editor.getImageScaledToCanvas()
      .toBlob((blob) => this.props.uploadImages(blob, this.props.school._id, this.props.header));
  }

    removeImage() {
        this.props.removeImage(this.props.school._id, this.props.header);
    }

    render() {

        return (
            <div className="col-xs-12 text-center">

              <div className="row padding-top-two">
                <AvatarEditor
                    ref={this.setEditorRef}
                    width={this.props.width}
                    height={this.props.height}
                    scale={this.state.scale}
                    image={this.state.image || '../../styles/img/noImage.jpg'}
                  />
              </div>
              <div className="row padding-top-one">
                <label className="pt-file-upload">
                    <input name="newImage" type="file" onChange={this.handleNewImage} />
                    <span className="pt-file-upload-input">{this.state.fileName}</span>
                  </label>
              </div>
     
              <div className="row padding-top-two">
                  <div className="col-sm-2 text-right">
                      <label>Zoom</label>
                  </div>
                  <div className="col-sm-8">
                      <Slider min={0.5} max={3} stepSize={0.1} labelStepSize={2.5} value={this.state.scale} onChange={this.handleScale} />
                  </div>
              </div>

              <div className="row">
                <div className="col-xs-7 padding-top-two text-left">
                    <button type="button" className="btn btn-primary rounded" onClick={this.onUploadImage}>Upload Image</button>&nbsp;&nbsp;
                    <button type="button" className="btn btn-default rounded" onClick={() => this.props.onClose()}>Close</button>
                </div>
                <div className="col-xs-5 padding-top-two text-right">
                    {(this.props.hasImage) && <button type="button" className="btn btn-warning rounded" onClick={this.removeImage}>Remove Image</button>}
                </div>
              </div>
            </div>
        );
    }
}

UploadImage.propTypes = {
  school: PropTypes.object.isRequired,
  uploadImages: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  hasImage: PropTypes.bool.isRequired,
  header: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired
};



function mapStateToProps(state) {

    return {
         school: state.school
    };
}

export default connect(mapStateToProps, actions)(UploadImage);