import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Nav, NavItem, Row, Col, ProgressBar } from 'react-bootstrap';
import DocumentList from './document-list';
import UploadFile from '../common/upload-file';


const DocumentTabs = (props) => {
    const { closeModal, uploadFile } = props;

    return (
         <div className="form-padding">
            <Tab.Container id="contact-tabs" defaultActiveKey="upload">
                <Row className="clearfix">
                    <Col sm={12}>
                        <Nav className="nav nav-tabs">
                            <NavItem eventKey="upload">
                                Upload File
                            </NavItem>
                            <NavItem eventKey="library">
                                From Library
                            </NavItem>
                        </Nav>

                        <Tab.Content animation>
                            <Tab.Pane eventKey="upload">
                                <br />
                                <UploadFile onDrop={uploadFile} closeModal={closeModal} />
                                {(props.uploadProgress >= 0) && <ProgressBar striped bsStyle="success" now={props.uploadProgress} />}
                            </Tab.Pane>

                            <Tab.Pane eventKey="library">
                                <br />
                                <DocumentList closeModal={closeModal} />
                            </Tab.Pane>
                        </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
          </div>       
    );
};

DocumentTabs.propTypes = {
  uploadProgress: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired
};


export default DocumentTabs;