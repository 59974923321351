import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const serviceListRow = (records) => {
        return records.map((record, index) => {
            return (
                <tr key={index}>
                    <td>{record.serviceCentre}</td>
                    <td>{moment(record.dateReturned).format('ll')}</td>
                    <td>${record.cost}</td>
                    <td>{record.comment}</td>
                </tr>
            );
        });
};

const ServiceHistory = (props) => {

     const { serviceHistory, openModal } = props;

    return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    Service History
                    <button type="button" className="pull-right btn btn-xs btn-primary" onClick={() => openModal('Update History')}>Edit</button>
                </div>
                <div className="panel-body">
                <div className="table-content-box">
                    <table className="table">
                        <thead className="thead-inverse">
                        <tr><td>Service Centre</td><td>Returned</td><td>Cost</td><td>Comment</td></tr>
                        </thead>
                        <tbody>
                        {serviceHistory && serviceListRow(serviceHistory)}
                        </tbody>
                    </table>
                </div>  
                </div>
            </div>
    );
};


ServiceHistory.propTypes = {
  serviceHistory: PropTypes.array,
  openModal: PropTypes.func.isRequired
};

export default ServiceHistory;