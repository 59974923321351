import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/tuition';
import GroupForm from './add-group-form';
import GroupStudents from './group-students';
import StudentsList from './student-list';

class TuitionGroupModal extends Component {

     constructor(props, context) {
        super(props, context);

        this.state = {
            modalView: 'students',
            students: this.props.tGroup.students
        };

        this.saveTGroup = this.saveTGroup.bind(this);
        this.modalView = this.modalView.bind(this);
        this.deleteTGroup = this.deleteTGroup.bind(this);
    }

    modalView(value) {
      if (value === 'students') {
        const students = this.props.tGroups.filter(item => item._id == this.props.tGroup._id)[0].students;
        this.setState({ modalView: value, students });
      } else {
        this.setState({ modalView: value });
      }
    }

    saveTGroup(group) {
      const tGroup = {...group, tutorId: group.tutor._id, tutorName: group.tutor.name};
      this.props.addTGroup(tGroup, this.props.tGroups.findIndex(row => row._id === group._id));
    }

    deleteTGroup() {
      if (window.confirm(`Confirm you would like to delete tuition group: ${this.props.tGroup.groupName}`)) { 
        this.props.removeTuitionGroup(this.props.tGroup._id);
        this.props.onClose();
      }
    }
    
    render() {
      const { tGroup, onClose } = this.props;

      if (this.state.modalView === 'students') return (
        <GroupStudents 
          students={this.state.students} 
          modalView={this.modalView}
          onSendEmail={this.props.onSendEmail}
          onClose={onClose}
          tGroupId={tGroup._id} />);
      if (this.state.modalView === 'add') return (
        <StudentsList 
          onCancel={this.modalView} 
          tGroupId={tGroup._id}
          excludeStudents={this.state.students.map(student => student._id)} />);
     
      return (
        <GroupForm 
          onSubmit={this.saveTGroup}
          onClose={this.modalView}
          onDelete={this.deleteTGroup}
          selectors={this.props.selectors}
          initialValues={Object.assign({}, tGroup, { tutor: tGroup.tutorId })} />);
        
    }
}

TuitionGroupModal.propTypes = {
  userSetting: PropTypes.object.isRequired,
  selectors: PropTypes.object.isRequired,
  tGroup: PropTypes.object.isRequired,
  tGroups: PropTypes.array.isRequired,
  addTGroup: PropTypes.func.isRequired,
  removeTuitionGroup: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSendEmail: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        userSetting: state.usersetting,
        selectors: state.school.selectors,
        tGroups: state.tuition
    };
}

export default connect(mapStateToProps, actions)(TuitionGroupModal);