import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import ReactDataGrid from 'react-data-grid';
import { Data } from 'react-data-grid-addons';
import { students, instruments, contacts} from './import-columns';
import Instructions from './import-instructions';

const Selectors = Data.Selectors;

class ImportListPage extends Component {
    
     constructor(props) {
        super(props);

        this.state = {
            rows: [],
            sortColumn: null, 
            sortDirection: null
        };

        this.rowGetter = this.rowGetter.bind(this);
        this.getRows = this.getRows.bind(this);
        this.getSize = this.getSize.bind(this);
        this.handleGridSort = this.handleGridSort.bind(this);
        this.importNow = this.importNow.bind(this);
        this.clearList = this.clearList.bind(this);
    }

     componentWillReceiveProps(nextProps) {
       this.setState({ rows: nextProps.importList.slice(0) });
    }

    importNow() {
        this.props.importNow(this.state.rows, this.props.userSetting.schoolId);
    }

    clearList() {
        this.props.clearImport();
    }

    rowGetter(i) {
        const rows = this.getRows();
        return rows[i];
    }

    getRows() {
         return Selectors.getRows(this.state);
     }

    getSize() {
        return this.getRows().length;
    }

    handleGridSort(sortColumn, sortDirection) {
        const state = Object.assign({}, this.state, {sortColumn: sortColumn, sortDirection: sortDirection});
        this.setState(state);
    }
  
    render() {

        const { importList } = this.props;

        if (importList.length === 0) {
            return <div><Instructions /></div>;
        }

        let columns = [];

        if (importList[0].serialNo) {
             columns = instruments;
        } else if (importList[0].contact1_email) {
             columns = students;
        } else {
             columns = contacts;
        }
  
        return (
            <div>
                <div className="text-right">
                    <button className="btn btn-primary btn-sm rounded" onClick={() => this.importNow()} type="button">Import Now</button>&nbsp;&nbsp;
                    <button className="btn btn-warning btn-sm rounded" onClick={() => this.clearList()} type="button">Cancel Import</button>
                </div>
                <ReactDataGrid
                    rowKey="_id"
                    onGridSort={this.handleGridSort}
                    columns={columns}
                    rowGetter={this.rowGetter}
                    rowsCount={this.getSize()}
                    minHeight={270}
                    />
            </div> 
        );
    }
}

ImportListPage.propTypes = {
  importList: PropTypes.array.isRequired,
  userSetting: PropTypes.object.isRequired,
  clearImport: PropTypes.func.isRequired,
  importNow: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return { 
      importList: state.selectLists.importList,
      userSetting: state.usersetting
    };
}

export default connect(mapStateToProps, actions)(ImportListPage);
