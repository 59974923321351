import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const historyListRow = (records) => {
        return records.map((record, index) => {
            return (
                <tr key={index}>
                    <td>{record.group}</td>
                    <td>{record.action}</td>
                    <td>{moment(record.date).format('LL')}</td>
                </tr>
            );
        });
};

const StudentHistory = ({ history }) => {

    return (
            <div className="panel panel-default">
                <div className="panel-body">
                <div className="table-content-box">
                    <table className="table">
                        <thead>
                        <tr><th>Group</th><th>Description</th><th>Date</th></tr>
                        </thead>
                        <tbody>
                        {history && historyListRow(history)}
                        </tbody>
                    </table>
                </div>  
                </div>
            </div>
    );
};


StudentHistory.propTypes = {
  studentHistory: PropTypes.array
};

export default StudentHistory;