import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import DropdownList from 'react-select';
import moment from 'moment';
import { required } from '../common/validation';
import momentLocaliser from 'react-widgets/lib/localizers/moment';
import 'react-widgets/dist/css/react-widgets.css';

momentLocaliser(moment);

const repeatNumbers = [];
let item = {};
for (let i = 1; i < 31; i++) { 
    item = { value: i, label: i };
    repeatNumbers.push(item);
}

 const selectColour = [
    { value: '#0066ff', label: 'Blue'}, 
    { value: '#996633', label: 'Brown'},
    { value: '#737373', label: 'Grey'}, 
    { value: '#008000', label: 'Green'},
    { value: '#00b8e6', label: 'Light Blue'},
    { value: '#ff0080', label: 'Pink'},
    { value: '#e60000', label: 'Red'},
    { value: '#ff6600', label: 'Orange'},
    { value: '#ac00e6', label: 'Purple'},
    { value: '#ffcc00', label: 'Yellow'}];  

const renderField = ({ input, label, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type="text" placeholder={label} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderDateTimePicker = ({ input: { onChange, value }, showTime, meta: { touched, error } }) => (
  <div>
    <DateTimePicker
        onChange={onChange}
        format="D MMM YYYY (ddd) - h:mm a"
        time={showTime}
        value={value ? new Date(value) : new Date()} />
    { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderDropdownList = ({ input, ...rest }) =>
  <DropdownList {...input} {...rest} onBlur={() => input.change} />;

const newEvent = (onClose) => (
    <div className="row">
        <div className="col-sm-12">
            <button type="submit" className="btn btn-success rounded">Save</button>&nbsp;&nbsp;
            <button type="button" className="btn btn-default rounded" onClick={() => onClose()}>Cancel</button>
        </div>       
    </div>
);

const editEvent = (removeEvent, onClose) => (
    <div className="row">
        <div className="col-sm-8">
            <button type="submit" className="btn btn-success rounded">Save</button>&nbsp;&nbsp;
            <button type="button" className="btn btn-default rounded" onClick={() => onClose()}>Cancel</button>
        </div>
        <div className="col-sm-4 text-right">
            <button type="button" className="btn btn-danger rounded" onClick={() => removeEvent()}>Remove Event</button>
        </div>      
    </div>
);

const repeatOptions = () => (
    <div className="form-group">
        <div className="row">
            <div className="col-sm-3">
                <label>Repeat</label>
            </div>
            <div className="col-sm-4">
                <Field name="options.frequency" type="text" simpleValue={true} component={renderDropdownList} options={[{ value: 1, label: 'Daily'}, { value: 7, label: 'Weekly'}]} />
            </div>
            <div className="col-sm-3">
                <Field name="options.repeat" type="text" simpleValue={true} component={renderDropdownList} options={repeatNumbers} />
            </div>
        </div>
    </div>
);

const EventForm = (props) => {
    const { handleSubmit, removeEvent, onClose, groups, isNew } = props;

    return (
        
        <div className="basic-form">
            <form onSubmit={handleSubmit}>

                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Title</label>
                        </div>
                        <div className="col-sm-8">
                            <Field name="eventTitle" type="text" component={renderField} validate={required} label="Event Title"/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Start</label>
                        </div>
                        <div className="col-sm-7">
                            <Field name="eventStart" type="text" component={renderDateTimePicker} />
                        </div>
                       
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>End</label>
                        </div>
                        <div className="col-sm-7">
                            <Field name="eventEnd" type="text" component={renderDateTimePicker} />
                        </div>
                       
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Location</label>
                        </div>
                        <div className="col-sm-8">
                            <Field name="eventVenue" type="text" component={renderField} label="Event Location"/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Comment</label>
                        </div>
                        <div className="col-sm-8">
                            <Field name="eventDescription" className="form-control" component="textarea" label="Event Comment"/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Groups</label>
                        </div>
                        <div className="col-sm-8">
                            <Field name="groups" type="text" component={renderDropdownList} multi={true} options={groups} valueKey="_id" labelKey="name" simpleValue={true} placeholder="Select on or more groups for this event" />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Colour</label>
                        </div>
                        <div className="col-sm-4">
                            <Field name="eventColour" type="text" simpleValue={true} component={renderDropdownList} options={selectColour} label="Select Event Colour"  />
                        </div>
                    </div>
                </div>

                {(isNew) && repeatOptions()}

                <hr />
                {(isNew) ? newEvent(onClose) : editEvent(removeEvent, onClose) }
            </form>
        </div>
    );
};

EventForm.propTypes = {
    ...propTypes,

};

renderField.propTypes = {
    input: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    meta: PropTypes.object.isRequired
};

renderDropdownList.propTypes = {
    input: PropTypes.object.isRequired
};

renderDateTimePicker.propTypes = {
    input: PropTypes.object.isRequired,
    showTime: PropTypes.bool,
    meta: PropTypes.object.isRequired
};


export default reduxForm({
    form: 'eventForm',
    enableReinitialize: true
})(EventForm);