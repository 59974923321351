import { IMPORT_LIST, SETTING_SELECT_FROM_EMAIL } from '../actions/types';

import initialState from './initial-state';

export default function(state = initialState.selectors, action) {
    switch(action.type) {

        case SETTING_SELECT_FROM_EMAIL:
             return { ...state, selectedFromEmail: action.payload  };

        case IMPORT_LIST:
             return { ...state, importList: action.payload  };

        default:
            return state;
    }
}