import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { Link } from 'react-router-dom';
import ToggleButton from 'react-toggle-button';


const renderToggleButton = ({ input }) => (
    <ToggleButton
        value={input.value || false}
        onToggle={(value) => {
            input.onChange(!value);
        }}
    />
);

const renderFullAdmin = ({ input, toggle }) => (
    <ToggleButton
        value={input.value || false}
        onToggle={(value) => {
            input.onChange(!value);
            toggle(value);
        }}
    />
);

const GroupList = (Groups) => {

        return Groups.map((group, index) => {
            return (
                <ul className="clearfix" key={index}>
                    <li className="pro-name">{group.name}</li>
                    <li className="project-member"><Field name={'groupPermissions.' + group._id} component={renderToggleButton} /></li>
                </ul>
            );
        });
};

const groupItems = (groups) => {
   return (
        <div className="admin-widgets">
            <div className="admin-head clearfix">
                <div className="pull-left">
                    <i className="fa fa-users name-ico blueBG"></i>
                    <span> Groups</span>
                </div>
            </div>
            <div className="detail-list">
                {GroupList(groups)}
            </div>
        </div>
   );
};

const menuItems = () => {
   return ( 
    <div className="admin-widgets">
        <div className="admin-head clearfix">
            <div className="pull-left">
                <i className="fa fa-bars name-ico blueBG"></i>
                <span> Menu Items</span>
            </div>
        </div>
        <div className="detail-list">
            <ul className="clearfix">
                <li className="pro-name">Accounts</li>
                <li className="project-member"><Field name="menuPermissions.accounts" component={renderToggleButton} /></li>
            </ul>
            <ul className="clearfix">
                <li className="pro-name">Contacts</li>
                <li className="project-member"><Field name="menuPermissions.contacts" component={renderToggleButton} /></li>
            </ul>
            <ul className="clearfix">
                <li className="pro-name">Contact Permission</li>
                <li className="project-member"><Field name="menuPermissions.contactPermission" component={renderToggleButton} /></li>
            </ul>
            <ul className="clearfix">
                <li className="pro-name">Dashboard</li>
                <li className="project-member"><Field name="menuPermissions.dashboard" component={renderToggleButton} /></li>
            </ul>
            <ul className="clearfix">
                <li className="pro-name">Events</li>
                <li className="project-member"><Field name="menuPermissions.events" component={renderToggleButton} /></li>
            </ul>
            <ul className="clearfix">
                <li className="pro-name">Forms</li>
                <li className="project-member"><Field name="menuPermissions.forms" component={renderToggleButton} /></li>
            </ul>
            <ul className="clearfix">
                <li className="pro-name">Instruments</li>
                <li className="project-member"><Field name="menuPermissions.instruments" component={renderToggleButton} /></li>
            </ul>
            <ul className="clearfix">
                <li className="pro-name">Library</li>
                <li className="project-member"><Field name="menuPermissions.library" component={renderToggleButton} /></li>
            </ul>
            <ul className="clearfix">
                <li className="pro-name">Settings</li>
                <li className="project-member"><Field name="menuPermissions.settings" component={renderToggleButton} /></li>
            </ul>
            <ul className="clearfix">
                <li className="pro-name">Students</li>
                <li className="project-member"><Field name="menuPermissions.students" component={renderToggleButton} label="" default="true" /></li>
            </ul>
            <ul className="clearfix">
                <li className="pro-name">Tuition</li>
                <li className="project-member"><Field name="menuPermissions.tuition" component={renderToggleButton} /></li>
            </ul>
        </div>
   </div>);
};


const PermissionForm = (props) => {

    const { handleSubmit, submitting, contact, onEmailPassword, sending, groups, toggle } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-sm-6">
                    <div className="col-sm-12">
                        <label className="col-sm-10">Contact Login Active</label>
                        <div className="col-sm-2"><Field name="loginActive" component={renderToggleButton} /></div>
                    </div>
                    <br /><hr /><br />
                    {!contact.fullAdmin && menuItems()}
                </div>
                <div className="col-sm-6">
                    <div className="col-sm-12">
                        <label className="col-sm-10">Full Admin Access</label>
                        <div className="col-sm-2"><Field name="fullAdmin" component={renderFullAdmin} toggle={toggle} /></div>
                    </div>
                    <br /><hr /><br />
                    {!contact.fullAdmin && groupItems(groups)}
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-xs-6">
                     <button type="submit" className="btn btn-primary rounded" disabled={submitting}>Save</button>&nbsp;&nbsp;
                     <Link to="contacts" className="btn btn-default rounded">Close</Link>
                </div>
                <div className="col-xs-6">
                     <div className="text-right">{(contact.loginActive) && <button type="button" className="btn btn-warning rounded" disabled={sending} onClick={() => onEmailPassword()}>Email Login</button>}</div>
                </div>
            </div>
    </form>
    );
};

PermissionForm.propTypes = {
    ...propTypes,
    groups: PropTypes.array.isRequired,
    newContact: PropTypes.bool
};

GroupList.propTypes = {
    Groups: PropTypes.array.isRequired
};

renderToggleButton.propTypes = {
    input: PropTypes.object.isRequired
};

renderFullAdmin.propTypes = {
    input: PropTypes.object.isRequired,
    toggle: PropTypes.func.isRequired
};

export default reduxForm({
    form: 'PermissionForm'
})(PermissionForm);