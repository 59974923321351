import { Component } from 'react';
import PropTypes from 'prop-types';

class EmptyToolbar extends Component {
  
  componentDidMount() {
		this.props.onToggleFilter();
	}
	
	render() {
		return null;
	}
}

EmptyToolbar.propTypes = {
  onToggleFilter: PropTypes.func
};


export default EmptyToolbar;