import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { required } from '../common/validation';
  
 const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} placeholder={label} type={type}/>
        { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const UpdatePassword = (props) => {
    
    const { handleSubmit, submitting } = props;
   
    return (
        <form onSubmit={handleSubmit} className="form-horizontal">
            <div className="form-group">
                <div className="col-md-12 space-20">
                    <Field name="password" type="password" component={renderField} label="Password" validate={required} />
                </div>
            </div>

             <div className="form-actions clearfix">
                <div className="remember-me">
                    <Field name="remember" id="remember" component="input" type="checkbox"/> <span>Remember me?</span>
                </div>
                <div className="submit-form pull-right">
                    <button type="submit" className="reset-pass-btn rounded" disabled={submitting}>Update</button>
                </div>

            </div>    
        </form>
    );
};

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired
};

UpdatePassword.propTypes = {
  ...propTypes
  
};

export default reduxForm({
    form: 'updatePassword'
})(UpdatePassword);