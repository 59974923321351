import axios from 'axios';
import Notifications from 'react-notification-system-redux';
import { SCHOOL_GROUP, GET_SCHOOL, ROOT_URL } from './types';


export function addGroup(group, schoolId, history) {
   return function(dispatch) {
    axios.post(`${ROOT_URL}/school/groups/${schoolId}`,  {group},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
          dispatch( {type: SCHOOL_GROUP, payload: response.data} );
          dispatch(Notifications.success({ title: 'Group Added' }));
          history.push('/');
     
      }).catch(error => {
        throw(error);
      });
  };
}

export function updateGroup(group, id) {

   return function(dispatch) {
    axios.put(`${ROOT_URL}/school/groups/${id}`, {group}, 
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {

          dispatch( {type: SCHOOL_GROUP, payload: response.data} );
          dispatch(Notifications.success({ title: 'Group Updated' }));

      }).catch(error => {
        throw(error);
      });
  };
}

export function deleteGroup(group, schoolId, history) {
   return function(dispatch) {
    axios.patch(`${ROOT_URL}/school/groups/${schoolId}`, {group},
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
          history.push('/');
          dispatch( {type: SCHOOL_GROUP, payload: response.data} );
          dispatch(Notifications.error({ title: 'Group Deleted' }));
  
      }).catch(error => {
        throw(error);
      });
  };
}

export function addRehearsal(rehearsal, schoolId) {
   const title = (rehearsal._id) ? 'Rehearsal Updated' : 'Rehearsal Added';
   return function(dispatch) {
    axios.post(`${ROOT_URL}/group/rehearsal/${schoolId}`, {rehearsal},
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
          dispatch( {type: GET_SCHOOL, school: response.data} );
          dispatch(Notifications.success({ title }));

        }).catch(error => {
        throw(error);
      });
  };
}

export function removeRehearsal(eventId, groupId) {
   return function(dispatch) {
    axios.delete(`${ROOT_URL}/group/rehearsal/${eventId}/${groupId}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
          dispatch( {type: GET_SCHOOL, school: response.data} );
          dispatch(Notifications.error({ title: 'Rehearsal Removed' }));

        }).catch(error => {
        throw(error);
      });
  };
}

export function addGroupContact(contact, groupId) {
   return function(dispatch) {
    axios.post(`${ROOT_URL}/group/contact/${groupId}`, {contact},
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
          dispatch( {type: GET_SCHOOL, school: response.data} );
          dispatch(Notifications.success({ title: 'Group Contact Added' }));

        }).catch(error => {
        throw(error);
      });
  };
}

export function removeGroupContact(contact, groupId) {
   return function(dispatch) {
    axios.put(`${ROOT_URL}/group/contact/${groupId}`, {contact},
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
          dispatch( {type: GET_SCHOOL, school: response.data} );
          dispatch(Notifications.error({ title: 'Group Contact Removed' }));

        }).catch(error => {
        throw(error);
      });
  };
}

