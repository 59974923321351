import React from 'react';
import { Field, reduxForm } from 'redux-form';
import DropdownList from 'react-select';

const dropdownData = (data) => {
  let newArry = data.map(function(obj) {
   return { _id: obj._id, name: obj.name, shortName: obj.shortName };
   });

   return newArry;
};

const renderDropdownList = ({ input, label, ...rest }) => (
  <div className="bulkUpdate">
    <label className="w120">{label}</label>
    <div className="fullWidth"><DropdownList {...input} {...rest} onBlur={() => input.change} /></div>
  </div>
);

const newStudentBulkForm = (props) => {
    const { handleSubmit, selectorsValues, selectGroups, closeModal, noStudents } = props;

    return (
        <div className="clearfix form-padding">
            <form onSubmit={handleSubmit}>
              <Field name="groupId" label="Group" type="text" valueKey="_id" labelKey="name" simpleValue={true} clearable={false} component={renderDropdownList} options={dropdownData(selectGroups)} />
              <Field name="tutor" label="Tutor" valueKey="_id" labelKey="name" simpleValue={true} clearable={false} component={renderDropdownList} options={selectorsValues.tutors} />
              <Field name="instrument" label="Instrument" type="text" valueKey="label" simpleValue={true} clearable={false} component={renderDropdownList} options={selectorsValues.instrumentList} />
              <Field name="status" label="Status" type="text" valueKey="label" simpleValue={true} clearable={false} component={renderDropdownList} options={selectorsValues.studentStatus}/>
              <div className="bulkUpdateFooter">
                <div>
                    <button type="submit" className="btn btn-sm btn-success rounded">Update Students</button>&nbsp;&nbsp;
                    <button type="button" className="btn btn-sm btn-default rounded" onClick={() => closeModal()}>Close</button>
                </div>
                <div>{noStudents} students will be updated</div>
              </div>  
           </form>
    </div>
    );
};

export default reduxForm({
    form: 'newStudentBulkForm'
})(newStudentBulkForm);