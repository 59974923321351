import _ from 'lodash';
import axios from 'axios';
import Notifications from 'react-notification-system-redux';
import moment from 'moment';
import {
  GET_SCHOOL,
  GET_LOGS,
  TOGGLE_MENU,
  GET_CONTACTS,
  GET_STUDENTS,
  LASTUPDATE_STUDENT,
  SEARCH_TEXT,
  SET_EMAIL_LIST,
  IMPORT_LIST,
  GET_LOG,
  SCHOOL_GROUP,
  ADD_ATTACHMENT,
  REMOVE_ATTACHMENT,
  CURRENT_URL,
  GET_FORMS,
  EMAIL_STATUS,
  CLEAR_EMAIL,
  SET_SCHOOL,
  SIGN_IN,
  CLEAR_ATTACHMENT,
  ROOT_URL,
} from './types';

export function reload(url, history) {
  history.push('/reload');
  return {
    type: CURRENT_URL,
    payload: url,
  };
}

export function getSchool(id, history) {
  return function (dispatch) {
    axios
      .get(`${ROOT_URL}/school/${id}`, { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        dispatch({
          type: GET_SCHOOL,
          school: response.data,
        });
        history.push('/dashboard');
      })
      .catch(() => {
        history.push('/signout');
      });
  };
}

export function getFullLogs(schoolId) {
  return function (dispatch) {
    axios
      .get(`${ROOT_URL}/school/logs/${schoolId}`, { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        const logItems = response.data.logItems.map((item) => {
          item.logDate = moment(item.logDate).format('llll');
          return item;
        });
        dispatch({ type: GET_LOGS, payload: logItems });
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function toggleMenu(status) {
  return function (dispatch) {
    axios
      .get(`${ROOT_URL}/contact/toggle-menu/${!status}`, { headers: { authorization: localStorage.getItem('token') } })
      .then(() => {
        dispatch({ type: TOGGLE_MENU });
      });
  };
}

export function getContacts(schoolId) {
  return function (dispatch) {
    axios
      .get(`${ROOT_URL}/contacts/${schoolId}`, { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        dispatch({ type: GET_CONTACTS, payload: response.data });
      });
  };
}

export function getStudents(schoolId, active, lastUpdate) {
  return function (dispatch) {
    axios
      .post(
        `${ROOT_URL}/students/${schoolId}/${active}`,
        { lastUpdate },
        { headers: { authorization: localStorage.getItem('token') } }
      )
      .then((response) => {
        dispatch({ type: GET_STUDENTS, payload: response.data });
        dispatch({ type: LASTUPDATE_STUDENT, payload: moment().toDate() });
      });
  };
}

export function getInstruments() {
  return;
}

export function addGroup(group, schoolId) {
  return function (dispatch) {
    axios
      .post(
        `${ROOT_URL}/school/groups/${schoolId}`,
        { group },
        { headers: { authorization: localStorage.getItem('token') } }
      )
      .then((response) => {
        dispatch({ type: SCHOOL_GROUP, payload: response.data });
        dispatch(Notifications.success({ title: 'Group Added' }));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getEvents(history) {
  history.push('/events');
}

export function getSettings(history) {
  history.push('/settings');
}

export function getEmailListGroups(groups, history) {
  return function (dispatch) {
    axios
      .post(`${ROOT_URL}/group-list`, { groups }, { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        dispatch({
          type: SET_EMAIL_LIST,
          payload: response.data,
        });
        history.push('/send-email');
      })
      .catch((error) => {
        //console.log('error', Object.assign({}, error));
        dispatch(Notifications.error({ title: error.response.data }));
      });
  };
}

export function resetEmailButton() {
  return { type: EMAIL_STATUS, payload: 'Send Email' };
}

export function SendEmail(emailDetails, schoolId) {
  return function (dispatch) {
    // dispatch({ type: EMAIL_STATUS, payload: 'Sending ...' });
    axios
      .post(`${ROOT_URL}/send-email/${schoolId}`, emailDetails, {
        headers: { authorization: localStorage.getItem('token') },
      })
      .then((response) => {
        dispatch(Notifications.success({ title: 'Emails Sent', message: response.data, autoDismiss: 15 }));
        dispatch({ type: EMAIL_STATUS, payload: 'Email Sent' });
      })
      .catch((error) => {
        dispatch(Notifications.error({ title: error.response.data }));
        dispatch({ type: EMAIL_STATUS, payload: 'Send Email' });
      });
  };
}

export function SendRosterEmail(emailDetails, groupId, term, schoolId) {
  return function (dispatch) {
    dispatch({ type: EMAIL_STATUS, payload: 'Sending ...' });
    axios
      .post(`${ROOT_URL}/send-email/${groupId}/${term}/${schoolId}`, emailDetails, {
        headers: { authorization: localStorage.getItem('token') },
      })
      .then((response) => {
        dispatch(Notifications.success({ title: 'Roster Emails Sent', message: response.data, autoDismiss: 15 }));
        dispatch({ type: EMAIL_STATUS, payload: 'Email Sent' });
      })
      .catch((error) => {
        dispatch(Notifications.error({ title: error.response.data }));
        dispatch({ type: EMAIL_STATUS, payload: 'Send Email' });
      });
  };
}

export function SendContactEmail(emailDetails, schoolId) {
  return function (dispatch) {
    dispatch({ type: EMAIL_STATUS, payload: 'Sending ...' });
    axios
      .put(`${ROOT_URL}/send-email/${schoolId}`, emailDetails, {
        headers: { authorization: localStorage.getItem('token') },
      })
      .then((response) => {
        dispatch(Notifications.success({ title: 'Emails Sent', message: response.data, autoDismiss: 15 }));
        dispatch({ type: EMAIL_STATUS, payload: 'Email Sent' });
      })
      .catch((error) => {
        dispatch(Notifications.error({ title: error.response.data }));
        dispatch({ type: EMAIL_STATUS, payload: 'Send Email' });
      });
  };
}

export function clearEmail() {
  return { type: CLEAR_EMAIL };
}

export function saveTemplate(template, id) {
  return function (dispatch) {
    axios
      .post(
        `${ROOT_URL}/library/template/${id}`,
        { template },
        { headers: { authorization: localStorage.getItem('token') } }
      )
      .then(() => {
        dispatch(Notifications.success({ title: 'Template Saved' }));
      })
      .catch(() => {
        dispatch(Notifications.warning({ title: 'Unable to Save Template' }));
      });
  };
}

export function searchText(searchText) {
  return {
    type: SEARCH_TEXT,
    payload: searchText,
  };
}

export function uploadImport(file, schoolId) {
  return function (dispatch) {
    if (file.length === 0) {
      dispatch(Notifications.error({ title: 'Invalid File', message: 'Max size 1 mb, File type csv' }));
    } else {
      const data = new FormData();
      data.append('file', file[0]);
      data.append('name', schoolId);

      axios
        .post(`${ROOT_URL}/utils/upload-import/${schoolId}`, data, {
          headers: { authorization: localStorage.getItem('token') },
        })
        .then((response) => {
          dispatch({
            type: IMPORT_LIST,
            payload: response.data,
          });
        });
    }
  };
}

export function uploadFile(file, schoolId) {
  return function (dispatch) {
    if (file.length === 0) {
      dispatch(Notifications.error({ title: 'Invalid File', message: 'Max size 1 mb, File type csv' }));
    } else {
      const data = new FormData();
      data.append('file', file[0]);
      data.append('name', schoolId);

      axios
        .post(`${ROOT_URL}/upload-file/${schoolId}`, data, {
          headers: { authorization: localStorage.getItem('token') },
        })
        .then((response) => {
          const attachment = _.pick(response.data, ['_id', 'label']);
          dispatch({
            type: ADD_ATTACHMENT,
            payload: attachment,
          });
        });
    }
  };
}

export function addAttachment(response) {
  return {
    type: ADD_ATTACHMENT,
    payload: response.data,
  };
}

export function displayWarning(message) {
  return Notifications.error({ title: message });
}

export function removeAttachment(index) {
  return {
    type: REMOVE_ATTACHMENT,
    payload: index,
  };
}

export function clearAttachment() {
  return { type: CLEAR_ATTACHMENT };
}

export function importNow(importList, schoolId) {
  return function (dispatch) {
    axios
      .post(`${ROOT_URL}/utils/import-now/${schoolId}`, importList, {
        headers: { authorization: localStorage.getItem('token') },
      })
      .then((response) => {
        dispatch(Notifications.success({ title: response.data }));
        dispatch({ type: IMPORT_LIST, payload: [] });
      });
  };
}

export function clearImport() {
  return {
    type: IMPORT_LIST,
    payload: [],
  };
}

export function getLogDetails(logId, history) {
  return function (dispatch) {
    axios
      .get(`${ROOT_URL}/email-details/${logId}`, { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        dispatch({
          type: GET_LOG,
          payload: response.data,
        });
        history.push('/log-details');
      });
  };
}

export function updateSchool(schoolDetails, schoolId) {
  return function (dispatch) {
    axios
      .post(
        `${ROOT_URL}/school/${schoolId}`,
        { schoolDetails },
        { headers: { authorization: localStorage.getItem('token') } }
      )
      .then((response) => {
        dispatch({ type: GET_SCHOOL, school: response.data });
        dispatch(Notifications.success({ title: 'School Details Updated' }));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function uploadLog(blob, schoolId) {
  return function (dispatch) {
    const data = new FormData();
    data.append('file', blob);

    axios
      .post(`${ROOT_URL}/upload-logo/${schoolId}`, data, { headers: { authorization: localStorage.getItem('token') } })
      .then(() => {
        dispatch(Notifications.success({ title: 'Logo Uploaded' }));
      });
  };
}

export function logSupportTicket(body, user) {
  return function (dispatch) {
    axios
      .post(
        `${ROOT_URL}/support/`,
        {
          message: body.message,
          subject: body.subject,
          tags: [user.schoolName],
        },
        { headers: { authorization: localStorage.getItem('token') } }
      )
      .then(() => {
        dispatch(
          Notifications.success({
            title: 'Support Ticket Received',
            message: `A response will be sent to: ${user.profile.email}`,
          })
        );
      })
      .catch(() => {
        dispatch(
          Notifications.error({
            title: 'Unable to send support ticket',
            message: 'Please email support on support@myschoolmusic.com.au',
          })
        );
      });
  };
}

export function changeSchool(schoolId, history) {
  return function (dispatch) {
    axios
      .get(`${ROOT_URL}/auth/change/${schoolId}`, { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        history.push('/change-school');
        dispatch({ type: 'USER_LOGOUT' });
        dispatch({ type: GET_SCHOOL, school: response.data });

        // Update State
        dispatch({ type: SET_SCHOOL, payload: response.data.school });
        dispatch({
          type: SIGN_IN,
          remember: true,
          profile: response.data.profile,
          menu: response.data.menu,
          schoolList: response.data.schoolList,
        });
        dispatch(Notifications.success({ title: `Signed into ${response.data.school.schoolName}` }));

        // Direct to admin page or Tutors
        if (response.data.school.fullAdmin || response.data.school.menuPermissions.dashboard) {
          history.push('/dashboard');
        } else {
          history.push('/tuition-list');
        }
      })
      .catch(() => {
        localStorage.removeItem('token');
        dispatch(Notifications.error({ title: 'Login Error', message: 'Unable to login contact administrator' }));
      });
  };
}

export function getForms(id) {
  return function (dispatch) {
    axios
      .get(`${ROOT_URL}/forms/${id}`, { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        dispatch({ type: GET_FORMS, form: response.data });
      })
      .catch((error) => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}
