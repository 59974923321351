import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/forms';
import { Modal } from 'react-bootstrap';
import InfoForm from './info-form';

class NewRegistrationInfo extends Component {

      constructor(props, context) {
        super(props, context);
        this.state = {
            showModal: false,
            selectedInfo: {},
            infoText: ''
        };

        this.saveInfo = this.saveInfo.bind(this);
        this.selectInfo = this.selectInfo.bind(this);
        this.setInfoField = this.setInfoField.bind(this);
        this.setInfoField = this.setInfoField.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }


  saveInfo(info) {
    this.setState({ showModal: false });
    const updatedInfo = Object.assign({} ,info, { infoText: this.state.infoText });
    this.props.saveNewRegistrationInfo(updatedInfo, this.props.userSetting.schoolId);
  }

  selectInfo(info) {
    const selectedInfo = (info === 'infoMusic') 
        ? { infoText: this.props.forms.newReg.musicProgramInfo, type: 'infoMusic' } 
        : { infoText: this.props.forms.newReg.parentAgreement,
            acceptAgreementText: this.props.forms.newReg.acceptAgreementText || 'I have read and accept the terms of the music program',
            type: 'parentAgreement'
        };
      this.setState({ showModal: true, selectedInfo });
  }

  setInfoField(infoText) {
        this.setState({infoText});
        }

 close() {
        this.setState({ showModal: false });
    }

    open() {
        this.setState({ showModal: true });
    }

   
    render() {
        return(
            <div>
                <ul className="list-group">
                    <li key="1"  onClick={() => this.selectInfo('infoMusic')} className="list-group-item">
                        <div className="row">
                            <div className="col-xs-10"><b>Music Information ...</b></div>
                            <div className="col-xs-2 text-right"><i className="fa fa-angle-right">&nbsp;</i></div>
                        </div>
                    </li>
                    <li key="2"  onClick={() => this.selectInfo('terms')} className="list-group-item">
                        <div className="row">
                            <div className="col-xs-10"><b>Terms and Conditions ...</b></div>
                            <div className="col-xs-2 text-right"><i className="fa fa-angle-right">&nbsp;</i></div>
                        </div>
                    </li>
                </ul>

                <Modal show={this.state.showModal} bsSize="large" onHide={this.close}>
                    <Modal.Header bsClass="modal-header primary" closeButton>
                        <Modal.Title>Registration Info</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-padding">
                            <InfoForm onSubmit={this.saveInfo}  onClose={this.close} initialValues={this.state.selectedInfo} onInfoField={this.setInfoField}  />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

NewRegistrationInfo.propTypes = {
  userSetting: PropTypes.object.isRequired,
  forms: PropTypes.object.isRequired,
  saveNewRegistrationInfo: PropTypes.func.isRequired
};


function mapStateToProps(state) {
  return {
        userSetting: state.usersetting,
        forms: state.forms
  };
}

export default connect(mapStateToProps, actions)(NewRegistrationInfo);
