import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import { Checkbox } from 'react-icheck';

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
  <div>
      <DropdownList {...input} {...rest} onBlur={() => input.change} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderCheckbox = ({ input, name }) => (
    <Checkbox 
      checkboxClass="icheckbox_square-blue" 
      increaseArea="20%"
      name={name}
      onChange={input.onChange}
      defaultChecked = {input.value ? true : false}
      />
  );

const renderComment = (comment) => (
    <div>
        <div className="row">
            <label className="col-sm-2">Comment:</label>
            <div className="col-sm-10">
                <div>{comment}</div>
            </div>
        </div>
        <hr />
    </div>
);

const renderGroups = ({ fields, selectorsValues, selectGroups }) => (
  <div>
      <table className="table table-bordered">
      <thead>
      <tr>
          <th width="15%">Group</th>
          <th width="30%">Instrument</th>
          <th width="30%">Tutor</th>
          <th width="25%">Status</th>
          <th width="10px"><i className="fa fa-trash" aria-hidden="true"></i></th>
      </tr>
      </thead>
      <tbody>
          {fields.map((groups, index) => {
             return (<tr key={index}>
                  <td><Field name={`${groups}.groupId`} type="text" valueKey="_id" labelKey="shortName" simpleValue={true} clearable={false} component={renderDropdownList} options={selectGroups} /></td>
                  <td><Field name={`${groups}.instrument`} type="text" valueKey="label" simpleValue={true} clearable={false} component={renderDropdownList} options={selectorsValues.instrumentList} /></td>
                  <td><Field name={`${groups}.tutor`} type="text" valueKey="_id" labelKey="name" simpleValue={true} clearable={false} component={renderDropdownList} options={selectorsValues.tutors} /></td>
                  <td><Field name={`${groups}.status`} type="text" valueKey="label" simpleValue={true} clearable={false} component={renderDropdownList} options={selectorsValues.studentStatus}/></td>
                  <td><div className="top-5" onClick={() => fields.remove(index)}><i className="fa fa-times">&nbsp;</i></div></td>
              </tr>);
          }
      )}
      </tbody>
      </table>
      <button type="button" className="btn btn-default btn-xs rounded pull-right" onClick={() => fields.push({})}>Add to a Group</button>
  </div>

);

const EnrolmentDetails = (props) => {
    const { handleSubmit, selectors, groupList, enrolments, closeStudent } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="clearfix">
                <div className="col-sm-12">
                    <h4>Enrolment Details</h4>
                    <div className="row">
                    <div className="form-group">
                        <div className="col-md-12">
                            <FieldArray name="groups" component={renderGroups} selectorsValues={selectors} selectGroups={groupList} enrolments={enrolments} />
                        </div>
                    </div>
                </div>
                <hr />
                {props.initialValues.comment && renderComment(props.initialValues.comment)}
                <div>
                    <div className="col-md-8 col-sm-12">
                        <div className="form-group">
                            <Field name="clearEnrolment" component={renderCheckbox} />&nbsp; <span>Clear student from enrolment list after saving</span>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 text-right">
                        <div>
                            <button type="submit" className="btn btn-sm btn-primary rounded">Save</button>&nbsp;&nbsp;
                            <button type="button" className="btn btn-sm btn-default rounded" onClick={() => closeStudent()}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
      </form> 
    );
};

EnrolmentDetails.propTypes = {
  ...propTypes,
};

renderGroups.propTypes = {
  fields: PropTypes.object.isRequired,
  selectorsValues: PropTypes.object.isRequired,
  selectGroups: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
  removeGroup: PropTypes.func
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};


renderCheckbox.propTypes = {
    input: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired
  };

export default reduxForm({
  form: 'EnrolmentDetails',
  enableReinitialize: true
})(EnrolmentDetails);

