import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { required } from '../common/validation';
import FormEditor from '../editor/FormEditor';
import { Checkbox } from '@blueprintjs/core';

const renderField = ({ input, meta: { touched, error } }) => (
  <div>
    <input className='form-control' {...input} type='text' />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderCheckbox = ({ input }) => (
  <label className='pt-control pt-checkbox pt-large'>
    <Checkbox checked={input.value} onChange={input.onChange} />
  </label>
);

const renderPortal = ({ input, meta: { touched, error } }) => (
  <div className='input-group'>
    <span className='input-group-addon'>portal.msmusic.com.au/</span>
    <input className='form-control' {...input} type='text' />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderEditor = ({ input, onPortalText }) => (
  <FormEditor value={input.value} handleOnChange={input.onChange} onBlur={onPortalText(input.value)} />
);

const SchoolDetailsForm = (props) => {
  const { handleSubmit, submitting, onPortalText } = props;
  // School Name, email, contact, number, website, portal-link, logo, colour
  return (
    <div className='basic-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>School</label>
            </div>
            <div className='col-sm-8'>
              <Field name='name' component={renderField} validate={required} />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>Email</label>
            </div>
            <div className='col-sm-8'>
              <Field name='email' component={renderField} />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>Portal URL</label>
            </div>
            <div className='col-sm-8'>
              <Field name='portalURL' component={renderPortal} validate={required} />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>Email Link</label>
            </div>
            <div className='col-sm-1'>
              <Field name='portalLink' component={renderCheckbox} />
            </div>
            <div className='col-sm-8'>Include a link to the parent portal in the footer of emails sent</div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>Hide Invoices</label>
            </div>
            <div className='col-sm-1'>
              <Field name='hideAccounts' component={renderCheckbox} />
            </div>
            <div className='col-sm-8'>Hide the invoice section on the parent portal</div>
          </div>
        </div>

        <hr />
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-12'>
              <Field name='portalText' type='textarea' onPortalText={onPortalText} component={renderEditor} />
            </div>
          </div>
        </div>
        <hr />
        <button type='submit' className='btn btn-primary rounded' disabled={submitting}>
          Save
        </button>
      </form>
    </div>
  );
};

SchoolDetailsForm.propTypes = {
  ...propTypes,
};

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

renderPortal.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'schoolDetails',
})(SchoolDetailsForm);
