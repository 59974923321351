import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { required } from '../common/validation';
import 'react-datepicker/dist/react-datepicker.css';

const renderDatePicker = ({ input: { onChange, value }, disabled }) =>
    <DatePicker 
        className="form-control"
        disabled={disabled}
        selected={value ? moment(value) : (disabled) ? '' : moment()}
        dateFormat="D MMM YYYY"
        onChange={onChange}
    />;

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
  <div>
      <DropdownList {...input} {...rest} onBlur={() => input.change} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderField = ({ input, type, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type={type} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderCurrencyField = ({ input, type, meta: { touched, error } }) => (
  <div className="input-group">
      <span className="input-group-addon">$</span>
      <input className="form-control" {...input} type={type} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const InstrumentServiceForm = (props) => {
    const { handleSubmit, serviceCentres, inService, openServiceCentres } = props;

    return (
        <div className="basic-form">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <div className="row">
                        <div className="col-xs-12 col-sm-5">
                            <div className="col-sm-5">
                                <label>Sent</label>
                            </div>
                            <div className="col-sm-7">
                                <Field name="dateSent" type="text" component={renderDatePicker} />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-7">
                            <div className="col-sm-4">
                                <label>Centre</label>
                            </div>
                            <div className="col-sm-7">
                                <Field name="serviceCentre" type="text" component={renderDropdownList} valueKey="name" labelKey="name" simpleValue={true} options={serviceCentres} validate={[required]} />
                            </div>
                            <div className="col-sm-1 padding-top-half">
                                <div onClick={() => openServiceCentres('Service Centres')}><i className="fa fa-plus-square blueColor" /></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-xs-12 col-sm-5">
                            <div className="col-sm-5">
                                <label>Returned</label>
                            </div>
                            <div className="col-sm-7">
                            <Field name="dateReturned" type="text" component={renderDatePicker} disabled={!inService} />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-7">
                            <div className="col-sm-4">
                                <label>Cost</label>
                            </div>
                            <div className="col-sm-5">
                                <Field name="cost" type="text" component={renderCurrencyField} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="col-sm-2">
                                <label>Comment</label>
                            </div>
                            <div className="col-sm-10">
                                <Field name="comment" type="text" component={renderField}  />
                            </div>
                        </div>
                    </div>
                </div>

                <hr />
                <div className="row">
                    <div className="col-sm-12">
                        <button type="submit" className="btn btn-success btn-sm rounded" disabled={inService}>Send For Service</button>&nbsp;&nbsp;
                        {inService && <button type="submit" className="btn btn-warning btn-sm rounded">Return from Service</button>}
                    </div>       
                </div>
               
            </form>
        </div>
    );
};

InstrumentServiceForm.propTypes = {
    ...propTypes
};

renderField.propTypes = {
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
};

renderCurrencyField.propTypes = {
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
};

renderDropdownList.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

renderDatePicker.propTypes = {
    input: PropTypes.object.isRequired,
    disabled: PropTypes.bool
};

export default reduxForm({
    form: 'InstrumentServiceForm',
    enableReinitialize: true
})(InstrumentServiceForm);