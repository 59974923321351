import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data } from 'react-data-grid-addons';

const Selectors = Data.Selectors;

class StudentsList extends Component {
    
     constructor(props) {
        super(props);

        this.state = {
            selectedStudents: [],
            rows: _.map(this.props.students, student => student),
            filters : {},
            sortColumn: null, 
            sortDirection: null,
        };

        this.rowGetter = this.rowGetter.bind(this);
        this.onRowsSelected = this.onRowsSelected.bind(this);
        this.onRowsDeselected = this.onRowsDeselected.bind(this);
        this.getRows = this.getRows.bind(this);
        this.getSize = this.getSize.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.onClearFilters = this.onClearFilters.bind(this);
        this.handleGridSort = this.handleGridSort.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.students !== this.props.students) {
          this.setState({rows: _.map(nextProps.students, student => student)});
        }
     }

    rowGetter(i) {
        const rows = this.getRows();
        return rows[i];
    }

    onRowsSelected(rows) {
        const selectedStudents = this.state.selectedStudents.concat(rows.map(r => r.row));
        this.setState({ selectedStudents });
        this.props.selectedStudents(selectedStudents);
    }

    onRowsDeselected(rows) {
        let ids = rows.map(r => r.row._id);
        const selectedStudents = this.state.selectedStudents.filter(student => !ids.some(id => id === student._id));
        this.setState({ selectedStudents });
        this.props.selectedStudents(selectedStudents);
    }

    getRows() {
         return Selectors.getRows(this.state);
     }

    getSize() {
        return this.getRows().length;
    }

    handleFilterChange(filter) {
        const newFilters = Object.assign({}, this.state.filters);
        if (filter.filterTerm) {
        newFilters[filter.column.key] = filter;
        } else {
        delete newFilters[filter.column.key];
        }
        this.setState({filters: newFilters});
    }

    onClearFilters() {
        //all filters removed
        this.setState({filters: {} });
    }

     handleGridSort(sortColumn, sortDirection) {
        const state = Object.assign({}, this.state, {sortColumn: sortColumn, sortDirection: sortDirection});
        this.setState(state);
    }

    studentTags(tags) {
        if (tags.value.length === 0) return null;
        return (
            <div>
               {tags.value.map((tag, index) => {
                   return <span key={index} className="label label-info label-padding">{tag}</span>;
                   })}
            </div>
        );
    }
  
    render() {

        if (!this.props.students) {
            return <div>loading...</div>;
        }

        const columns = [
            {
                key: 'firstName',
                name: 'First Name',
                sortable : true,
                filterable : true,
                width: 140
            },
            {
                key: 'lastName',
                name: 'Last Name',
                sortable : true,
                filterable : true,
                width: 150
            },
             {
                key: 'grade',
                name: 'Grade',
                sortable : true,
                filterable : true,
                width: 70
            },
             {
                key: 'class',
                name: 'Class',
                sortable : true,
                filterable : true,
                width: 80
            },
             {
                key: 'groupSummary',
                name: 'Groups',
                sortable : true,
                filterable : true,
            },
            {
                key: 'instruments',
                name: 'Instruments',
                sortable : true,
                filterable : true,
            },
            {
                key: 'studentTags',
                name: 'Tags',
                sortable : true,
                filterable : true,
                formatter: this.studentTags
            }
            ];

        return (
            <div className="padding-top-one">
              <div>
                  <ReactDataGrid
                          rowKey="_id"
                          onGridSort={this.handleGridSort}
                          columns={columns}
                          rowGetter={this.rowGetter}
                          rowsCount={this.getSize()}
                          minHeight={this.getSize() * 35 + 85}
                          onRowClick={this.onRowClick}
                          toolbar={<Toolbar enableFilter={true}/>}
                          onAddFilter={this.handleFilterChange}
                          onClearFilters={this.onClearFilters}
                          rowSelection={{
                              showCheckbox: true,
                              enableShiftSelect: true,
                              onRowsSelected: this.onRowsSelected,
                              onRowsDeselected: this.onRowsDeselected,
                              selectBy: {
                                  keys: { rowKey: '_id', values: this.state.selectedStudents.map(row => row._id)}
                              }
                          }} />
                      </div>
                      <div className="admin-label text-right margin-top-quarter"><i className="blueBG"> { (this.getSize() === this.state.rows.length) ? `${this.state.rows.length} Students` : `Showing ${this.getSize()} of ${this.state.rows.length} Students`} </i></div>
                  </div>
        );
    }
}

StudentsList.propTypes = {
  students: PropTypes.object.isRequired,
  getStudent: PropTypes.func.isRequired,
  getStudents: PropTypes.func.isRequired,
  selectedStudents: PropTypes.func.isRequired
};

export default StudentsList;
