import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
    <div>
        <DropdownList {...input} {...rest} onBlur={() => input.change} />
        { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
);

const ReEnroleBulk = (props) => {
    
    const { handleSubmit, onRemove } = props;
   
    return (
        <form onSubmit={handleSubmit}>
             <div className="row">   
                <div className="col-sm-9">
                    <div className="form-group"> 
                        <label>Remove selected students from re-enrolment list</label>
                    </div>
                </div>
                <div className="col-sm-3 text-right">
                    <div className="form-group"> 
                        <button type="button" className="btn btn-sm btn-warning rounded" onClick={() => onRemove('remove')}>Remove</button>
                    </div>
                </div>
             </div>
             <div className="hr-line" />
             <div className="row">
                <div className="col-sm-9">
                    <div className="form-group"> 
                        <label>Clear previous re-enrolment data from selected students</label>
                    </div>
                </div>
                <div className="col-sm-3 text-right">
                    <div className="form-group"> 
                        <button type="button" className="btn btn-sm btn-info rounded" onClick={() => onRemove('clear')}>Clear</button>
                    </div>
                </div>
            </div>
        </form>
    );
};

ReEnroleBulk.propTypes = {
    ...propTypes
};

renderDropdownList.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'ReEnroleBulk'
})(ReEnroleBulk);