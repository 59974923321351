import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import SignInForm from './signin-form';
import * as actions from '../../actions/auth';

class SignIn extends Component {

     constructor(props, context) {
        super(props, context);
      
        this.submitSignin = this.submitSignin.bind(this);
        this.tokenSignin = this.tokenSignin.bind(this);
    }

    submitSignin(values) {
        this.props.signinUser(values, this.props.history);
    }

     tokenSignin() {
        this.props.tokenSignin();
    }

 render() {
   
     //Check for signin token if found sign user index
     const token = localStorage.getItem('token');
     if (token) {
         this.props.tokenSignin(this.props.history);
         return <div>&nbsp;</div>;
     }

    return (
        <div>
            <div className="user-access-page">
                <div className="row">
                    <div className="col-sm-offset-3 col-sm-6 col-md-offset-4 col-md-4">
                        <div className="login-widget">
                            <div className="login-box">
                                <h3 className="blueBG">MSM Admin Sign In</h3>
                                    <SignInForm onSubmit={this.submitSignin} />

                                <div className="login-footer">
                                    <Link to="password">Forgot Password?</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <div className="copyrights">© Copyrights 2016 <a href="http://www.myschoolmusic.com.au/" target="_blank">My School Music</a> All Rights Reserved.
                </div>
            </footer>
        </div>
        );

 }
}

SignIn.propTypes = {
    tokenSignin: PropTypes.func.isRequired,
    signinUser: PropTypes.func.isRequired
};


export default connect(null, actions)(SignIn);


