import _ from 'lodash';
import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import SchoolDetails from './school-details-form';

class SchoolDetailPage extends Component {

    constructor(props, context) {
        super(props, context);

         this.state = {
            portalText: this.props.school.portalText
        };

        this.saveSchool = this.saveSchool.bind(this);
        this.setPortalText = this.setPortalText.bind(this);
    }

    componentDidMount() {
        this.props.getForms(this.props.userSetting.schoolId);
      }

    setPortalText(portalText) {
        this.setState({portalText});
        }

    saveSchool(values) {
      const schoolDetails = _.pick(values, ['name', 'email', 'telNo', 'website', 'portalURL', 'portalLink', 'hideAccounts']);
      schoolDetails.portalText = this.state.portalText;
        this.props.updateSchool(schoolDetails, this.props.school._id);
        }
   
    render() {

        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="panel panel-default">
                        <div className="panel-heading">School Details</div>
                        <div className="panel-body">
                            <SchoolDetails onSubmit={this.saveSchool} initialValues={this.props.school} onPortalText={this.setPortalText} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SchoolDetailPage.propTypes = {
  getForms: PropTypes.func.isRequired,
  userSetting: PropTypes.object.isRequired,
  school: PropTypes.object.isRequired,
  updateSchool: PropTypes.func.isRequired
};



function mapStateToProps(state) {

    return {
         userSetting: state.usersetting,
         school: state.school
    };
}

export default connect(mapStateToProps, actions)(SchoolDetailPage);