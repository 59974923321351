import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

    const renderActivityList = (logs, viewEmail) => {
        return logs.map((activity, index) => {
            return (
                <li key={index} className={activity.cssStyle + ' clearfix'} onClick={() => (activity.emailLogId) ? viewEmail(activity.emailLogId) : null}>
                    <div className="logHeading">
                        <div>{activity.description}</div>
                        <div className="emailLog"> 
                            {(activity.emailStats && activity.emailStats.Processing) ? <OverlayTrigger placement="top" overlay={<Tooltip id="processing">Processing</Tooltip>}><span className="label label-primary mr5">{activity.emailStats.Processing}</span></OverlayTrigger> : null}
                            {(activity.emailStats && activity.emailStats.Delivered) ? <OverlayTrigger placement="top" overlay={<Tooltip id="delivered">Delivered</Tooltip>}><span className="label label-success mr5">{activity.emailStats.Delivered}</span></OverlayTrigger> : null}
                            {(activity.emailStats && activity.emailStats.Delayed) ? <OverlayTrigger placement="top" overlay={<Tooltip id="delayed">Delayed</Tooltip>}><span className="label label-warning mr5">{activity.emailStats.Delayed}</span></OverlayTrigger> : null}
                            {(activity.emailStats && activity.emailStats.Failed) ? <OverlayTrigger placement="top" overlay={<Tooltip id="failed">Failed</Tooltip>}><span className="label label-danger mr5">{activity.emailStats.Failed}</span></OverlayTrigger> : null}
                            {(activity.emailLogId) && <i className="fa fa-chevron-right blueColor"></i>}
                        </div>
                    </div>
                    <div className="small blueColor logHeading">
                        <div>{activity.contact}</div>
                        <div>{moment(activity.logDate).format('llll')}</div>
                    </div>
                </li>
            );
        });
                 
    };

    const Activity = (props) => { 

        const { logs, viewEmail} = props;

        if (!logs || logs.length === 0) return null; 
       
        return (
        <div className="member-list">
            <ul className="list-unstyled">
                {renderActivityList(logs, viewEmail)}
            </ul>
        </div>
        );
    };

Activity.propTypes = {
    logs: PropTypes.array.isRequired,
    viewEmail: PropTypes.func.isRequired
};
  
export default Activity; 