import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data } from 'react-data-grid-addons';

const Selectors = Data.Selectors;

class FullLogsPage extends Component {

   constructor(props) {
        super(props);

        this.state = {
            rows: [],
            filters : {},
            sortColumn: null, 
            sortDirection: null
        };

        this.rowGetter = this.rowGetter.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.getRows = this.getRows.bind(this);
        this.getSize = this.getSize.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.onClearFilters = this.onClearFilters.bind(this);
        this.handleGridSort = this.handleGridSort.bind(this);
    }

    componentDidMount() {
         this.props.getFullLogs(this.props.userSetting.schoolId);
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.logs !== this.props.logs) {
          this.setState({rows: nextProps.logs.slice(0)});
        }
     }

     rowGetter(i) {
        const rows = this.getRows();
        return rows[i];
    }


    onRowClick(rowIdx, row) {
     if (row.emailLogId) this.props.getLogDetails(row.emailLogId, this.props.history);
    }

    getRows() {
         return Selectors.getRows(this.state);
     }

    getSize() {
        return this.getRows().length;
    }

    handleFilterChange(filter) {
        const newFilters = Object.assign({}, this.state.filters);
        if (filter.filterTerm) {
        newFilters[filter.column.key] = filter;
        } else {
        delete newFilters[filter.column.key];
        }
        this.setState({filters: newFilters});
    }

    onClearFilters() {
        this.setState({filters: {} });
    }

    handleGridSort(sortColumn, sortDirection) {
        const state = Object.assign({}, this.state, {sortColumn: sortColumn, sortDirection: sortDirection});
        this.setState(state);
    }

    rowRenderer(rowProps) {
        return (<div className={(rowProps.row.emailLogId) ? 'row-query' : ''}><ReactDataGrid.Row {...rowProps}/></div>);
    } 

    emailStatus({row}) {
        return (
            <div className="emailStatus">
                {row.description}
                <div>
                    {(row.emailStats && row.emailStats.Processing) ? <OverlayTrigger placement="top" overlay={<Tooltip id="processing">Processing</Tooltip>}><span className="label label-primary mr5">{row.emailStats.Processing}</span></OverlayTrigger> : null}
                    {(row.emailStats && row.emailStats.Delivered) ? <OverlayTrigger placement="top" overlay={<Tooltip id="delivered">Delivered</Tooltip>}><span className="label label-success mr5">{row.emailStats.Delivered}</span></OverlayTrigger> : null}
                    {(row.emailStats && row.emailStats.Delayed) ? <OverlayTrigger placement="top" overlay={<Tooltip id="delayed">Delayed</Tooltip>}><span className="label label-warning mr5">{row.emailStats.Delayed}</span></OverlayTrigger> : null}
                    {(row.emailStats && row.emailStats.Failed) ? <OverlayTrigger placement="top" overlay={<Tooltip id="failed">Failed</Tooltip>}><span className="label label-danger mr5">{row.emailStats.Failed}</span></OverlayTrigger> : null}
                </div>
            </div>
        );
    }

    render() {

         if (!this.props.logs) {
            return <div>loading...</div>;
        }


        const columns = [
            {
                key: 'logDate',
                name: 'Date',
                filterable : true,
                width: 240
            },
            {
                key: 'contact',
                name: 'Contact',
                sortable : true,
                filterable : true,
                width: 180
            },
             {
                key: 'description',
                name: 'Description',
                sortable : true,
                filterable : true,
                formatter: this.emailStatus
            }
            ];
 
        return(
            <div>
                <div className="pageheader">
                    <h2>Full Logs</h2>
                </div>
                <section id="main-content">
                    <div className="row">
                        <div className="col-md-12 col-lg-11">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    <div>
                                        <ReactDataGrid
                                            rowKey="_id"
                                            onGridSort={this.handleGridSort}
                                            columns={columns}
                                            rowGetter={this.rowGetter}
                                            rowsCount={this.getSize()}
                                            minHeight={this.getSize() * 35 + 85}
                                            onRowClick={this.onRowClick}
                                            toolbar={<Toolbar enableFilter={true}/>}
                                            onAddFilter={this.handleFilterChange}
                                            onClearFilters={this.onClearFilters}
                                            rowRenderer={this.rowRenderer} 
                                             />
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

FullLogsPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  logs: PropTypes.array.isRequired,
  getFullLogs: PropTypes.func.isRequired,
  getLogDetails: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        userSetting: state.usersetting,
        logs: state.logs.logItems
    };
}

export default connect(mapStateToProps, actions)(FullLogsPage);