import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../../actions/invoice';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import EmailForm from './email-form';
import EmailTemplates from './template-list';
import AttachDocument from './document-tabs';
import Preview from './preview';
import SaveTemplate from './save-template-modal';
import { ROOT_URL } from '../../actions/types';

const emailTemplate = `<br /><table border="0" style="background: #f9f9f9; border: 0; border-collapse: collapse; border-spacing: 0 !important; margin: 0 auto; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%" bgcolor="#f9f9f9">
<tr>
<td style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding: 10px 50px; vertical-align: top" valign="top">
  <p style="color: #72808E; font: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding: 0">
  <strong>Invoice Details</strong>
  </p>
  <hr />
  <p style="color: #72808E; font: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding: 0">
  Invoice For: [INVOICE_TO]
  </p>
  
  <p style="color: #72808E; font: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding: 0">
  Payment Due By: [DUE_DATE]
  </p>
  
  <p style="color: #72808E; font: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding: 0">
  Amount: $[AMOUNT]
  </p>
  <hr />
  <p style="color: #72808E; font: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding: 0">
  <a href="https://portal.msmusic.com.au/invoice/[INVOICE_ID]" style="-moz-border-radius: 4px; -webkit-border-radius: 4px; background: #3197D6; border-color: #3197d6; border-radius: 3px; border-style: solid; border-width: 8px 18px; color: #ffffff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; text-decoration: none">View Invoice</a>
  </p>

</td>
</tr>
</table>`;

class SendInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailBody: '',
      emailStatus: 'Send Email',
      modalHeading: '',
      showModal: false,
      showAttachment: false,
      showTemplate: false,
      email: { emailBody: '' },
    };

    this.sendInvoice = this.sendInvoice.bind(this);
    this.setEmailBody = this.setEmailBody.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.selectTemplate = this.selectTemplate.bind(this);
    this.openPreview = this.openPreview.bind(this);
    this.closePreview = this.closePreview.bind(this);
  }

  componentDidMount() {
    this.props.clearEmail();
    if (!this.props.contacts.length) this.props.getContacts(this.props.userSetting.schoolId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.email.emailStatus !== this.props.email.emailStatus) {
      this.setState({ emailStatus: nextProps.email.emailStatus });
    }
  }

  sendInvoice(values) {
    this.setState({ emailStatus: 'Sending...' });
    const email = {
      emailFrom: values.fromEmail,
      emailSubject: values.subject,
      emailBody: values.emailBody,
      attachments: this.props.email.attachments,
      emailList: this.props.invoices.selectedInvoices,
      bccList: values.bccList,
    };

    this.props.sendInvoice(email, this.props.userSetting.schoolId);
  }

  setEmailBody(emailBody) {
    this.setState({ emailBody });
  }

  openModal(modalHeading) {
    this.setState({ showModal: true, modalHeading });
  }

  closeModal() {
    this.setState({ showModal: false, modalHeading: '' });
  }

  openPreview() {
    this.setState({ showPreview: true });
  }

  closePreview() {
    this.setState({ showPreview: false });
  }

  uploadFile(files, rejected) {
    if (rejected.length > 0) {
      this.props.displayWarning('Maxium size per file is: 10Mb');
    } else {
      const config = {
        headers: { authorization: localStorage.getItem('token'), 'content-type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const uploaded = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          this.setState({ uploaded });
          if (uploaded === 100) {
            setTimeout(() => this.closeModal(), 2000);
          }
        },
      };

      const data = new FormData();
      files.forEach((file) => {
        data.append('file', file);
        data.append('name', this.props.userSetting.schoolId);
        data.append('label', 'Attachments');
      });
      axios.post(`${ROOT_URL}/upload-file/${this.props.userSetting.schoolId}`, data, config).then((response) => {
        this.props.addAttachment(response);
      });
    }
  }

  deleteAttachment(index) {
    this.props.removeAttachment(index);
  }

  selectTemplate(template) {
    this.setState({ email: template, emailBody: template.emailBody });
  }

  render() {
    return (
      <div>
        <div className='pageheader'>
          <Link to='accounts'>Invoice List</Link> / Send Invoices
        </div>
        <section id='main-content'>
          <div className='row'>
            <div className='col-md-12 col-lg-12'>
              <div className='panel panel-success'>
                <div className='panel-heading'>Email Details</div>
                <div className='panel-body'>
                  <EmailForm
                    onSubmit={this.sendInvoice}
                    fromEmails={this.props.fromEmails}
                    onEmailBody={this.setEmailBody}
                    initialValues={this.state.email}
                    attachments={this.props.email.attachments}
                    openModal={this.openModal}
                    closeModal={this.closeModal}
                    onRemoveAttachment={this.deleteAttachment}
                    onTemplate={this.openTemplate}
                    emailStatus={this.state.emailStatus}
                    emailNumber={this.props.invoices.selectedInvoices.length}
                    openPreview={this.openPreview}
                    contactList={this.props.contacts}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header className='modal-header primary' closeButton>
            <Modal.Title>{this.state.modalHeading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.modalHeading === 'Attachments' && (
              <AttachDocument
                uploadFile={this.uploadFile}
                closeModal={this.closeModal}
                uploadProgress={this.state.uploaded}
              />
            )}
            {this.state.modalHeading === 'Templates' && (
              <EmailTemplates onSelect={this.selectTemplate} closeModal={this.closeModal} />
            )}
            {this.state.modalHeading === 'Save as Template' && (
              <SaveTemplate
                onSubmit={this.saveTemplate}
                closeModal={this.closeModal}
                initialValues={{
                  emailBody: this.state.emailBody,
                  subject:
                    this.props.emailForm && this.props.emailForm.values.subject
                      ? this.props.emailForm.values.subject
                      : '',
                }}
              />
            )}
          </Modal.Body>
        </Modal>

        <Modal show={this.state.showPreview} bsSize='lg' onHide={this.closePreview}>
          <Modal.Header className='modal-header primary' closeButton>
            <Modal.Title>Email Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Preview
              sendPreview={this.sendPreview}
              studentId={this.props.invoices.invoiceList[this.props.invoices.selectedInvoices[0]].studentId}
              invoice={this.props.invoices.invoiceList[this.props.invoices.selectedInvoices[0]]}
              email={this.state.emailBody + emailTemplate}
              contactList={this.props.contacts}
              closeModal={this.closePreview}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

SendInvoice.propTypes = {
  userSetting: PropTypes.object.isRequired,
  fromEmails: PropTypes.array.isRequired,
  invoices: PropTypes.object.isRequired,
  email: PropTypes.object.isRequired,
  emailForm: PropTypes.object,
  contacts: PropTypes.array.isRequired,
  sendInvoice: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  removeAttachment: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
  getContacts: PropTypes.func.isRequired,
  clearEmail: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    userSetting: state.usersetting,
    fromEmails: state.usersetting.profile.fromEmails,
    email: state.email,
    invoices: state.invoices,
    contacts: state.contacts.all,
    emailForm: state.form.emailForm,
  };
}

export default connect(mapStateToProps, actions)(SendInvoice);
