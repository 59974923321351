import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

moment.locale('en-au');

const header = (invDetails, id) => {
  if (invDetails.hasHeaderImage) return (
    <tr>
        <td className="title">
            <img src={`https://portal.msmusic.com.au/invh${id}.png?lastmod=${invDetails.hasHeaderImage}`} alt="Invoice Footer" />
        </td>
        <td>
            Invoice #: 123<br />
            Created: {moment().format('ll')}<br />
            Due: {moment().add(14, 'days').format('ll')}
        </td>
    </tr>
  );

  return (
    <tr>
      <td className="title">
          <div dangerouslySetInnerHTML={{__html: invDetails.invoiceHeader}} />
      </td>
      <td>
          Invoice #: 123<br />
          Created: {moment().format('ll')}<br />
          Due: {moment().add(14, 'days').format('ll')}
      </td>
  </tr>
  );
};

const InvoicePreview = (props) => {
    const { invDetails, id } = props;

    return (
      <div className="invoice-box">
        <table cellPadding="0" cellSpacing="0">
          <tbody>

          <tr className="top">
                <td colSpan="2">
                    <table>
                      <tbody>
                        {header(invDetails, id)}
                      </tbody>
                    </table>
                </td>
            </tr>

          <tr className="information">
                <td colSpan="2">
                    <table>
                        <tr>
                            <td>{(invDetails.hasHeaderImage) && <div dangerouslySetInnerHTML={{__html: invDetails.invoiceHeader}} />}</td>
                            <td>
                                Mark Carter<br />
                                Student: Barbra Carter
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>

          <tr className="heading">
              <td>Item</td>
              <td>Price</td>
            </tr>
            
            <tr className="item">
                <td>Term Fee</td>
                <td>$200.00</td>
            </tr>
            
            <tr className="item">
              <td>Instrument Hire - Flute</td>
              <td>$100.00</td>
            </tr>

            <tr className="total">
                <td>&nbsp;</td>
                <td>Total: $300.00</td>
            </tr>
            <tr><td colSpan="2">&nbsp;</td></tr>
            <tr>
                <td colSpan="2">
                    <div dangerouslySetInnerHTML={{__html: invDetails.invoiceFooter}} />
                </td>
            </tr>
            <tr>
                <td colSpan="2">
                    {invDetails.hasFooterImage && <img src={`https://portal.msmusic.com.au/invf${id}.png?lastmod=${invDetails.hasFooterImage}`} alt="Invoice Footer" />}
                </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
};

InvoicePreview.propTypes = {
  id: PropTypes.string.isRequired,
  invDetails: PropTypes.object.isRequired,
};

export default InvoicePreview;