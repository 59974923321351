import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
    <div>
        <DropdownList {...input} {...rest} onBlur={() => input.change} />
        { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
);


const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type={type} placeholder={label}/>
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);


const NewStudentForm = (props) => {
    const { handleSubmit, submitting, selectors, closeStudent } = props;

    return (
        <div className="clearfix basic-form">
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-12 col-md-5">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-3 col-md-4">
                                    <label>First Name</label>
                                </div>
                                <div className="col-sm-8 col-md-8">
                                    <Field name="firstName" type="text" component={renderField} tabIndex="1" label="First Name"/>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-3  col-md-4">
                                    <label>Last Name</label>
                                </div>
                                <div className="col-sm-8 col-md-8">
                                    <Field name="lastName" type="text" component={renderField} tabIndex="2" label="Last Name"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-3 col-md-4">
                                    <label>Gender</label>
                                </div>
                                <div className="col-sm-8 col-md-8">
                                    <Field name="gender" type="text" tabIndex="3" simpleValue={true} valueKey="label" clearable={false} component={renderDropdownList} options={[{ label: 'Male'}, { label: 'Female'}]} label="Gender"/>
                                </div> 
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-3 col-md-4">
                                    <label>Grade / Class</label>
                                </div>
                                <div className="col-sm-4 col-md-4">
                                    <Field name="grade" type="text" tabIndex="4" simpleValue={true} valueKey="label" clearable={false} component={renderDropdownList} options={selectors.grade} textField="gradeName" />
                                </div>
                                <div className="col-sm-4 col-md-4">   
                                    <Field name="class" type="text" tabIndex="5" component={renderField} label="Class"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-3">
                                <label>Tags</label>
                            </div>
                            <div className="col-sm-6">
                                <Field name="studentTags" type="text" simpleValue={true} valueKey="tag" labelKey="tag" component={renderDropdownList} multi={true} options={selectors.studentTags} tabIndex="6" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="margin-top-one">
                    <div className="col-xs-6">
                        <button type="submit" disabled={submitting} className="btn btn-primary rounded">Save</button>
                     </div>
                     <div className="col-xs-6 text-right">   
                        <button type="button" className="btn btn-sm btn-default rounded" onClick={() => closeStudent()}>Close</button>
                    </div>
                </div>

            </form>
        </div>
    );
};

NewStudentForm.propTypes = {
    ...propTypes,
    selectors: PropTypes.object.isRequired
};

renderField.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

renderDropdownList.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'newStudentForm'
})(NewStudentForm);