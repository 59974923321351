import { NOTHING, GET_SCHOOL, SCHOOL_GROUP,  UPDATE_INSTRUMENT_LIST, UPDATE_STUDENT_STATUS_LIST,
        UPDATE_STUDENT_GRADE_LIST, UPDATE_CONTACT_CATEGORIES_LIST, UPDATE_VENUE_LIST,
        UPDATE_MEETING_TIMES_LIST, UPDATE_INVOICE, UPDATE_ALERT, UPDATE_SELECTORS } from '../actions/types';
import initialState from './initial-state';

export default function(state = initialState.school, action) {
    switch(action.type) {
      
        case GET_SCHOOL:
            return  action.school;
            
       // case UPDATE_GROUP:
         //   return [...state.filter(group => group._Id !== action.payload._Id),
           //     Object.assign({}, action.payload)
           // ];

        case SCHOOL_GROUP:

            return Object.assign({}, state, {
                groups: action.payload.groups,
                logs: action.payload.logs
            });

           // return Object.assign({}, state, {
           //     groups: [ ...state.groups, action.payload ]
           // });

          case UPDATE_INSTRUMENT_LIST:
          
             return { ...state, 
                 selectors: { ...state.selectors, instrumentList: action.payload }
             };

            case UPDATE_STUDENT_STATUS_LIST:
          
             return { ...state, 
                 selectors: { ...state.selectors, studentStatus: action.payload }
             };

             case UPDATE_STUDENT_GRADE_LIST:
          
             return { ...state, 
                 selectors: { ...state.selectors, grade: action.payload }
             };

             case UPDATE_CONTACT_CATEGORIES_LIST:
          
             return { ...state, 
                 selectors: { ...state.selectors, contactCategories: action.payload }
             };

             case UPDATE_VENUE_LIST:
          
             return { ...state, 
                 selectors: { ...state.selectors, venues: action.payload }
             };

            case UPDATE_MEETING_TIMES_LIST:
          
             return { ...state, 
                 selectors: { ...state.selectors, meetingTimes: action.payload }
             };

             case UPDATE_SELECTORS:
                return { ...state, selectors: action.payload };

            case UPDATE_INVOICE:
                return { ...state, invoices: action.payload };

            case UPDATE_ALERT:
                return { ...state, alerts: { ...state.alerts, newStudents: action.payload } };

            case NOTHING:
                return state;

        default:
            return state;
    }
}