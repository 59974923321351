import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/students';
import { Modal } from 'react-bootstrap';
import ReactDataGrid from 'react-data-grid';
import Student from './student-page';

class StudentSearchPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            showStudent: false
        };

        this.getSearchList = this.getSearchList.bind(this);
        this.rowGetter = this.rowGetter.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.closeStudent = this.closeStudent.bind(this);
    }

   
    componentDidMount() {
      this.props.getStudentsearch(this.props.userSetting.schoolId);
    }

    componentWillReceiveProps(nextProps) {
             this.getSearchList(nextProps);
    }

    componentWillUnmount() {
        this.setState({rows: [] });
    }

    
     getSearchList(nextProps) {
         const searchText = nextProps.students.searchText;
         
         if (searchText && searchText.length > 0) {
             this.setState({ rows: this.props.students.studentSearch.filter(student => 
                Object.keys(student).some(key => student[key].includes(searchText))) });
         } else {
            this.setState({rows: [] });
         }
    }

    rowGetter(i) {
    return this.state.rows[i];
  }

    onRowClick(rowIdx, row) {
        if (row) {
            this.setState({ showStudent: true, id: row._id })
        }
    }

    closeStudent() {
        this.setState({ showStudent: false });
    }

    render() {

         if (this.state.rows.length === 0) {
             return(<div>&nbsp;</div>);
        }    

        const columns = [
            {
                key: 'firstName',
                name: 'First Name',
                sortable : true,
                filterable : true,
                width: 150
            },
            {
                key: 'lastName',
                name: 'Last Name',
                sortable : true,
                filterable : true,
                width: 150
            },
             {
                key: 'grade',
                name: 'Grade',
                sortable : true,
                filterable : true,
                width: 70
            },
             {
                key: 'class',
                name: 'Class',
                sortable : true,
                filterable : true,
                width: 80
            },
             {
                key: 'groups',
                name: 'Groups',
                sortable : true,
                filterable : true,
            },
            {
                key: 'instrument',
                name: 'Instruments',
                sortable : true,
                filterable : true,
            }
            
            ];
        
        return (
            <div>
                <section id="main-content">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    <div>
                                        <ReactDataGrid
                                            rowKey="_id"
                                            columns={columns}
                                            rowGetter={this.rowGetter}
                                            rowsCount={this.state.rows.length}
                                            onRowClick={this.onRowClick}
                                            minHeight={500} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Modal show={this.state.showStudent} dialogClassName="fullSize" onHide={this.closeStudent}>
                    <Modal.Body>
                        <Student id={this.state.id} onClose={this.closeStudent} />
                    </Modal.Body>
                </Modal>
            </div>

        );
    }
}

StudentSearchPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  students: PropTypes.array.isRequired,
  getStudentsearch: PropTypes.func.isRequired
  
};

function mapStateToProps(state) {
  return { 
      userSetting: state.usersetting,
      students: state.students 
    };
}

export default connect(mapStateToProps, actions)(StudentSearchPage);
