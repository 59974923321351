import axios from 'axios';
import Notifications from 'react-notification-system-redux';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { GET_ROSTER, GET_PARENTS, ROSTER_EMAIL, SET_EMAIL_LIST, NO_ACTION, ROOT_URL } from './types';

export function getRoster(id) {
  return function(dispatch) {
    axios.get(`${ROOT_URL}/roster/${id}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        dispatch({ type: GET_ROSTER, payload: response.data });
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function saveRosterSetup(rosterSettings, groupId) {
   return function(dispatch) {
    axios.post(`${ROOT_URL}/roster-settings/${groupId}`, rosterSettings,
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then((response) => {
            dispatch(Notifications.success({ title: 'Roster Saved' }));
            dispatch({ type: GET_ROSTER, payload: response.data });
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function getParentList(id) {
  return function(dispatch) {
    axios.get(`${ROOT_URL}/roster-parents/${id}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        dispatch({
          type: GET_PARENTS,
          payload: response.data
        });
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function saveRosterEmail(rosterEmail, groupId) {
  return function(dispatch) {
   axios.put(`${ROOT_URL}/roster-settings/${groupId}`, rosterEmail,
   { headers: { authorization: localStorage.getItem('token') } 
   } )
   .then(() => {
           dispatch(Notifications.success({ title: 'Roster Email Saved' }));
           dispatch({ type: ROSTER_EMAIL, payload: rosterEmail });
     }).catch(error => {
       dispatch(Notifications.error({ title: error.response.data.error }));
       
     });
 };
}

export function createRoster(id, schoolId, term) {
  return function(dispatch) {
    axios.get(`${ROOT_URL}/roster/${id}/${schoolId}/${term}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        dispatch({ type: GET_ROSTER, payload: response.data });
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function deleteRoster(id, term) {
  return function(dispatch) {
    axios.delete(`${ROOT_URL}/roster/${id}/${term}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        dispatch({ type: GET_ROSTER, payload: response.data });
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function removeEvent(id, term, ids) {
  return function(dispatch) {
    if (ids.length === 0) {
      dispatch(Notifications.warning({ title: 'Select at least one roster item to delete' }));
    } else {
      axios.put(`${ROOT_URL}/roster-item/${id}/${term}`, ids,
      { headers: { authorization: localStorage.getItem('token') } 
      })
      .then(response => {
          dispatch({ type: GET_ROSTER, payload: response.data });
        }).catch(error => {
          dispatch(Notifications.error({ title: error.response.data.error }));
        });
    }
  };
}

export function addEvent(id, term, event) {
  return function(dispatch) {
    axios.post(`${ROOT_URL}/roster-item/${id}/${term}`, event,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        dispatch({ type: GET_ROSTER, payload: response.data });
        dispatch(Notifications.success({ title: 'Roster Event Added' }));
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function addRosterParent(rosteredParent) {
  return function(dispatch) {
    axios.post(`${ROOT_URL}/roster-add-parent/`, rosteredParent,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(() => {
       // dispatch({ type: GET_ROSTER, payload: response.data });
      // dispatch(Notifications.success({ title: 'OK' }));
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function autoFill(id, term) {
  return function(dispatch) {
    axios.get(`${ROOT_URL}/roster-fill/${id}/${term}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        dispatch({ type: GET_ROSTER, payload: response.data });
       // dispatch(Notifications.success({ title: 'Roster Event Added' }));
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function exportRoster(id, term, exportOption) {

      axios.get(`${ROOT_URL}/roster-export/${id}/${term}/${exportOption}`,
      { headers: { authorization: localStorage.getItem('token') } 
      })
      .then(response => {
          fileDownload(response.data, `Term_${term}_Roster_List-${moment().format('D-MMM-YYYY')}.csv`);
          });
  
    return {
      type: NO_ACTION,
      payload: ''
    };
  }

  export function emailList(emailList) {
      return { type: SET_EMAIL_LIST, payload: emailList };
  }








 