import axios from 'axios';
import Notifications from 'react-notification-system-redux';
import {reset} from 'redux-form';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { GET_INSTRUMENTS, GET_INSTRUMENT, INSTRUMENT_COMMENT_ADD, INSTRUMENT_COMMENT_DEL, 
      ADD_INSTRUMENT, NO_ACTION, GET_INSTRUMENTS_ARCHIVE, SET_EMAIL_LIST, UPDATE_SELECTORS,
      CURRENT_URL, UPDATE_INSTRUMENTS, ROOT_URL  } from './types';

export function reload(url, history) {
  history.push('/reload');
  return {
    type: CURRENT_URL,
    payload: url
  };

 }

export function getInstruments(schoolId, listType) {

  return function(dispatch) {
    axios.get(`${ROOT_URL}/instruments/${schoolId}/${listType}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        dispatch({
          type: listType ? GET_INSTRUMENTS : GET_INSTRUMENTS_ARCHIVE,
          payload: response.data
        });
      });
  };
}

export function getInstrument(instrument_id, openModal) {
  return function(dispatch) {
   axios.get(`${ROOT_URL}/instrument/${instrument_id}`,
   { headers: { authorization: localStorage.getItem('token') } 
   } )
   .then(response => {
           dispatch( {type: GET_INSTRUMENT, payload: response.data} );
           openModal();
     }).catch(error => {
       throw(error);
     });
 };
}

export function emailList(emailList, history) {
  if (!emailList.length) {
      return function(dispatch) {
        dispatch(Notifications.error({ title: 'Select at least one record' }));
      };
    } else {
      return function(dispatch) {
        axios.post(`${ROOT_URL}/hire-list/`, {emailList},
        { headers: { authorization: localStorage.getItem('token') } 
        } )
        .then(response => {
                dispatch( {type: SET_EMAIL_LIST, payload: response.data} );
                history.push('/send-email');
          }).catch(error => {
            throw(error);
          });
      };
  }
}

export function addInstrument(instrument, schoolId) {
   return function(dispatch) {
    axios.post(`${ROOT_URL}/instrument/${schoolId}`, instrument,
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
           dispatch( {type: ADD_INSTRUMENT, payload: response.data } );
           dispatch(Notifications.success({ title: 'Instrument Added' }));
     
      }).catch(error => {
        throw(error);
      });
  };
}

export function updateInstrument(instrumentDetails, instrument_id, closeModal) {

   return function(dispatch) {
    axios.put(`${ROOT_URL}/instrument/${instrument_id}`, instrumentDetails,
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
           dispatch( {type: UPDATE_INSTRUMENTS, payload: response.data } );
           dispatch(Notifications.success({ title: 'Instrument Details Saved' }));
           closeModal();
      }).catch(error => {
        throw(error);
      });
  };
}

export function instrumentHire(hireDetails, instrument_id, closeModal) {
   return function(dispatch) {
    axios.post(`${ROOT_URL}/instrument/hire/${instrument_id}`, {hireDetails},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
           dispatch( {type: UPDATE_INSTRUMENTS, payload: response.data } );
           dispatch(Notifications.success({ title: 'Instrument Hire Details Saved' }));
           closeModal();
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data }));
      });
  };
}

export function returnHire(returnDetails, instrument_id, closeModal) {
   return function(dispatch) {
    axios.put(`${ROOT_URL}/instrument/hire/${instrument_id}`, {returnDetails},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
           dispatch( {type: UPDATE_INSTRUMENTS, payload: response.data } );
           dispatch(Notifications.success({ title: 'Instrument Returned from Hire' }));
           closeModal();
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data }));
      });
  };
}


export function saveHireDetails(hireDetails, instrument_id) {
  return function(dispatch) {
   axios.patch(`${ROOT_URL}/instrument/hire/${instrument_id}`, hireDetails,
   { headers: { authorization: localStorage.getItem('token') } 
   } )
   .then(() => {
         // dispatch( {type: GET_INSTRUMENT, payload: response.data } );
          dispatch(Notifications.success({ title: 'Hire Details Updated' }));
    
     }).catch(error => {
       throw(error);
     });
 };
}

export function addInstrumentComment(comment, instrument_id) {
   return function(dispatch) {
    axios.post(`${ROOT_URL}/instrument/comment/${instrument_id}`, comment,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
         dispatch(reset('commentForm'));
         dispatch( {type: INSTRUMENT_COMMENT_ADD, payload: response.data} );
         dispatch(Notifications.success({ title: 'Comment added to instrument' }));
     
      }).catch(error => {
        throw(error);
      });
     
  };
}


export function removeInstrumentComment(id, index, instrumentId) {

   return function(dispatch) {
    axios.delete(`${ROOT_URL}/instrument/comment/${id}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(() => {
         dispatch( {type: INSTRUMENT_COMMENT_DEL, payload: index, id: instrumentId} );
         dispatch(Notifications.error({ title: 'Comment removed from instrument' }));
     
      }).catch(error => {
        throw(error);
      });
      
  };
}

export function exportInstruments(exportList, schoolId) {

    axios.post(`${ROOT_URL}/utils/instrument-export/${schoolId}`, {exportList},
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        fileDownload(response.data, `Instrument_List-${moment().format('D-MMM-YYYY')}.csv`);
        });

  return {
    type: NO_ACTION,
    payload: ''
  };
  }

  export function removeInstrument(id) {
       return function(dispatch) {
        axios.delete(`${ROOT_URL}/instrument/${id}`,
        { headers: { authorization: localStorage.getItem('token') } 
        })
        .then((response) => {
             dispatch({ type: GET_INSTRUMENTS_ARCHIVE, payload: response.data });
             dispatch(Notifications.error({ title: 'Instrument Removed' }));
          }).catch(error => {
            throw(error);
          });
      };
    }

 export function checkReserved(reservedFor, selectedStudent) {

   return function(dispatch) {
     if (selectedStudent && reservedFor && reservedFor !== selectedStudent) {
       dispatch(Notifications.error({ title: 'Instrument Reserved', message: `Instrument Reserved for ${reservedFor.label}` }));
     }
  };
}

export function saveServiceCentre(serviceCentre, schoolId) {
  return function(dispatch) {
   axios.post(`${ROOT_URL}/instrument/service-centre/${schoolId}`, serviceCentre,
   { headers: { authorization: localStorage.getItem('token') } 
   } )
   .then((response) => {
          dispatch( {type: UPDATE_SELECTORS, payload: response.data } );
          dispatch(Notifications.success({ title: 'Service Centre Details Updated' }));
    
     }).catch(error => {
       throw(error);
     });
 };
}

export function removeServiceCentre(id, schoolId) {
  return function(dispatch) {
   axios.delete(`${ROOT_URL}/instrument/service-centre/${schoolId}/${id}`,
   { headers: { authorization: localStorage.getItem('token') } 
   } )
   .then((response) => {
          dispatch( {type: UPDATE_SELECTORS, payload: response.data } );
          dispatch(Notifications.success({ title: 'Service Centre Removed' }));
    
     }).catch(error => {
       throw(error);
     });
 };
}

export function sendForSerivice(serviceDetails, instrument_id, closeModal) {
  return function(dispatch) {
   axios.post(`${ROOT_URL}/instrument/service/${instrument_id}`, serviceDetails,
   { headers: { authorization: localStorage.getItem('token') } 
   } )
   .then(response => {
          dispatch( {type: UPDATE_INSTRUMENTS, payload: response.data } );
          dispatch(Notifications.success({ title: 'Instrument Service Details Saved' }));
          closeModal();
     }).catch(error => {
       throw(error);
     });
 };
}

export function returnFromSerivice(serviceDetails, instrument_id, closeModal) {
  return function(dispatch) {
   axios.put(`${ROOT_URL}/instrument/service/${instrument_id}`, serviceDetails,
   { headers: { authorization: localStorage.getItem('token') } 
   } )
   .then(response => {
          dispatch( {type: UPDATE_INSTRUMENTS, payload: response.data } );
          dispatch(Notifications.success({ title: 'Instrument Returned from Service' }));
          closeModal();
     }).catch(error => {
       throw(error);
     });
 };
}

export function updateSerivceHistory(instrument, instrument_id) {
     return function(dispatch) {
      axios.post(`${ROOT_URL}/instrument-history/${instrument_id}`, instrument.serviceHistory,
      { headers: { authorization: localStorage.getItem('token') } 
      } )
      .then(() => {
             dispatch( {type: GET_INSTRUMENT, payload: instrument } );
             dispatch(Notifications.success({ title: 'Service History Updated' }));
        }).catch(error => {
          throw(error);
        });
    };
  }

  export function updateHireHistory(instrument, instrument_id) {
    return function(dispatch) {
     axios.put(`${ROOT_URL}/instrument-history/${instrument_id}`, instrument.hireHistory,
     { headers: { authorization: localStorage.getItem('token') } 
     } )
     .then(() => {
            dispatch( {type: GET_INSTRUMENT, payload: instrument } );
            dispatch(Notifications.success({ title: 'Hire History Updated' }));
       }).catch(error => {
         throw(error);
       });
   };
 }
