import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { Link } from 'react-router-dom';
import DropdownList from 'react-select';
import { Checkbox } from 'react-icheck';
import { DateInput } from '@blueprintjs/datetime';
import moment from 'moment';
import { required } from '../common/validation';

const renderDatePicker = ({ input, dateMin, dateMax, meta: { touched, error } }) => (
  <div>
    <DateInput
      value={input.value}
      onChange={input.onChange}
      format={'DD MMM YYYY'}
      minDate={moment().add(dateMin, 'years').toDate()}
      maxDate={moment().add(dateMax, 'years').toDate()}
    />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderDropdownList = ({ input, ...rest }) => <DropdownList {...input} {...rest} onBlur={() => input.change} />;

const renderField = ({ input, tabIndex, type, meta: { touched, error } }) => (
  <div>
    <input className='form-control' {...input} type={type} tabIndex={tabIndex} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderCheckbox = ({ input }) => (
  <Checkbox
    checkboxClass='icheckbox_square-blue'
    increaseArea='20%'
    name={input.name}
    onChange={input.onChange}
    defaultChecked={input.value ? true : false}
  />
);

const ContactForm = (props) => {
  const { handleSubmit, submitting, initialValues, selectors, deleteContact } = props;

  return (
    <div className='basic-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-7'>
              <div className='col-sm-4'>
                <label>First Name</label>
              </div>
              <div className='col-sm-8'>
                <Field name='firstName' tabIndex='1' type='text' component={renderField} validate={required} />
              </div>
            </div>
            <div className='col-xs-12 col-sm-5'>
              <div className='col-sm-5'>
                <label>Category</label>
              </div>
              <div className='col-sm-7'>
                <Field
                  name='category'
                  tabIndex='5'
                  type='text'
                  simpleValue={true}
                  valueKey='label'
                  component={renderDropdownList}
                  options={selectors.contactCategories}
                  label='Select Contact Category'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-7'>
              <div className='col-sm-4'>
                <label>Last Name</label>
              </div>
              <div className='col-sm-8'>
                <Field name='lastName' tabIndex='2' type='text' component={renderField} validate={required} />
              </div>
            </div>
            <div className='col-xs-12 col-sm-5'>
              <div className='col-sm-5'>
                <label>Role</label>
              </div>
              <div className='col-sm-7'>
                <Field name='role' tabIndex='6' type='text' component={renderField} />
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-7'>
              <div className='col-sm-4'>
                <label>Email</label>
              </div>
              <div className='col-sm-8'>
                <Field name='email' tabIndex='3' type='text' component={renderField} validate={required} />
              </div>
            </div>
            <div className='col-xs-12 col-sm-5'>
              <div className='col-sm-5'>
                <label>WWC Number</label>
              </div>
              <div className='col-sm-7'>
                <Field name='wwc.number' tabIndex='7' type='text' component={renderField} />
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-7'>
              <div className='col-sm-4'>
                <label>Mobile</label>
              </div>
              <div className='col-sm-8'>
                <Field name='mobile' tabIndex='4' type='text' component={renderField} />
              </div>
            </div>
            <div className='col-xs-12 col-sm-5'>
              <div className='col-sm-5'>
                <label>WWC Exp</label>
              </div>
              <div className='col-sm-7'>
                <Field name='wwc.expDate' type='text' component={renderDatePicker} dateMin='-7' dateMax='10' />
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-7'>
              <div className='col-sm-4'>
                <label>Training</label>
              </div>
              <div className='col-sm-8'>
                <div className='row'>
                  <div className='col-xs-1'>
                    <Field name='firstAid' component={renderCheckbox} />
                  </div>
                  <div className='col-xs-4 small'>First Aid</div>
                  <div className='col-xs-1'>
                    <Field name='anaphalyxis' component={renderCheckbox} />
                  </div>
                  <div className='col-xs-5 small'>Anaphalyxis</div>
                </div>
              </div>
            </div>
            <div className='col-xs-12 col-sm-5'>
              <div className='col-sm-5'>
                <label>Date of Birth</label>
              </div>
              <div className='col-sm-7'>
                <Field name='wwc.dob' type='text' component={renderDatePicker} dateMin='-85' dateMax='-15' />
              </div>
            </div>
          </div>
        </div>

        <hr />
        <div className='row'>
          <div className='col-sm-5'>
            <button type='submit' className='btn btn-primary rounded' disabled={submitting}>
              {initialValues ? 'Save' : 'Add'}
            </button>
            &nbsp;&nbsp;
            <Link to='contacts' className='btn btn-default rounded'>
              {initialValues ? 'Close' : 'Cancel'}
            </Link>
          </div>
          <div className='col-sm-5 text-right padding-top-half'>
            {initialValues && initialValues.lastLoginDate && (
              <span style={{ color: '#aaa' }}>
                Last logged in: {moment(initialValues.lastLoginDate).format('D MMM YY @h:mm a')}
              </span>
            )}
          </div>
          <div className='col-sm-2 text-right'>
            {initialValues && (
              <button type='button' className='btn btn-danger rounded' onClick={() => deleteContact()}>
                Delete
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

ContactForm.propTypes = {
  ...propTypes,
  selectors: PropTypes.object.isRequired,
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
};

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
};

renderField.propTypes = {
  type: PropTypes.string.isRequired,
  tabIndex: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

renderDatePicker.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  dateMin: PropTypes.number.isRequired,
  dateMax: PropTypes.number.isRequired,
};

export default reduxForm({
  form: 'contactForm',
})(ContactForm);
