import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/students';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import StudentModal from './re-enrole-student-modal';
import ExportForm from '../students/export-form';
import ReactDataGrid from 'react-data-grid';
import { Data, Filters } from 'react-data-grid-addons';
import EmptyToolbar from '../common/grid-toolbar';

const Selectors = Data.Selectors;
const { SingleSelectFilter } = Filters;

class EnroleStudentPage extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            rows: [],
            selectedStudents: [],
            filters : {},
            sortColumn: null, 
            sortDirection: null,
            showStudent: false,
            showModal: false,
            modalHeading: '',
            student: {},
            infoText: ''
        };
        
        this.onRowsSelected = this.onRowsSelected.bind(this);
        this.onRowsDeselected = this.onRowsDeselected.bind(this);
        this.rowGetter = this.rowGetter.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.getRows = this.getRows.bind(this);
        this.getSize = this.getSize.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.onClearFilters = this.onClearFilters.bind(this);
        this.handleGridSort = this.handleGridSort.bind(this);
        this.selectStudent = this.selectStudent.bind(this);
        this.closeStudent = this.closeStudent.bind(this);
        this.openStudent = this.openStudent.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.exportStudents = this.exportStudents.bind(this);
        this.clearStudents = this.clearStudents.bind(this);
    }

     componentDidMount() {
        this.props.getEnrolments(this.props.userSetting.schoolId);
    }

     componentWillReceiveProps(nextProps) {
        if (nextProps.students !== this.props.students) {
          this.setState({ rows: this.formatList(nextProps.students) });
        }
     }

    formatList(studentList) {
        if (studentList.length === 0) return [];
        return _.map(studentList, student => {
            return ({
                _id: student._id, 
                firstName: student.firstName,
                lastName: student.lastName,
                studentName: `${student.firstName} ${student.lastName}`,
                grade: student.grade,
                studentTags: student.studentTags,
                EnrolmentCompleted: (student.enrolment) ? moment(student.enrolment.enrolmentDate).format('lll') : '',
                enrolmentList: (student.enrolment) ? student.enrolment.groups.map(group => `${group.shortName} (${group.instrument})`).toString().replace(/,/g, ', ') : '',
                saved: (student.enrolment) ? student.enrolment.saved : false
            });
        });
    }

    selectStudent(id) {
       const student = this.props.students[id];
       this.openStudent(student);
    }

    openStudent(student) {
        this.setState({ showStudent: true, student });
    }

    closeStudent() {
        this.setState({ showStudent: false });
    }

    openModal(modal) {
        this.setState({ showModal: true, modalHeading: modal });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    exportStudents(exportOptions) {

        let exportList = [];
        if (this.state.selectedStudents.length === 0) { 
            exportList = this.state.rows.map(row => row._id);
        } else {
            exportList = this.state.selectedStudents.map(student => student._id);
        }
        
        this.props.exportStudents(exportList, exportOptions, this.props.userSetting.schoolId);
        this.closeModal();
    }

     sendEmail() {
        const studetList = this.state.selectedStudents; 
        this.props.emailList(studetList);
        if (studetList.length) this.props.history.push('/send-email');
    }

    clearStudents() {
        const studetList = this.state.selectedStudents; 

        if (studetList.length === 0) {
            alert('Select at least one student to clear from the enrolment list');
        } else if (window.confirm('Confirm you would like to remove the selected students from the enrolment list')) { 
            this.props.removeEnrolments(studetList.map(student => student._id), this.props.userSetting.schoolId); 
        } 
    }

    rowGetter(i) {
        const rows = this.getRows();
        return rows[i];
    }

     onRowsSelected(rows) {
        this.setState({selectedStudents: this.state.selectedStudents.concat(rows.map(r => r.row))});
    }

    onRowsDeselected(rows) {
        let ids = rows.map(r => r.row._id);
        this.setState({selectedStudents: this.state.selectedStudents.filter(student => !ids.some(id => id === student._id) )});
    }

    onRowClick(rowIdx, row) {
       if (row) {
           this.selectStudent(row._id);
        }
    }

    getRows() {
         return Selectors.getRows(this.state);
     }

    getSize() {
        return this.getRows().length;
    }

    handleFilterChange(filter) {
        const newFilters = Object.assign({}, this.state.filters);
        if (filter.filterTerm) {
        newFilters[filter.column.key] = filter;
        } else {
        delete newFilters[filter.column.key];
        }
        this.setState({filters: newFilters});
    }

    onClearFilters() {
        //all filters removed
        this.setState({filters: {} });
    }

     handleGridSort(sortColumn, sortDirection) {
        const state = Object.assign({}, this.state, {sortColumn: sortColumn, sortDirection: sortDirection});
        this.setState(state);
    }

    getValidFilterValues() {
        return [{ label: 'Yes', value: true }, { label: 'No', value: false }];
      }

    studentTags(tags) {
        if (tags.value.length === 0) return null;
        return (
            <div>
               {tags.value.map((tag, index) => {
                   return <span key={index} className="label label-info label-padding">{tag}</span>;
                   })}
            </div>
        );
    }

    saveEnrolment(value) {
        return (
        <div>
            <i className={(value.value) ? 'fa fa-check' : ''} aria-hidden="true"></i>
        </div>);
    }

    render () {


         const columns = [
            {
                key: 'studentName',
                name: 'Name',
                sortable : true,
                filterable : true,
                width: 180
            },
             {
                key: 'grade',
                name: 'Grade',
                sortable : true,
                filterable : true,
                width: 70
            },
             {
                key: 'enrolmentList',
                name: 'Enrolment For',
                sortable : true,
                filterable : true,
            },
            {
                key: 'EnrolmentCompleted',
                name: 'Completed',
                sortable : true,
                width: 100,
                filterable: true,
                formatter: this.returning,
                getRowMetaData: (row) => row
            },
            {
                key: 'saved',
                name: 'Saved',
                sortable : true,
                width: 70,
                filterable: true,
                filterRenderer: SingleSelectFilter,
                formatter: this.saveEnrolment,
                getRowMetaData: (row) => row
            },
            {
                key: 'studentTags',
                name: 'Tags',
                sortable : true,
                filterable : true,
                formatter: this.studentTags
            },
            {
               width: 18
           }
            ];

         return(
              <div>
                <div className="pageheader">
                    <h2>Student Enrolment</h2>
                </div>
                <section id="main-content">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-6">
                                            <div className="pt-button-group .modifier">
                                                <Link to="/students" className="pt-button pt-intent-primary pt-icon-new-person">All</Link>
                                                <Link to="/new-registrations" className="pt-button pt-intent-primary pt-icon-new-person">New</Link>
                                                <div className="pt-button pt-intent-primary pt-active pt-icon-following" role="button">Enrol</div>
                                                <Link to="/re-enrolments" className="pt-button pt-intent-primary pt-icon-new-person">Re-Enrol</Link>
                                                <Link to="/archive" className="pt-button pt-intent-primary pt-icon-blocked-person">Archive</Link>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6">
                                            <div className="text-right">
                                                <button type="button" className="btn btn-warning btn-trans btn-xs rounded" onClick={() => this.sendEmail()} >Email</button>&nbsp;
                                                <button type="button" className="btn btn-info btn-trans btn-xs rounded" onClick={() => this.clearStudents()} >Clear</button>&nbsp;
                                                <button type="button" className="btn btn-xs btn-default rounded" onClick={() => this.openModal('Export')} >Export</button>
                                            </div>
                                        </div>
                                    </div>
                                        <div className="col-xs-12">
                                            <div>
                                                <div>
                                                    <ReactDataGrid
                                                        rowKey="_id"
                                                        onGridSort={this.handleGridSort}
                                                        columns={columns}
                                                        rowGetter={this.rowGetter}
                                                        rowsCount={this.getSize()}
                                                        minHeight={this.getSize() * 35 + 85}
                                                        onRowClick={this.onRowClick}
                                                        toolbar={<EmptyToolbar />}
                                                        onAddFilter={this.handleFilterChange}
                                                        onClearFilters={this.onClearFilters}
                                                        getValidFilterValues={this.getValidFilterValues}
                                                        rowSelection={{
                                                            showCheckbox: true,
                                                            enableShiftSelect: true,
                                                            onRowsSelected: this.onRowsSelected,
                                                            onRowsDeselected: this.onRowsDeselected,
                                                            selectBy: {
                                                                keys: { rowKey: '_id', values: this.state.selectedStudents.map(row => row._id)}
                                                            }
                                                        }} />
                                                </div>
                                                <div className="admin-label text-right margin-top-quarter"><i className="blueBG"> { (this.getSize() === this.state.rows.length) ? `${this.state.rows.length} Students` : `Showing ${this.getSize()} of ${this.state.rows.length} Students`} </i></div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Modal show={this.state.showStudent} bsSize="lg" onHide={this.closeStudent}>
                    <Modal.Header bsClass="modal-header primary" closeButton>
                        <Modal.Title>{this.state.student.firstName} {this.state.student.lastName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-padding">
                           <StudentModal student={this.state.student} closeStudent={this.closeStudent} enrolment={true} />
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <Modal.Header bsClass="modal-header primary" closeButton>
                        <Modal.Title>{this.state.modalHeading}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-padding">
                            {(this.state.modalHeading === 'Export') && <ExportForm onSubmit={this.exportStudents} listType={'enrolment'} />}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

EnroleStudentPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  selectors: PropTypes.object.isRequired,
  students: PropTypes.object.isRequired,
  exportStudents: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
  getEnrolments: PropTypes.func.isRequired,
  emailList: PropTypes.func.isRequired,
  removeEnrolments: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        userSetting: state.usersetting,
        selectors: state.school.selectors,
        groups: state.school.groups,
        students: state.students.studentList
    };
}

export default connect(mapStateToProps, actions)(EnroleStudentPage);