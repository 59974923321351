import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/forms';
import { Modal } from 'react-bootstrap';
import FormsGrid from './forms-grid';
import FormDetails from './form-details-page';
import AddForm from './add-form';
import FormResponse from './form-response';

class FormsPage extends Component {

 constructor(props, context) {
    super(props, context);
    this.state = {
        showModal: false,
        modalHeading: '',
        component: 'forms',
        form: {}
        };

        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.addForm = this.addForm.bind(this);
        this.showForm = this.showForm.bind(this);
        this.showComponent = this.showComponent.bind(this);
        this.updateOrder = this.updateOrder.bind(this);
    }

    componentDidMount() {
       this.props.getCustomForms(this.props.userSetting.schoolId);
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.forms !== this.props.forms && this.state.form) {
        this.setState({form: nextProps.forms[this.state.form._id] });
      }
   }

    openModal(modalHeading) {
        this.setState({ showModal: true, modalHeading });
    }

    addForm(values) {
       this.props.saveCustomForm(values, this.props.userSetting.schoolId);
       this.setState({ showModal: false });
    }

    updateOrder(questions) {
        //this.props.saveCustomForm(values, this.props.userSetting.schoolId);
        const form = this.state.form;
        form.questions = questions;
      //  this.setState({ form });
     }

    showForm(form) {
        this.setState({ component: 'formResponse', form });
     }

    showComponent(component) {
        this.setState({ component });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    onRowClick(rowIdx, row) {
      if (row) {
         this.setState({ showLargeModal: true, selectedTGroup: row, modalHeading: row.groupName });
      }
    }

     render() {
       const { showModal, modalHeading, component } = this.state;
        return(
            <div>
                <div className="pageheader"><h2>Forms</h2></div>
                <section id="main-content">
                        <div className="row">
                            <div className="col-md-12 col-lg-11">
                                <div className="panel">
                                    <div className="panel-body">
                                        {component === 'forms' && <FormsGrid openModal={this.openModal} forms={this.props.forms} showForm={this.showForm} />}
                                        {component === 'formDetails' && <FormDetails form={this.state.form} onButtonClicked={this.showComponent} />}
                                        {component === 'formResponse' && <FormResponse form={this.state.form} onButtonClicked={this.showComponent} />}
                                    </div>
                                </div>
                            </div>
                       </div>
                    </section>

                    <Modal show={showModal} onHide={this.closeModal}>
                        <Modal.Header bsClass="modal-header primary" closeButton>
                            <Modal.Title>{modalHeading}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-padding">
                                {(modalHeading === 'Add Form') && <AddForm onSubmit={this.addForm} onClose={this.closeModal} forms={this.props.forms} />}
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
        );
    }
}

FormsPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  getCustomForms: PropTypes.func.isRequired,
  forms: PropTypes.object.isRequired,
  saveCustomForm: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        userSetting: state.usersetting,
        forms: state.forms.customForms
    };
}

export default connect(mapStateToProps, actions)(FormsPage);
