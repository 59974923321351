import React from 'react';
import PropTypes from 'prop-types';

const rendServiceCentre = (serviceCentres, editCS) => {
        return serviceCentres.map((serviceCentre, index) => {
            return (
                <li key={index} className="list-group-item">
                  
                  <div className="row">
                    <div className="col-xs-10"><h4>{serviceCentre.name}</h4></div>
                    <div className="col-xs-2 text-right"><a onClick={() => editCS(serviceCentre)}><i className="fa fa-pencil-square-o">&nbsp;Edit</i></a></div>
                  </div>
                  <div className="row">
                    <div className="col-xs-2">Contact</div>
                    <div className="col-xs-10">{serviceCentre.contact}</div>
                  </div>
                  <div className="row">
                    <div className="col-xs-2">Email</div>
                    <div className="col-xs-10">{serviceCentre.email}</div>
                  </div>
                  <div className="row">
                    <div className="col-xs-2">Tel</div>
                    <div className="col-xs-10">{serviceCentre.telNo}</div>
                  </div>
                  <div className="row">
                    <div className="col-xs-2">Address</div>
                    <div className="col-xs-10">{serviceCentre.address}</div>
                  </div>
                </li>
            );
        });
};

const ServiceCentreList = (props) => {

    const { serviceCentres, editCS } = props;

    return (
      <ul className="list-group">
          {rendServiceCentre(serviceCentres, editCS)}
      </ul>
    );
};


ServiceCentreList.propTypes = {
    serviceCentres: PropTypes.array.isRequired,
    editCS: PropTypes.func.isRequired
};

export default ServiceCentreList;