import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { DateInput } from '@blueprintjs/datetime';
import { required } from '../common/validation';
import moment from 'moment';

const renderField = ({ input, type, meta: { touched, error } }) => (
  <div>
    <input className='form-control' {...input} type={type} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderDatePicker = ({ input, meta: { touched, error } }) => {
  const isDob = input.name === 'wwc.dob';

  return (
    <div>
      <DateInput
        value={input.value}
        onChange={input.onChange}
        format={'DD MMM YYYY'}
        minDate={isDob ? moment().add(-65, 'years').toDate() : moment().toDate()}
        maxDate={isDob ? moment().add(-16, 'years').toDate() : moment().add(12, 'years').toDate()}
      />
      {touched && error && (
        <div className='admin-label text-right margin-top-quarter'>
          <i className='redBG'>{error}</i>
        </div>
      )}
    </div>
  );
};

const ProfileForm = (props) => {
  const { handleSubmit, submitting, openModal } = props;

  return (
    <div className='basic-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>First Name</label>
            </div>
            <div className='col-sm-6'>
              <Field name='firstName' type='text' component={renderField} validate={required} />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>Last Name</label>
            </div>
            <div className='col-sm-6'>
              <Field name='lastName' type='text' component={renderField} validate={required} />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>Email</label>
            </div>
            <div className='col-sm-8'>
              <Field name='email' type='text' component={renderField} validate={required} />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>Mobile</label>
            </div>
            <div className='col-sm-7'>
              <Field name='mobile' type='text' component={renderField} />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>WWC No.</label>
            </div>
            <div className='col-sm-7'>
              <Field name='wwc.number' type='text' component={renderField} />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>WWC Expiry</label>
            </div>
            <div className='col-sm-7'>
              <Field name='wwc.expDate' type='text' component={renderDatePicker} />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>Date of Birth</label>
            </div>
            <div className='col-sm-7'>
              <Field name='wwc.dob' type='text' component={renderDatePicker} />
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-between'>
          <button type='submit' className='btn btn-primary rounded' disabled={submitting}>
            Save
          </button>
          <button type='button' className='btn rounded' onClick={openModal}>
            Change Password
          </button>
        </div>
      </form>
    </div>
  );
};

ProfileForm.propTypes = {
  ...propTypes,
};

renderField.propTypes = {
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

renderDatePicker.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'profileForm',
})(ProfileForm);
