import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import moment from 'moment';
import { required, number } from '../common/validation';

const dow = [
  { label: 'Sun' },
  { label: 'Mon' },
  { label: 'Tue' },
  { label: 'Wed' },
  { label: 'Thu' },
  { label: 'Fri' },
  { label: 'Sat' },
];

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <input className='form-control' {...input} type={type} placeholder={label} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderTimeField = ({ input, meta: { touched, error } }) => (
  <div>
    <input
      className='form-control'
      {...input}
      type='text'
      onBlur={() =>
        input.value && input.onChange(moment(input.value, ['HH:mm', 'hh:mm a', 'h a', 'HH']).format('h:mm a'))
      }
    />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
  <div>
    <DropdownList {...input} {...rest} onBlur={() => input.change} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const AddPrivateForm = (props) => {
  const { handleSubmit, submitting, onClose, onDelete, selectors, initialValues, studentList } = props;

  return (
    <div className='basic-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>Student</label>
            </div>
            <div className='col-sm-7'>
              <Field
                name='groupName'
                type='text'
                component={renderDropdownList}
                options={studentList}
                clearable={false}
                onChange={(e) => {
                  props.change('instruments', e.instrument);
                  props.change(
                    'tutor',
                    selectors.tutors ? selectors.tutors.find((item) => item._id === e.tutor) || null : null
                  );
                }}
                validate={required}
              />
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>Tutor</label>
            </div>
            <div className='col-sm-7'>
              <Field
                name='tutor'
                type='text'
                valueKey='_id'
                labelKey='name'
                component={renderDropdownList}
                options={selectors.tutors}
                validate={required}
              />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>Instrument</label>
            </div>
            <div className='col-sm-7'>
              <Field
                name='instruments'
                type='text'
                simpleValue={true}
                valueKey='label'
                component={renderDropdownList}
                options={selectors.instrumentList}
              />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>Day</label>
            </div>
            <div className='col-sm-7'>
              <Field
                name='defaultDay'
                type='text'
                component={renderDropdownList}
                options={dow}
                valueKey='label'
                simpleValue={true}
                placeholder='Day of week for Rehearsal'
              />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>Start time</label>
            </div>
            <div className='col-sm-3'>
              <Field name='defaultTime' type='text' component={renderTimeField} />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>Duration</label>
            </div>
            <div className='col-sm-3'>
              <Field name='defaultDuration' type='text' component={renderField} label='In Minutes' validate={number} />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3'>
              <label>Venue</label>
            </div>
            <div className='col-sm-7'>
              <Field
                name='defaultVenue'
                type='text'
                simpleValue={true}
                valueKey='label'
                component={renderDropdownList}
                options={selectors.venues}
              />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-9'>
            <button type='submit' className='btn btn-primary rounded' disabled={submitting}>
              {initialValues ? 'Save' : 'Add Group'}
            </button>
            &nbsp;&nbsp;
            <button type='button' className='btn btn-default rounded' onClick={() => onClose('students')}>
              {initialValues ? '< Back' : 'Cancel'}
            </button>
          </div>
          <div className='col-sm-3 text-right'></div>
          {initialValues && (
            <button type='button' className='btn btn-danger rounded' onClick={() => onDelete()}>
              Delete Group
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

AddPrivateForm.propTypes = {
  ...propTypes,
};

renderField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

renderTimeField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'addPrivateForm',
})(AddPrivateForm);
