import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/contacts';
import ReactDataGrid from 'react-data-grid';
import { Data } from 'react-data-grid-addons';
import { Link } from "react-router-dom";
import EmptyToolbar from '../common/grid-toolbar';

const Selectors = Data.Selectors;

class Contacts extends Component {
    
     constructor(props) {
        super(props);

        this.state = {
            selectedContacts: [],
            rows: this.props.contacts.slice(0),
            filters : {},
            sortColumn: null, 
            sortDirection: null
        };

        this.rowGetter = this.rowGetter.bind(this);
        this.onRowsSelected = this.onRowsSelected.bind(this);
        this.onRowsDeselected = this.onRowsDeselected.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.getRows = this.getRows.bind(this);
        this.getSize = this.getSize.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.onClearFilters = this.onClearFilters.bind(this);
        this.handleGridSort = this.handleGridSort.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.exportContacts = this.exportContacts.bind(this);
    }

    componentDidMount() {
        if (!this.props.userSetting.schoolId) {
            this.props.reload(this.props.location.pathname, this.props.history);
        } else {
            this.props.getContacts(this.props.userSetting.schoolId, this.props.history);
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.contacts !== this.props.contacts) {
          this.setState({rows: nextProps.contacts.slice(0) });
        }
     }

     sendEmail() {
        const contactList = this.state.selectedContacts; //.map(index => this.state.rows[index]);
        this.props.emailList(contactList);
        if (contactList.length) this.props.history.push('/send-contact-email');
    }

    rowGetter(i) {
        const rows = this.getRows();
        return rows[i];
    }

    onRowsSelected(rows) {
        this.setState({selectedContacts: this.state.selectedContacts.concat(rows.map(r => r.row))});
    }

    onRowsDeselected(rows) {
        let id = rows.map(r => r.row.Id);
        this.setState({selectedContacts: this.state.selectedContacts.filter(contacts => contacts.Id != id )});
    }

    onRowClick(rowIdx, row) {
        if (row) this.props.getContact(row.Id, this.props.history);
   
    }

    getRows() {
         return Selectors.getRows(this.state);
     }

    getSize() {
        return this.getRows().length;
    }

    handleFilterChange(filter) {
        const newFilters = Object.assign({}, this.state.filters);
        if (filter.filterTerm) {
        newFilters[filter.column.key] = filter;
        } else {
        delete newFilters[filter.column.key];
        }
        this.setState({filters: newFilters});
    }

    onClearFilters() {
        //all filters removed
        this.setState({filters: {} });
    }

     handleGridSort(sortColumn, sortDirection) {
        const state = Object.assign({}, this.state, {sortColumn: sortColumn, sortDirection: sortDirection});
        this.setState(state);
    }

    exportContacts() {
        let exportList = [];
        if (this.state.selectedContacts.length === 0) { 
            exportList = this.state.rows.map(row => row.Id);
        } else {
            exportList = this.state.selectedContacts.map(contact => contact.Id);
        }
        this.props.exportContacts(exportList, this.props.userSetting.schoolId);
    }

    loginActive(value) {
        return (
        <div>
            <i className={(value.value) ? 'fa fa-check' : ''} aria-hidden="true"></i>
        </div>);
    }


    render() {

        const columns = [
            {
                key: 'firstName',
                name: 'First Name',
                sortable : true,
                filterable : true,
                width: 130
            },
            {
                key: 'lastName',
                name: 'Last Name',
                sortable : true,
                filterable : true,
                width: 140
            },
            {
                key: 'email',
                name: 'Email',
                sortable : true,
                filterable : true,
            },
            {
                key: 'loginActive',
                name: 'Active',
                sortable : true,
                width: 65,
                formatter: this.loginActive,
                getRowMetaData: (row) => row
            },
            {
                key: 'category',
                name: 'Category',
                sortable : true,
                filterable : true,
            },
            {
                key: 'role',
                name: 'Role',
                sortable : true,
                filterable : true,
            }
            ];

        
        if (!this.props.contacts) {
            return <div>loading...</div>;
        }
        return (
            <div>
                <div className="pageheader">
                    <h2>Contacts</h2>
                </div>
                <section id="main-content">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    <div className="text-right">
                                        <button type="button" className="btn btn-warning btn-trans btn-xs rounded" onClick={() => this.sendEmail()}>Send Email</button>&nbsp;&nbsp;
                                        <Link to="/add-contact" className="btn btn-primary btn-trans btn-xs rounded">Add Contact</Link>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-xs btn-default rounded" onClick={() => this.exportContacts()} >Export</button>
                                    </div>
                                    <div>
                                    <ReactDataGrid
                                            rowKey="Id"
                                            onGridSort={this.handleGridSort}
                                            columns={columns}
                                            rowGetter={this.rowGetter}
                                            rowsCount={this.getSize()}
                                            minHeight={this.getSize() * 35 + 85}
                                            onRowClick={this.onRowClick}
                                            toolbar={<EmptyToolbar />}
                                            onAddFilter={this.handleFilterChange}
                                            onClearFilters={this.onClearFilters}
                                            rowSelection={{
                                                showCheckbox: true,
                                                enableShiftSelect: true,
                                                onRowsSelected: this.onRowsSelected,
                                                onRowsDeselected: this.onRowsDeselected,
                                                 selectBy: {
                                                    keys: { rowKey: 'Id', values: this.state.selectedContacts.map(row => row.Id)}
                                                }
                                            }} />
                                        </div>
                                        <div className="admin-label text-right margin-top-quarter"><i className="blueBG"> { (this.getSize() === this.state.rows.length) ? `${this.state.rows.length} Contacts` : `Showing ${this.getSize()} of ${this.state.rows.length} Contacts`} </i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

Contacts.propTypes = {
  userSetting: PropTypes.object.isRequired,
  contacts: PropTypes.array.isRequired,
  getContact: PropTypes.func.isRequired,
  getContacts: PropTypes.func.isRequired,
  emailList: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
  exportContacts: PropTypes.func.isRequired
};

function mapStateToProps(state) {

  return { 
      userSetting: state.usersetting,
      contacts: state.contacts.all 
    };
}

export default connect(mapStateToProps, actions)(Contacts);
