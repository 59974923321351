import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

const renderField = ({ input }) => (
  <div>
      <input className="form-control" {...input} type="text" placeholder="Instrument return comment" />
  </div>
);

const renderCurrencyField = ({ input }) => (
    <div className="input-group">
        <span className="input-group-addon">$</span>
        <input className="form-control" type="text" {...input} />
    </div>
  );

const renderDatePicker = ({ input: { onChange, value }}) =>
    <DatePicker 
        className="form-control"
        selected={value ? moment(value) : moment()}
        dateFormat="D MMM YYYY"
        onChange={onChange}
    />;

const ReturnInstrumentForm = (props) => {
    const { handleSubmit, onClose } = props;

    return (
        <div className="basic-form">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Return Date</label>
                        </div>
                        <div className="col-sm-5">
                            <Field name="returnDate" type="text" component={renderDatePicker} />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Comment</label>
                        </div>
                        <div className="col-sm-8">
                            <Field name="comment" type="text" component={renderField} />
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <button type="submit" className="btn btn-primary rounded">Return Instrument</button>&nbsp;&nbsp;
                        <button type="button" className="btn btn-default rounded" onClick={() => onClose()}>Cancel</button>
                    </div>       
                </div>
            </form>
        </div>
    );
};

ReturnInstrumentForm.propTypes = {
    ...propTypes,

};

renderField.propTypes = {
    input: PropTypes.object.isRequired
};

renderCurrencyField.propTypes = {
    input: PropTypes.object.isRequired
  };

renderDatePicker.propTypes = {
    input: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'ReturnInstrumentForm'
})(ReturnInstrumentForm);