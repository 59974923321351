import React, { Component } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";

class DateRange extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      invoiceFrom: moment(this.props.dateFrom),
      invoiceTo:  moment(this.props.dateTo)
    };

    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
  }

  handleChangeStart(date) {
      this.props.updateRange({ invoiceFrom: date, invoiceTo: this.state.invoiceTo });
      this.setState({ invoiceFrom: date });
  }

  handleChangeEnd(date) {
    this.props.updateRange({ invoiceFrom: this.state.invoiceFrom, invoiceTo: date });
    this.setState({ invoiceTo: date });
  }

  render() {
    return (
        <div>
          <div className="navigation-style clearfix">
            <ul className="list-unstyled">
              <li>
                <DatePicker
                  dateFormat="D MMM YY"
                  selected={this.state.invoiceFrom}
                  invoiceFrom
                  startDate={this.state.invoiceFrom}
                  endDate={this.state.invoiceTo}
                  onChange={this.handleChangeStart}
                  className="date-input form-control"
                  placeholderText="Date From"
                />
              </li>
              <li>
                <DatePicker
                  dateFormat="D MMM YY"
                  className="form-control date-input"
                  selected={this.state.invoiceTo}
                  invoiceTo
                  startDate={this.state.invoiceFrom}
                  endDate={this.state.invoiceTo}
                  onChange={this.handleChangeEnd}
                  placeholderText="Date To"
                />
              </li>
            </ul>
            
          </div>
        </div>
    );
  }
}

DateRange.propTypes = {
  updateRange: PropTypes.func.isRequired,
  dateFrom: PropTypes.object.isRequired,
  dateTo: PropTypes.object.isRequired
};

export default DateRange;
