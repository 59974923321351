import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import usersetting from './reducer-user';
import school from './reducer-school';
import students from './reducer-students';
import contacts from './reducer-contacts';
import selectLists from './reducer-select-lists';
import logs from './reducer-logs';
import forms from './reducer-forms';
import events from './reducer-events';
import instruments from './reducer-instruments';
import email from './reducer-email';
import library from './reducer-library';
import invoices from './reducer-invoices';
import roster from './reducer-roster';
import tuition from './reducer-tuition';
import { reducer as notifications} from 'react-notification-system-redux';

const appReducer = combineReducers({
  form,
  usersetting,
  school,
  students,
  contacts,
  selectLists,
  notifications,
  logs,
  forms,
  events,
  instruments,
  email,
  library,
  invoices,
  roster,
  tuition
 });

 const rootReducer = (state, action) => {
   if (action.type === 'USER_LOGOUT') {
     state = undefined;
   }

   return appReducer(state, action);
 };

export default rootReducer;