import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/students';
import ReactDataGrid from 'react-data-grid';
import { Modal } from 'react-bootstrap';
import { Data } from 'react-data-grid-addons';
import moment from 'moment';
import { Link } from "react-router-dom";
import ExportForm from './export-form';
import EmptyToolbar from '../common/grid-toolbar';
import Student from './student-page';

const Selectors = Data.Selectors;

class ArchiveListPage extends Component {
    
     constructor(props) {
        super(props);

        this.state = {
            rows: _.map(this.props.students, student => student),
            selectedStudents: [],
            filters : {},
            sortColumn: null, 
            sortDirection: null,
            showExport: false,
            showStudent: false
        };

        this.rowGetter = this.rowGetter.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.onRowsSelected = this.onRowsSelected.bind(this);
        this.onRowsDeselected = this.onRowsDeselected.bind(this);
        this.getRows = this.getRows.bind(this);
        this.getSize = this.getSize.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.onClearFilters = this.onClearFilters.bind(this);
        this.handleGridSort = this.handleGridSort.bind(this);
        this.closeExport = this.closeExport.bind(this);
        this.closeStudent = this.closeStudent.bind(this);
        this.openExport = this.openExport.bind(this);
        this.exportStudents = this.exportStudents.bind(this);
        this.deleteStudents = this.deleteStudents.bind(this);
    }


    componentDidMount() {
        if (!this.props.userSetting.schoolId) {
            this.props.reload(this.props.location.pathname, this.props.history);
        } else {
            this.props.getStudents(this.props.userSetting.schoolId, false);
        }
    }


     componentWillReceiveProps(nextProps) {
        
        if (nextProps.students !== this.props.students) {
          this.setState({rows: _.map(nextProps.students, student => student) });
        }
     }

     formatDate(value) {
        return (<div>{moment(value.value).format('ll')}</div>);
    }

    rowGetter(i) {
        const rows = this.getRows();
        return rows[i];
    }

    onRowClick(rowIdx, row) {
        if (row) {
            this.setState({ showStudent: true, id: row._id })
        }
    }

    onRowsSelected(rows) {
        this.setState({selectedStudents: this.state.selectedStudents.concat(rows.map(r => r.row._id))});
    }

    onRowsDeselected(rows) {
        let ids = rows.map(r => r.row._id);
        this.setState({selectedStudents: this.state.selectedStudents.filter(student => !ids.some(id => id === student) )});
    }

    getRows() {
         return Selectors.getRows(this.state);
     }

    getSize() {
        return this.getRows().length;
    }

    handleFilterChange(filter) {
        const newFilters = Object.assign({}, this.state.filters);
        if (filter.filterTerm) {
        newFilters[filter.column.key] = filter;
        } else {
        delete newFilters[filter.column.key];
        }
        this.setState({filters: newFilters});
    }

    onClearFilters() {
        //all filters removed
        this.setState({filters: {} });
    }

     handleGridSort(sortColumn, sortDirection) {
        const state = Object.assign({}, this.state, {sortColumn: sortColumn, sortDirection: sortDirection});
        this.setState(state);
    }

    openExport() {
        this.setState({ showExport: true });
    }

    closeExport() {
        this.setState({ showExport: false });
    }

    closeStudent() {
        this.setState({ showStudent: false });
    }

    exportStudents(exportOptions) {
        const exportList = this.state.rows.map(row => row._id);
        this.props.exportStudents(exportList, exportOptions, this.props.userSetting.schoolId);
        this.closeExport();
    }

    deleteStudents() {
        if (window.confirm(`Are you sure you want to delete the selected students`)) {
          this.props.deleteStudents(this.state.selectedStudents);
        }
    }
        
       
    render() {

        if (!this.props.students) {
            return <div>loading...</div>;
        }

        const columns = [
            {
                key: 'firstName',
                name: 'First Name',
                sortable : true,
                filterable : true,
                width: 150
            },
            {
                key: 'lastName',
                name: 'Last Name',
                sortable : true,
                filterable : true,
                width: 150
            },
             {
                key: 'grade',
                name: 'Grade',
                sortable : true,
                filterable : true,
                width: 70
            },
             {
                key: 'class',
                name: 'Class',
                sortable : true,
                filterable : true,
                width: 80
            },
             {
                key: 'groupSummary',
                name: 'Groups',
                sortable : true,
                filterable : true,
            },
            {
                key: 'instruments',
                name: 'Instruments',
                sortable : true,
                filterable : true,
            },
            {
                key: 'lastUpdated',
                name: 'Updated',
                sortable : true,
                filterable : true,
                formatter: this.formatDate,
                width: 110
            }
            ];

        
       
        return (
            <div>
                <div className="pageheader">
                    <h2>Student Archive</h2>
                </div>
                <section id="main-content">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="panel panel-warning">
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="pt-button-group .modifier">
                                                <Link to="/students" className="pt-button pt-intent-primary pt-icon-people">all</Link>
                                                <Link to="/new-registrations" className="pt-button pt-intent-primary pt-icon-new-person">New</Link>
                                                <Link to="/enrolments" className="pt-button pt-intent-primary pt-icon-following">Enrol</Link>
                                                <Link to="/re-enrolments" className="pt-button pt-intent-primary pt-icon-following">Re-Enrol</Link>
                                                <div className="pt-button pt-intent-primary pt-active pt-icon-blocked-person" role="button">Archive</div>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="text-right">
                                                {this.state.selectedStudents.length ? <button type="button" className="btn btn-danger btn-trans btn-xs rounded" onClick={() => this.deleteStudents()} >Delete Students</button> : null}&nbsp;&nbsp;
                                                <button type="button" className="btn btn-xs btn-default rounded" onClick={() => this.openExport()} >Export</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    <ReactDataGrid
                                            rowKey="_id"
                                            onGridSort={this.handleGridSort}
                                            columns={columns}
                                            rowGetter={this.rowGetter}
                                            rowsCount={this.getSize()}
                                            minHeight={this.getSize() * 35 + 85}
                                            onRowClick={this.onRowClick}
                                            toolbar={<EmptyToolbar />}
                                            onAddFilter={this.handleFilterChange}
                                            onClearFilters={this.onClearFilters}
                                            rowSelection={{
                                                showCheckbox: true,
                                                enableShiftSelect: true,
                                                onRowsSelected: this.onRowsSelected,
                                                onRowsDeselected: this.onRowsDeselected,
                                                selectBy: {
                                                    keys: { rowKey: '_id', values: this.state.selectedStudents}
                                                }
                                            }} />
                                        </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                 <Modal show={this.state.showExport} onHide={this.closeExport}>
                    <Modal.Header bsClass="modal-header primary" closeButton>
                        <Modal.Title>Student Export</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-padding">
                            <ExportForm onSubmit={this.exportStudents} />
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showStudent} dialogClassName="fullSize" onHide={this.closeStudent}>
                    <Modal.Body>
                        <Student id={this.state.id} onClose={this.closeStudent} />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

ArchiveListPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  students: PropTypes.object.isRequired,
  getStudent: PropTypes.func.isRequired,
  getStudents: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
  exportStudents: PropTypes.func.isRequired
};

function mapStateToProps(state) {

  return { 
      userSetting: state.usersetting,
      students: state.students.studentArchive 
    };
}

export default connect(mapStateToProps, actions)(ArchiveListPage);
