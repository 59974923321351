import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { DateInput } from "@blueprintjs/datetime";
import { required } from '../common/validation';
import moment from 'moment';

const renderField = ({ input, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type="text" />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderDatePicker = ({ input, meta: { touched, error } }) => (
  <div>
      <DateInput value={input.value} onChange={input.onChange} format={'DD MMM YYYY'} minDate={moment().startOf('year').toDate()} maxDate={moment().endOf('year').toDate()} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const AddRosterItemForm = (props) => {
    const { handleSubmit, submitting } = props;

    return (
        <div className="basic-form">
            <form onSubmit={handleSubmit}>
            <div className="form-group">
                    <div className="row">
                    <div className="col-sm-3">
                        <label>Roster Label</label>
                    </div>
                    <div className="col-sm-5">
                        <Field name="rosterLabel" type="text" component={renderField} validate={required} />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <div className="row">
                    <div className="col-sm-3">
                        <label>Date</label>
                    </div>
                    <div className="col-sm-7">
                      <Field name="rosterDate" type="text"component={renderDatePicker} validate={required} />
                    </div>
                </div>
                </div>
        
            <button type="submit" className="btn btn-primary rounded" disabled={submitting}>Add Event</button>
        </form>
    </div>
    );
};

AddRosterItemForm.propTypes = {
    ...propTypes
};

renderField.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

renderDatePicker.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'AddRosterItemForm'
})(AddRosterItemForm);