import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

const UploadImport = (props) => {

    const { onDrop } = props;

    return (
        <div className="basic-form">
            <form>
                <div className="form-group">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <div className="col-sm-4">
                                <label>Select File</label>
                            </div>
                            <div className="col-sm-8">
                                <Dropzone onDrop={onDrop} className="dropzone" maxSize={1048576} >
                                    <div>Drop or Click to select import file.</div>
                                    </Dropzone>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

UploadImport.propTypes = {
  onDrop: PropTypes.func.isRequired
};

export default UploadImport;