import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import { Data } from 'react-data-grid-addons';


const Selectors = Data.Selectors;

class FormsGrid extends Component {

 constructor(props, context) {
    super(props, context);
      this.state = {
          rows: _.map(this.props.forms, form => form)
          };

      this.rowGetter = this.rowGetter.bind(this);
      this.getRows = this.getRows.bind(this);
      this.getSize = this.getSize.bind(this);
      this.onRowClick = this.onRowClick.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.forms !== this.state.rows) {
          this.setState({ rows: _.map(nextProps.forms, form => form )});
        }
     }

    getRows() {
      return Selectors.getRows(this.state);
    }

    rowGetter(i) {
      const rows = this.getRows();
      return rows[i];
    }

    getSize() {
        return this.getRows().length;
    }

    onRowClick(rowIdx, row) {
      if (row) this.props.showForm(row);
  }

     render() {
        const columns = [
            {
                key: 'formTitle',
                name: 'Form',
            },
             {
                key: 'noStudents',
                name: 'Students',
                width: 100,
            },
            {
                key: 'completed',
                name: 'Completed',
                minColumnWidth: 10,
                width: 100,
            }];

        return(
            <div>
              <div className="col-md-12 text-right">
                  <button type="button" className="btn btn-warning btn-trans btn-xs rounded" onClick={() => this.props.openModal('Add Form')} >Add Form</button>&nbsp;
              </div>
              <div className="col-md-12">
                  <div>
                      <div>
                          <ReactDataGrid
                              rowKey="_id"
                              columns={columns}
                              rowGetter={this.rowGetter}
                              rowsCount={this.getSize()}
                              minHeight={this.getSize() * 35 + 85}
                              onRowClick={this.onRowClick}
                              />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

FormsGrid.propTypes = {
  forms: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  showForm: PropTypes.func.isRequired
};

export default FormsGrid;
