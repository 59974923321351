import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { DateInput } from '@blueprintjs/datetime';
import DropdownList from 'react-select';
import moment from 'moment';
import { required } from '../common/validation';

const paymentType = [
  { label: 'Cheque' },
  { label: 'Credit Card' },
  { label: 'Cash' },
  { label: 'EFT' },
  { label: 'Direct Credit' },
  { label: 'Flexischools' },
  { label: 'Voucher' },
  { label: 'Write-off' },
];

const renderDatePicker = ({ input, meta: { touched, error } }) => (
  <div>
    <DateInput
      value={input.value}
      onChange={input.onChange}
      format={'DD MMM YYYY'}
      minDate={moment().add(-1, 'years').toDate()}
      maxDate={moment().add(6, 'months').toDate()}
    />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
  <div>
    <DropdownList {...input} {...rest} onBlur={() => input.change} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderField = ({ input, type, meta: { touched, error } }) => (
  <div className='input-group'>
    <span className='input-group-addon'>$</span>
    <input className='form-control' {...input} type={type} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const PaymentForm = (props) => {
  const { handleSubmit, submitting, closeModal, viewInvoice } = props;

  return (
    <div className='basic-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-5'>
              <label>Payment Date</label>
            </div>
            <div className='col-sm-5'>
              <Field name='paymentDate' type='text' component={renderDatePicker} />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-5'>
              <label>Payment Method</label>
            </div>
            <div className='col-sm-4'>
              <Field
                name='paymentType'
                type='text'
                simpleValue={true}
                valueKey='label'
                tabIndex='3'
                clearable={false}
                component={renderDropdownList}
                options={paymentType}
              />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-5'>
              <label>Amount Paid</label>
            </div>
            <div className='col-sm-3'>
              <Field name='amount' component={renderField} validate={required} />
            </div>
          </div>
        </div>
        <hr />
        <div className='row'>
          <div className='col-sm-8'>
            <button type='submit' disabled={submitting} className='btn btn-sm btn-primary rounded'>
              Receive Payment
            </button>
            &nbsp;&nbsp;
            <button type='button' className='btn btn-default btn-sm rounded' onClick={() => closeModal()}>
              Close
            </button>
          </div>
          <div className='col-sm-4 text-right'>
            <button type='button' className='btn btn-info btn-sm rounded' onClick={() => viewInvoice()}>
              View Invoice
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

PaymentForm.propTypes = {
  ...propTypes,
};

renderDatePicker.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

renderField.propTypes = {
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'paymentForm',
})(PaymentForm);
