import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { required } from '../common/validation';

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type={type} placeholder={label}/>
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const AddInstrumentForm = (props) => {
    const { handleSubmit, submitting, onClose } = props;

    return (
        <div className="basic-form">
            <form onSubmit={handleSubmit}>

                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-4">
                            <label>Instrument</label>
                        </div>
                        <div className="col-sm-6">
                            <Field name="instrument" type="text" component={renderField} validate={required} />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-4">
                            <label>Manufacturer</label>
                        </div>
                        <div className="col-sm-6">
                            <Field name="manufacturer" type="text" component={renderField} />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-4">
                            <label>Model</label>
                        </div>
                        <div className="col-sm-6">
                            <Field name="model" type="text" component={renderField} />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-4">
                            <label>Serial No</label>
                        </div>
                        <div className="col-sm-5">
                            <Field name="serialNo" type="text" component={renderField} validate={required} />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-4">
                            <label>School No</label>
                        </div>
                        <div className="col-sm-5">
                            <Field name="schoolNo" type="text" component={renderField} />
                        </div>
                    </div>
                </div>
                <hr />
                
                <div className="row">
                    <div className="col-sm-11">
                        <button type="submit" className="btn btn-primary rounded" disabled={submitting}>Add</button>&nbsp;&nbsp;
                        <button type="button" className="btn btn-default rounded" onClick={() => onClose()}>Cancel</button>
                    </div>       
                </div>
            </form>
        </div>
    );
};

AddInstrumentForm.propTypes = {
    ...propTypes
};

renderField.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'AddInstrumentForm'
})(AddInstrumentForm);