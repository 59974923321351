import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { reset } from 'redux-form';
import QuestionForm from './question-form';

class Questions extends Component {

      constructor(props, context) {
        super(props, context);
        this.state = {
            showModal: false,
            question: null
        };

        this.removeQuestion = this.removeQuestion.bind(this);
        this.saveQuestion = this.saveQuestion.bind(this);
        this.selectQuestion = this.selectQuestion.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }

 removeQuestion(question) {
        if (window.confirm(`Remove question from the form`)) { 
            this.setState({ showModal: false });
            this.props.removeQuestion(question); 
        } 
    }

  saveQuestion(question) {
    this.setState({ showModal: false });
    this.props.saveQuestion(question, this.props.userSetting.schoolId);
    
  }

  selectQuestion(question) {
      this.setState({ showModal: true, question });
  }

  close() {
        this.setState({ showModal: false, question: null });
    }

  open() {
        reset('registrationQuestion');
        this.setState({ showModal: true });
    }

    renderList(questions) {
        return questions.map((question) => {
            return (
                <li key={question._id}  onClick={() => this.selectQuestion(question)} className="list-group-item">
                    <div className="row">
                        <div className="col-xs-10"><b>{question.questionText}</b></div>
                        <div className="col-xs-2 text-right"><i className="fa fa-angle-right">&nbsp;</i></div>
                    </div>
                </li>
            );
        });
    }

    render() {
        const { questions } = this.props;

        return(
            <div>
                <div className="text-right">
                    <button type="button" onClick={() => this.selectQuestion({})} className="btn btn-info btn-xs rounded">Add a Question</button>
                </div>

                <ul className="list-group">
                    {this.renderList(questions)}
                </ul>

                <Modal show={this.state.showModal} onHide={this.close}>
                    <Modal.Header bsClass="modal-header primary" closeButton>
                        <Modal.Title>{(!this.state.question) ? 'New ' : ''}Registration Question</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-padding">
                            <QuestionForm onSubmit={this.saveQuestion} onDelete={this.removeQuestion} onClose={this.close} initialValues={this.state.question}  />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

Questions.propTypes = {
  userSetting: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  saveQuestion: PropTypes.func.isRequired,
  removeQuestion: PropTypes.func.isRequired
};


function mapStateToProps(state) {
  return {
        userSetting: state.usersetting
  };
}

export default connect(mapStateToProps)(Questions);
