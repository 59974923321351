import axios from 'axios';
import Notifications from 'react-notification-system-redux';
import {reset} from 'redux-form';
import moment from 'moment';
import fileDownload from 'js-file-download';
import { NOTHING, LASTUPDATE_STUDENT, SET_EMAIL_LIST, GET_STUDENTS, UPDATE_STUDENT, GET_STUDENT_LIST, NO_ACTION, GET_STUDENTS_SEARCH,
        STUDENT_COMMENT_ADD, STUDENT_COMMENT_DEL, GET_INSTRUMENT, REMOVE_STUDENT_LIST, UPDATE_STUDENT_IN_LIST,
        GET_ARCHIVE_STUDENTS, UPDATE_STUDENTS, CURRENT_URL, ARCHIVE_STUDENTS, GET_CONTACT,
        UPDATE_ALERT, RESTORE_STUDENT, ROOT_URL  } from './types';

export function reload(url, history) {
  history.push('/reload');
  return {
    type: CURRENT_URL,
    payload: url
  };

 }

export function updateAlert(value) {
  return ({ type: UPDATE_ALERT, payload: value });
}

export function getStudents(schoolId, active, lastUpdate) {
  return function(dispatch) {
    axios.post(`${ROOT_URL}/students/${schoolId}/${active}`, {lastUpdate},
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
      if (response.data === 'up-to-date') {
        dispatch({ type: NOTHING, payload: null });
      } else {
        if (active) {
          dispatch({ type: GET_STUDENTS, payload: response.data });
          dispatch({ type: LASTUPDATE_STUDENT, payload: moment().toDate() });
        } else {
          dispatch({ type: GET_ARCHIVE_STUDENTS, payload: response.data });
        }
      }
    });
  };
}

export function emailList(emailList) {
    if (!emailList.length) {
      return function(dispatch) {
        dispatch(Notifications.error({ title: 'Select at least one student' }));
      };
    } else {
      return { type: SET_EMAIL_LIST, payload: emailList };
    }
}

export function getStudent(student_id) {
   return function(dispatch) {
    axios.get(`${ROOT_URL}/student/${student_id}`,
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
            dispatch( {type: UPDATE_STUDENT, payload: response.data, id: student_id} );
      }).catch(error => {
        throw(error);
      });
  };
}

export function addStudent(student, schoolId, history) {

   return function(dispatch) {
    axios.post(`${ROOT_URL}/student/${schoolId}`, {student},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
            dispatch(Notifications.success({ title: 'Student Added' }));
            history.push(`/student/${response.data}/new`);
      }).catch(error => {
         dispatch(Notifications.error({ title: error.response.data }));
      });
  };
}


export function updateStudentContact(contactDetails, student_id, studentList = false) {
   return function(dispatch) {
    axios.put(`${ROOT_URL}/student-contact/${student_id}`, {contactDetails},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then((response) => {
           dispatch(Notifications.success({ title: 'Student Contact Details Saved' }));
           studentList && dispatch( {type:  UPDATE_STUDENT_IN_LIST, payload: response.data, id: student_id} );
          
      }).catch(error => {
        throw(error);
      });
  };
}

export function updateStudent(studentDetails, student_id, studentList = false) {
   return function(dispatch) {
    axios.put(`${ROOT_URL}/student/${student_id}`, {studentDetails},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then((response) => {
        if (studentList) {
          dispatch( {type: UPDATE_STUDENT_IN_LIST, payload: response.data, id: student_id } );
        } else {
          dispatch( {type: UPDATE_STUDENT, payload: response.data, id: student_id } );
        }
          dispatch(Notifications.success({ title: 'Student Details Saved' }));
     
      }).catch(error => {
        throw(error);
      });
  };
}

// export function removeGroup(studentGroups, student_id) {
//    return function(dispatch) {
//     axios.put(`${ROOT_URL}/student/${student_id}`, {studentDetails},
//     { headers: { authorization: localStorage.getItem('token') } 
//     } )
//     .then((response) => {
//            dispatch( {type: UPDATE_STUDENT, payload: response.data, id: student_id } );
//            dispatch(Notifications.success({ title: 'Student Details Saved' }));
     
//       }).catch(error => {
//         throw(error);
//       });
//   };
// }

export function toggleStudentStatus(student_id) {

   return function(dispatch) {
    axios.patch(`${ROOT_URL}/student/${student_id}`, {},
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        if (response.data == 'removed') {
          dispatch( {type: ARCHIVE_STUDENTS, payload: student_id } );
          dispatch(Notifications.error({ title: 'Student Moved to \'Student Archive\'' }));
        } else {
          dispatch( {type: RESTORE_STUDENT, payload: student_id } );
          dispatch(Notifications.success({ title: 'Student restored to \'All Students\'' }));
        }
      }).catch(error => {
        throw(error);
      });
     
  };
}

export function addStudentComment(comment, student_id) {
   return function(dispatch) {
    axios.post(`${ROOT_URL}/student/comment/${student_id}`, comment,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
         dispatch(reset('commentForm'));
         dispatch( {type: STUDENT_COMMENT_ADD, payload: response.data, id: student_id} );
         dispatch(Notifications.success({ title: 'Comment added to student' }));
     
      }).catch(error => {
        throw(error);
      });
     
  };
}

export function removeStudentComment(id, index, student_id) {

   return function(dispatch) {
    axios.delete(`${ROOT_URL}/student/comment/${id}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(() => {

         dispatch( {type: STUDENT_COMMENT_DEL, payload: index, id: student_id} );
         dispatch(Notifications.error({ title: 'Comment removed from student' }));
         // browserHistory.push('/');
     
      }).catch(error => {
        throw(error);
      });
     
  };
}

export function getNewStudents(schoolId) {
  return function(dispatch) {
    axios.get(`${ROOT_URL}/new-students/${schoolId}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        const newStudents = [];
        response.data.map((item) => {
          item.regDate = moment(item.registration.registrationDate).format('lll');
          newStudents.push(item);
        });
        dispatch({ type: GET_STUDENT_LIST, payload: newStudents });
      });
  };
}

export function getReEnrolments(schoolId) {
  return function(dispatch) {
    axios.get(`${ROOT_URL}/re-enrole/${schoolId}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        dispatch({ type: GET_STUDENT_LIST, payload: response.data });
      });
  };
}

export function getEnrolments(schoolId) {
  return function(dispatch) {
    axios.get(`${ROOT_URL}/enrolments/${schoolId}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        dispatch({ type: GET_STUDENT_LIST, payload: response.data });
      });
  };
}

export function exportStudents(exportList, exportOptions, schoolId) {

    axios.post(`${ROOT_URL}/utils/students-export/${schoolId}`, {exportList, exportOptions},
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        fileDownload(response.data, `Student_List-${moment().format('D-MMM-YYYY')}.csv`);
        });

  return {
    type: NO_ACTION,
    payload: ''
  };
}

export function exportRoll(groupId, schoolId, exportOptions) {
      axios.get(`${ROOT_URL}/utils/roll/${groupId}/${schoolId}/${exportOptions.term}/${exportOptions.bandOrder ? 'true' : 'false'}`,
      { headers: { authorization: localStorage.getItem('token') } 
      })
      .then(response => {
          fileDownload(response.data, `Student_Roll-${moment().format('D-MMM-YYYY')}.csv`);
          });
  
    return {
      type: NO_ACTION,
      payload: ''
    };
  }

  export function getStudentsearch(schoolId) {
  return function(dispatch) {
    axios.get(`${ROOT_URL}/students/search/${schoolId}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        dispatch({
          type: GET_STUDENTS_SEARCH,
          payload: response.data
        });
      });
  };
}

export function addStudentsToReEnrole(groupList, schoolId) {

  return function(dispatch) {
    axios.post(`${ROOT_URL}/re-enrole/${schoolId}`, {groupList},
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(() => axios.get(`${ROOT_URL}/re-enrole/${schoolId}`,
        { headers: { authorization: localStorage.getItem('token') } }))
    .then(response => {
        dispatch({ type: GET_STUDENT_LIST, payload: response.data });
    }).catch(error => {
        throw(error);
      });
  };
}

export function removeFormReEnrole(students, action, schoolId) {

  return function(dispatch) {
    if (!students.length) {
        dispatch(Notifications.error({ title: 'Select at least one student' }));
      } else {
        axios.put(`${ROOT_URL}/re-enrole/${schoolId}`, { students, action },
          { headers: { authorization: localStorage.getItem('token') } 
          })
          .then(() => axios.get(`${ROOT_URL}/re-enrole/${schoolId}`,
              { headers: { authorization: localStorage.getItem('token') } }))
          .then(response => {
              dispatch({ type: GET_STUDENT_LIST, payload: response.data });

          }).catch(error => {
              throw(error);
        });
      }
  };
}

export function getInstrument(instrument_id, history) {
   return function(dispatch) {
    axios.get(`${ROOT_URL}/instrument/${instrument_id}`,
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
            dispatch( {type: GET_INSTRUMENT, payload: response.data} );
            history.push('/instrument');
      }).catch(error => {
        throw(error);
      });
  };
}

export function getContact(id, history) {
  return function(dispatch) {
     axios.get(`${ROOT_URL}/contact/${id}`,
     { headers: { authorization: localStorage.getItem('token') } 
     })
     .then(response => {
         // Convert Group Permissions array to an object
         let objGroups = {};
         for (let field in response.data.groupPermissions) {
           objGroups[response.data.groupPermissions[field]] = true; 
         }
         response.data.groupPermissions = objGroups;
 
         dispatch({ type: GET_CONTACT, payload: response.data });
         history.push('/contact');
       });
   };
 }

export function setGroup(groupDetails, student_id) {
   return function(dispatch) {
    axios.post(`${ROOT_URL}/student-group/${student_id}`, groupDetails,
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then((response) => {
        const newStudents = [];
        response.data.map((item) => {
          item.regDate = moment(item.regDate).format('lll');
          newStudents.push(item);
        });
        dispatch({ type: GET_STUDENT_LIST, payload: newStudents });
        dispatch(Notifications.success({ title: 'Student Group Details Saved' }));
     
      }).catch(error => {
        throw(error);
      });
  };
}

export function updateNewStudents(schoolId, group, students, closeModal) {
  return function(dispatch) {
   axios.post(`${ROOT_URL}/new-student-update/${schoolId}`, { group, students },
   { headers: { authorization: localStorage.getItem('token') } 
   })
   .then((response) => {
        const newStudents = [];
        response.data.map((item) => {
          item.regDate = moment(item.registration.registrationDate).format('lll');
          newStudents.push(item);
        });
        dispatch({ type: GET_STUDENT_LIST, payload: newStudents });
        closeModal();
     }).catch(error => {
       dispatch(Notifications.error({ title: error.response.data.error }));
     });
 };
}

export function deleteNewStudent(id) {
   return function(dispatch) {
    axios.delete(`${ROOT_URL}/student/${id}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(() => {
         dispatch({ type: REMOVE_STUDENT_LIST, payload: id });
         dispatch(Notifications.warning({ title: 'New Student Deleted' }));
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function deleteStudents(ids) {
  return function(dispatch) {
   axios.post(`${ROOT_URL}/delete-students/`, { ids },
   { headers: { authorization: localStorage.getItem('token') } 
   })
   .then(() => {
        dispatch({ type: RESTORE_STUDENT, payload: ids });
        dispatch(Notifications.warning({ title: 'Students Deleted' }));
     }).catch(error => {
       dispatch(Notifications.error({ title: error.response.data.error }));
     });
 };
}


export function updateTags(ids, schoolId, tag) {
  return function(dispatch) {
   axios.post(`${ROOT_URL}/student/tags/${schoolId}`, { ids, tag },
   { headers: { authorization: localStorage.getItem('token') } 
   })
   .then((response) => {
        dispatch({ type: UPDATE_STUDENTS, payload: response.data });
        dispatch({ type: LASTUPDATE_STUDENT, payload: moment().toDate() });
     }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
     });
 };
}

export function updateGroups(ids, schoolId, { oldGroup, newGroup }) {
  return function(dispatch) {
   axios.post(`${ROOT_URL}/student/move-group/${schoolId}`, { ids, oldGroup, newGroup },
   { headers: { authorization: localStorage.getItem('token') } 
   })
   .then((response) => {
        dispatch({ type: UPDATE_STUDENTS, payload: response.data });
        dispatch({ type: LASTUPDATE_STUDENT, payload: moment().toDate() });
     }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
     });
 };
}

export function archiveStudents(ids, schoolId) {
  return function(dispatch) {
   axios.post(`${ROOT_URL}/student/archive/${schoolId}`, ids,
   { headers: { authorization: localStorage.getItem('token') } 
   })
   .then(() => {
        dispatch({ type: ARCHIVE_STUDENTS, payload: ids });
        dispatch({ type: LASTUPDATE_STUDENT, payload: moment().toDate() });
     }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
     });
 };
}

export function moveToNextGrade(schoolId) {
  return function(dispatch) {
   axios.get(`${ROOT_URL}/student/grades/${schoolId}`,
   { headers: { authorization: localStorage.getItem('token') } 
   })
  .then(() => axios.post(`${ROOT_URL}/students/${schoolId}/true`, { lastUpdate: null },
    { headers: { authorization: localStorage.getItem('token') } }))
  .then(response => {
      dispatch({ type: GET_STUDENTS, payload: response.data });
      dispatch({ type: LASTUPDATE_STUDENT, payload: moment().toDate() });
      dispatch(Notifications.success({ title: 'Student grades updated' }));
  }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
     });
 };
}

export function updateStudentDiscount(discount, student_id) {
  return function(dispatch) {
   axios.post(`${ROOT_URL}/student/discount/${student_id}`, discount,
   { headers: { authorization: localStorage.getItem('token') } 
   } )
   .then(() => {
        dispatch(Notifications.success({ title: 'Student Discount Updated' }));
    }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
    });
 };
}

export function saveEnrolment(enrolment, studentId) {
  return function(dispatch) {
   axios.post(`${ROOT_URL}/enrolments/${studentId}`, enrolment,
   { headers: { authorization: localStorage.getItem('token') } 
   })
   .then((response) => {
      dispatch({ type: GET_STUDENT_LIST, payload: response.data });
      dispatch(Notifications.success({ title: 'Student Enrolment Saved' }));
     }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
     });
 };
}

export function removeEnrolments(students, schoolId) {
  return function(dispatch) {
   axios.put(`${ROOT_URL}/enrolments/${schoolId}`, students,
   { headers: { authorization: localStorage.getItem('token') } 
   })
   .then((response) => {
        dispatch({ type: GET_STUDENT_LIST, payload: response.data });
     }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
     });
 };
}
 
export function sendEmailPreview(email, schoolId) {
  return function(dispatch) {
    if (!email.subject || !email.fromEmail) {
      dispatch(Notifications.warning({ title: 'Email From and Subject Required' }));
    } else {
      axios.post(`${ROOT_URL}/send-preview/${schoolId}`, email,
      { headers: { authorization: localStorage.getItem('token') } 
      } )
      .then(() => {
             dispatch(Notifications.success({ title: 'Email Preview Sent' }));
        }).catch(error => {
           dispatch(Notifications.error({ title: error.response.data.error }));
        });
    }
 };
}

export function makeActive(students, newStudents, schoolId) {
  return function(dispatch) {
   axios.post(`${ROOT_URL}/students/makeactive/${schoolId}`, students,
   { headers: { authorization: localStorage.getItem('token') } 
   })
   .then(() => {
      dispatch({ type: GET_STUDENT_LIST, payload: newStudents });
      dispatch({ type: UPDATE_ALERT, payload: newStudents.length });
      dispatch(Notifications.success({ title: `${students.length} Students Set to Active` }));
     }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
     });
 };
}