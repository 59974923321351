import React from 'react';

const ImportInstructions = () => {

    return (
        <div>
            
        </div>
    );
};

export default ImportInstructions;