import React from 'react';

import '../../scripts/redactor.css';
import '../../scripts/rPlugins.min.js';

class EmailEditor extends React.Component {
  componentDidMount() {
    const { value, handleOnChange, contacts } = this.props;
    const variables = contacts
      ? ['[Contact_First]', '[Contact_Last]', '[Contact_Email]']
      : [
          '[Parent_First]',
          '[Parent_Last]',
          '[Student_First]',
          '[Student_Last]',
          '[Parent_Title]',
          '[Student_Groups]',
          '[Student_Instruments]',
          '[Portal_Link]',
        ];

    this.rEditor = new window.$R('#editor', {
      styles: true,
      plugins: ['table', 'alignment', 'fontfamily', 'fontsize', 'fontcolor', 'variable', 'video', 'fullscreen'],
      variables,
      callbacks: {
        blur: () => {
          const source = this.rEditor.source.getCode();
          const content = source
            .replace(/<span data-redactor-type="variable">/g, '')
            .replace(/]<\/span>/g, ']')
            .replace(/&nbsp;/g, ' ');
          handleOnChange(content);
        },
      },
    });
    if (value) {
      this.rEditor.source.setCode(value);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.rEditor.source.setCode(nextProps.value);
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return <div id='editor' ref='rEditor' />;
  }
}

export default EmailEditor;
