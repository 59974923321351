import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/students';
import ReactDataGrid from 'react-data-grid';
import { Data } from 'react-data-grid-addons';

const Selectors = Data.Selectors;

class EmailContactList extends Component {

     constructor(props) {
        super(props);

        this.state = {
            selectedIndexes: this.setSelected(),
            rows: this.props.contactList.slice(0),
            filters : {},
            sortColumn: null, 
            sortDirection: null
        };

        this.rowGetter = this.rowGetter.bind(this);
        this.onRowsSelected = this.onRowsSelected.bind(this);
        this.onRowsDeselected = this.onRowsDeselected.bind(this);
        this.setSelected = this.setSelected.bind(this);
        this.getRows = this.getRows.bind(this);
        this.getSize = this.getSize.bind(this);
    }

    rowGetter(i) {
        const rows = this.getRows();
        return rows[i];
    }

    setSelected() {
        const _selected = [];
        for (let i = 0; i < this.props.contactList.length; i++) {
        _selected.push(i);
        }
        return _selected;
    }

    onRowsSelected(rows) {
        this.setState({selectedIndexes: this.state.selectedIndexes.concat(rows.map(r => r.rowIdx))});
        this.props.selectedContacts({rows, status: true});
}

    onRowsDeselected(rows) {
        let rowIndexes = rows.map(r => r.rowIdx);
        this.props.selectedContacts({rows, status: false});
        this.setState({selectedIndexes: this.state.selectedIndexes.filter(i => rowIndexes.indexOf(i) === -1)});
    }

    getRows() {
         return Selectors.getRows(this.state);
     }

    getSize() {
        return this.getRows().length;
    }

    sendEmail() {
        const contactList = this.state.selectedIndexes.map(index => this.state.rows[index]);
        this.props.emailList(contactList);
        if (contactList.length) this.props.history.push('/send-email');
    }        
       
    render() {
        if (!this.props.contactList) {
            return <div>loading...</div>;
        }

        const columns = [
            {
                key: 'firstName',
                name: 'First Name',
                width: 150
            },
            {
                key: 'lastName',
                name: 'Last Name',
                width: 150
            },
             {
                key: 'email',
                name: 'Email',
                width: 180
            },
             
             {
                key: 'category',
                name: 'Category',
            },
            {
                key: 'role',
                name: 'Role',

            }
            ];

        
       
        return (
            <div>
            <ReactDataGrid
                    rowKey="Id"
                    columns={columns}
                    rowGetter={this.rowGetter}
                    rowsCount={this.getSize()}
                    minHeight={this.getSize() * 35 + 85}
                    rowSelection={{
                        showCheckbox: true,
                        enableShiftSelect: true,
                        onRowsSelected: this.onRowsSelected,
                        onRowsDeselected: this.onRowsDeselected,
                        selectBy: {
                        indexes: this.state.selectedIndexes
                        }
                    }} />
                </div> 
                            
        );
    }
}

EmailContactList.propTypes = {
  contactList: PropTypes.array.isRequired,
  selectedContacts: PropTypes.func.isRequired,
  emailList: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return { 
      contactList: state.contacts.emailList 
    };
}

export default connect(mapStateToProps, actions)(EmailContactList);
