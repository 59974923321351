import axios from 'axios';
import Notifications from 'react-notification-system-redux';
import { ROOT_URL, UPDATE_SELECTORS } from './types';

// ########## Instruments ##########

export function updateList(item, action, schoolId, itemOld = {}) {

   return function(dispatch) {
    axios.post(`${ROOT_URL}/settings/lists/${schoolId}`, {item, action, itemOld},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
        dispatch( {type: UPDATE_SELECTORS, payload: response.data.selectors} );
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data }));
      });
  };
}

export function updateTermDates(termDates, schoolId) {

   return function(dispatch) {
    axios.post(`${ROOT_URL}/settings/terms/${schoolId}`, {termDates},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(() => {
        dispatch(Notifications.success({ title: 'Term Dates Updated' }));
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data }));
      });
  };
}

export function addStudentTag(tag, schoolId) {
     return function(dispatch) {
      axios.post(`${ROOT_URL}/settings/tags/${schoolId}`, tag,
      { headers: { authorization: localStorage.getItem('token') } 
      } )
      .then(response => {
          dispatch( {type: UPDATE_SELECTORS, payload: response.data.selectors} );
        }).catch(error => {
          dispatch(Notifications.error({ title: error.response.data }));
        });
    };
  }

  export function removeStudentTag(tag, schoolId) {
    return function(dispatch) {
     axios.put(`${ROOT_URL}/settings/tags/${schoolId}`, tag,
     { headers: { authorization: localStorage.getItem('token') } 
     } )
     .then(response => {
        dispatch( {type: UPDATE_SELECTORS, payload: response.data.selectors} );
       }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data }));
       });
   };
 }
