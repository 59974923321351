import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/library';
import { Tree } from '@blueprintjs/core';

class DocumentList extends Component {

     constructor(props) {
        super(props);

        this.state = {
            nodes: (this.props.documents) ? JSON.parse(JSON.stringify(this.props.documents)) : []
        };

        this.handleNodeClick = this.handleNodeClick.bind(this);
        this.handleNodeCollapse = this.handleNodeCollapse.bind(this);
        this.handleNodeExpand = this.handleNodeExpand.bind(this);
    }

    componentDidMount() {
       if (!this.props.documents) this.props.getDocuments(this.props.userSetting.schoolId);
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.documents !== this.props.documents) {
          this.setState({ nodes: JSON.parse(JSON.stringify(nextProps.documents))});
        }
     }

    handleNodeClick(nodeData, _nodePath, e) {
        const originallySelected = nodeData.isSelected;
        if (!e.shiftKey) {
            this.forEachNode(this.state.nodes, (n) => n.isSelected = false);
        }
        nodeData.isSelected = originallySelected == null ? true : !originallySelected;
        if (nodeData.hasCaret) {
          nodeData.isExpanded = !nodeData.isExpanded;
          nodeData.iconName = (nodeData.isExpanded) ? 'folder-open' : 'folder-close';
          this.setState(this.state);
        } else {
          if (this.props.template) {
              this.props.addTemplateAttachment(this.props.template, { id: nodeData.id, label: nodeData.label });
          } else {
              this.props.addAttachment({ _id: nodeData.id, label: nodeData.label });
          } 
          this.props.closeModal();
        }
    }

    handleNodeCollapse(nodeData) {
        nodeData.isExpanded = false;
        nodeData.iconName = 'folder-close';
        this.setState(this.state);
    }

    handleNodeExpand(nodeData) {
        nodeData.isExpanded = true;
        nodeData.iconName = 'folder-open';
        this.setState(this.state);
    }

    forEachNode(nodes, callback) {
        if (nodes == null) {
            return;
        }

        for (const node of nodes) {
            callback(node);
            this.forEachNode(node.childNodes, callback);
        }
    }

       
    render() {
        if (!this.props.documents) {
            return <div>loading...</div>;
        }

        return (
            <div>
              <Tree
                contents={this.props.template ? this.state.nodes.filter(item => item.label !== 'Attachments') : this.state.nodes}
                onNodeClick={this.handleNodeClick}
                onNodeCollapse={this.handleNodeCollapse}
                onNodeExpand={this.handleNodeExpand}
              />
            </div> 
        );
    }
}

DocumentList.propTypes = {
  userSetting: PropTypes.object.isRequired,
  documents: PropTypes.array,
  getDocuments: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  addAttachment: PropTypes.func.isRequired,
  addTemplateAttachment: PropTypes.func.isRequired,
  template: PropTypes.string
};

function mapStateToProps(state) {
  return { 
      userSetting: state.usersetting,
      documents: state.library.documents 
    };
}

export default connect(mapStateToProps, actions)(DocumentList);
