import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/contacts';
import { Modal } from 'react-bootstrap';
import {reset} from 'redux-form';
import EmailForm from './from-email-form';

class EmailList extends Component {

      constructor(props, context) {
        super(props, context);
        this.state = {
            showModal: false
        };

        this.removeEmail = this.removeEmail.bind(this);
        this.saveEmail = this.saveEmail.bind(this);
        this.selectEmail = this.selectEmail.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }

 removeEmail(email) {
        {if(window.confirm(`Remove '${email.fromEmailAddress}' from your list of email address`)) { this.props.removeFromEmail(email, this.props.userSetting.schoolId); } }
    }

  saveEmail(email) {
    this.setState({ showModal: false });
    this.props.updateFromEmail(email, this.props.userSetting.schoolId);
    
  }

  selectEmail(email) {
      this.props.selectedFromEmail(email);
      this.setState({ showModal: true });
  }

  close() {
        this.setState({ showModal: false });
    }

  open() {
        reset('meetingTimesForm');
        this.setState({ showModal: true });
    }

    renderList() {

        return this.props.userSetting.profile.fromEmails.map((email) => {
            return (
                <li key={email._id} className="list-group-item">
                <div className="row">
                    <div className="col-sm-10 small"><b>{email.fromEmailAddress}</b></div>
                    <div className="col-sm-1" onClick={() => this.selectEmail(email)}><i className="fa fa-pencil-square-o">&nbsp;</i></div>                  
                    <div className="col-sm-1" onClick={() => this.removeEmail(email)}><i className="fa fa-times">&nbsp;</i></div>
                    <div className="col-sm-12"><span className="small grey-text">From: {email.fromName}</span></div>
                </div>
                </li>
            );
        });
    }

    render() {

        return(
            <div>
                <div className="text-right">
                    <button type="button" onClick={() => this.selectEmail({})} className="btn btn-info btn-xs rounded">Add Email Address</button>
                </div>

                <ul className="list-group">
                    {this.renderList()}
                </ul>

                <Modal show={this.state.showModal} onHide={this.close}>
                    <Modal.Header bsClass="modal-header primary" closeButton>
                        <Modal.Title>Email Address</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EmailForm onSubmit={this.saveEmail} initialValues={this.props.settings.selectedFromEmail} />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

EmailList.propTypes = {
  userSetting: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  updateFromEmail: PropTypes.func.isRequired,
  selectedFromEmail: PropTypes.func.isRequired,
  removeFromEmail: PropTypes.func.isRequired
};


function mapStateToProps(state) {

  return {
        userSetting: state.usersetting,
        settings: state.selectLists
  };
}

export default connect(mapStateToProps, actions)(EmailList);
