import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tab, Nav, NavItem, Row, Col } from 'react-bootstrap';
import * as actions from '../../actions/index';
import EmailDetails from './email-details';
import RecipientLists from './email-recipients';

class LogDetailsPage extends Component {

    constructor(props, context) {
        super(props, context);

    }

    render() {

        const { emailLog } = this.props;

        if (!emailLog) {
      
            return <div>loading...</div>;
        }

        return (
            <div>
                <div className="pageheader">
                    <Link to="/">Dashboard</Link> / Email Log
                </div>
                <section id="main-content">
                        <div className="row">
                            <div className="col-md-11 col-lg-10">
                                <div className="panel panel-primary">
                                    <div className="panel-heading"></div>
                                    <div className="panel-body">
                                       
                                        <Tab.Container id="contact-tabs" defaultActiveKey="recipients">
                                            <Row className="clearfix">
                                                <Col sm={12}>
                                                    <Nav className="nav nav-tabs">
                                                        <NavItem eventKey="details">
                                                            Details
                                                        </NavItem>
                                                        <NavItem eventKey="recipients">
                                                            Recipients
                                                        </NavItem>
                                                    </Nav>

                                                    <Tab.Content animation>
                                                        <Tab.Pane eventKey="details">
                                                            <EmailDetails  emailDetails={emailLog} />
                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="recipients">
                                                            <RecipientLists />
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                 </Col>
                                            </Row>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
        );
    }
}

LogDetailsPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  emailLog: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        userSetting: state.usersetting,
        emailLog : state.logs.emailLog
    };
}

export default connect(mapStateToProps, actions)(LogDetailsPage);