import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tab, Nav, NavItem, Row, Col } from 'react-bootstrap';
import * as actions from '../../actions/contacts';
import { Link } from "react-router-dom";
import ContactForm from './contact-form';
import PermissionForm from './permission-form';
import Students from './student-list';
import CommentForm from '../common/comment-form';
import CommentList from '../common/comment-list';

class ContactPage extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            sending: false
        };

        this.saveForm = this.saveForm.bind(this);
        this.savePermission = this.savePermission.bind(this);
        this.addComment = this.addComment.bind(this);
        this.removeComment = this.removeComment.bind(this);
        this.emailPassword = this.emailPassword.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleAccess = this.toggleAccess.bind(this);
        this.deleteContact = this.deleteContact.bind(this);
  }
         componentWillMount() {
          
        // this.props.getContact(this.props.params.id);
        
        }    

        saveForm(values) {
            this.props.updateContact({details: values}, this.props.contact._id);
        }

        savePermission(values) {
            this.props.updatePermission(values, this.props.contact._id);
        }

        deleteContact() {
            if (window.confirm(`Confirm you would like to delete contact: ${this.props.contact.details.firstName} ${this.props.contact.details.lastName}`)) { 
                this.props.deleteContact(this.props.contact._id, this.props.contact.schoolId, this.props.history);
            }
        }

         addComment(comment) {
             this.props.addContactComment(comment, this.props.contact._id);
        }

         removeComment(id, index) {
            this.props.removeContactComment(this.props.contact._id, id, index);
         }

         emailPassword() {
            this.props.sendPassword(this.props.contact.details.email, this.props.userSetting.schoolName, this.props.contact.schoolId);
            this.setState({ sending: true });
        }

         toggle(value) {
            this.props.toggelPermission(value);
         }

         toggleAccess(value) {
            this.props.toggelAccess(value);
    }

    render() {

        const { contact, groups, selectors, students } = this.props;

        if (!contact.details || contact.details.length === 0) {
      
            return <div>loading...</div>;
        }

        return (
            <div>
                <div className="pageheader"><Link to="/contacts">Contact List</Link> / Contact</div>
                <section id="main-content">
                        <div className="row">
                            <div className="col-md-12 col-lg-11">
                                <div className="panel panel-primary">
                                    <div className="panel-heading">{`${contact.details.firstName} ${contact.details.lastName}`}</div>
                                    <div className="panel-body">

                                        <Tab.Container id="contact-tabs" defaultActiveKey="details">
                                            <Row className="clearfix">
                                                <Col sm={12}>
                                                    <Nav bsStyle="tabs">
                                                        <NavItem eventKey="details">
                                                            Details
                                                        </NavItem>
                                                        {(this.props.userSetting.fullAdmin || this.props.userSetting.menuPermissions.contactPermission) &&<NavItem eventKey="permissions">Permissions</NavItem>}
                                                        {(contact.details.category && contact.details.category.toLowerCase().includes('tutor')) &&<NavItem eventKey="students">Students</NavItem>}
                                                        <NavItem eventKey="comments">
                                                            Comments &nbsp;<span className="label badge badge-primary">{ (contact.details.noComments > 0) ? contact.details.noComments : ''}</span>
                                                        </NavItem>
                                                    </Nav>

                                                    <Tab.Content animation>
                                                        <Tab.Pane eventKey="details">
                                                            <div className="form-padding">
                                                                <br />
                                                                <ContactForm onSubmit={this.saveForm} initialValues={contact.details} selectors={selectors} deleteContact={this.deleteContact} />
                                                            </div>
                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="permissions">
                                                            <div className="form-padding">
                                                                <br />
                                                                <PermissionForm 
                                                                    onSubmit={this.savePermission} 
                                                                    initialValues={this.props.contact} 
                                                                    contact={this.props.contact} 
                                                                    groups={groups} 
                                                                    onEmailPassword={this.emailPassword} 
                                                                    sending={this.state.sending}
                                                                    toggle={this.toggle} 
                                                                    />

                                                            </div>
                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="students">
                                                            <div className="form-padding">
                                                                <br />
                                                                <Students id={contact._id} students={students} />
                                                            </div>
                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="comments">
                                                            <div className="form-padding">
                                                                <br />
                                                                <CommentForm onSubmit={this.addComment} />
                                                                <hr />
                                                                <CommentList onDelete={this.removeComment} comments={contact.details.comments} />
                                                            </div>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                 </Col>
                                            </Row>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
        );
    }
}

ContactPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  selectors: PropTypes.object.isRequired,
  students: PropTypes.object.isRequired,
  updateContact: PropTypes.func.isRequired,
  addContactComment: PropTypes.func.isRequired,
  removeContactComment: PropTypes.func.isRequired,
  sendPassword: PropTypes.func.isRequired,
  toggelPermission: PropTypes.func.isRequired,
  toggelAccess: PropTypes.func.isRequired,
  updatePermission: PropTypes.func.isRequired,
  deleteContact: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        userSetting: state.usersetting,
        contact: state.contacts.contact,
        groups: state.school.groups,
        selectors: state.school.selectors,
        students: state.students.all
    };
}

export default connect(mapStateToProps, actions)(ContactPage);