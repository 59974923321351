import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import GroupList from './group-list';
import ActivityList from './activity';
import * as actions from '../../actions';
import AddGroupForm from './add-group-form';

class DashboardPage extends Component {

    constructor(props, context) {
    super(props, context);

    this.state = {
            showAddGroup: false
        };

        this.openAddGroup = this.openAddGroup.bind(this);
        this.closeAddGroup = this.closeAddGroup.bind(this);
        this.emailGroups = this.emailGroups.bind(this);
        this.addGroup = this.addGroup.bind(this);
        this.emailLog = this.emailLog.bind(this);

  }

    componentDidMount() {
        if (!this.props.userSetting.schoolId) {
          this.props.reload(this.props.location.pathname, this.props.history);
        } else {
            this.props.getSchool(this.props.userSetting.schoolId, this.props.history);
            if (!this.props.userSetting.lastUpdate.students) {
                this.props.getStudents(this.props.userSetting.schoolId, true, null);
            } 
        }
    }

    emailGroups(groups) {
        this.props.getEmailListGroups(groups, this.props.history);
    }

    addGroup(group) {
        this.props.addGroup(group, this.props.userSetting.schoolId, this.props.history);
        this.closeAddGroup();
    }

    openAddGroup() {
        this.setState({ showAddGroup: true });
    }

    closeAddGroup() {
        this.setState({ showAddGroup: false });
    }

    emailLog(emailLogId) {
        this.props.getLogDetails(emailLogId, this.props.history);
    }
 

    render() {
     if (!this.props.school.name) {
             return(<div>Loading...</div>);
        }     
        
       return (
            <div>
                <div className="pageheader"><h2>Dashboard</h2></div>
                <section id="main-content">
                        <div className="row">
                            <div className="col-sm-6 col-lg-6">
                              <GroupList onSubmit={this.emailGroups} groups={this.props.school.groups} addGroup={this.openAddGroup} totalStudents={this.props.totalStudents} />
                            </div>

                            <div className="col-sm-6 col-lg-5">
                                <div className="panel panel-success">
                                    <div className="panel-heading">Activity Logs</div>
                                    <div className="panel-body">
                                        <div className="text-right">
                                            <Link to="logs" className="btn btn-info btn-trans btn-xs rounded">View All Activity</Link>
                                        </div>
                                       <ActivityList logs={this.props.school.logs} viewEmail={this.emailLog} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Modal show={this.state.showAddGroup} onHide={this.closeAddGroup}>
                        <Modal.Header className="modal-header primary" closeButton>
                            <Modal.Title>Add a Group</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-padding">
                                <AddGroupForm onSubmit={this.addGroup} onClose={this.closeAddGroup} />
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
        );
    }
}

DashboardPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  school: PropTypes.object.isRequired,
  getSchool: PropTypes.func.isRequired,
  getEmailListGroups: PropTypes.func.isRequired,
  addGroup: PropTypes.func.isRequired,
  getLogDetails: PropTypes.func.isRequired,
  getStudents: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
  totalStudents: PropTypes.number
};

function mapStateToProps(state) {
  return {
    userSetting: state.usersetting,
    school: state.school,
    totalStudents: state.students.totalStudents
  };
}

export default connect(mapStateToProps, actions)(DashboardPage);
