import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type={type} placeholder={label}/>
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const ContactForm = (props) => {
    const { handleSubmit, submitting } = props;

    return (
        <div className="basic-form">
            <form onSubmit={handleSubmit}>
            <div className="form-group">
                    <div className="row">
                    <div className="col-sm-3">
                        <label>Name</label>
                    </div>
                    <div className="col-sm-7">
                        <Field name="name" type="text" component={renderField} label="Contact Name"/>
                    </div>
                </div>
                </div>
                <div className="form-group">
                <div className="row">
                    <div className="col-sm-3">
                        <label>Role</label>
                    </div>
                    <div className="col-sm-7">
                        <Field name="role" type="text" component={renderField} label="Contact Role"/>
                    </div>
                </div>
                </div>
        
            <button type="submit" className="btn btn-primary rounded" disabled={submitting}>Save</button>
        </form>
    </div>
    );
};

ContactForm.propTypes = {
    ...propTypes
};

renderField.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'contactlForm'
})(ContactForm);