import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { required } from '../common/validation';

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
      <input className="form-control space-5" {...input} type={type} placeholder={label}/>
      {touched && error && <h5><span className="label label-warning pull-right">{error}</span></h5>}
  </div>
);

const AccountsForm = (props) => {
    
    const { handleSubmit, initialValues, submitting } = props;
   
    return (
        <div className="basic-form">
          <form onSubmit={handleSubmit}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-4">
                      <label>Account No</label>
                  </div>
                  <div className="col-sm-3">
                      <Field name="value" type="text" component={renderField} validate={required}/>
                  </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                  <div className="col-sm-4">
                      <label>Account Description</label>
                  </div>
                  <div className="col-sm-6">
                      <Field name="label" type="text" component={renderField} validate={required}/>
                  </div>
              </div>
            </div>
              
            <hr />
            <button type="submit" disabled={submitting} className="btn btn-primary">{(initialValues.label) ? 'Save' : 'Add'}</button>
          </form>
        </div>
    );
};

AccountsForm.propTypes = {
    ...propTypes
};

renderField.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'accountsList'
})(AccountsForm);