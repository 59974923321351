import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const renderList = (payments, removePayment) => {
    if (!payments) {
      return null;
    }
    return payments.map((item, index) => {
      return (
        <li key={index} className="list-group-item">
            <div className="row">
                <div className="col-xs-3">Date: {moment(item.paymentDate).format('D MMM YY')}</div>
                <div className="col-xs-4">Type: {item.paymentType}</div>
                <div className="col-xs-4">Amount: $ {item.amount}</div>
                <div className="col-sm-1 text-right" onClick={() => removePayment(item._id)}><i className="fa fa-times">&nbsp;</i></div>
            </div>
        </li>
      );
    });
  };

const InvoicePayments = (props) => {
    const { payments, removePayment } = props;

    return (
        <div className="padding-top-one">
            <ul className="list-group">
                {renderList(payments, removePayment)}
            </ul>
        </div>
    );
};

InvoicePayments.propTypes = {
    payments: PropTypes.array,
    removePayment: PropTypes.func.isRequired
};


export default InvoicePayments;