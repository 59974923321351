import { GET_ROSTER, GET_PARENTS, ROSTER_EMAIL } from '../actions/types';
import initialState from './initial-state';

export default function(state = initialState.roster, action) {
    switch(action.type) {

        case GET_ROSTER:
             return { ...state, ...action.payload };

        case GET_PARENTS:
             return { ...state, parentList: action.payload };

        case ROSTER_EMAIL:
            if (action.payload.emailType === '1') {
              return { ...state, oneDayEmail: action.payload };
            }
            return { ...state, sevenDayEmail: action.payload };

        default:
            return state;
    }
}