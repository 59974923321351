import React from 'react';

import '../../scripts/redactor.css';
import '../../scripts/rPlugins.min.js';

class ShowHTML extends React.Component {
  componentDidMount() {
    const { value } = this.props;

    this.rEditor = new window.$R('#editor', {
      toolbar: false,
    });
    if (value) {
      this.rEditor.source.setCode(value);
    }
  }

  render() {
    return <div id='editor' ref='rEditor' />;
  }
}

export default ShowHTML;
