import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { required } from '../common/validation';

const renderField = ({ input, label, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type="text" placeholder={label} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const SupportForm = (props) => {

    const { handleSubmit, pristine, submitting, onClose } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <div className="row">
                    <div className="col-sm-12">
                        <Field name="subject" type="text" component={renderField} label="Subject" validate={[required]}/>
                    </div>
                </div>
            </div>
            <div className="form-group">
              <div className="row">
                  <div className="col-sm-12">
                      <Field name="message" className="form-control noRadius" rows="8" component="textarea" placeholder="What can we help you with" />
                      <div className="status-action clearfix">
                          <div className="right-action-link pull-right">
                              <button type="button" className="btn btn-default btn-xs btn-no-margin rounded" onClick={onClose}>Cancel</button>&nbsp;&nbsp;
                              <button type="submit" className="btn btn-primary btn-xs btn-no-margin rounded" disabled={pristine || submitting}>Submit</button>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
        </form>
    );
};

SupportForm.propTypes = {
    ...propTypes
};

renderField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};


export default reduxForm({
    form: 'supportForm'
})(SupportForm);