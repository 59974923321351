import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/contacts';
import ContactForm from './contact-form';

class AddContactPage extends Component {

     constructor(props, context) {
        super(props, context);
      
        this.saveForm = this.saveForm.bind(this);
    }

    saveForm(values) {
        this.props.addContact(
                { schoolId: this.props.userSetting.schoolId,
                  schoolName: this.props.userSetting.schoolName }, values, this.props.history);
        }

    render () {

         return(
            <div>
                <div className="pageheader"><h2>Add a Contact</h2></div>
                <section id="main-content">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="panel panel-primary">
                                <div className="panel-heading">New Contact Details</div>
                                <div className="panel-body">
                                        <ContactForm onSubmit={this.saveForm} selectors={this.props.selectors} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

AddContactPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  selectors: PropTypes.object.isRequired,
  addContact: PropTypes.func.isRequired
};


function mapStateToProps(state) {

    return {
        userSetting: state.usersetting,
        selectors: state.school.selectors
    };
}

export default connect(mapStateToProps, actions)(AddContactPage);