import _ from 'lodash';
import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/students';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactDataGrid from 'react-data-grid';
import { Data } from 'react-data-grid-addons';
import StudentModal from './new-student-modal';
import NewBulkForm from './new-student-bulk-form';
import ExportForm from '../students/export-form';
import EmptyToolbar from '../common/grid-toolbar';

const Selectors = Data.Selectors;

class NewStudentPage extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            rows: [],
            selectedStudents: [],
            filters : {},
            sortColumn: null, 
            sortDirection: null,
            showStudent: false,
            showBulkUpdate: false,
            student: {},
            infoText: ''
        };
        
        this.rowGetter = this.rowGetter.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.getRows = this.getRows.bind(this);
        this.getSize = this.getSize.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.onClearFilters = this.onClearFilters.bind(this);
        this.handleGridSort = this.handleGridSort.bind(this);
        this.formatList = this.formatList.bind(this);
        this.onRowsSelected = this.onRowsSelected.bind(this);
        this.onRowsDeselected = this.onRowsDeselected.bind(this);
        this.closeStudent = this.closeStudent.bind(this);
        this.openStudent = this.openStudent.bind(this);
        this.closeBulkUpdate = this.closeBulkUpdate.bind(this);
        this.openBulkUpdate = this.openBulkUpdate.bind(this);
        this.closeExport = this.closeExport.bind(this);
        this.openExport = this.openExport.bind(this);
        this.exportStudents = this.exportStudents.bind(this);
        this.adjustComments = this.adjustComments.bind(this);
        this.makeActive = this.makeActive.bind(this);
        this.updateStudents = this.updateStudents.bind(this);
    }

    

     componentDidMount() {
        if (!this.props.userSetting.schoolId) {
          this.props.reload(this.props.location.pathname, this.props.history);
        } else {
            this.props.getNewStudents(this.props.userSetting.schoolId);
        }
    }

     componentWillReceiveProps(nextProps) {
        if (nextProps.students !== this.props.students) {
          this.setState({ rows: this.formatList(nextProps.students) });
        }
     }

    formatList(studentList) {
       return _.map(studentList, student => {
            return ({
            _id: student._id,
            name: `${student.firstName} ${student.lastName}`,
            firstName: student.firstName,
            lastName: student.lastName,
            grade: student.grade,
            regDate: student.regDate,
            groupSummary: student.groupSummary,
            instruments: student.instruments,
            studentTags: student.studentTags
            });
        });
    }

    rowGetter(i) {
        const rows = this.getRows();
        return rows[i];
    }

     onRowsSelected(rows) {
        this.setState({selectedStudents: this.state.selectedStudents.concat(rows.map(r => r.row))});
    }

    onRowsDeselected(rows) {
        let ids = rows.map(r => r.row._id);
        this.setState({selectedStudents: this.state.selectedStudents.filter(student => !ids.some(id => id === student._id) )});
    }

    onRowClick(rowIdx, row) {
        if (row) this.openStudent(this.props.students[row._id]);
  
    }

    getRows() {
         return Selectors.getRows(this.state);
     }

    getSize() {
        return this.getRows().length;
    }

    handleFilterChange(filter) {
        const newFilters = Object.assign({}, this.state.filters);
        if (filter.filterTerm) {
        newFilters[filter.column.key] = filter;
        } else {
        delete newFilters[filter.column.key];
        }
        this.setState({filters: newFilters});
    }

    onClearFilters() {
        //all filters removed
        this.setState({filters: {} });
    }

     handleGridSort(sortColumn, sortDirection) {
        const state = Object.assign({}, this.state, {sortColumn: sortColumn, sortDirection: sortDirection});
        this.setState(state);
    }
 
    openStudent(student) {
        this.setState({ showStudent: true, student });
    }

    closeStudent() {
        this.setState({ showStudent: false });
    }

    openBulkUpdate() {
        this.setState({ showBulkUpdate: true });
    }

    closeBulkUpdate() {
        this.setState({ showBulkUpdate: false });
    }

    openExport() {
        this.setState({ showExport: true });
    }

    closeExport() {
        this.setState({ showExport: false });
    }

    adjustComments(comments) {
        this.setState({ ...this.state.student, noComents: this.state.student.noComents + comments });
    }

     exportStudents(exportOptions) {

        let exportList = [];
        if (this.state.selectedStudents.length === 0) { 
            exportList = this.state.rows.map(row => row._id);
        } else {
            exportList = this.state.selectedStudents.map(student => student._id);
        }
        
        this.props.exportStudents(exportList, exportOptions, this.props.userSetting.schoolId);
        this.closeExport();
    }

    makeActive() {
        const studetList = this.state.selectedStudents.map(student => student._id);
        if (studetList.length) {
            const newList = _.filter(this.props.students, student => !studetList.includes(student._id));
            this.props.makeActive(studetList, newList, this.props.userSetting.schoolId);
        } else {
            alert('Select one or more students to set to active status');
        }
    }

    updateStudents(values) {
        const studetList = this.state.selectedStudents.map(student => student._id);
        if (studetList.length) {
          this.props.updateNewStudents(this.props.userSetting.schoolId, values, studetList, this.closeBulkUpdate);
        }
    }

    sendEmail() {
        const studetList = this.state.selectedStudents;
        if (studetList.length) {
            this.props.emailList(studetList);
        } else {
            this.props.emailList(this.state.rows);
        }
        this.props.history.push('/send-email');
    }

    studentTags(tags) {
        if (tags.value.length === 0) return null;
        return (
            <div>
               {tags.value.map((tag, index) => {
                   return <span key={index} className="label label-info label-padding">{tag}</span>;
                   })}
            </div>
        );
    }

    render () {
        const { selectors, groups } = this.props;
        const columns = [
            {
                key: 'name',
                name: 'Student',
                sortable : true,
                filterable : true,
                width: 200
            },
             {
                key: 'grade',
                name: 'Grade',
                sortable : true,
                filterable : true,
                width: 70
            },
            {
                key: 'regDate',
                name: 'Reg. On',
                sortable : true,
                filterable : true,
                width: 180
            },
             {
                key: 'groupSummary',
                name: 'Group',
                sortable : true,
                filterable : true,
            },
            {
                key: 'instruments',
                name: 'Instruments',
                sortable : true,
                filterable : true,
            },
            {
                key: 'studentTags',
                name: 'Tags',
                sortable : true,
                filterable : true,
                formatter: this.studentTags
            },
            {
               width: 18
           }
            ];

         return(
              <div>
                <div className="pageheader">
                    <h2>New Registrations</h2>
                </div>
                <section id="main-content">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="pt-button-group .modifier">
                                                <Link to="/students" className="pt-button pt-intent-primary pt-icon-new-person">All</Link>
                                                <div className="pt-button pt-intent-primary pt-active pt-icon-people" role="button">New</div>
                                                <Link to="/enrolments" className="pt-button pt-intent-primary pt-icon-following">Enrol</Link>
                                                <Link to="/re-enrolments" className="pt-button pt-intent-primary pt-icon-following">Re-Enrol</Link>
                                                <Link to="/archive" className="pt-button pt-intent-primary pt-icon-blocked-person">Archive</Link>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="text-right">
                                                <button type="button" className="btn btn-warning btn-trans btn-xs rounded" onClick={() => this.sendEmail()} >Send Email</button>&nbsp;
                                                <button type="button" className="btn btn-primary btn-trans btn-xs rounded" onClick={() => this.makeActive()} >Set as Active</button>&nbsp;
                                                <button type="button" className="btn btn-info btn-trans btn-xs rounded" onClick={() => this.openBulkUpdate()} >Bulk Update</button>&nbsp;
                                                <button type="button" className="btn btn-xs btn-default rounded" onClick={() => this.openExport()} >Export</button>
                                            </div>
                                        </div>
                                    </div>
                                        <div className="col-xs-12">
                                            <div>
                                                <ReactDataGrid
                                                    rowKey="_id"
                                                    onGridSort={this.handleGridSort}
                                                    columns={columns}
                                                    rowGetter={this.rowGetter}
                                                    rowsCount={this.getSize()}
                                                    minHeight={this.getSize() * 35 + 85}
                                                    onRowClick={this.onRowClick}
                                                    toolbar={<EmptyToolbar />}
                                                    onAddFilter={this.handleFilterChange}
                                                    onClearFilters={this.onClearFilters}
                                                    rowSelection={{
                                                            showCheckbox: true,
                                                            enableShiftSelect: true,
                                                            onRowsSelected: this.onRowsSelected,
                                                            onRowsDeselected: this.onRowsDeselected,
                                                            selectBy: {
                                                            keys: { rowKey: '_id', values: this.state.selectedStudents.map(row => row._id)}
                                                        }
                                                        }}
                                                        />
                                            </div>
                                            <div className="admin-label text-right margin-top-quarter"><i className="blueBG"> { (this.getSize() === this.state.rows.length) ? `${this.state.rows.length} Students` : `Showing ${this.getSize()} of ${this.state.rows.length} Students`} </i></div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Modal show={this.state.showStudent} bsSize="lg" onHide={this.closeStudent}>
                    <Modal.Header bsClass="modal-header primary" closeButton>
                        <Modal.Title>{this.state.student.firstName} {this.state.student.lastName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-padding">
                            <StudentModal 
                                student={this.state.student} 
                                closeStudent={this.closeStudent} 
                                noComents={this.adjustComments}
                                noStudents={this.state.rows.length} />
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showBulkUpdate} onHide={this.closeBulkUpdate}>
                    <Modal.Header bsClass="modal-header primary" closeButton>
                        <Modal.Title>Bulk Update</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-padding">
                            <NewBulkForm onSubmit={this.updateStudents} selectorsValues={selectors} selectGroups={groups} closeModal={this.closeBulkUpdate} noStudents={this.state.selectedStudents.length} />
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showExport} onHide={this.closeExport}>
                    <Modal.Header bsClass="modal-header primary" closeButton>
                        <Modal.Title>Student Export</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-padding">
                            <ExportForm onSubmit={this.exportStudents} listType={'registration'} />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

NewStudentPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  selectors: PropTypes.object.isRequired,
  students: PropTypes.object.isRequired,
  exportStudents: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
  emailList: PropTypes.func.isRequired,
  getNewStudents: PropTypes.func.isRequired,
  updateAlert: PropTypes.func.isRequired,
  makeActive: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        userSetting: state.usersetting,
        selectors: state.school.selectors,
        groups: state.school.groups,
        students: state.students.studentList
    };
}

export default connect(mapStateToProps, actions)(NewStudentPage);