import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import FormEditor from '../editor/FormEditor';

const renderEditor = ({ input, onInfoField }) => (
  <FormEditor value={input.value} handleOnChange={input.onChange} onBlur={onInfoField(input.value)} />
);

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <label>{label}</label>
    <div className='margin-top-half'>
      <input className='form-control' {...input} type={type} placeholder={label} />
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);

const acceptTerms = (acceptText) => {
  if (acceptText)
    return (
      <div>
        <div className='col-xs-12 col-sm-12'>
          <div className='form-group'>
            <Field name='acceptAgreementText' type='text' component={renderField} label='Accept Terms Text' />
          </div>
        </div>
      </div>
    );
};

const InfoForm = (props) => {
  const { handleSubmit, submitting, onInfoField, onClose, initialValues } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className='form-group'>
        <div className='col-sm-12'>
          <Field name='infoText' type='textarea' onInfoField={onInfoField} component={renderEditor} />
        </div>
        <div className='clearfix'>&nbsp;</div>
      </div>
      {acceptTerms(initialValues.acceptAgreementText)}
      <button type='submit' className='btn btn-primary rounded' disabled={submitting}>
        Save
      </button>
      &nbsp;&nbsp;
      <button type='button' className='btn btn-default rounded' onClick={() => onClose()}>
        Cancel
      </button>
    </form>
  );
};

InfoForm.propTypes = {
  ...propTypes,
};

renderEditor.propTypes = {
  input: PropTypes.object.isRequired,
  onInfoField: PropTypes.func.isRequired,
};

renderField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'newRegistrationInfo',
})(InfoForm);
