import React from 'react';
import { Field, reduxForm, propTypes } from 'redux-form';

const CommentForm = (props) => {

    const { handleSubmit, pristine, submitting } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-12">
                    <Field name="comment" className="form-control noRadius" component="textarea" placeholder="Add a comment" />
                     <div className="status-action clearfix">
                        <div className="right-action-link pull-right">
                            <button type="submit" className="btn btn-primary btn-xs btn-no-margin rounded" disabled={pristine || submitting}>Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

CommentForm.propTypes = {
    ...propTypes
};


export default reduxForm({
    form: 'commentForm'
})(CommentForm);