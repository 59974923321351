import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm, propTypes } from 'redux-form';
import { Checkbox } from 'react-icheck';
import DropdownList from 'react-select';
import { required, none } from '../common/validation';

const dropdownData = data => data.map(obj => ({ value: obj }));

const renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
        <input className="form-control" {...input} type={type} placeholder={label}/>
         {touched && error && <h5><span className="label label-warning pull-right">{error}</span></h5>}
    </div>
  );

const renderCheckbox = ({ input, name }) => (
    <Checkbox 
      checkboxClass="icheckbox_square-blue" 
      increaseArea="20%"
      name={name}
      onChange={input.onChange}
      defaultChecked = {input.value ? true : false}
      />
  );

  const renderQuestionDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
    <div className="has-error">
        <DropdownList {...input} {...rest} onBlur={() => input.change} />
            { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
    );

    const renderComment = ({ input, label }) => <input className="form-control" {...input} placeholder={label} type="text" />;

const displayText = (questionDetails, index) => (
  <div key={index}>
    <div className="row">
        <div className="col-md-12">
        <div className="form-group">
            <div dangerouslySetInnerHTML={{ __html: questionDetails.questionText}} />
        </div>
        </div>
    </div>
    <div className="hr-line" />
  </div>
  );

  const displayHeading = (questionDetails, index) => (
    <div key={index}>
        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <h4>{questionDetails.questionText}</h4>
                    {questionDetails.required ? <hr /> : null}
                </div>
            </div>
        </div>
    </div>
  );

  const displayQuestion = (questionDetails, question, index) => (
    <div key={index}>
        <div className="row">
            <div className="col-sm-12">{ questionDetails.questionText }</div>
        </div>
        <div className="row">
            <div className="col-md-12 padding-top-half">
                <div className="form-group">
                    <Field name={`${question}.answer`} type="text" component={renderField} validate={(questionDetails.required) ? required : none} />
                </div>
            </div>
      </div>
      <div className="hr-line" />
    </div>
  );

  const questionComment = (questionDetails, question) => (
    <div className="row">
        <div className="col-sm-12 padding-top-half">
            <Field name={`${question}.comment`} type="text" component={renderComment} label={questionDetails.commentText} />
        </div>
    </div>
);

  const displaySelection = (questionDetails, question, index) => {
    if (questionDetails.multiOptions) {
        return (
            <div key={index}>
                <div className="form-group">
                <div className="row">
                    <div className="col-sm-12">
                        { questionDetails.questionText }
                    </div>
                    <div className="col-sm-12 padding-top-half">
                        <Field name={`${question}.answer`} type="text" multi component={renderQuestionDropdownList} options={dropdownData(questionDetails.answers)} labelKey="value" simpleValue={true} validate={(questionDetails.required) ? required : none} />
                    </div>
                </div>
                    { (questionDetails.commentAllowed) && questionComment(questionDetails, question) }
                </div>
                <div className="hr-line" />
            </div>
        );
    } 
    
    return (
        <div key={index}>
            <div className="form-group">
            <div className="row">
                <div className="col-sm-9 padding-top-half">{ questionDetails.questionText }</div>
                <div className="col-sm-3">
                    <Field name={`${question}.answer`} type="text" clearable={(question.required) ? false : true} component={renderQuestionDropdownList} options={dropdownData(questionDetails.answers)} labelKey="value" simpleValue={true} validate={(questionDetails.required) ? required : none} />
                </div>
            </div>
                { (questionDetails.commentAllowed) && questionComment(questionDetails, question) }

            </div>
            <div className="hr-line" />
        </div>
        );
    };

  const displayCheckbox = (questionDetails, question, index) => (
    <div key={index}>
        <div className="form-group">
            <div className="row">
                <div className="col-sm-12"><Field name={question} component={renderCheckbox} validate={(questionDetails.required) ? required : none} />&nbsp; <span>{questionDetails.questionText}</span></div>
            </div>
        </div>
        <div className="hr-line" />
    </div>
  );

const renderForm = ({ fields, questions }) => (
  <div>
          {fields.map((question, index) => {
            switch(questions[index].type) {
        
            case 'Heading':
                return displayHeading(questions[index], index);

            case 'Text':
                return displayText(questions[index], index);

            case 'Question':
                return displayQuestion(questions[index], question, index);

            case 'Selection':
                return displaySelection(questions[index], question, index);

            case 'Checkbox':
                return displayCheckbox(questions[index], question, index);
            }
          }
      )}
  </div>

);

const PreviewForm = (props) => {
    const { onClose, initialValues } = props;

    return (
        <div className="form-padding-20">
            <form>
                <div className="row">
                    <div className="col-sm-12">
                        <FieldArray name="questions" component={renderForm} questions={initialValues.questions} />
                    </div>  
                </div>
                <hr /> 
                <div className="row">
                    <div className="col-sm-12 text-right">
                        <button type="button" className="btn btn-default rounded" onClick={() => onClose()}>Close</button>
                    </div>
                </div>    
            </form>
        </div>
    );
};

PreviewForm.propTypes = {
    ...propTypes
};

renderField.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

renderCheckbox.propTypes = {
    input: PropTypes.object.isRequired,
    name: PropTypes.string
};

renderComment.propTypes = {
    input: PropTypes.object.isRequired,
    name: PropTypes.string
};

renderForm.propTypes = {
    fields: PropTypes.array.isRequired,
    questions: PropTypes.string
};

renderQuestionDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string
};

export default reduxForm({
    form: 'PreviewForm'
})(PreviewForm);