import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import * as actions from '../../actions/invoice';
import { Link } from 'react-router-dom';
import InvoicesettingsForm from './invoice-settings-form';
import UploadImage from './upload-image';
import InvoicePreview from './invoice-preview';

class InvoiceSettingsPage extends Component {

     constructor(props, context) {
        super(props, context);

        this.state = {
            showModal: false,
            showLargeModal: false,
            largeModalTitle: '',
            footerField: this.props.invoiceSettings.invoiceFooter,
            headerField: this.props.invoiceSettings.invoiceHeader
        };

        this.saveInvoiceSetup = this.saveInvoiceSetup.bind(this);
        this.footerField = this.footerField.bind(this);
        this.headerField = this.headerField.bind(this);
        this.open = this.open.bind(this);
        this.openLarge = this.openLarge.bind(this);
        this.close = this.close.bind(this);
    }

    saveInvoiceSetup(inv) {
        const footerField = this.state.footerField;
        const headerField = this.state.headerField;

        if (inv.nextInvoiceNumber < this.props.invoiceSettings.nextInvoiceNumber) {
            alert('Invoice number cannot be less than the current invoice number');
        } else {
            this.props.invoiceSetup(inv, footerField, headerField, this.props.userSetting.schoolId);
        }
    }

    footerField(footerField) {
        this.setState({ footerField });
    }

    headerField(headerField) {
        this.setState({ headerField });
    }

    close() {
        this.setState({ showModal: false, showLargeModal: false });
    }

    open() {
            this.setState({ showModal: true });
        }

    openLarge(largeModalTitle) {
        this.setState({ showLargeModal: true, largeModalTitle });
    }

    render() {

        return(
            <div>
                <div className="pageheader">
                    <Link to="accounts">Invoice List</Link> / Account Settings
                </div>
                <section id="main-content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-11 col-md-10 col-lg-8">
                                <div className="panel panel-default">
                                    <div className="panel-heading">Account Settings</div>
                                    <div className="panel-body">
                                        <InvoicesettingsForm 
                                            onSubmit={this.saveInvoiceSetup} 
                                            initialValues={this.props.invoiceSettings} 
                                            openModal={this.open}
                                            openLargeModal={this.openLarge} 
                                            onFooterField={this.footerField} 
                                            onHeaderField={this.headerField}
                                            pdfInvoice={this.getPdfInvoice}
                                            accounts={this.props.accounts} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
                <Modal show={this.state.showModal} onHide={this.close}>
                    <Modal.Header className="modal-header primary" closeButton>
                        <Modal.Title>{this.state.imageType}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-padding">
                          <UploadImage 
                            width={300} 
                            height={100} 
                            hasImage={this.props.invoiceSettings.hasHeaderImage || false} 
                            header={true}
                            onClose={this.close} />
                        </div>
                        <div className="clearfix" />
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showLargeModal} bsSize="lg" onHide={this.close}>
                    <Modal.Header className="modal-header primary" closeButton>
                        <Modal.Title>{this.state.largeModalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-padding">
                        {(this.state.largeModalTitle === 'Upload Invoice Footer') && 
                          <UploadImage 
                            width={740} 
                            height={200} 
                            hasImage={this.props.invoiceSettings.hasFooterImage || false} 
                            header={false} 
                            onClose={this.close} />}
                        {(this.state.largeModalTitle === 'Invoice Preview') && 
                            <InvoicePreview invDetails={this.props.invoiceSettings} id={this.props.userSetting.schoolId} />}
                        </div>
                        <div className="clearfix" />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

InvoiceSettingsPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  invoiceSettings: PropTypes.object.isRequired,
  accounts: PropTypes.array.isRequired,
  invoiceSetup: PropTypes.func.isRequired
};


function mapStateToProps(state) {
  return {
        userSetting: state.usersetting,
        invoiceSettings: state.school.invoices || {},
        accounts: state.school.selectors.accounts
  };
}

export default connect(mapStateToProps, actions)(InvoiceSettingsPage);