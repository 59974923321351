import React from 'react';
import PropTypes from 'prop-types';

const CommentListRow = (props) => {
     
     if (!props.comments) return;

     return props.comments.map((comment, index) => {
            return (
                
                <div key={index} className={(props.comments.length === index + 1) ? 'media no-border' : 'media'}>
                      <div className="media-body">  
                            <div className="col-xs-10">
                                <h2><strong>{comment.commentBy}</strong></h2>
                                <span className="time-stat">{comment.commentDate}</span>
                                <p className="status-text">{
                                   comment.comment.split("\n").map(function(item) {
                                        return (<span key={item}>{item}<br/></span>);
                                    })}</p>
                            </div>
                            <div className="col-xs-2">
                                    <div className="text-right" onClick={() => props.onDelete(comment._id, index)}><i className="fa fa-times">&nbsp;</i></div>
                            </div>
                             
                           
                            
                            
                    </div>
                </div>

               
            );
     });
};

const CommentList = (props) => {

    return (
        <div id="stream">
            {CommentListRow(props)}
        </div>
    
    );
};

CommentList.propTypes = {
  onDelete: PropTypes.func.isRequired
};

export default CommentList;