import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, FieldArray, reduxForm, formValueSelector, propTypes } from "redux-form";
import DropdownList from "react-select";

const renderDropdownList = ({ input, change, index, meta: { touched, error }, ...rest }) => (
  <div>
    <DropdownList
      {...input}
      {...rest}
      onChange={(value) => {
        change(`invoiceItems[${index}].description`, value.label);
        input.onChange(value.value);
      }}
      onBlur={() => input.change}
    />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderCurrencyField = ({ input, type, meta: { touched, error } }) => (
  <div className='input-group'>
    <span className='input-group-addon'>$</span>
    <input className='form-control' {...input} type={type} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderField = ({ input, type, tabIndex, meta: { touched, error } }) => (
  <div>
    <input className='form-control' {...input} type={type} tabIndex={tabIndex} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderItems = ({ fields, accounts, change }) => (
  <div>
    <table className='table table-bordered'>
      <thead>
        <tr>
          <th width='20%'>Account</th>
          <th width='45%'>Description</th>
          <th width='25%'>Amount</th>
          <th width='10%'>
            <i className='fa fa-trash' aria-hidden='true'></i>
          </th>
        </tr>
      </thead>
      <tbody>
        {fields.map((invoiceItems, index) => {
          return (
            <tr key={index}>
              <td>
                <Field
                  name={`${invoiceItems}.account`}
                  type='text'
                  labelKey='value'
                  clearable={false}
                  component={renderDropdownList}
                  options={accounts}
                  change={change}
                  index={index}
                />
              </td>
              <td>
                <Field name={`${invoiceItems}.description`} type='text' component={renderField} />
              </td>
              <td>
                <Field name={`${invoiceItems}.amount`} type='text' component={renderCurrencyField} />
              </td>
              <td>
                <div className='top-5' onClick={() => fields.remove(index)}>
                  <i className='fa fa-times'>&nbsp;</i>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
    <button type='button' className='btn btn-info btn-xs rounded pull-right' onClick={() => fields.push({})}>
      Add Item
    </button>
  </div>
);

let InvoiceForm = (props) => {
  const { handleSubmit, submitting, accounts, initialValues, change, invAmount, closeModal } = props;

  return (
    <div className='basic-form'>
      <form onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col-xs-8'>To: {initialValues.invoiceTo}</div>
          <div className='col-xs-4 text-right'>Date: {initialValues.invoiceDate}</div>
        </div>
        <div className='row padding-top-half'>
          <div className='col-xs-6'>Total: ${invAmount}</div>
          <div className='col-xs-6 text-right'>Due Date: {initialValues.dueDate}</div>
        </div>

        <div className='row padding-top-one'>
          <div className='col-xs-12'>
            <FieldArray name='invoiceItems' component={renderItems} accounts={accounts} change={change} />
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-12'>
            <button type='submit' className='btn btn-primary btn-sm rounded' disabled={submitting}>
              Save
            </button>
            &nbsp;&nbsp;
            <button type='button' className='btn btn-default btn-sm rounded' onClick={() => closeModal()}>
              Close
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

InvoiceForm.propTypes = {
  ...propTypes,
};

renderItems.propTypes = {
  fields: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

renderCurrencyField.propTypes = {
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  tabIndex: PropTypes.string,
};

renderField.propTypes = {
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  tabIndex: PropTypes.string,
};

InvoiceForm = reduxForm({
  form: "invoiceForm",
})(InvoiceForm);

const selector = formValueSelector("invoiceForm");
InvoiceForm = connect((state) => {
  const invoiceItems = selector(state, "invoiceItems") || [];
  const invAmount = invoiceItems.reduce((totalAmt, item) => totalAmt + (item.amount * 1 || 0), 0).toFixed(2);
  return {
    invAmount,
  };
})(InvoiceForm);

export default InvoiceForm;
