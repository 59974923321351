import axios from 'axios';
import moment from 'moment';
import Notifications from 'react-notification-system-redux';
import { GET_EVENTS, SELECT_EVENT, CURRENT_URL, ROOT_URL } from './types';

export function reload(url, history) {
  history.push('/reload');
  return {
    type: CURRENT_URL,
    payload: url
  };
 }

export function getEvents(id) {

  return function(dispatch) {
    axios.get(`${ROOT_URL}/events/${id}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        const eventList = response.data.map(event => {
          event.eventStart = moment(event.eventStart).toDate();
          event.eventEnd = moment(event.eventEnd).toDate();
          return event;
        });
        dispatch({
          type: GET_EVENTS,
          payload: eventList
        });
      }).catch(error => {
        throw(error);
      });
  };
}

export function selectEvent(event) {
  return {
    type: SELECT_EVENT,
    payload: event
  };
 }

export function addEvent(event, schoolId) {
   return function(dispatch) {
    axios.post(`${ROOT_URL}/event/${schoolId}`,  {event},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
        const eventList = response.data.map(event => {
          event.eventStart = moment(event.eventStart).toDate();
          event.eventEnd = moment(event.eventEnd).toDate();
          return event;
        });
          dispatch( {type: GET_EVENTS, payload: eventList} );
          dispatch(Notifications.success({ title: 'Event Added' }));
     
      }).catch(error => {
        throw(error);
      });
  };
}

export function removeEvent(eventId, option) {
   return function(dispatch) {
    axios.delete(`${ROOT_URL}/event/${eventId}/${option}`,
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
        const eventList = response.data.map(event => {
          event.eventStart = moment(event.eventStart).toDate();
          event.eventEnd = moment(event.eventEnd).toDate();
          return event;
        });
          dispatch( {type: GET_EVENTS, payload: eventList} );
          dispatch(Notifications.error({ title: 'Event Removed' }));
     
      }).catch(error => {
        throw(error);
      });
  };
}

export function updateEvent(event, option) {
   return function(dispatch) {
    axios.put(`${ROOT_URL}/event/${option}`, {event},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {
        const eventList = response.data.map(event => {
          event.eventStart = moment(event.eventStart).toDate();
          event.eventEnd = moment(event.eventEnd).toDate();
          return event;
        });
          dispatch( {type: GET_EVENTS, payload: eventList} );
          dispatch(Notifications.success({ title: 'Event Updated' }));
     
      }).catch(error => {
        throw(error);
      });
  };
}