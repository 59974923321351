import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AvatarEditor from 'react-avatar-editor';
import { Slider, Checkbox } from "@blueprintjs/core";
import * as actions from '../../actions';


class UploadLogo extends Component {

    constructor(props, context) {
        super(props, context);

         this.state = {
            scale: 1,
            rectangle: false,
            fileName: 'Choose image...'
        };

     this.handleNewImage = this.handleNewImage.bind(this);
     this.setEditorRef = this.setEditorRef.bind(this);
     this.changeShape = this.changeShape.bind(this);
     this.handleScale = this.handleScale.bind(this);
     this.onUploadLogo = this.onUploadLogo.bind(this);
    }

    handleNewImage(e) {
      this.setState({ image: e.target.files[0], fileName: e.target.files[0].name });
    }

    changeShape() {
      this.setState({ rectangle: !this.state.rectangle });
    }

     handleScale(scale) {
      this.setState({ scale });
    }

    setEditorRef(editor) {
      if (editor) this.editor = editor;
    }

    onUploadLogo() {
    this.editor.getImageScaledToCanvas()
      .toBlob((blob) => this.props.uploadLog(blob, this.props.school._id));
  }

   
    render() {

        return (
            <div className="col-xs-12 text-center">

              <div className="row padding-top-two">
                <AvatarEditor
                    ref={this.setEditorRef}
                    width={(this.state.rectangle) ? 120 : 60}
                    height={60}
                    scale={this.state.scale}
                    image={this.state.image || '../../styles/img/noImage.jpg'}
                  />
              </div>
              <div className="row padding-top-one">
                <label className="pt-file-upload">
                    <input name="newImage" type="file" onChange={this.handleNewImage} />
                    <span className="pt-file-upload-input">{this.state.fileName}</span>
                  </label>
              </div>
     
              <div className="row padding-top-two">
                  <div className="col-sm-2 text-right">
                      <label>Zoom</label>
                  </div>
                  <div className="col-sm-8">
                      <Slider min={0.5} max={3} stepSize={0.1} labelStepSize={2.5} value={this.state.scale} onChange={this.handleScale} />
                  </div>
              </div>

              <div className="row padding-top-two">
                  <div className="col-sm-2 text-right">
                      <label>Rectangle</label>
                  </div>
                  <div className="col-sm-8 padding-left-10">
                       <label className="pt-control pt-checkbox pt-large">
                          <Checkbox checked={this.state.rectangle} onChange={this.changeShape} />
                       </label>
                  </div>
              </div>

              <div className="col-sm-12 padding-top-two">
                  <button type="button" className="btn btn-primary rounded" onClick={this.onUploadLogo}>Upload Logo</button>
              </div>
            </div>
        );
    }
}

UploadLogo.propTypes = {
  school: PropTypes.object.isRequired,
  updateSchool: PropTypes.func.isRequired,
  uploadLog: PropTypes.func.isRequired
};



function mapStateToProps(state) {

    return {
         school: state.school
    };
}

export default connect(mapStateToProps, actions)(UploadLogo);