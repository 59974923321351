import _ from 'lodash';
import axios from 'axios';
import Notifications from 'react-notification-system-redux';
import {reset} from 'redux-form';
import moment from 'moment';
import fileDownload from 'js-file-download';
import { GET_CONTACTS, GET_CONTACT, UPDATE_CONTACT, UPDATE_SELECTORS,
  CONTACT_COMMENT_ADD, CONTACT_COMMENT_DEL, UPDATE_PROFILE, NO_ACTION,
  UPDATE_FROM_EMAIL, SETTING_SELECT_FROM_EMAIL, ADD_CONTACT, CURRENT_URL,
  TOGGLE_PERMISSION, GET_STUDENTS, SET_EMAIL_CONTACT_LIST, ROOT_URL  } from './types';

export function reload(url, history) {
  history.push('/reload');
  return {
    type: CURRENT_URL,
    payload: url
  };
 }

export function addContact(school, contact, history) {
   return function(dispatch) {
    axios.post(`${ROOT_URL}/contact`, {school, contact},
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        // Get Contact details for the current school
        const newContact = _.pick(response.data.newContact.details, ['email', 'firstName', 'lastName']);
        newContact.Id = response.data.newContact._id;
        
         dispatch( {type: ADD_CONTACT, payload: newContact, contact: response.data.newContact} );
         dispatch( {type: UPDATE_SELECTORS, payload: response.data.school.selectors} );
         
         dispatch(Notifications.success({ title: 'Contact Added' }));
         history.push('/contact-permission/' + newContact.Id);
     
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error, message: error.response.data.message }));
      });
     
  };
}

export function updateContact(contact, contact_id) {
    
   return function(dispatch) {
    axios.put(`${ROOT_URL}/contact/${contact_id}`, {contact},
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {

          // Convert Group Permissions array to an object
          let objGroups = {};
          for (let field in response.data.contactSchool.groupPermissions) {
            objGroups[response.data.contactSchool.groupPermissions[field]] = true; 
          }
          response.data.contactSchool.groupPermissions = objGroups;

          dispatch( {type: UPDATE_CONTACT, payload: response.data.contactSchool} );
          dispatch( {type: UPDATE_SELECTORS, payload: response.data.school.selectors} );
          dispatch(Notifications.success({ title: 'Contact Updated' }));

      }).catch((error) => {
        if (error.response.data.error.includes('duplicate key')) {
          dispatch(Notifications.error({ title: 'Email address already exists' }));
        }
      });
  };
}

export function updatePermission(contact, contactId) {
   return function(dispatch) {
    axios.put(`${ROOT_URL}/contact/permission/${contactId}`, {contact},
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(() => {
         dispatch(Notifications.success({ title: 'Contact permissions updated' }));
         dispatch({ type: GET_CONTACT, payload: contact });
     
      }).catch(() => {
        dispatch(Notifications.error({ title: 'Could not update permissions' }));
      });
  };
}

export function updatePassword(password, closeModal) {
  return function(dispatch) {
   axios.post(`${ROOT_URL}/contact/password`, password,
   { headers: { authorization: localStorage.getItem('token') } 
   })
   .then(() => {
        dispatch(Notifications.success({ title: 'Password updated' }));
        closeModal();
     }).catch(() => {
       dispatch(Notifications.error({ title: 'Could not update password' }));
     });
 };
}

export function getContacts(schoolId) {

  return function(dispatch) {
    axios.get(`${ROOT_URL}/contacts/${schoolId}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        dispatch({
          type: GET_CONTACTS,
          payload: response.data
        });
      });
  };
}

export function getStudents(schoolId) {

  return function(dispatch) {
    axios.get(`${ROOT_URL}/students/${schoolId}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        dispatch({
          type: GET_STUDENTS,
          payload: response.data
        });

      });
  };
}

export function getContact(id, history) {
 return function(dispatch) {
    axios.get(`${ROOT_URL}/contact/${id}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        // Convert Group Permissions array to an object
        let objGroups = {};
        for (let field in response.data.groupPermissions) {
          objGroups[response.data.groupPermissions[field]] = true; 
        }
        response.data.groupPermissions = objGroups;

        dispatch({ type: GET_CONTACT, payload: response.data });
        history.push('/contact');
      });
  };
}

export function addContactComment(comment, contactId) {

   return function(dispatch) {
    axios.post(`${ROOT_URL}/contact/comment/${contactId}`, {comment},
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
         dispatch(reset('commentForm'));
         dispatch( {type: CONTACT_COMMENT_ADD, payload: response.data} );
         dispatch(Notifications.success({ title: 'Comment added to contact' }));
         // browserHistory.push('/');
     
      }).catch(error => {
        throw(error);
      });
     
  };
}


export function removeContactComment(id, commentId, index) {

   return function(dispatch) {
    axios.delete(`${ROOT_URL}/contact/comment/${id}/${commentId}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(() => {

         dispatch( {type: CONTACT_COMMENT_DEL, payload: index} );
         dispatch(Notifications.error({ title: 'Comment removed from contact' }));
         // browserHistory.push('/');
     
      }).catch(error => {
        throw(error);
      });
     
  };
}

export function deleteContact(id, schoolId, history) {
  return function(dispatch) {
    axios.delete(`${ROOT_URL}/contact/${id}/${schoolId}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        dispatch( {type: UPDATE_SELECTORS, payload: response.data.selectors} );
        dispatch(Notifications.warning({ title: 'Contact Deleted' }));
        history.push('contacts');

      })
      .catch((error) => {
        dispatch(Notifications.error({ title: error.response.data.error, message: error.response.data.message }));
      });
  };
}

export function updateProfile(profile) {

  return function(dispatch) {
    
    axios.post(`${ROOT_URL}/contact/profile`, {profile},
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(() => {

        dispatch({
          type: UPDATE_PROFILE,
          payload: profile
        });

         // Show message
         dispatch(Notifications.success({ title: 'Profile Updated' }));

    })
      .catch(() => {
        dispatch(Notifications.error({ title: 'Profile was not updated' }));
      });
  };
}

export function selectedFromEmail(email) {

  return {
    type: SETTING_SELECT_FROM_EMAIL,
    payload: email
  };

 }

export function updateFromEmail(email) {

   return function(dispatch) {
    axios.post(`${ROOT_URL}/contact/profile/emails`, {email},
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {

            dispatch( {type: UPDATE_FROM_EMAIL, payload: response.data} );
     
      }).catch(error => {
        throw(error);
      });
  };
}

export function removeFromEmail(email) {

   return function(dispatch) {
    axios.delete(`${ROOT_URL}/contact/profile/emails/${email._id}`,
    { headers: { authorization: localStorage.getItem('token') } 
    } )
    .then(response => {

       dispatch( {type: UPDATE_FROM_EMAIL, payload: response.data} );
     
      }).catch(error => {
        throw(error);
      });
  };
}

export function emailContactLogin(contactId) {

  return function(dispatch) {
      axios.get(`${ROOT_URL}/auth/new-contact/${contactId}`, )
      .then(() => {
        dispatch(Notifications.success({ title: 'Login details sent' }));
        }).catch(() => {
          dispatch(Notifications.error({ title: 'Unable to send login details' }));
        });
    };
}

export function emailList(emailList) {
    if (!emailList.length) {
      return function(dispatch) {
        dispatch(Notifications.error({ title: 'Select at least one Contact' }));
      };
    } else {
      return { type: SET_EMAIL_CONTACT_LIST, payload: emailList };
    }
}

export function sendPassword(emailAddress, schoolName, schoolId) {
  return function(dispatch) {
      axios.post(`${ROOT_URL}/auth/password`, { emailAddress, schoolName , schoolId })
      .then(() => {
        dispatch(Notifications.success({ title: 'Email sent to contact' }));
        }).catch(() => {
          dispatch(Notifications.error({ title: 'Unable to send paswword email' }));
        });
    };
}

export function exportContacts(exportList, schoolId) {
    axios.post(`${ROOT_URL}/utils/contacts-export/${schoolId}`, {exportList},
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        fileDownload(response.data, `contacts_List-${moment().format('D-MMM-YYYY')}.csv`);
        });

  return {
    type: NO_ACTION,
    payload: ''
  };
  }

export function toggelPermission(value) {
  return { type: TOGGLE_PERMISSION, payload: value };
}
