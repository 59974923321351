import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/tuition';
import ReactDataGrid from 'react-data-grid';
import { Data } from 'react-data-grid-addons';
import EmptyToolbar from '../common/grid-toolbar';

const Selectors = Data.Selectors;

class AddStudentListPage extends Component {
    
     constructor(props) {
        super(props);

        this.state = {
            selectedStudents: [],
            rows: _.map(this.props.students, student => student).filter(student => !this.props.excludeStudents.includes(student._id)),
            filters : {},
            sortColumn: null, 
            sortDirection: null,
        };

        this.rowGetter = this.rowGetter.bind(this);
        this.onRowsSelected = this.onRowsSelected.bind(this);
        this.onRowsDeselected = this.onRowsDeselected.bind(this);
        this.getRows = this.getRows.bind(this);
        this.getSize = this.getSize.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.onClearFilters = this.onClearFilters.bind(this);
        this.handleGridSort = this.handleGridSort.bind(this);
        this.addSelectedStudents = this.addSelectedStudents.bind(this);
    }

    addSelectedStudents() {
        const students = this.state.selectedStudents.map(student => student._id);
        this.props.addSelectedStudents(students, this.props.tGroupId);
    }

    rowGetter(i) {
        const rows = this.getRows();
        return rows[i];
    }

    onRowsSelected(rows) {
        this.setState({selectedStudents: this.state.selectedStudents.concat(rows.map(r => r.row))});
    }

    onRowsDeselected(rows) {
        let ids = rows.map(r => r.row._id);
        this.setState({selectedStudents: this.state.selectedStudents.filter(student => !ids.some(id => id === student._id) )});
    }

    getRows() {
         return Selectors.getRows(this.state);
     }

    getSize() {
        return this.getRows().length;
    }

    handleFilterChange(filter) {
        const newFilters = Object.assign({}, this.state.filters);
        if (filter.filterTerm) {
        newFilters[filter.column.key] = filter;
        } else {
        delete newFilters[filter.column.key];
        }
        this.setState({filters: newFilters});
    }

    onClearFilters() {
        //all filters removed
        this.setState({filters: {} });
    }

     handleGridSort(sortColumn, sortDirection) {
        const state = Object.assign({}, this.state, {sortColumn: sortColumn, sortDirection: sortDirection});
        this.setState(state);
    }

    studentTags(tags) {
        if (tags.value.length === 0) return null;
        return (
            <div>
               {tags.value.map((tag, index) => {
                   return <span key={index} className="label label-info label-padding">{tag}</span>;
                   })}
            </div>
        );
    }
  
    render() {
        const { onCancel, students } = this.props;

        if (!students) {
            return <div>loading...</div>;
        }

        const columns = [
            {
                key: 'firstName',
                name: 'First Name',
                sortable : true,
                filterable : true,
                width: 130
            },
            {
                key: 'lastName',
                name: 'Last Name',
                sortable : true,
                filterable : true,
                width: 130
            },
            {
                key: 'grade',
                name: 'Grade',
                sortable : true,
                filterable : true,
                width: 70
            },
            {
                key: 'groupSummary',
                name: 'Groups',
                sortable : true,
                filterable : true,
            },
            {
                key: 'instruments',
                name: 'Instruments',
                sortable : true,
                filterable : true,
            },
            {
                key: 'studentTags',
                name: 'Tags',
                sortable : true,
                filterable : true,
                formatter: this.studentTags
            }
            ];

        
       
        return (
        <div>
            <div className="text-right">
                <button type="button" className="btn btn-xs btn-primary rounded" onClick={() => this.addSelectedStudents()}>Add Selected Students</button>&nbsp;
                <button type="button" className="btn btn-xs btn-default rounded" onClick={() => onCancel('students')}>&lt;&nbsp; Back</button>
            </div>

            <div className="padding-top-one">
                <ReactDataGrid
                rowKey="_id"
                onGridSort={this.handleGridSort}
                columns={columns}
                rowGetter={this.rowGetter}
                rowsCount={this.getSize()}
                minHeight={380}
                toolbar={<EmptyToolbar />}
                onAddFilter={this.handleFilterChange}
                onClearFilters={this.onClearFilters}
                rowSelection={{
                    showCheckbox: true,
                    enableShiftSelect: true,
                    onRowsSelected: this.onRowsSelected,
                    onRowsDeselected: this.onRowsDeselected,
                    selectBy: {
                        keys: { rowKey: '_id', values: this.state.selectedStudents.map(row => row._id)}
                    }
                }} />
            </div>
        </div>
        );
    }
}

AddStudentListPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  students: PropTypes.object.isRequired,
  excludeStudents: PropTypes.array.isRequired,
  tGroupId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  addSelectedStudents: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return { 
      userSetting: state.usersetting,
      students: state.students.all 
    };
}

export default connect(mapStateToProps, actions)(AddStudentListPage);
