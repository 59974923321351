import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tab, Nav, NavItem, Row, Col } from 'react-bootstrap';
import * as actions from '../../actions/students';
import StudentForm from './student-form';
import StudentContactForm from './student-contact-form';
import CommentForm from '../common/comment-form';
import CommentList from '../common/comment-list';
import StudentDiscountForm from './student-discount-form';
import StudentAccountForm from './student-account-form';
import RegDetails from '../students-registration/new-student-reg-details';
import StudentHistory from './student-history';

class StudentPage extends Component {

    constructor(props, context) {
        super(props, context);
        
        this.saveForm = this.saveForm.bind(this);
        this.removeStudent = this.removeStudent.bind(this);
        this.saveStudentContact = this.saveStudentContact.bind(this);
        this.addComment = this.addComment.bind(this);
        this.removeComment = this.removeComment.bind(this);
        this.hiredInst = this.hiredInst.bind(this);
        this.studentList = this.studentList.bind(this);
        this.removeGroup = this.removeGroup.bind(this);
        this.showContact = this.showContact.bind(this);
        this.updateStudentDiscount = this.updateStudentDiscount.bind(this);
        this.updateStudent = this.updateStudent.bind(this);
    }

  
    componentDidMount() {
        if (!this.props.userSetting.schoolId) {
            this.props.reload(this.props.location.pathname, this.props.history);
        } else {
            this.props.getStudent(this.props.id);
        }
    }    


    removeGroup(index) {

        const studentForm = this.props.studentForm.values;
        const groupName =  this.props.groups.find(group => group._id === studentForm.groups[index].groupId).name;
        
        if (window.confirm(`Confirm removing student from the ${groupName}`)) { 
           const groups = [ ...studentForm.groups.slice(0, index), ...studentForm.groups.slice(index +1)];
           this.props.updateStudent(Object.assign({}, studentForm, { groups }), this.props.match.params.id); 

        } else {
            this.props.reload(this.props.location.pathname, this.props.history);
        }
     }

     saveForm(values) {
          this.props.updateStudent(values, this.props.id);
        }

    removeStudent() {
            this.props.toggleStudentStatus(this.props.id);
            this.props.onClose()
        }

    saveStudentContact(values) {
            this.props.updateStudentContact(values, this.props.id);
        }

    addComment(comment) {
             this.props.addStudentComment(comment, this.props.id);
        }

    removeComment(id, index) {
            this.props.removeStudentComment(id, index, this.props.id);
         }

    hiredInst(instrumentId) {
             this.props.getInstrument(instrumentId, this.props.history);
         }

    showContact(id) {
            this.props.getContact(id, this.props.history);
        }

    studentList() {
           //  this.props.getInstrument(instrumentId);
         }
    
    updateStudentDiscount(value) {
        this.props.updateStudentDiscount(value, this.props.id);
    }

    updateStudent(value) {
        this.props.updateStudent(value, this.props.id);
    }

    render() {

        const { student, groups, selectors, onClose, params } = this.props;

        if (!student) {
            return <div>loading...</div>;
        }

        return (
            <div>
                <div className="panel">
                    <div className="panel-body noPadding">
                    <div className="dataModal">
                      <h5>{student.firstName + ' ' + student.lastName}</h5>
                      <div onClick={onClose}><i className="fa fa-times" /></div>
                    </div>
                       
                        <Tab.Container id="contact-tabs" defaultActiveKey={(params && params.new) ? 'contacts' : 'details'}>
                            <Row className="clearfix">
                                <Col sm={12}>
                                    <Nav className="nav nav-tabs">
                                        <NavItem eventKey="details">
                                            Details
                                        </NavItem>
                                        <NavItem eventKey="contacts">
                                            Contacts
                                        </NavItem>
                                        <NavItem eventKey="comments">
                                            Comments &nbsp;<span className="label badge blueBG">{ (student.noComments > 0) ? student.noComments : ''}</span>
                                        </NavItem>
                                        <NavItem eventKey="account">
                                            Account
                                        </NavItem>
                                        {(student.registration && student.registration.questions.length > 0) && <NavItem eventKey="registration">
                                            Registration
                                        </NavItem>}
                                        <NavItem eventKey="history">
                                            History
                                        </NavItem>
                                    </Nav>

                                    <Tab.Content animation>
                                        <Tab.Pane eventKey="details">
                                            <br />
                                            <StudentForm 
                                                onSubmit={this.saveForm}
                                                initialValues={student}
                                                selectors={selectors}
                                                groupList={groups}
                                                onDelete={this.removeStudent}
                                                hiredInst={this.hiredInst}
                                                userSettings={this.props.userSetting}
                                                removeGroup={this.removeGroup}
                                                showContact={this.showContact}
                                                onClose={onClose} />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="contacts">
                                            <br />
                                            <StudentContactForm onSubmit={this.saveStudentContact} initialValues={student.studentContact} onClose={onClose} />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="comments">
                                            <div className="form-padding">
                                                <br />
                                                <CommentForm onSubmit={this.addComment} />
                                                <hr />
                                                <CommentList onDelete={this.removeComment} comments={student.comments} />
                                            </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="account">
                                            <br />
                                            <StudentDiscountForm onSubmit={this.updateStudentDiscount} initialValues={student.discount} accounts={selectors.accounts}  />
                                            <StudentAccountForm onSubmit={this.updateStudent} initialValues={{ voucher: student.voucher, mediCareName: student.mediCareName }} DOB={student.DOB} />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="registration">
                                            <br />
                                            {(student.registration) && <RegDetails regDetails={student.registration} />}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="history">
                                            <br />
                                            <StudentHistory history={student.groupHistory}  />
                                        </Tab.Pane>
                                    </Tab.Content>
                                    </Col>
                            </Row>
                        </Tab.Container>
                        </div>
                    </div>
                </div>
        );
    }
}

StudentPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  student: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  selectors: PropTypes.object.isRequired,
  updateStudent: PropTypes.func.isRequired,
  toggleStudentStatus: PropTypes.func.isRequired,
  updateStudentContact: PropTypes.func.isRequired,
  addStudentComment: PropTypes.func.isRequired,
  removeStudentComment: PropTypes.func.isRequired,
  getInstrument: PropTypes.func.isRequired,
  getStudent: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  reload: PropTypes.func.isRequired,
  studentForm: PropTypes.object,
  updateStudentDiscount: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
    const id = ownProps.params ? ownProps.params.id : ownProps.id;
    return {
        userSetting: state.usersetting,
        student : state.students.all[id],
        groups: state.school.groups,
        selectors: state.school.selectors,
        studentForm: state.form.studentForm,
        id
    };
}

export default connect(mapStateToProps, actions)(StudentPage);