import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/settings';
import StudentTagForm from './form-student-tags';
import { Modal } from 'react-bootstrap';
import { reset } from 'redux-form';

class StudentTags extends Component {
  
   constructor(props, context) {
        super(props, context);
        this.state = {
            showModal: false
        };

        this.removeTag = this.removeTag.bind(this);
        this.saveTag = this.saveTag.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }

    removeTag(item) {
        {if(window.confirm(`Remove '${item.tag}' from the student tags`)) { this.props.removeStudentTag(item, this.props.userSetting.schoolId); } }
    }

    saveTag(item) {
        this.setState({ showModal: false });
        this.props.addStudentTag(item, this.props.userSetting.schoolId, this.props.history);
        
    }

  close() {
        this.setState({ showModal: false });
    }

  open() {
        reset('studenttags');
        this.setState({ showModal: true });
    }

  renderList() {

    return this.props.studentTags.map((tag, index) => {
      return (
        <li key={index} className="list-group-item">
            <div className="row">
                <div className="col-sm-10 small"><b>{tag.tag}</b></div>
                <div className="col-sm-1" onClick={() => this.removeTag(tag)}><i className="fa fa-times">&nbsp;</i></div>
            </div>
        </li>
      );
    });
  }

  render() {
    return (
    <div className="clearfix">
        <div className="col-md-7 col-lg-6">
            <div className="panel panel-default">
                <div className="panel-heading">Student Tags</div>
                <div className="panel-body">
                    <div className="text-right">
                        <button type="button" onClick={() => this.open()} className="btn btn-primary btn-xs rounded">Add a Tag</button>
                    </div>
            
                    <ul className="list-group">
                        {this.renderList()}
                    </ul>
                </div>
            </div>
        </div>

        <Modal show={this.state.showModal} onHide={this.close}>
            <Modal.Header closeButton>
                <Modal.Title>Student Tags</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <StudentTagForm onSubmit={this.saveTag} />
            </Modal.Body>
           
        </Modal>
    </div>
    );
  }
}

StudentTags.propTypes = {
    userSetting: PropTypes.object.isRequired,
    studentTags: PropTypes.array.isRequired,
    settings: PropTypes.object.isRequired,
    addStudentTag: PropTypes.func.isRequired,
    removeStudentTag: PropTypes.func.isRequired
};

function mapStateToProps(state) {

  return {
        userSetting: state.usersetting,
        studentTags: state.school.selectors.studentTags,
        settings: state.selectLists
  };
}

export default connect(mapStateToProps, actions)(StudentTags);
