import { GET_FORMS,
    GET_CUSTOM_FORMS,
    UPDATE_NEW_REGISTRATION,
    UPDATE_ENROLE_QUESTIONS,
    REMOVE_CUSTOM_FORM,
    UPDATE_STUDENTS_CUSTOM_FORMS } from '../actions/types';
import _ from 'lodash';
import initialState from './initial-state';

export default function(state = initialState.forms, action) {
    switch(action.type) {

         case GET_FORMS:
             return action.form;

        case UPDATE_NEW_REGISTRATION:
             return { ...state, newReg: action.payload };

        case UPDATE_ENROLE_QUESTIONS:
            return { ...state, 
                reEnrolment: { ...state.reEnrolment, 
                    questions: action.payload }
             };

        case GET_CUSTOM_FORMS:
            return { ...state, customForms: _.mapKeys(action.payload, '_id') };

        case REMOVE_CUSTOM_FORM:
            return { ...state, customForms: _.omit(state.customForms, action.payload) };

        case UPDATE_STUDENTS_CUSTOM_FORMS:
             return { ...state, customForms: 
                { ...state.customForms, [action.id]: 
                    { ...state.customForms[action.id], noStudents: action.payload.length, completed: action.payload.filter(item => item.completed).length, students: action.payload } } };

        default:
            return state;
    }
}