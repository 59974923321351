import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/library';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import { required } from '../common/validation';

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
  <div>
      <DropdownList {...input} {...rest} onBlur={() => input.change} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderField = ({ input, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type="text" />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

class SaveTemplateForm extends Component { 

    constructor(props, context) {
        super(props, context);

        this.saveTemplate = this.saveTemplate.bind(this);
    }

    componentDidMount() {
        this.props.getTemplateFolders(this.props.userSetting.schoolId);
    }

    saveTemplate(values) {
        const template = { label: values.label, subject: values.subject, emailBody: values.emailBody };
        this.props.saveTemplate(template, values.folder._id);
        this.props.closeModal();
    }

    render() {
        const { handleSubmit, submitting } = this.props;

        return (
            <div className="basic-form">
                <form onSubmit={handleSubmit(this.saveTemplate)} >
                <div className="form-group">
                        <div className="row">
                        <div className="col-sm-3">
                            <label>Label</label>
                        </div>
                        <div className="col-sm-7">
                        <Field name="label" type="text" component={renderField} validate={required}/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                        <div className="row">
                        <div className="col-sm-3">
                            <label>Subject</label>
                        </div>
                        <div className="col-sm-7">
                        <Field name="subject" type="text" component={renderField} />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                        <div className="row">
                        <div className="col-sm-3">
                            <label>Folder</label>
                        </div>
                        <div className="col-sm-7">
                        <Field name="folder" type="text" valueKey="_id" clearable={false} component={renderDropdownList} options={this.props.folders} validate={[required]} />
                        </div>
                    </div>
                </div>
                    
                <button type="submit" className="btn btn-primary btn-sm rounded" disabled={submitting}>Save</button>&nbsp;&nbsp;
                <button type="button" className="btn btn-default btn-sm rounded" onClick={() => this.props.closeModal()}>Close</button>
            </form>
        </div>
        );
    }
}

SaveTemplateForm.propTypes = {
    ...propTypes,
    folders: PropTypes.array.isRequired,
    userSetting: PropTypes.array.isRequired
};

renderDropdownList.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

renderField.propTypes = {
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};


function mapStateToProps(state) {
    return {
        folders: state.library.folders,
        userSetting: state.usersetting,
    };
  }
  
export default connect(mapStateToProps, actions)(reduxForm({
        form: 'saveTemplate'
    })(SaveTemplateForm));

