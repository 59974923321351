import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/settings';
import ContactCategoriesForm from './form-contact-categories';
import { Modal } from 'react-bootstrap';
import {reset} from 'redux-form';

class ContactCategoriesList extends Component {
  
   constructor(props, context) {
        super(props, context);
        this.state = {
            showModal: false,
            selectedItem: {}
        };

        this.removeItem = this.removeItem.bind(this);
        this.saveList = this.saveList.bind(this);
        this.selectCategory = this.selectCategory.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }

  removeItem(item) {
        if (window.confirm(`Remove '${item.label}' from the contact categories list`)) {
            const action = { type: 'remove', list: 'contactCategories', listName: 'Contact Categories' };
            this.props.updateList(item, action, this.props.userSetting.schoolId);
        }
    }

  saveList(item) {
    const itemOld = this.state.selectedItem;
    const action = { type: 'add', list: 'contactCategories', listName: 'Contact Categories' };

    if (itemOld.label) {
        action.type = 'update';
    }
    this.setState({ showModal: false });
    this.props.updateList(item, action, this.props.userSetting.schoolId, itemOld);
  }

  selectCategory(category) {
      this.setState({ selectedItem: category, showModal: true });
  }

  close() {
        this.setState({ showModal: false });
    }

  open() {
        reset('contactCategoriesForm');
        this.setState({ showModal: true });
    }

  renderList() {

    return this.props.categories.map((category, index) => {
      return (
        <li key={index} className="list-group-item">
            <div className="row">
                <div className="col-sm-10 small"><b>{category.label}</b></div>
                <div className="col-sm-1" onClick={() => this.selectCategory(category)}><i className="fa fa-pencil-square-o">&nbsp;</i></div>
                <div className="col-sm-1" onClick={() => this.removeItem(category)}><i className="fa fa-times">&nbsp;</i></div>
            </div>
        </li>
      );
    });
  }

  render() {
    return (
    <div className="clearfix">
        <div className="col-md-7 col-lg-6">
            <div className="panel panel-default">
                <div className="panel-heading">Contact Categories List</div>
                <div className="panel-body">
                    <div className="text-right">
                        <button type="button" onClick={() => this.selectCategory({})} className="btn btn-primary btn-xs rounded">Add a Category</button>
                    </div>
                    <ul className="list-group">
                        {this.renderList()}
                    </ul>
                </div>
            </div>
        </div>

        <Modal show={this.state.showModal} onHide={this.close}>
            <Modal.Header className="modal-header primary" closeButton>
                <Modal.Title>Contact Categories List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ContactCategoriesForm onSubmit={this.saveList} initialValues={this.state.selectedItem}  />
            </Modal.Body>
           
        </Modal>
    </div>
    );
  }
}

ContactCategoriesList.propTypes = {
    userSetting: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    settings: PropTypes.object.isRequired,
    updateList: PropTypes.func.isRequired
};

function mapStateToProps(state) {

  return {
        userSetting: state.usersetting,
        categories: state.school.selectors.contactCategories,
        settings: state.selectLists
  };
}

export default connect(mapStateToProps, actions)(ContactCategoriesList);
