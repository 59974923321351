import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import { Checkbox } from "@blueprintjs/core";

const renderDropdownList = ({ input, ...rest }) =>
<DropdownList {...input} {...rest} required onBlur={() => input.change} />;

const renderCheckbox = ({ input }) => (
  <label className="pt-control pt-checkbox pt-large">
      <Checkbox checked={input.value} onChange={input.onChange} />
  </label>
);

const ExportRoll = (props) => {
    const { handleSubmit, submitting } = props;

    return (
        <div className="clearfix form-padding">
            <form onSubmit={handleSubmit}>
                <ul className="list-group">
                    <li key="1" className="list-group-item">
                        <div className="row">
                            <div className="col-sm-8">Term</div>
                            <div className="col-sm-4"><Field name="term" type="text" simpleValue={true} clearable={false} component={renderDropdownList} options={[{ value: '1', label: 'Term 1'}, { value: '2', label: 'Term 2'}, { value: '3', label: 'Term 3'}, { value: '4', label: 'Term 4'}]} /></div>
                        </div>
                    </li>
                    <li key="2" className="list-group-item">
                        <div className="row">
                            <div className="col-sm-8">Order By Band Order</div>
                            <div className="col-sm-4"><Field name="bandOrder" component={renderCheckbox} /></div>
                        </div>
                    </li>
                </ul>
                <div className="text-center"><button type="submit" disabled={submitting} className="btn btn-primary">Download Student Roll</button></div>
        </form>
    </div>
    );
};

ExportRoll.propTypes = {
    ...propTypes
};

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired
};


export default reduxForm({
    form: 'exportRoll'
})(ExportRoll);