import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/settings';
import InstrumentForm from './form-instruments';
import { Modal } from 'react-bootstrap';
import {reset} from 'redux-form';

class InstumentList extends Component {
  
   constructor(props, context) {
        super(props, context);
        this.state = {
            showModal: false,
            selectedItem: {}
        };

        this.removeItem = this.removeItem.bind(this);
        this.saveList = this.saveList.bind(this);
        this.selectInstrument = this.selectInstrument.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }

 removeItem(item) {
        if (window.confirm(`Remove '${item.label}' from the instruments list`)) {
            const action = { type: 'remove', list: 'instrumentList', listName: 'Instruments' };
            this.props.updateList(item, action, this.props.userSetting.schoolId);
        }
    }

  saveList(item) {
    const itemOld = this.state.selectedItem;
    const action = { type: 'add', list: 'instrumentList', listName: 'Instruments' };

    if (itemOld.label) {
        action.type = 'update';
    }
    this.setState({ showModal: false });
    this.props.updateList(item, action, this.props.userSetting.schoolId, itemOld);
  }

 selectInstrument(instrument) {
      this.setState({ selectedItem: instrument, showModal: true });
  }

  close() {
        this.setState({ showModal: false });
    }

    open() {
        reset('instrumentList');
        this.setState({ showModal: true });
    }

  renderList() {

    return this.props.instruments.map((instrument, index) => {
      return (
        <li key={index} className="list-group-item">
            <div className="row">
                <div className="col-sm-6 small"><b>{instrument.label}</b></div>
                <div className="col-sm-4 "><span className="small grey-text">Type: {instrument.instrumentType}</span></div>
                <div className="col-sm-1" onClick={() => this.selectInstrument(instrument)}><i className="fa fa-pencil-square-o">&nbsp;</i></div>
                <div className="col-sm-1" onClick={() => this.removeItem(instrument)}><i className="fa fa-times">&nbsp;</i></div>
            </div>
        </li>
      );
    });
  }

  render() {
    return (
    <div className="clearfix">
        <div className="col-md-7 col-lg-6">
            <div className="panel panel-default">
                <div className="panel-heading">Instrument List</div>
                <div className="panel-body">
                    <div className="text-right">
                        <button type="button" onClick={() => this.selectInstrument({})} className="btn btn-primary btn-xs rounded">Add Instrument</button>
                    </div>
            
                    <ul className="list-group">
                        {this.renderList()}
                    </ul>
                </div>
            </div>
        </div>

        <Modal show={this.state.showModal} onHide={this.close}>
            <Modal.Header className="modal-header primary" closeButton>
                <Modal.Title>Instrument List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InstrumentForm onSubmit={this.saveList} initialValues={this.state.selectedItem}  />
            </Modal.Body>
           
        </Modal>
    </div>
    );
  }
}

InstumentList.propTypes = {
    userSetting: PropTypes.object.isRequired,
    instruments: PropTypes.array.isRequired,
    settings: PropTypes.object.isRequired,
    updateList: PropTypes.func.isRequired
};

function mapStateToProps(state) {

  return {
        userSetting: state.usersetting,
        instruments: state.school.selectors.instrumentList,
        settings: state.selectLists
  };
}

export default connect(mapStateToProps, actions)(InstumentList);
