import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { Link } from 'react-router-dom';
//import validate from './validate'

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type={type} placeholder={label}/>
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderTextArea = ({ input }) => <textarea rows="2" className="form-control" {...input} />;

const tableRenderField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
      <input className="form-control space-5" {...input} type={type} placeholder={label}/>
      {touched && error && <h5><span className="label label-warning pull-right">{error}</span></h5>}
    </div>
);


const GroupForm = (props) => {
  const { handleSubmit, submitting, deleteGroup } = props;

  return (
    <div className="basic-form">
        <form onSubmit={handleSubmit}>
            <div className="clearfix">
                <div className="panel panel-default">
                    <div className="panel-body">
                        <div className="col-sm-12">Group Details Parent Portal Group Message</div>
                        <div className="col-sm-12 col-md-5 margin-top-one">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-sm-3 col-md-4">
                                        <label>Group Name</label>
                                    </div>
                                    <div className="col-sm-5 col-md-8">
                                        <Field name="name" type="text" component={renderField} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">   
                                <div className="row">
                                    <div className="col-sm-3 col-md-4">
                                        <label>Short Name</label>
                                    </div>
                                    <div className="col-sm-2 col-md-5">
                                        <Field name="shortName" type="text" component={renderField} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-7 margin-top-one">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-sm-3 col-md-4">
                                        <label>Message Title</label>
                                    </div>
                                    <div className="col-sm-8 col-md-8">
                                        <Field name="groupMessage.messageTitle" type="text" component={renderField} label="Displayed on parent portal for this group" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-sm-3 col-md-4">
                                        <label>Message</label>
                                    </div>
                                    <div className="col-sm-9 col-md-8">
                                        <Field name="groupMessage.message" type="textarea" component={renderTextArea} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-8">
                            <button type="submit" className="btn btn-sm btn-primary rounded" disabled={submitting}>Update</button>&nbsp;&nbsp;
                             <Link to="dashboard" className="btn btn-trans btn-sm rounded btn-default">Cancel</Link>
                        </div>
                        <div className="col-sm-4 text-right">  
                            <button type="button" className="btn btn-danger btn-sm rounded" onClick={deleteGroup}>Delete Group</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
  );
};

GroupForm.propTypes = {
    ...propTypes
};

renderField.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

renderTextArea.propTypes = {
    input: PropTypes.object.isRequired
};

tableRenderField.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

export default reduxForm({
  form: 'groupForm',
})(GroupForm);
