import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';

const options = [{ value: 'true', label: 'Remove all events in this groups'}, { value: 'false', label: 'Remove only this one event'}];

const renderDropdownList = ({ input, ...rest }) =>
  <DropdownList {...input} {...rest} onBlur={() => input.change} />;

const RemoveAllForm = (props) => {
    const { handleSubmit, onClose,  } = props;

    return (
        
        <div>
            <form onSubmit={handleSubmit}>

                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-12">
                            <Field name="removeAll" type="text" clearable={false} simpleValue={true} component={renderDropdownList} options={options} />
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-12">
                        <button type="submit" className="btn btn-success rounded">Remove</button>&nbsp;&nbsp;
                        <button type="button" className="btn btn-default rounded" onClick={() => onClose()}>Cancel</button>
                    </div>       
                </div>
            </form>
        </div>
    );
};

RemoveAllForm.propTypes = {
    ...propTypes,

};

renderDropdownList.propTypes = {
    input: PropTypes.object.isRequired
};



export default reduxForm({
    form: 'eventForm1'
})(RemoveAllForm);