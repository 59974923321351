import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/contacts';
import PermissionForm from './permission-form';
//import { browserHistory } from 'react-router';

class AddPermissionPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      sending: false,
    };

    this.saveForm = this.saveForm.bind(this);
    this.toggle = this.toggle.bind(this);
    this.emailPassword = this.emailPassword.bind(this);
  }

  saveForm(values) {
    this.props.updatePermission(values, this.props.match.params.id);
    // if (values.loginActive) this.props.emailContactLogin(this.props.params.id);
    // browserHistory.push('/contacts');
  }

  emailPassword() {
    this.props.sendPassword(this.props.contact.details.email, this.props.userSetting.schoolName);
    this.setState({ sending: true });
  }

  toggle(value) {
    this.props.toggelPermission(value);
  }

  render() {
    return (
      <div>
        <div className='pageheader'>
          <h2>Contact Access</h2>
        </div>
        <section id='main-content'>
          <div className='row'>
            <div className='col-md-10 col-lg-10'>
              <div className='panel panel-success'>
                <div className='panel-body ng-binding'>
                  <PermissionForm
                    onSubmit={this.saveForm}
                    initialValues={this.props.contact}
                    contact={this.props.contact}
                    groups={this.props.groups}
                    toggle={this.toggle}
                    onEmailPassword={this.emailPassword}
                    sending={this.state.sending}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

AddPermissionPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  updatePermission: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  getContact: PropTypes.func.isRequired,
  toggelPermission: PropTypes.func.isRequired,
  toggelAccess: PropTypes.func.isRequired,
  sendPassword: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    userSetting: state.usersetting,
    contact: state.contacts.contact,
    groups: state.school.groups,
  };
}

export default connect(mapStateToProps, actions)(AddPermissionPage);
