import { GET_DOCUMENTS, GET_TEMPLATES, GET_FOLDERS } from '../actions/types';
import initialState from './initial-state';

export default function(state = initialState.library, action) {
    switch(action.type) {
        
        case GET_DOCUMENTS:
            return { ...state, documents: action.payload, fileList: action.fileList };

        case GET_TEMPLATES:
            return { ...state, templates: action.payload };

        case GET_FOLDERS:
            return { ...state, folders: action.payload };

        default:
            return state;
    }
}