import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/students';
import { Modal } from 'react-bootstrap';
import ReactDataGrid from 'react-data-grid';
import { Data } from 'react-data-grid-addons';
import { Link } from 'react-router-dom';
import Student from './student-page';
import ExportForm from './export-form';
import BulkUpdate from './bulk-update-modal';
import EmptyToolbar from '../common/grid-toolbar';

const Selectors = Data.Selectors;

class StudentsListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedStudents: [],
      rows: _.map(this.props.students, (student) => student),
      filters: {},
      sortColumn: null,
      sortDirection: null,
      showModal: false,
      showStudent: false,
      modalHeading: '',
    };

    this.rowGetter = this.rowGetter.bind(this);
    this.onRowsSelected = this.onRowsSelected.bind(this);
    this.onRowsDeselected = this.onRowsDeselected.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.getRows = this.getRows.bind(this);
    this.getSize = this.getSize.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.onClearFilters = this.onClearFilters.bind(this);
    this.handleGridSort = this.handleGridSort.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeStudent = this.closeStudent.bind(this);
    this.openModal = this.openModal.bind(this);
    this.exportStudents = this.exportStudents.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  componentWillMount() {
    if (!this.props.userSetting.schoolId) {
      this.props.reload(this.props.location.pathname, this.props.history);
    } else {
      this.props.getStudents(this.props.userSetting.schoolId, true, this.props.userSetting.lastUpdate.students);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.students !== this.props.students) {
      this.setState({ rows: _.map(nextProps.students, (student) => student) });
    }
  }

  openModal(modalHeading) {
    this.setState({ showModal: true, modalHeading });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  closeStudent() {
    this.setState({ showStudent: false });
  }

  exportStudents(exportOptions) {
    let exportList = [];
    if (this.state.selectedStudents.length === 0) {
      exportList = this.state.rows.map((row) => row._id);
    } else {
      exportList = this.state.selectedStudents.map((student) => student._id);
    }

    this.props.exportStudents(exportList, exportOptions, this.props.userSetting.schoolId);
    this.closeModal();
  }

  sendEmail() {
    const studetList = this.state.selectedStudents;
    this.props.emailList(studetList);
    if (studetList.length) this.props.history.push('/send-email');
  }

  rowGetter(i) {
    const rows = this.getRows();
    return rows[i];
  }

  onRowsSelected(rows) {
    this.setState({ selectedStudents: this.state.selectedStudents.concat(rows.map((r) => r.row)) });
  }

  onRowsDeselected(rows) {
    let ids = rows.map((r) => r.row._id);
    this.setState({
      selectedStudents: this.state.selectedStudents.filter((student) => !ids.some((id) => id === student._id)),
    });
  }

  onRowClick(rowIdx, row) {
    if (row) {
      this.setState({ showStudent: true, id: row._id });
    }
  }

  getRows() {
    return Selectors.getRows(this.state);
  }

  getSize() {
    return this.getRows().length;
  }

  handleFilterChange(filter) {
    const newFilters = Object.assign({}, this.state.filters);
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
    this.setState({ filters: newFilters });
  }

  onClearFilters() {
    //all filters removed
    this.setState({ filters: {} });
  }

  handleGridSort(sortColumn, sortDirection) {
    const state = Object.assign({}, this.state, { sortColumn: sortColumn, sortDirection: sortDirection });
    this.setState(state);
  }

  hireInstrument(value) {
    return (
      <div>
        <i className={value.value.length ? 'fa fa-check' : ''} aria-hidden='true'></i>
      </div>
    );
  }

  studentTags(tags) {
    if (tags.value.length === 0) return null;
    return (
      <div>
        {tags.value.map((tag, index) => {
          return (
            <span key={index} className='label label-info label-padding'>
              {tag}
            </span>
          );
        })}
      </div>
    );
  }

  render() {
    if (!this.props.students) {
      return <div>loading...</div>;
    }

    const columns = [
      {
        key: 'firstName',
        name: 'First Name',
        sortable: true,
        filterable: true,
        width: 140,
      },
      {
        key: 'lastName',
        name: 'Last Name',
        sortable: true,
        filterable: true,
        width: 150,
      },
      {
        key: 'grade',
        name: 'Grade',
        sortable: true,
        filterable: true,
        width: 70,
      },
      {
        key: 'class',
        name: 'Class',
        sortable: true,
        filterable: true,
        width: 80,
      },
      {
        key: 'groupSummary',
        name: 'Groups',
        sortable: true,
        filterable: true,
      },
      {
        key: 'instruments',
        name: 'Instruments',
        sortable: true,
        filterable: true,
      },
      {
        key: 'hiringInstruments',
        name: 'Hire',
        width: 65,
        sortable: true,
        filterable: true,
        formatter: this.hireInstrument,
        getRowMetaData: (row) => row,
      },
      {
        key: 'studentTags',
        name: 'Tags',
        sortable: true,
        filterable: true,
        formatter: this.studentTags,
      },
      {
        key: '_id',
        width: 18,
      },
    ];

    return (
      <div>
        <div className='pageheader'>
          <h2>All Students</h2>
        </div>
        <section id='main-content'>
          <div className='row'>
            <div className='col-md-12 col-lg-12'>
              <div className='panel panel-default'>
                <div className='panel-body'>
                  <div className='row'>
                    <div className='col-sm-7'>
                      <div className='pt-button-group .modifier'>
                        <div className='pt-button pt-intent-primary pt-active pt-icon-people' role='button'>
                          All
                        </div>
                        <Link to='/new-registrations' className='pt-button pt-intent-primary pt-icon-new-person'>
                          New
                        </Link>
                        <Link to='/enrolments' className='pt-button pt-intent-primary pt-icon-following'>
                          Enrol
                        </Link>
                        <Link to='/re-enrolments' className='pt-button pt-intent-primary pt-icon-following'>
                          Re-Enrol
                        </Link>
                        <Link to='/archive' className='pt-button pt-intent-primary pt-icon-blocked-person'>
                          Archive
                        </Link>
                      </div>
                    </div>
                    <div className='col-sm-5'>
                      <div className='text-right'>
                        <button
                          type='button'
                          className='btn btn-warning btn-trans btn-xs rounded'
                          onClick={() => this.sendEmail()}
                        >
                          Email
                        </button>
                        &nbsp;
                        <Link to='/add-student' className='btn btn-primary btn-trans btn-xs rounded'>
                          Add
                        </Link>
                        &nbsp;
                        <button
                          type='button'
                          className='btn btn-xs btn-info rounded'
                          onClick={() => this.openModal('Bulk Update')}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          type='button'
                          className='btn btn-xs btn-default rounded'
                          onClick={() => this.openModal('Export')}
                        >
                          Export
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='col-xs-12'>
                    <div>
                      <ReactDataGrid
                        rowKey='_id'
                        onGridSort={this.handleGridSort}
                        columns={columns}
                        rowGetter={this.rowGetter}
                        rowsCount={this.getSize()}
                        minHeight={this.getSize() * 35 + 85}
                        onRowClick={this.onRowClick}
                        toolbar={<EmptyToolbar />}
                        onAddFilter={this.handleFilterChange}
                        onClearFilters={this.onClearFilters}
                        rowSelection={{
                          showCheckbox: true,
                          enableShiftSelect: true,
                          onRowsSelected: this.onRowsSelected,
                          onRowsDeselected: this.onRowsDeselected,
                          selectBy: {
                            keys: { rowKey: '_id', values: this.state.selectedStudents.map((row) => row._id) },
                          },
                        }}
                      />
                    </div>
                    <div className='admin-label text-right margin-top-quarter'>
                      <i className='blueBG'>
                        {' '}
                        {this.getSize() === this.state.rows.length
                          ? `${this.state.rows.length} Students`
                          : `Showing ${this.getSize()} of ${this.state.rows.length} Students`}{' '}
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header bsClass='modal-header primary' closeButton>
            <Modal.Title>{this.state.modalHeading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-padding'>
              {this.state.modalHeading === 'Bulk Update' && (
                <BulkUpdate selectedStudents={this.state.selectedStudents} onClose={this.closeModal} />
              )}
              {this.state.modalHeading === 'Export' && <ExportForm onSubmit={this.exportStudents} />}
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.showStudent} dialogClassName='fullSize' onHide={this.closeStudent}>
          <Modal.Body>
            <Student id={this.state.id} onClose={this.closeStudent} history={this.props.history} />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

StudentsListPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  students: PropTypes.object.isRequired,
  getStudent: PropTypes.func.isRequired,
  getStudents: PropTypes.func.isRequired,
  exportStudents: PropTypes.func.isRequired,
  emailList: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    userSetting: state.usersetting,
    students: state.students.all,
  };
}

export default connect(mapStateToProps, actions)(StudentsListPage);
