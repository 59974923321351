import React from "react";
import { Modal } from 'react-bootstrap';
import ReactToPrint from "react-to-print";
import TestTable from './testing-table';



class Example extends React.Component {
  render() {
    return (
      <Modal show={true} bsSize="lg">
      <Modal.Body>
      <div>
        <ReactToPrint
          trigger={() => <a href="#">Print this out!</a>}
          content={() => this.componentRef}
        />
   
        <TestTable ref={el => (this.componentRef = el)} />
 
        
      </div>
      </Modal.Body>
                </Modal>

    );
  }
}

export default Example;
