import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tab, Nav, NavItem, Row, Col, Modal } from 'react-bootstrap';
import * as actions from '../../actions/instruments';
import InstrumentHire from './instrument-hire-page';
import InstrumentDetails from './instrument-details-page';
import CommentForm from '../common/comment-form';
import CommentList from '../common/comment-list';
import ReturnInstrumentForm from './return-instrument-form';
import ServiceForm from './instrument-service-form';
import ServiceHistory from './service-history';
import ServiceCentres from './service-centre-page';
import InstrumentSummary from './instrument-summary';
import ServiceHistoryForm from './service-history-form';
import HireHistoryForm from './hire-history-form';


class InstrumentPage extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            showModal: false,
            modalHeading: ''
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.returnHire = this.returnHire.bind(this);
        this.addComment = this.addComment.bind(this);
        this.removeComment = this.removeComment.bind(this);
        this.saveSerivice = this.saveSerivice.bind(this);
        this.updateServiceHistory = this.updateServiceHistory.bind(this);
        this.updateHireHistory = this.updateHireHistory.bind(this);
    }

    componentDidlMount() {
        if (!this.props.userSetting.schoolId) {
            this.props.reload(this.props.location.pathname, this.props.history);
        }
        this.props.getInstrument(this.props.id);
     }

        openModal(modalHeading) {
            this.setState({ showModal: true, modalHeading });
        }

        closeModal() {
            this.setState({ showModal: false, modalHeading: '' });
        }

        addComment(comment) {
             this.props.addInstrumentComment(comment, this.props.instrument._id);
        }

        removeComment(id, index) {
            this.props.removeInstrumentComment(id, index);
         }

        returnHire(values) {
            this.props.returnHire(values, this.props.instrument._id, this.props.onClose);
            this.closeModal();
        }

        saveSerivice(values) {
            if (this.props.instrument.inService) {
                this.props.returnFromSerivice(values, this.props.instrument._id, this.props.onClose);
            } else {
                this.props.sendForSerivice(values, this.props.instrument._id, this.props.onClose);
            }
        }

        updateServiceHistory(values) {
            this.props.updateSerivceHistory(values, this.props.instrument._id);
            this.closeModal();
        }

        updateHireHistory(values) {
            this.props.updateHireHistory(values, this.props.instrument._id);
            this.closeModal();
        }


    render() {

        const { instrument, onClose, openTab } = this.props;

        if (!instrument) {
      
            return <div>loading...</div>;
        }

        return (
            <div>
                <section id="main-content">
                        <Tab.Container id="contact-tabs" defaultActiveKey={ openTab || 'details'}>
                            <Row className="clearfix">
                                <Col sm={12}>
                                    <Nav className="nav nav-tabs">
                                        <NavItem eventKey="details">
                                            Details
                                        </NavItem>
                                        <NavItem eventKey="hire">
                                            Hire
                                        </NavItem>
                                        <NavItem eventKey="service">
                                            Service
                                        </NavItem>
                                        <NavItem eventKey="comments">
                                            Comments &nbsp;<span className="label badge blueBG">{ (instrument.noComments > 0) ? instrument.noComments : ''}</span>
                                        </NavItem>
                                    </Nav>

                                    <Tab.Content animation>
                                        <Tab.Pane eventKey="details">
                                            <br />
                                            <InstrumentDetails onClose={onClose} />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="hire">
                                            <br />
                                            <InstrumentHire openModal={this.openModal} onClose={onClose} />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="service">
                                            <br />
                                            <InstrumentSummary instrument={instrument} />
                                            <hr />
                                            <ServiceForm 
                                                onSubmit={this.saveSerivice}
                                                serviceCentres={this.props.serviceCentres} 
                                                inService={instrument.inService}
                                                initialValues={instrument.serviceDetails}
                                                openServiceCentres={this.openModal}
                                                    />

                                            <ServiceHistory serviceHistory={instrument.serviceHistory} openModal={this.openModal} />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="comments">
                                            <div className="form-padding">
                                                <br />
                                                <InstrumentSummary instrument={instrument} />
                                                <hr />
                                                <CommentForm onSubmit={this.addComment} />
                                                <hr />
                                                <CommentList onDelete={this.removeComment} comments={instrument.comments} />
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                    </Col>
                            </Row>
                        </Tab.Container>
                    </section>
                    <Modal show={this.state.showModal} onHide={this.closeModal} bsSize={(this.state.modalHeading === 'Update History' || this.state.modalHeading === 'Update Hire History') ? 'lg' : 'md'} >
                        <Modal.Header className="modal-header primary" closeButton>
                            <Modal.Title>{this.state.modalHeading}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-padding">
                                {(this.state.modalHeading === 'Return Instrument') && <ReturnInstrumentForm onSubmit={this.returnHire} onClose={this.closeModal} />}
                                {(this.state.modalHeading === 'Service Centres') && <ServiceCentres closeModal={this.closeModal} />}
                                {(this.state.modalHeading === 'Update History') && <ServiceHistoryForm closeModal={this.closeModal} onSubmit={this.updateServiceHistory} initialValues={instrument} />}
                                {(this.state.modalHeading === 'Update Hire History') && <HireHistoryForm closeModal={this.closeModal} onSubmit={this.updateHireHistory} initialValues={instrument} />}
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
        );
    }
}

InstrumentPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  instrument: PropTypes.object.isRequired,
  serviceCentres: PropTypes.array.isRequired,
  updateInstrument: PropTypes.func.isRequired,
  instrumentHire: PropTypes.func.isRequired,
  addInstrumentComment: PropTypes.func.isRequired,
  removeInstrumentComment: PropTypes.func.isRequired,
  checkReserved: PropTypes.func.isRequired,
  returnHire: PropTypes.func.isRequired,
  sendForSerivice: PropTypes.func.isRequired,
  returnFromSerivice: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
  updateSerivceHistory: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        userSetting: state.usersetting,
        instrument : state.instruments.selectedInstrument,
        serviceCentres: state.school.selectors.serviceCentres
    };
}

export default connect(mapStateToProps, actions)(InstrumentPage);