import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import FormEditor from '../editor/FormEditor';

const renderEditor = ({ input, onEmail }) => (
  <FormEditor
    value={input.value}
    handleOnChange={input.onChange}
    variables={['[Parent_First]', '[Parent_Last]', '[Student_First]', '[Student_Last]', '[Roster_Date]']}
    onBlur={onEmail(input.value)}
  />
);

const renderField = ({ input, meta: { touched, error } }) => (
  <div>
    <div className='margin-top-half'>
      <input className='form-control' {...input} type='text' />
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);

const RosterEmailForm = (props) => {
  const { handleSubmit, submitting, onEmail, closeModal } = props;

  return (
    <div className='basic-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-2'>
              <label className='padding-top-half'>Subject</label>
            </div>
            <div className='col-sm-10'>
              <Field name='emailSubject' type='text' component={renderField} />
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-12'>
              <Field name='emailText' type='textarea' onEmail={onEmail} component={renderEditor} />
            </div>
            <div className='clearfix'>&nbsp;</div>
          </div>
        </div>
        <button type='submit' className='btn btn-primary rounded' disabled={submitting}>
          Save
        </button>
        &nbsp;&nbsp;
        <button type='button' className='btn btn-default rounded' onClick={() => closeModal()}>
          Close
        </button>
      </form>
    </div>
  );
};

RosterEmailForm.propTypes = {
  ...propTypes,
};

renderEditor.propTypes = {
  input: PropTypes.object.isRequired,
  onEmail: PropTypes.func.isRequired,
};

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'rosterEmailForm',
})(RosterEmailForm);
