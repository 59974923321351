import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/';
import {Link } from 'react-router-dom';
import { DropdownButton, MenuItem, Modal } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import SupportForm from './support-form';

class Header extends Component {
   
    constructor(props, context) {
    super(props, context);

    this.state = {
        showModal: false
    };

    this.newRegistration = this.newRegistration.bind(this);
    this.newStudents = this.newStudents.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.studentSearchText = this.studentSearchText.bind(this);
    this.studentSearch = this.studentSearch.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.saveSupportTicket = this.saveSupportTicket.bind(this);
    this.changeSchool = this.changeSchool.bind(this);
  }

    toggleMenu() {
        this.props.toggleMenu(this.props.menu);
    }
  
    newRegistration() {
        this.props.history.push('/new-registrations');
    }

    studentSearch() {
        this.props.history.push('/student-search');
    }

    studentSearchText(event) {
        const searchText = event.target.value.toLowerCase();
        this.props.searchText(searchText);
    }

    changeSchool(schoolId) {
        this.props.changeSchool(schoolId, this.props.history);
    }

    saveSupportTicket(values) {
        this.props.logSupportTicket(values, this.props.userSetting);
        setTimeout(() =>{ this.closeModal(); }, 1500);
    }

    openModal(modal) {
        this.setState({ showModal: true, modalHeading: modal });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    newStudents(students) {
         if (students > 0) {
            return (
                <li>
                    <button className="sidebar-toggle" id="toggle-left" onClick={this.newRegistration}>
                        <i className="fa fa-user-plus"><span className="redBG">{students}</span></i>
                    </button>
                </li>
                );
            }
     }

     renderSchoolList(schoolList) {
        return schoolList.filter(item => item.schoolId !== this.props.userSetting.schoolId).map((school, index) => <MenuItem key={index} eventKey={school.schoolId} onSelect={(value) => this.changeSchool(value)}>{school.schoolName}</MenuItem>);
     }

    render() {
        const { alerts, userSetting, userSetting: { profile, schoolList } } = this.props;
        const usernName = `${profile.firstName} ${profile.lastName}`;
        if (!alerts) return <div>Loading...</div>;

        return(
            <header id="header">
                <div className="brand">
                    <Link to="/" className="logo">
                        <img src="https://admin.msmusic.com.au/msm_logo.png" />
                    </Link>
                </div>
                <ul className="nav navbar-nav navbar-left">
                    
                    <li className="toggle-navigation toggle-left">
                        <button className="sidebar-toggle" id="toggle-left" onClick={this.toggleMenu}>
                            <i className="fa fa-bars">&nbsp;</i>
                        </button>
                    </li>

                     {(userSetting.fullAdmin || userSetting.menuPermissions.students)  && this.newStudents(alerts.newStudents)}

                    {(userSetting.fullAdmin || userSetting.groupPermissions.length > 0) && <li className="hidden-xs">
                        <input type="text" className="search" onClick={this.studentSearch} onChange={this.studentSearchText} placeholder="Find a Parent / Student..."/>
                        <button type="submit" className="btn btn-sm btn-search rounded">
                            <i className="fa fa-search">&nbsp;</i>
                        </button>
                    </li>}
                </ul>
                

                <ul className="nav navbar-nav pull-right"> 
                    <li>
                        <button className="sidebar-toggle btn btn-xs btn-primary" id="toggle-left" onClick={this.openModal}>
                            <span className="label label-info">Need Help</span>
                        </button>
                    </li>
                    <li>
                        <DropdownButton bsStyle="default" title={<i className="fa fa-user">&nbsp; {usernName}</i>} noCaret pullRight id="toggle-profile">
                            <LinkContainer to="/profile"><MenuItem eventKey="1">Profile</MenuItem></LinkContainer>
                            {schoolList && schoolList.length > 1 && <MenuItem divider />}
                            {schoolList && schoolList.length > 1 && this.renderSchoolList(schoolList)}
                            {schoolList && schoolList.length > 1 && <MenuItem divider />}
                            <LinkContainer to="/signout"><MenuItem eventKey="2">Sign Out</MenuItem></LinkContainer>
                        </DropdownButton>
                    </li>

                </ul>

                <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <Modal.Header bsClass="modal-header primary" closeButton>
                        <Modal.Title>Support</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-xs-12">
                            <ul className="list-group">
                                <li className="list-group-item" key={1}>
                                    <a className="help-link" href="http://help.msmusic.com.au" target="_blank">
                                        <strong>View our Getting Started and other Help Guides </strong>
                                        <div className="pull-right">
                                            <i className="fa fa-chevron-right"></i>
                                        </div>
                                    </a>
                                </li> 
                            </ul>
                        </div>
                        
                        <div className="col-xs-12 text-center padding-top-one"><p className="text-muted">Can't find what you are looking for in our <a href="http://help.msmusic.com.au" target="_blank">help guide</a>, ask us.</p></div>
                        <div className="form-padding">
                            <SupportForm onSubmit={this.saveSupportTicket} onClose={this.closeModal} />
                        </div>
                        
                    </Modal.Body>
                </Modal>
            </header>
        );
    }
}

Header.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  menu: PropTypes.bool,
  alerts: PropTypes.object.isRequired,
  userSetting: PropTypes.object.isRequired,
  searchText: PropTypes.func.isRequired,
  logSupportTicket: PropTypes.func.isRequired,
  changeSchool: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        menu: state.usersetting.menu,
        alerts: state.school.alerts,
        students: state.students.all,
        userSetting: state.usersetting
    };
}

export default connect(mapStateToProps, actions)(Header);