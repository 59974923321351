import React from 'react';

import '../../scripts/redactor.css';
import '../../scripts/rPlugins.min.js';

class FormEditor1 extends React.Component {
  componentDidMount() {
    const { value, variables, handleOnChange } = this.props;
    const plugins = ['table', 'alignment', 'fontfamily', 'fontsize', 'fontcolor', 'fullscreen'];
    if (variables) plugins.push('variable');

    this.rEditor = new window.$R('#editor1', {
      styles: true,
      plugins,
      variables,
      callbacks: {
        blur: () => {
          const source = this.rEditor.source.getCode();
          const content = source
            .replace(/<span data-redactor-type="variable">/g, '')
            .replace(/]<\/span>/g, ']')
            .replace(/&nbsp;/g, ' ');
          handleOnChange(content);
        },
      },
    });
    if (value) {
      this.rEditor.source.setCode(value);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.rEditor.source.setCode(nextProps.value);
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return <div id='editor1' ref='rEditor' />;
  }
}

export default FormEditor1;
