import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import { required } from '../common/validation';
import { Tag, Checkbox } from '@blueprintjs/core';
import EmailEditor from '../editor/EmailEditor';

const renderEditor = ({ input, onEmailBody, editorText, contacts }) => (
  <EmailEditor
    value={input.value}
    editorText={editorText}
    handleOnChange={input.onChange}
    onBlur={onEmailBody(input.value)}
    contacts={contacts}
  />
);

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
  <div>
    <DropdownList {...input} {...rest} onChange={input.onChange} onBlur={() => input.onBlur(input.value)} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderField = ({ input, label, meta: { touched, error } }) => (
  <div>
    <input className='form-control' {...input} type='text' placeholder={label} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderCheckbox = ({ input, label }) => (
  <Checkbox checked={input.value} className='pt-control pt-checkbox pt-large checkbox-text' onChange={input.onChange}>
    {label}
  </Checkbox>
);

const renderAttachmentList = (list, onRemoveAttachment) => {
  return list.map((attachment, index) => {
    return (
      <Tag className='pt-intent-primary mr5' key={attachment._id} onRemove={() => onRemoveAttachment(index)}>
        {attachment.label}
      </Tag>
    );
  });
};

const renderAttachments = (attachments, onRemoveAttachment) => (
  <div className='form-group'>
    <div className='row'>
      <div className='col-sm-2'>
        <label>Attachments</label>
      </div>
      <div className='col-sm-10'>{renderAttachmentList(attachments, onRemoveAttachment)}</div>
    </div>
  </div>
);

const renderBCC = (contactList) => (
  <div className='form-group'>
    <div className='row'>
      <div className='col-sm-2'>
        <label>Bcc</label>
      </div>
      <div className='col-sm-10'>
        <Field
          name='bccList'
          type='text'
          component={renderDropdownList}
          simpleValue={true}
          multi={true}
          options={contactList.map((contact) => ({
            value: contact.email,
            label: `${contact.firstName} ${contact.lastName}`,
          }))}
        />
      </div>
    </div>
  </div>
);

const renderButtons = (openPreview, openModal, onClose, showClose, emailStatus) => (
  <span>
    <button type='button' className='btn btn-sm btn-success rounded' onClick={() => openPreview()}>
      Preview
    </button>
    &nbsp;
    {emailStatus !== 'Send Email' && (
      <button type='button' className='btn btn-sm btn-info rounded' onClick={() => openModal('Save as Template')}>
        Save
      </button>
    )}
    &nbsp;&nbsp;
    {showClose && (
      <button type='button' className='btn btn-sm btn-default rounded' onClick={() => onClose()}>
        Close
      </button>
    )}
  </span>
);

const EmailForm = (props) => {
  const {
    handleSubmit,
    fromEmails,
    attachments,
    onRemoveAttachment,
    onEmailBody,
    openModal,
    emailNumber,
    contacts,
    emailStatus,
    contactList,
    openPreview,
    onClose,
    showClose,
  } = props;

  return (
    <div className='basic-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-2'>
              <label>From EmailS</label>
            </div>
            <div className='col-sm-5'>
              <Field
                name='fromEmail'
                type='text'
                labelKey='fromEmailAddress'
                clearable={false}
                component={renderDropdownList}
                options={fromEmails}
                validate={required}
              />
            </div>
            <div className='col-sm-5 text-right'>
              <a onClick={() => openModal('Attachments')}>
                <i className='fa fa-paperclip' aria-hidden='true'></i> Attachments
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a onClick={() => openModal('Templates')}>
                <i className='fa fa-file-text-o' aria-hidden='true'></i> Templates
              </a>
            </div>
          </div>
        </div>
        {!contacts && renderBCC(contactList)}
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-2'>
              <label>Subject</label>
            </div>
            <div className='col-sm-10'>
              <Field name='subject' type='text' component={renderField} label='Subject' validate={required} />
            </div>
          </div>
        </div>
        {attachments.length ? renderAttachments(attachments, onRemoveAttachment) : ''}
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-12'>
              <Field
                name='emailBody'
                type='textarea'
                onEmailBody={onEmailBody}
                component={renderEditor}
                contacts={contacts}
              />
              <div className='admin-label margin-top-quarter'>
                <i className='blueBG'>{emailNumber} Emails will be sent</i>
              </div>
            </div>
            <div className='clearfix'>&nbsp;</div>
          </div>
        </div>
        <button type='submit' className='btn btn-sm btn-warning rounded' disabled={emailStatus !== 'Send Email'}>
          {emailStatus}
        </button>
        &nbsp;&nbsp;
        {!contacts && renderButtons(openPreview, openModal, onClose, showClose, emailStatus)}
      </form>
    </div>
  );
};

EmailForm.propTypes = {
  ...propTypes,
};

renderField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
};

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
};

renderEditor.propTypes = {
  input: PropTypes.object.isRequired,
  onEmailBody: PropTypes.func.isRequired,
  contacts: PropTypes.bool,
};

export default reduxForm({
  form: 'emailForm',
  enableReinitialize: true,
})(EmailForm);
