import axios from 'axios';
import fileDownload from 'js-file-download';
import moment from 'moment';
import Notifications from 'react-notification-system-redux';
import { GET_TUITION, ADD_TUITION, UPDATE_TUITION, NO_ACTION, ROOT_URL } from './types';

export function getTuitionGroups(id) {
  return function(dispatch) {
    axios.get(`${ROOT_URL}/tuition/${id}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        dispatch({ type: GET_TUITION, payload: response.data });
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function addTGroup(group, idx = 0) {
  return function(dispatch) {
   axios.post(`${ROOT_URL}/tuition`, group,
   { headers: { authorization: localStorage.getItem('token') } 
   } )
   .then((response) => {
           dispatch(Notifications.success({ title: (group._id) ? 'Group Updated' : 'Group Added' }));
           dispatch({ type: (group._id) ? UPDATE_TUITION : ADD_TUITION, payload: response.data, idx });
     }).catch(error => {
       dispatch(Notifications.error({ title: error.response.data.error }));
     });
 };
}

export function removeTuitionGroup(id) {
  return function(dispatch) {
    axios.delete(`${ROOT_URL}/tuition/${id}`,
    { headers: { authorization: localStorage.getItem('token') } 
    })
    .then(response => {
        dispatch(Notifications.success({ title: 'Group Deleted' }));
        dispatch({ type: GET_TUITION, payload: response.data });
      }).catch(error => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function addSelectedStudents(students, id) {
  return function(dispatch) {
   axios.post(`${ROOT_URL}/tuition-student/${id}`, students,
   { headers: { authorization: localStorage.getItem('token') } 
   } )
   .then((response) => {
           dispatch(Notifications.success({ title: 'Students Added' }));
           dispatch({ type: GET_TUITION, payload: response.data });
     }).catch(error => {
       dispatch(Notifications.error({ title: error.response.data.error }));
     });
 };
}

export function removeSelectedStudents(students, id) {
  return function(dispatch) {
   axios.put(`${ROOT_URL}/tuition-student/${id}`, students,
   { headers: { authorization: localStorage.getItem('token') } 
   } )
   .then((response) => {
           dispatch(Notifications.success({ title: 'Students Removed' }));
           dispatch({ type: GET_TUITION, payload: response.data });
     }).catch(error => {
       dispatch(Notifications.error({ title: error.response.data.error }));
     });
 };
}

export function exportTGroups(exportList) {
  axios.post(`${ROOT_URL}/tuition-export`, exportList,
  { headers: { authorization: localStorage.getItem('token') } 
  })
  .then(response => {
      fileDownload(response.data, `Tuition_Export-${moment().format('D-MMM-YYYY')}.csv`);
      });

return {
  type: NO_ACTION,
  payload: ''
};
}

export function saveTEvent(event, idx) {
  return function(dispatch) {
   axios.post(`${ROOT_URL}/tuition-event`, event,
   { headers: { authorization: localStorage.getItem('token') } 
   } )
   .then((response) => {
           dispatch(Notifications.success({ title: 'Event Added' }));
           dispatch({ type: UPDATE_TUITION, payload: response.data, idx });
     }).catch(error => {
       dispatch(Notifications.error({ title: error.response.data.error }));
     });
 };
}

export function removeTEvent(id, eventId, idx) {
  return function(dispatch) {
   axios.delete(`${ROOT_URL}/tuition-event/${id}/${eventId}`, 
   { headers: { authorization: localStorage.getItem('token') } 
   } )
   .then((response) => {
           dispatch(Notifications.success({ title: 'Event Deleted' }));
           dispatch({ type: UPDATE_TUITION, payload: response.data, idx });
     }).catch(error => {
       dispatch(Notifications.error({ title: error.response.data.error }));
     });
 };
}

export function removeWeek(id, week) {
  return function(dispatch) {
   axios.delete(`${ROOT_URL}/tuition-week/${id}/${week}`,
   { headers: { authorization: localStorage.getItem('token') } 
   } )
   .then((response) => {
           dispatch({ type: GET_TUITION, payload: response.data });
     }).catch(error => {
       dispatch(Notifications.error({ title: error.response.data.error }));
     });
 };
}

export function cloneWeek(id, week, cloneValues) {
  return function(dispatch) {
   axios.post(`${ROOT_URL}/tuition-week/${id}/${week}`, cloneValues,
   { headers: { authorization: localStorage.getItem('token') } 
   } )
   .then((response) => {
           dispatch({ type: GET_TUITION, payload: response.data });
     }).catch(error => {
       dispatch(Notifications.error({ title: error.response.data.error }));
     });
 };
}

export function displayWarning(message) {
  return Notifications.error({ title: message });
}

