import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import ToggleButton from 'react-toggle-button';
import DropdownList from 'react-select';

const borderRadiusStyle = { borderRadius: 2 };
const renderToggleButton = ({ input }) => (
    <ToggleButton
        value={input.value || false}
        onToggle={(value) => input.onChange(!value)}
        thumbStyle={borderRadiusStyle}
        trackStyle={borderRadiusStyle}
    />
);

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
    <div>
        <DropdownList {...input} {...rest} onBlur={() => input.change} />
        { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
  );


const ExportInvoiceForm = (props) => {
    const { handleSubmit, submitting } = props;

    return (
        <div className="clearfix form-padding">
            <form onSubmit={handleSubmit}>
                <div className="admin-widgets">
                    <div className="detail-list">
                        <ul className="clearfix">
                            <li className="pro-name">
                                <label className="col-sm-3 text-right padding-top-half">Format</label>
                                <div className="col-sm-8"><Field name="exportFormat" type="text" simpleValue={true} valueKey="label" tabIndex="3" clearable={false} component={renderDropdownList} options={[{ label: 'Excel'}, { label: 'Myob'}, { label: 'Myob-Payments'}, { label: 'Xero'}]} /></div>
                            </li>
                        </ul>
                    </div>
                </div>
                <br />
                <div className="text-center"><button type="submit" disabled={submitting} className="btn btn-primary">Download Invoice List</button></div>
        </form>
    </div>
    );
};

ExportInvoiceForm.propTypes = {
    ...propTypes
};

renderToggleButton.propTypes = {
    input: PropTypes.object.isRequired
};

renderDropdownList.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
  };


export default reduxForm({
    form: 'exportInvoiceForm'
})(ExportInvoiceForm);