import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import Select from 'react-select';

const formTemplates = [
  { value: 'blank', label: 'Blank Form' },
  { value: 'bandCamp', label: 'Band Camp Form' },
  { value: 'workshop', label: 'Band Workshop Permission Form' },
  { value: 'permission', label: 'Performance Permission Form' },
  { value: 'medical', label: 'Medical Form' }
];

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type={type} placeholder={label}/>
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderDropdownList = ({ input, ...rest }) =>
<Select {...input} {...rest} onBlur={() => input.change} />;

const AddCustomForm = ({ handleSubmit, submitting, onClose, forms }) => {
    const formTypes = formTemplates.concat(_.map(forms, item => ({ value: item._id, label: ` Copy - ${item.formTitle}` })));

    return (
        <div className="basic-form">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label>Title</label>
                  </div>
                  <div className="col-sm-10">
                    <Field name="formTitle" type="text" component={renderField} label="Form Title"/>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label>Type</label>
                  </div>
                  <div className="col-sm-10">
                    <Field name="formType" type="text" simpleValue={true} clearable={false} component={renderDropdownList} options={formTypes} />
                  </div>
                </div>
              </div>
              <hr />
            <button type="submit" className="btn btn-primary rounded" disabled={submitting}>Add</button>&nbsp;
            <button type="button" className="btn btn-default rounded" onClick={() => onClose()}>Close</button>
        </form>
    </div>
    );
};

AddCustomForm.propTypes = {
    ...propTypes
};

renderField.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'AddCustomForm'
})(AddCustomForm);