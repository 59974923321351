import _ from 'lodash';
import axios from 'axios';
import {reset} from 'redux-form';
import Notifications from 'react-notification-system-redux';
import moment from 'moment';
import { ROOT_URL, SIGN_IN, SET_SCHOOL, GET_STUDENTS, LASTUPDATE_STUDENT,
  GET_INSTRUMENTS, GET_SCHOOL, PWD_TOKEN } from './types';

export function signinUser(signin, history) {

  return function(dispatch) {
    axios.post(`${ROOT_URL}/auth/signin`, signin)
    .then(response => {
      // Update State
       dispatch( { type: SET_SCHOOL, payload: response.data.school } );
       dispatch( { type: SIGN_IN, remember: (signin.remember) ? true : false, profile: response.data.profile, menu: response.data.menu, schoolList: response.data.schoolList });
      
      // Save token
      localStorage.setItem('token', response.data.token);      
      
       // Direct to admin page or Tutors
       if (response.data.school.fullAdmin || response.data.school.menuPermissions.dashboard) {
        history.push('/dashboard'); 
       } else {
        history.push('/tuition-list'); 
       } 
     
      }).catch((error) => {
        //console.log(' from here : ', error.response.status)
        if (!error.response) {
          dispatch(Notifications.error({ title: 'Connect Error', message: 'Please check your Internet connection / firewall', autoDismiss: 0, action: {
            label: 'See more details here',
            callback: () => window.open('http://help.msmusic.com.au/faq/connection-error')
          } }));
         } else if (error.response.status === 401) {
           dispatch(Notifications.error({ title: 'Login Error', message:  'Invalid email or password' }));
         } else {
          dispatch(Notifications.error({ title: 'System Error', message:  'Contact support@msmusic.com.au' }));
         }
      });
     
  };
}

export function tokenSignin(history) {
  return function(dispatch) {
    axios.get(`${ROOT_URL}/auth/signin`,
    { headers: { authorization: localStorage.getItem('token') } 
    }).then(response => {

      // Update State
       dispatch( {type: SET_SCHOOL, payload: response.data.school } );
       dispatch( {type: SIGN_IN, remember: true, profile: response.data.profile, menu: response.data.menu, schoolList: response.data.schoolList } );
   
       // Direct to admin page or Tutors
       if (response.data.school.fullAdmin || response.data.school.menuPermissions.dashboard) {
        history.push('/dashboard'); 
       } else {
        history.push('/tuition-list'); 
       }
      }).catch((err) => {
         localStorage.removeItem('token');
         dispatch(Notifications.error({ title: 'Login Error', message: 'Unable to login contact administrator' }));
      });
    
  };
}

export function signoutUser(settings) {
  if (settings.signin && !settings.remember) localStorage.removeItem('token');
  return { type: 'USER_LOGOUT' };
}

export function resetPassword(email) {
  return function(dispatch) {
      axios.post(`${ROOT_URL}/auth/password`, email)
      .then(() => {
          dispatch(Notifications.success({ title: 'Reset password link sent' }));
          dispatch(reset('resetPassword'));

        }).catch((error) => {
          dispatch(Notifications.error({ title: (error.response) ? error.response.data.message : 'Unable to find your account details' }));
        });
    };
}

export function checkPwdtoken(token) {
  return function(dispatch) {
      axios.get(`${ROOT_URL}/auth/password/${token}`)
      .then(() => {
        
        // token Valid
        dispatch( {type: PWD_TOKEN, payload: true} );

        // token Not Valid
        }).catch(() => {
          dispatch( {type: PWD_TOKEN, payload: false} );
          dispatch(Notifications.error({ title: 'Password link is invalid or has expired', message: 'Request a new link through the \'Forgot Password\' Option', autoDismiss: 10 }));
        });
    };
}

export function updatePassword(pwd, token, history) {
  return function(dispatch) {
      axios.put(`${ROOT_URL}/auth/password/${token}`, {pwd})
      .then(response => {
        // Save token
        localStorage.setItem('token', response.data.token);   

        return axios.get(`${ROOT_URL}/auth/signin`,
        { headers: { authorization: localStorage.getItem('token') } });
      }).then(response => { 
        const userDtails = _.pick(response.data.school, ['schoolName', 'schoolId', 'menuPermissions', 'groupPermissions', 'fullAdmin']);
      
        // Update State
        dispatch( {type: SET_SCHOOL, payload: userDtails } );
        dispatch( {type: SIGN_IN, remember: true, profile: response.data.profile} );  
        
        // Direct to admin page
        history.push('/dashboard');  
        
      }).catch(() => {
          dispatch( {type: PWD_TOKEN, payload: false} );
          dispatch(Notifications.error({ title: 'Password link is invalid or has expired', message: 'Request a new link through the \'Forgot Password\' Option', autoDismiss: 10 }));
      });
    };
}

export function reload(token, url, history) {

return function(dispatch) {

    axios.get(`${ROOT_URL}/auth/signin`,
    { headers: { authorization: token } 
    }).then(response => {
      const school = _.pick(response.data.school, ['schoolName', 'schoolId', 'menuPermissions', 'groupPermissions', 'fullAdmin']);
      const id = school.schoolId;

      // Update State
       dispatch( {type: SET_SCHOOL, payload: response.data.school } );
       dispatch( {type: SIGN_IN, remember: true, profile: response.data.profile} );

       // Get School Details
      axios.get(`${ROOT_URL}/school/${id}`,
        { headers: { authorization: token } })
      .then(response => {
          dispatch({ type: GET_SCHOOL, school: response.data });

          // Get Students
          return axios.post(`${ROOT_URL}/students/${id}/true`, {lastUpdate: null},
            { headers: { authorization: token } 
            });
      }).then(response => {
            dispatch({ type: GET_STUDENTS, payload: response.data });
            dispatch({ type: LASTUPDATE_STUDENT, payload: moment().toDate() });
          
          // Get Instruments
         return axios.get(`${ROOT_URL}/instruments/${id}/true`,
            { headers: { authorization: token } });
        }).then(response => {
                dispatch({ type: GET_INSTRUMENTS, payload: response.data });
                history.push(url); 
        
        }).catch(() => {
         localStorage.removeItem('token');
         dispatch(Notifications.error({ title: 'Reload Error', message: 'Unable to reload the page please signin again' }));
         history.push('/signin'); 
    });
      }).catch(() => {
         localStorage.removeItem('token');
         dispatch(Notifications.error({ title: 'Login Error', message: 'Unable to login contact administrator' }));
         history.push('/signin');
      });
    
  };
}