import React from 'react';
import PropTypes from 'prop-types';

const InstumentSummary = (props) => (
  <div className="row">
    <div className="col-sm-4">
      <label>Instrument: <span className="lightGrey">{props.instrument.instrument}</span></label>
    </div>
    <div className="col-sm-4">
      <label>Serial No: <span className="lightGrey">{props.instrument.serialNo}</span></label>
    </div>
    <div className="col-sm-4">
      <label>School No: <span className="lightGrey">{props.instrument.schoolNo}</span></label>
    </div>
  </div>
);

InstumentSummary.propTypes = {
  instrument: PropTypes.object.isRequired
}

export default InstumentSummary;