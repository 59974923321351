import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import { Data, Filters } from 'react-data-grid-addons';
import EmptyToolbar from '../common/grid-toolbar';

const Selectors = Data.Selectors;
const { SingleSelectFilter } = Filters;

class SelectStudents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedStudents: [],
      rows: _.map(this.props.students, (student) => ({
        ...student,
        hiringInstruments: !!student.hiringInstruments.length,
      })).filter((student) => !this.props.excludeStudents.includes(student._id)),
      filters: {},
      sortColumn: null,
      sortDirection: null,
    };

    this.rowGetter = this.rowGetter.bind(this);
    this.onRowsSelected = this.onRowsSelected.bind(this);
    this.onRowsDeselected = this.onRowsDeselected.bind(this);
    this.getRows = this.getRows.bind(this);
    this.getSize = this.getSize.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.onClearFilters = this.onClearFilters.bind(this);
    this.handleGridSort = this.handleGridSort.bind(this);
  }

  rowGetter(i) {
    const rows = this.getRows();
    return rows[i];
  }

  onRowsSelected(rows) {
    this.setState({ selectedStudents: this.state.selectedStudents.concat(rows.map((r) => r.row)) });
  }

  onRowsDeselected(rows) {
    let ids = rows.map((r) => r.row._id);
    this.setState({
      selectedStudents: this.state.selectedStudents.filter((student) => !ids.some((id) => id === student._id)),
    });
  }

  getRows() {
    return Selectors.getRows(this.state);
  }

  getSize() {
    return this.getRows().length;
  }

  handleFilterChange(filter) {
    const newFilters = Object.assign({}, this.state.filters);
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
    this.setState({ filters: newFilters });
  }

  onClearFilters() {
    //all filters removed
    this.setState({ filters: {} });
  }

  handleGridSort(sortColumn, sortDirection) {
    const state = Object.assign({}, this.state, { sortColumn: sortColumn, sortDirection: sortDirection });
    this.setState(state);
  }

  hireInstrument(value) {
    return (
      <div>
        <i className={value.value ? 'fa fa-check' : ''} aria-hidden='true'></i>
      </div>
    );
  }

  studentTags(tags) {
    if (tags.value.length === 0) return null;
    return (
      <div>
        {tags.value.map((tag, index) => {
          return (
            <span key={index} className='label label-info label-padding'>
              {tag}
            </span>
          );
        })}
      </div>
    );
  }

  getValidFilterValues() {
    return [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ];
  }

  render() {
    const { onClose, onAdd } = this.props;
    const columns = [
      {
        key: 'firstName',
        name: 'First Name',
        sortable: true,
        filterable: true,
        width: 130,
      },
      {
        key: 'lastName',
        name: 'Last Name',
        sortable: true,
        filterable: true,
        width: 140,
      },
      {
        key: 'grade',
        name: 'Grade',
        sortable: true,
        filterable: true,
        width: 70,
      },
      {
        key: 'groupSummary',
        name: 'Groups',
        sortable: true,
        filterable: true,
      },
      {
        key: 'hiringInstruments',
        name: 'Hire',
        width: 70,
        sortable: true,
        filterable: true,
        filterRenderer: SingleSelectFilter,
        formatter: this.hireInstrument,
        getRowMetaData: (row) => row,
      },
      {
        key: 'instruments',
        name: 'Instruments',
        sortable: true,
        filterable: true,
      },
      {
        key: 'studentTags',
        name: 'Tags',
        sortable: true,
        filterable: true,
        formatter: this.studentTags,
      },
      {
        width: 18,
      },
    ];

    return (
      <div>
        <div className='row'>
          <div className='col-md-12 text-right'>
            <button
              type='button'
              className='btn btn-sm btn-primary rounded'
              onClick={() => onAdd(this.state.selectedStudents)}
            >
              Add
            </button>
            &nbsp;
            <button type='button' className='btn btn-sm btn-default rounded' onClick={() => onClose()}>
              Close
            </button>
          </div>
        </div>
        <hr />
        <div className='row'>
          <div className='col-md-11'>
            <ReactDataGrid
              rowKey='_id'
              onGridSort={this.handleGridSort}
              columns={columns}
              rowGetter={this.rowGetter}
              rowsCount={this.getSize()}
              maxHeight='500'
              toolbar={<EmptyToolbar />}
              onAddFilter={this.handleFilterChange}
              onClearFilters={this.onClearFilters}
              getValidFilterValues={this.getValidFilterValues}
              rowSelection={{
                showCheckbox: true,
                enableShiftSelect: true,
                onRowsSelected: this.onRowsSelected,
                onRowsDeselected: this.onRowsDeselected,
                selectBy: {
                  keys: { rowKey: '_id', values: this.state.selectedStudents.map((row) => row._id) },
                },
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

SelectStudents.propTypes = {
  students: PropTypes.object.isRequired,
  excludeStudents: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    students: state.students.all,
  };
}

export default connect(mapStateToProps)(SelectStudents);
