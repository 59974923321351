import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Field, FieldArray, reduxForm, propTypes } from 'redux-form';

const renderField = ({ input, type, tabIndex, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type={type} tabIndex={tabIndex} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderDatePicker = ({ input: { onChange, value }}) =>
<DatePicker 
    className="form-control"
    selected={value ? moment(value) : moment()}
    dateFormat="D MMM YYYY"
    onChange={onChange}
/>;

const renderHistory = ({ fields }) => (
  <div>
      <table className="table table-bordered">
      <thead>
      <tr>
          <th width="24%">Student</th>
          <th width="15%">Hired From</th>
          <th width="15%">Hired To</th>
          <th width="11%">Amount</th>
          <th width="11%">Deposit</th>
          <th>Comment</th>
          <th width="10px"><i className="fa fa-trash" aria-hidden="true"></i></th>
      </tr>
      </thead>
      <tbody>
          {fields.map((records, index) => (
              <tr key={index}>
                <td><Field name={`${records}.hiredBy`} type="text" component={renderField} /></td>
                <td><Field name={`${records}.dateHired`} type="text" component={renderDatePicker} /></td>
                <td><Field name={`${records}.dateReturned`} type="text" component={renderDatePicker} /></td>
                <td><Field name={`${records}.hirePrice`} type="text" component={renderField} /></td>
                <td><Field name={`${records}.deposit`} type="text" component={renderField} /></td>
                <td><Field name={`${records}.comment`} type="text" component={renderField} /></td>
                <td><div className="top-5" onClick={() => fields.remove(index)}><i className="fa fa-times">&nbsp;</i></div></td>
              </tr>))}
      </tbody>
      </table>
      <div className="margin-top-one">
         <button type="button" className="btn btn-success btn-xs rounded pull-right" onClick={() => fields.push({})}>Add</button>
      </div>
     
  </div>

);

const HireHistoryForm = (props) => {
    const { handleSubmit, submitting, closeModal } = props;
    return (
        <div className="basic-form">
          <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group">
                    <div className="col-md-12">
                        <FieldArray name="hireHistory" component={renderHistory} />
                    </div>
                </div>
            </div> 
            <hr />
            <div className="row">
                <div className="col-xs-10">
                    <button type="submit" className="btn btn-primary btn-sm rounded" disabled={submitting}>Save</button>&nbsp;&nbsp;
                    <button type="button" className="btn btn-sm btn-default rounded" onClick={() => closeModal()}>Cancel</button>
                </div>
            </div>
        </form>
    </div>
    );
};

HireHistoryForm.propTypes = {
    ...propTypes
};

renderField.propTypes = {
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    tabIndex: PropTypes.string
};

export default reduxForm({
    form: 'hireHistoryForm'
})(HireHistoryForm);