import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import DropdownList from 'react-select';
import moment from 'moment';
import { required, number } from '../common/validation';
import momentLocaliser from 'react-widgets/lib/localizers/moment';
import 'react-widgets/dist/css/react-widgets.css';

momentLocaliser(moment);

const repeatNumbers = [];
let item = {};
for (let i = 1; i < 31; i++) { 
    item = { value: i, label: i };
    repeatNumbers.push(item);
}

 const selectColour = [
    { value: '#0066ff', label: 'Blue'}, 
    { value: '#996633', label: 'Brown'},
    { value: '#737373', label: 'Grey'}, 
    { value: '#008000', label: 'Green'},
    { value: '#00b8e6', label: 'Light Blue'},
    { value: '#ff0080', label: 'Pink'},
    { value: '#e60000', label: 'Red'},
    { value: '#ff6600', label: 'Orange'},
    { value: '#ac00e6', label: 'Purple'},
    { value: '#ffcc00', label: 'Yellow'}];  

const renderField = ({ input, label, meta: { touched, error } }) => (
  <div className="row">
    <div className="col-sm-3">
        <input className="form-control" {...input} type="text" placeholder={label} />
    </div>
    { touched && (error && <div className="admin-label margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderDateTimePicker = ({ input: { onChange, value }, showTime, meta: { touched, error } }) => (
  <div>
    <DateTimePicker
        onChange={onChange}
        format="D MMM YYYY (ddd) - h:mm a"
        time={showTime}
        value={value ? new Date(value) : new Date()} />
    { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
  <div>
      <DropdownList {...input} {...rest} onBlur={() => input.change} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);
const renderTGroupList = ({ input, groupValues, meta: { touched, error }, ...rest }) => (
  <div>
    <DropdownList {...input} {...rest} onChange={(option) => groupValues(option)} onBlur={() => input.change} />
    { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>

);

const newEvent = (onClose) => (
    <div className="row">
        <div className="col-sm-12">
            <button type="submit" className="btn btn-success rounded">Save</button>&nbsp;&nbsp;
            <button type="button" className="btn btn-default rounded" onClick={() => onClose()}>Cancel</button>
        </div>       
    </div>
);

const editEvent = (removeEvent, onClose) => (
    <div className="row">
        <div className="col-sm-8">
            <button type="submit" className="btn btn-success rounded">Save</button>&nbsp;&nbsp;
            <button type="button" className="btn btn-default rounded" onClick={() => onClose()}>Cancel</button>
        </div>
        <div className="col-sm-4 text-right">
            <button type="button" className="btn btn-danger rounded" onClick={() => removeEvent()}>Remove Event</button>
        </div>      
    </div>
);

const repeatOptions = () => (
    <div className="form-group">
        <div className="row">
            <div className="col-sm-3">
                <label>Recurring</label>
            </div>
            <div className="col-sm-4">
                <Field name="frequency" type="text" simpleValue={true} component={renderDropdownList} options={[{ value: 1, label: 'Daily'}, { value: 7, label: 'Weekly'}, { value: 14, label: 'Fortnightly'}]} />
            </div>
            <div className="col-sm-3">
                <Field name="repeat" type="text" simpleValue={true} component={renderDropdownList} options={repeatNumbers} />
            </div>
        </div>
    </div>
);

const TuitionEventForm = (props) => {
    const { handleSubmit, removeEvent, onClose, tGroups, selectors, initialValues, groupValues } = props;

    return (
        <div className="basic-form">
            <form onSubmit={handleSubmit}>

                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Tuition Group</label>
                        </div>
                        <div className="col-sm-8">
                        <Field name="tGroup" type="text" component={renderTGroupList} options={tGroups} valueKey="_id" simpleValue={true} groupValues={groupValues} validate={required} />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Start</label>
                        </div>
                        <div className="col-sm-7">
                            <Field name="startDate" type="text" component={renderDateTimePicker} validate={required} />
                        </div>
                       
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Duration</label>
                        </div>
                        <div className="col-sm-9">
                          <Field name="duration" type="text" component={renderField} validate={[required, number]} />
                        </div>
                       
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Venue</label>
                        </div>
                        <div className="col-sm-8">
                        <Field name="eventVenue" type="text" simpleValue={true} component={renderDropdownList} valueKey="label" options={selectors.venues}  />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Colour</label>
                        </div>
                        <div className="col-sm-4">
                            <Field name="eventColour" type="text" simpleValue={true} component={renderDropdownList} options={selectColour} validate={required} />
                        </div>
                    </div>
                </div>

                {(initialValues.isNew) && repeatOptions()}

                <hr />
                {(initialValues.isNew) ? newEvent(onClose) : editEvent(removeEvent, onClose) }
            </form>
        </div>
    );
};

TuitionEventForm.propTypes = {
    ...propTypes,

};

renderField.propTypes = {
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    meta: PropTypes.object.isRequired
};

renderDropdownList.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

renderTGroupList.propTypes = {
  input: PropTypes.object.isRequired,
  groupValues: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired
};

renderDateTimePicker.propTypes = {
    input: PropTypes.object.isRequired,
    showTime: PropTypes.bool,
    meta: PropTypes.object.isRequired
};


export default reduxForm({
    form: 'tuitionEventForm',
    enableReinitialize: true
})(TuitionEventForm);