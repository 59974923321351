import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/settings';
import AccountsForm from './form-accounts';
import { Modal } from 'react-bootstrap';
import {reset} from 'redux-form';

class AccountsList extends Component {
  
   constructor(props, context) {
        super(props, context);
        this.state = {
            showModal: false,
            selectedItem: {}
        };

        this.removeItem = this.removeItem.bind(this);
        this.saveList = this.saveList.bind(this);
        this.selectAccount = this.selectAccount.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }

 removeItem(item) {
        if (window.confirm(`Remove '${item.label}' from the accounts list`)) {
            const action = { type: 'remove', list: 'accounts', listName: 'Accounts' };
            this.props.updateList(item, action, this.props.userSetting.schoolId);
        }
    }

  saveList(item) {
    const itemOld = this.state.selectedItem;
    const action = { type: 'add', list: 'accounts', listName: 'Accounts' };

    if (itemOld.label) {
        action.type = 'update';
    }
    this.setState({ showModal: false });
    this.props.updateList(item, action, this.props.userSetting.schoolId, itemOld);
  }

 selectAccount(account) {
      this.setState({ selectedItem: account, showModal: true });
  }

  close() {
        this.setState({ showModal: false });
    }

    open() {
        reset('accountsList');
        this.setState({ showModal: true });
    }

  renderList() {

    return this.props.accounts.map((account, index) => {
      return (
        <li key={index} className="list-group-item">
            <div className="row">
                <div className="col-sm-3 small"><b>{account.value}</b></div>
                <div className="col-sm-7 "><span className="small grey-text">Description: {account.label}</span></div>
                <div className="col-sm-1" onClick={() => this.selectAccount(account)}><i className="fa fa-pencil-square-o">&nbsp;</i></div>
                <div className="col-sm-1" onClick={() => this.removeItem(account)}><i className="fa fa-times">&nbsp;</i></div>
            </div>
        </li>
      );
    });
  }

  render() {
    return (
    <div className="clearfix">
        <div className="col-md-7 col-lg-6">
            <div className="panel panel-default">
                <div className="panel-heading">Accounts List</div>
                <div className="panel-body">
                    <div className="text-right">
                        <button type="button" onClick={() => this.selectAccount({})} className="btn btn-primary btn-xs rounded">Add</button>
                    </div>
            
                    <ul className="list-group">
                        {(this.props.accounts) && this.renderList()}
                    </ul>
                </div>
            </div>
        </div>

        <Modal show={this.state.showModal} onHide={this.close}>
            <Modal.Header className="modal-header primary" closeButton>
                <Modal.Title>Accounts List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AccountsForm onSubmit={this.saveList} initialValues={this.state.selectedItem}  />
            </Modal.Body>
           
        </Modal>
    </div>
    );
  }
}

AccountsList.propTypes = {
    userSetting: PropTypes.object.isRequired,
    accounts: PropTypes.array.isRequired,
    settings: PropTypes.object.isRequired,
    updateList: PropTypes.func.isRequired
};

function mapStateToProps(state) {

  return {
        userSetting: state.usersetting,
        accounts: state.school.selectors.accounts,
        settings: state.selectLists
  };
}

export default connect(mapStateToProps, actions)(AccountsList);
