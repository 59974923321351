import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/groups';
import GroupForm from './group-form';

class AddGroupPage extends Component {

    constructor(props, context) {
        super(props, context);
      
        this.saveForm = this.saveForm.bind(this);
    }

    saveForm(values) {
        this.props.addGroup(values, this.props.school._id, this.props.history);
        }

    render() {

         const { selectors } = this.props;

        return (
            <div>
                <div className="pageheader"><h2>Add a Group</h2></div>
                <section id="main-content">
                    <div className="row">
                        <div className="col-md-10 col-lg-8">
                            <div className="panel panel-success">
                                <div className="panel-body ng-binding">
                                        <GroupForm onSubmit={this.saveForm} selectors={selectors} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

AddGroupPage.propTypes = {
    selectors: PropTypes.object.isRequired,
    school: PropTypes.object.isRequired,
    addGroup: PropTypes.func.isRequired
};


function mapStateToProps(state) {
    return {
        selectors: state.school.selectors,
        school: state.school
    };
}

export default connect(mapStateToProps, actions)(AddGroupPage);