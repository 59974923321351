import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import { required, number } from '../common/validation';

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
  <div>
      <DropdownList {...input} {...rest} onBlur={() => input.change} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderField = ({ input, label, type, tabIndex, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type={type} tabIndex={tabIndex} placeholder={label}/>
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const StudentDiscountForm = (props) => {
    const { handleSubmit, submitting, accounts } = props;

    return (
      <div className="panel panel-default">
        <div className="panel-body">
            <h4>Student Discount</h4>
            <div className="basic-form">
                <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <div className="row">
                        <div className="col-xs-12 col-sm-5">
                            <div className="col-sm-4">
                                <label>Type</label>
                            </div>
                            <div className="col-sm-8">
                                <Field name="discountType" type="text" simpleValue={true} tabIndex="2" clearable={false} component={renderDropdownList} options={[{ value: true, label: '%'}, { value: false, label: '$'}]} />
                            </div> 
                        </div>
                        <div className="col-xs-12 col-sm-7">
                          <div className="col-sm-4">
                                  <label>Apply to item</label>
                              </div>
                              <div className="col-sm-8">
                                  <Field name="account" type="text" simpleValue={true} tabIndex="1" clearable={false} component={renderDropdownList} options={accounts} />
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                      <div className="col-xs-12 col-sm-5">
                            <div className="col-sm-4">
                                <label>Value</label>
                            </div>
                            <div className="col-sm-8">
                                <Field name="value" type="text" component={renderField} tabIndex="3" validate={[required, number]} />
                            </div> 
                        </div>
                        <div className="col-xs-12 col-sm-7">
                            <div className="col-sm-4">
                                <label>Description</label>
                            </div>
                            <div className="col-sm-8">
                            <Field name="description" type="text" component={renderField} tabIndex="4" validate={required} />
                            </div>
                        </div>
                    </div>
                </div>
                <button type="submit" disabled={submitting} className="btn btn-primary">Save</button>
            </form>
        </div>
      </div>
    </div>
    );
};

StudentDiscountForm.propTypes = {
    ...propTypes
};

renderField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  tabIndex: PropTypes.string
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};


export default reduxForm({
    form: 'studentDiscountForm'
})(StudentDiscountForm);