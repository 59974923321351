import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import { Checkbox } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import moment from 'moment';
import { required, email } from '../common/validation';

const dropdownData = (data) => {
  let newArry = data.map(function (obj) {
    return { groupId: obj._id, name: obj.name, shortName: obj.shortName };
  });

  return newArry;
};

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
  <div>
    <DropdownList {...input} {...rest} onBlur={() => input.change} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderDropdownTutor = ({ input, showContact, meta: { touched, error }, ...rest }) => (
  <div className='tutorBox'>
    <DropdownList {...input} {...rest} onBlur={() => input.change} />
    {input.value && (
      <a onClick={() => showContact(input.value)}>
        <i className='fa fa-link'></i>
      </a>
    )}
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderCheckbox = ({ input }) => (
  <label className='pt-control pt-checkbox pt-large'>
    <Checkbox checked={input.value} onChange={input.onChange} label='Photo Consent' />
  </label>
);

const renderField = ({ input, label, type, tabIndex, meta: { touched, error } }) => (
  <div>
    <input className='form-control' {...input} type={type} tabIndex={tabIndex} placeholder={label} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderDatePicker = ({ input, max, min, meta: { touched, error } }) => (
  <div>
    <DateInput
      value={input.value}
      onChange={input.onChange}
      format={'DD MMM YYYY'}
      minDate={moment().add(min, 'years').toDate()}
      maxDate={moment().add(max, 'years').toDate()}
    />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const viewInstrument = (hiredInst, instruments) => {
  return instruments.map((instrument, index) => {
    return (
      <span key={index}>
        {index > 0 ? ', ' : ''}
        <a onClick={() => hiredInst(instrument.id)}>{instrument.name}</a>
      </span>
    );
  });
};

const formType = (initialValues, submitting, onDelete, onClose) => {
  if (!initialValues)
    return (
      <div>
        <div className='col-xs-12 col-sm-12'>
          <div className='form-group'>
            <div className='col-sm-2'>
              <label>Contact Email</label>
            </div>
            <div className='col-sm-8'>
              <Field
                name='studentContact.contact1.email'
                type='text'
                component={renderField}
                label='Contact Email'
                validate={[required, email]}
              />
            </div>
          </div>
        </div>
        <br />
        <br />

        <div className='col-xs-12 col-sm-12'>
          <button type='submit' className='btn btn-primary' disabled={submitting}>
            {initialValues ? 'Save' : 'Add'}
          </button>
          &nbsp;&nbsp;
          <button type='button' className='btn btn-trans rounded' onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    );

  return (
    <div className='row'>
      <div className='col-xs-6 col-sm-6'>
        <button type='submit' className='btn btn-primary rounded' disabled={submitting}>
          {initialValues ? 'Save' : 'Add'}
        </button>
        &nbsp;&nbsp;
        <button type='button' className='btn btn-trans rounded' onClick={onClose}>
          Close
        </button>
      </div>
      <div className='col-xs-6 col-sm-6 text-right'>
        <button
          type='button'
          className={initialValues.active ? 'btn btn-danger rounded' : 'btn btn-success rounded'}
          onClick={() => onDelete()}
        >
          {initialValues.active ? 'Archive' : 'Restore'}
        </button>
      </div>
    </div>
  );
};

const hireAndCreatedRow = (hiredInst, initialValues) => (
  <div>
    <div className='col-sm-7'>
      <label>
        Hiring:{' '}
        <span className='small'>
          {initialValues.hiringInstruments.length > 0
            ? viewInstrument(hiredInst, initialValues.hiringInstruments)
            : 'Not hiring an instrument'}
        </span>
      </label>
    </div>
    <div className='col-sm-5'>
      <label>Date Add: {moment(initialValues.createDate).format('DD MMM YY')}</label>
    </div>
  </div>
);

const renderGroups = ({ fields, selectorsValues, selectGroups, permissions, removeGroup, showContact }) => (
  <div>
    <table className='table table-bordered'>
      <thead>
        <tr>
          <th width='28%'>Group</th>
          <th width='28%'>Instrument</th>
          <th width='25%'>Tutor</th>
          <th width='19%'>Status</th>
          <th width='10px'>
            <i className='fa fa-trash' aria-hidden='true'></i>
          </th>
        </tr>
      </thead>
      <tbody>
        {fields.map((groups, index) => {
          if (
            permissions.fullAdmin ||
            (permissions.groupPermissions &&
              permissions.groupPermissions.some((id) => id === fields.get(index).groupId || !fields.get(index).groupId))
          )
            return (
              <tr key={index}>
                <td>
                  <Field
                    name={`${groups}.groupId`}
                    type='text'
                    valueKey='groupId'
                    labelKey='name'
                    simpleValue={true}
                    clearable={false}
                    component={renderDropdownList}
                    options={dropdownData(selectGroups)}
                  />
                </td>
                <td>
                  <Field
                    name={`${groups}.instrument`}
                    type='text'
                    valueKey='label'
                    simpleValue={true}
                    clearable={false}
                    component={renderDropdownList}
                    options={selectorsValues.instrumentList}
                  />
                </td>
                <td>
                  <Field
                    name={`${groups}.tutor`}
                    type='text'
                    valueKey='_id'
                    labelKey='name'
                    simpleValue={true}
                    clearable={false}
                    component={renderDropdownTutor}
                    options={selectorsValues.tutors}
                    showContact={showContact}
                  />
                </td>
                <td>
                  <Field
                    name={`${groups}.status`}
                    type='text'
                    valueKey='label'
                    simpleValue={true}
                    clearable={false}
                    component={renderDropdownList}
                    options={selectorsValues.studentStatus}
                  />
                </td>
                <td>
                  <div
                    className='top-5'
                    onClick={() => {
                      fields.remove(index);
                      removeGroup(index);
                    }}
                  >
                    <i className='fa fa-times'>&nbsp;</i>
                  </div>
                </td>
              </tr>
            );
        })}
      </tbody>
    </table>
    <button type='button' className='btn btn-success btn-xs rounded pull-right' onClick={() => fields.push({})}>
      Add to a Group
    </button>
  </div>
);

const StudentForm = (props) => {
  const {
    handleSubmit,
    submitting,
    initialValues,
    selectors,
    groupList,
    onDelete,
    hiredInst,
    userSettings,
    removeGroup,
    showContact,
    onClose,
  } = props;

  return (
    <div className='basic-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>First Name</label>
              </div>
              <div className='col-sm-8'>
                <Field
                  name='firstName'
                  type='text'
                  component={renderField}
                  tabIndex='1'
                  label='First Name'
                  validate={required}
                />
              </div>
            </div>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>Gender / DOB</label>
              </div>
              <div className='col-sm-4'>
                <Field
                  name='gender'
                  type='text'
                  simpleValue={true}
                  valueKey='label'
                  tabIndex='3'
                  clearable={false}
                  component={renderDropdownList}
                  options={[{ label: 'Male' }, { label: 'Female' }]}
                  label='Gender'
                />
              </div>
              <div className='col-sm-4'>
                <Field
                  name='DOB'
                  type='text'
                  component={renderDatePicker}
                  min={-18}
                  max={-4}
                  tabIndex='4'
                  label='Class'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>Last Name</label>
              </div>
              <div className='col-sm-8'>
                <Field
                  name='lastName'
                  type='text'
                  component={renderField}
                  tabIndex='2'
                  label='Last Name'
                  validate={required}
                />
              </div>
            </div>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-sm-4'>
                <label>Grade / Class</label>
              </div>
              <div className='col-sm-4'>
                <Field
                  name='grade'
                  type='text'
                  simpleValue={true}
                  valueKey='label'
                  tabIndex='4'
                  clearable={false}
                  component={renderDropdownList}
                  options={selectors.grade}
                  textField='gradeName'
                />
              </div>
              <div className='col-sm-4'>
                <Field name='class' type='text' component={renderField} tabIndex='6' label='Class' />
              </div>
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='col-sm-2'>
                <label>Comment</label>
              </div>
              <div className='col-sm-10'>
                <Field name='comment' type='text' component={renderField} tabIndex='7' />
              </div>
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='col-sm-2'>
                <label>Tags</label>
              </div>
              <div className='col-sm-8'>
                <Field
                  name='studentTags'
                  type='text'
                  simpleValue={true}
                  valueKey='tag'
                  labelKey='tag'
                  component={renderDropdownList}
                  multi={true}
                  options={selectors.studentTags}
                  tabIndex='8'
                />
              </div>
              <div className='col-sm-2'>
                <Field name='photoConsent' component={renderCheckbox} />
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='col-sm-2'>
                <label>Private Tutor</label>
              </div>
              <div className='col-sm-5'>
                <Field name='privateTutor' type='text' component={renderField} tabIndex='9' />
              </div>
              <div className='col-sm-5'>{initialValues && hireAndCreatedRow(hiredInst, initialValues)}</div>
            </div>
          </div>
        </div>

        <hr />

        <div className='row'>
          <div className='form-group'>
            <div className='col-md-12'>
              <FieldArray
                name='groups'
                component={renderGroups}
                selectorsValues={selectors}
                selectGroups={groupList}
                permissions={userSettings}
                removeGroup={removeGroup}
                showContact={showContact}
              />
            </div>
          </div>
        </div>
        <hr />

        {formType(initialValues, submitting, onDelete, onClose)}
      </form>
    </div>
  );
};

StudentForm.propTypes = {
  ...propTypes,
  selectors: PropTypes.object.isRequired,
  groupList: PropTypes.array.isRequired,
  removeGroup: PropTypes.func,
  onDelete: PropTypes.func,
};

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
};

renderGroups.propTypes = {
  fields: PropTypes.object.isRequired,
  selectorsValues: PropTypes.object.isRequired,
  selectGroups: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
  removeGroup: PropTypes.func,
};

renderField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  tabIndex: PropTypes.string,
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'studentForm',
  enableReinitialize: true,
})(StudentForm);
