import { GET_CONTACTS, GET_CONTACT, UPDATE_CONTACT, CONTACT_COMMENT_ADD, ADD_CONTACT, 
    CONTACT_COMMENT_DEL, TOGGLE_PERMISSION, SET_EMAIL_CONTACT_LIST } from '../actions/types';
import initialState from './initial-state';

export default function(state = initialState.contacts, action) {
    switch(action.type) {
        
        case GET_CONTACTS:
            return { ...state, all: action.payload };
        
         case ADD_CONTACT:
            return { ...state, all: [...state.all, action.payload], contact: action.contact };

        case GET_CONTACT:
            return { ...state, contact: action.payload  };

        case UPDATE_CONTACT:
            return { ...state, contact: action.payload  };

        case SET_EMAIL_CONTACT_LIST:
            return { ...state, emailList: action.payload };

        case CONTACT_COMMENT_ADD:
            return { ...state, 
                        contact: { ...state.contact,
                            details: { ...state.contact.details, noComments: state.contact.details.noComments + 1,
                                comments: [ action.payload, ...state.contact.details.comments.slice() ] 
                                } 
                            }
                                  
                        };

        case CONTACT_COMMENT_DEL:

            return { ...state, 
                        contact: { ...state.contact,
                            details: { ...state.contact.details, noComments: state.contact.details.noComments - 1,
                                comments: [ ...state.contact.details.comments.slice(0, action.payload), ...state.contact.details.comments.slice(action.payload + 1) ] 
                                } 
                            }
                                  
                        };

        case TOGGLE_PERMISSION:
            return { ...state, 
                contact: { ...state.contact, fullAdmin: !action.payload }
            };

        default:
            return state;
    }
}


