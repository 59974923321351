import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/settings';
import StudentStatusForm from './form-student-status';
import { Modal } from 'react-bootstrap';
import {reset} from 'redux-form';

class StudentStatusList extends Component {
  
   constructor(props, context) {
        super(props, context);
        this.state = {
            showModal: false,
            selectedItem: {}
        };

        this.removeItem = this.removeItem.bind(this);
        this.saveList = this.saveList.bind(this);
        this.selectStatus = this.selectStatus.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }

  removeItem(item) {
        if (window.confirm(`Remove '${item.label}' from the student status list`)) {
            const action = { type: 'remove', list: 'studentStatus', listName: 'Student Status' };
            this.props.updateList(item, action, this.props.userSetting.schoolId);
        }
    }

  saveList(item) {
    const itemOld = this.state.selectedItem;
    const action = { type: 'add', list: 'studentStatus', listName: 'Student Status' };

    if (itemOld.label) {
        action.type = 'update';
    }
    this.setState({ showModal: false });
    this.props.updateList(item, action, this.props.userSetting.schoolId, itemOld);
  }

  selectStatus(status) {
      this.setState({ selectedItem: status, showModal: true });
  }

  close() {
        this.setState({ showModal: false });
    }

  open() {
        reset('studentStatusList');
        this.setState({ showModal: true });
    }

  renderedit(status) {
      return (
        <div>
            <div className="col-sm-1" onClick={() => this.selectStatus(status)}><i className="fa fa-pencil-square-o">&nbsp;</i></div>
            <div className="col-sm-1" onClick={() => this.removeItem(status)}><i className="fa fa-times">&nbsp;</i></div>
        </div>
    );
  }

  renderList() {

    return this.props.studentStatus.map((status, index) => {
      return (
        <li key={index} className="list-group-item">
            <div className="row">
                <div className="col-sm-10 small"><b>{status.label}</b></div>
                { (status.label !== 'Active') && this.renderedit(status) }
                
            </div>
        </li>
      );
    });
  }

  render() {
    return (
    <div className="clearfix">
        <div className="col-md-7 col-lg-6">
            <div className="panel panel-default">
                <div className="panel-heading">Student Status List</div>
                <div className="panel-body">
                    <div className="text-right">
                        <button type="button" onClick={() => this.selectStatus({})} className="btn btn-primary btn-xs rounded">Add Student Status</button>
                    </div>
            
                    <ul className="list-group">
                        {this.renderList()}
                    </ul>
                </div>
            </div>
        </div>

        <Modal show={this.state.showModal} onHide={this.close}>
            <Modal.Header className="modal-header primary" closeButton>
                <Modal.Title>Student Status List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <StudentStatusForm onSubmit={this.saveList} initialValues={this.state.selectedItem}  />
            </Modal.Body>
           
        </Modal>
    </div>
    );
  }
}

StudentStatusList.propTypes = {
    userSetting: PropTypes.object.isRequired,
    studentStatus: PropTypes.array.isRequired,
    settings: PropTypes.object.isRequired,
    updateList: PropTypes.func.isRequired
};

function mapStateToProps(state) {

  return {
        userSetting: state.usersetting,
        studentStatus: state.school.selectors.studentStatus,
        settings: state.selectLists
  };
}

export default connect(mapStateToProps, actions)(StudentStatusList);
