import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import * as actions from '../../actions/students';
import StudentForm from './student-form';

class AddStudentPage extends Component {

    constructor(props, context) {
        super(props, context);
        
        this.addStudent = this.addStudent.bind(this);
    }
 
    addStudent(student) {
       this.props.addStudent(student, this.props.userSetting.schoolId, this.props.history);
    }

    render () {
        const { groups, selectors, userSetting } = this.props;

         return(
            <div>
                <div className="pageheader">
                    <Link to="/students">Student List</Link> / Add Student
                </div>
                <section id="main-content">
                    <div className="row">
                        <div className="col-md-12 col-lg-11">
                                <div className="panel panel-primary">
                                    <div className="panel-heading">Add a Student</div>
                                    <div className="panel-body">
                                        <StudentForm onSubmit={this.addStudent} selectors={selectors} groupList={groups} userSettings={userSetting} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

AddStudentPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  selectors: PropTypes.object.isRequired,
  addStudent: PropTypes.func.isRequired
};

function mapStateToProps(state) {

    return {
        userSetting: state.usersetting,
        selectors: state.school.selectors,
        groups: state.school.groups
    };
}

export default connect(mapStateToProps, actions)(AddStudentPage);