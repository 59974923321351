import React from 'react';
import PropTypes from 'prop-types';

const studentListRow = (records) => {
  return records.map((record, index) => {
      return (
          <tr key={index}>
              <td>{record.studentName}</td>
              <td>{record.studentYear}</td>
              <td>{record.studentClass}</td>
              <td>{record.instruments}</td>
          </tr>
      );
  });
};

const EventDetails = (props) => {
  const { event, tGroups, onClose, removeEvent } = props;
  const selectedGroup = tGroups.filter(group => group._id === event.groupId)[0];

  return (
    <div>
      <div className="panel panel-default">
        <div className="panel-body">
          <div className="table-content-box">
              <table className="table">
                  <thead className="thead-inverse">
                    <tr><td>Group</td><td>{selectedGroup.groupName}</td></tr>
                  </thead>
                  <tbody>
                    <tr key="2"><td width="120">Tutor:</td><td>{selectedGroup.tutorName}</td></tr>
                    <tr key="3"><td width="120">Instruments:</td><td>{selectedGroup.instruments}</td></tr>
                    <tr key="4"><td width="120">Venue:</td><td>{event.eventVenue}</td></tr>
                  </tbody>
              </table>
          </div>
        </div>
      </div>    

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="table-content-box">
              <table className="table">
                  <thead className="thead-inverse">
                    <tr><td>Student</td><td>Year</td><td>Class</td><td>Instrument</td></tr>
                  </thead>
                  <tbody>
                    {selectedGroup.students && studentListRow(selectedGroup.students)}
                  </tbody>
              </table>
          </div>
        </div>
      </div>
      <div className="row"> 
        <div className="col-xs-6">
          <button type="button" className="btn btn-warning btn-sm rounded" onClick={() => removeEvent(event.groupId, event.eventId)}>Delete Event</button>
        </div> 
        <div className="col-xs-6 text-right">
          <button type="button" className="btn btn-default btn-sm rounded" onClick={() => onClose()}>Close</button>
        </div> 
      </div>
    </div>
  );
};

EventDetails.propTypes = {
  tGroups: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  removeEvent: PropTypes.func.isRequired
};

export default EventDetails;
