import React from 'react';
import PropTypes from 'prop-types';

const renderQuestions = (questions, response) => (
  <ul className='list-unstyled'>
    {questions.map((question, index) => (
      <li key={index}>
        {question.type === 'Text' ? (
          <div dangerouslySetInnerHTML={{ __html: response[index].questionText }} />
        ) : (
          <div className='list-detail-2'>
            <span className='box-heading-2'>{question.questionText}</span>
            <span className='box-description-2'>Response: {response[index].response}</span>
            <span className='box-time-2'>{response[index].comment}</span>
          </div>
        )}
      </li>
    ))}
  </ul>
);

const DisplayResponse = (props) => {
  const { questions, response, onClose } = props;
  const filteredQuestions =
    questions.length === response.length ? questions : questions.filter((item) => item.type !== 'Text');

  if (response.length === 0)
    return (
      <div>
        <div className='col-sm-12'>Form has not been completed yet</div>
        <br />
        <br />
        <button type='button' className='btn btn-default rounded' onClick={() => onClose()}>
          Close
        </button>
      </div>
    );

  return (
    <div>
      <div className='col-sm-12'>
        <div className='our-clients-2 admin-widget-2'>
          <div className='content-list-2'>{renderQuestions(filteredQuestions, response)}</div>
        </div>
      </div>
      <button type='button' className='btn btn-default rounded' onClick={() => onClose()}>
        Close
      </button>
    </div>
  );
};

DisplayResponse.propTypes = {
  questions: PropTypes.array.isRequired,
  response: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DisplayResponse;
