import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { required } from '../common/validation';

const renderDatePicker = ({ input: { onChange, value }}) =>
    <DatePicker 
        className="form-control"
        selected={value ? moment(value) : moment()}
        dateFormat="D MMM YY"
        onChange={onChange}
    />;

const renderField = ({ input, label, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type="text" placeholder={label}/>
      {touched && error && <h5><span className="label label-warning pull-right">{error}</span></h5>}
    </div>
);

const HolidayForm = (props) => {
    
    const { handleSubmit, initialValues, submitting } = props;
   
    return (
      <div className="basic-form">
        <form onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="row">
                  <div className="col-sm-4">
                      <label>Holiday</label>
                  </div>
                  <div className="col-sm-6">
                      <Field name="label" component={renderField} label="Holiday Description"  validate={required} />
                  </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                  <div className="col-sm-4">
                      <label>Date</label>
                  </div>
                  <div className="col-sm-3">
                      <Field name="holidayDate" type="text" component={renderDatePicker} />
                  </div>
              </div>
            </div>

            <hr />
            <button type="submit" disabled={submitting} className="btn btn-primary rounded">{(initialValues.label) ? 'Save' : 'Add'}</button>
        </form>
      </div>
    );
};

HolidayForm.propTypes = {
    ...propTypes
};

renderDatePicker.propTypes = {
    input: PropTypes.object.isRequired
};

renderField.propTypes = {
    label: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'holidays'
})(HolidayForm);