import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import * as actions from '../../actions/roster';
import RosterSetup from './roster-form';
import Roster from './roster-term-page';
import RosterEmail from './roster-email-form';


class RosterPage extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
          showModal: false,
          reminder: '',
          rosterEmail: {}
      };

        this.saveForm = this.saveForm.bind(this);
        this.saveEmail = this.saveEmail.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.onEmailChanged = this.onEmailChanged.bind(this);
    }

    componentDidMount() {
      this.props.getRoster(this.props.group_id);
      this.props.getParentList(this.props.group_id);
    }

    saveForm(values) {
        this.props.saveRosterSetup(values, this.props.group_id);
        }

    onEmailChanged(rosterEmail) {
      this.setState({ rosterEmail: { emailText: rosterEmail } });
        }

    saveEmail(values) {
      const rosterEmail = Object.assign({} , values, { emailText: this.state.rosterEmail.emailText, emailType: this.state.reminder });
      this.props.saveRosterEmail(rosterEmail, this.props.group_id);
      this.closeModal();
    }

    closeModal() {
          this.setState({ showModal: false });
      }
  
    openModal(reminder) {
          const { oneDayEmail, sevenDayEmail } = this.props.roster;
          const rosterEmail = {};

          if (reminder === '1') {
            rosterEmail.emailSubject = (oneDayEmail && oneDayEmail.emailSubject)
              ? oneDayEmail.emailSubject
              : 'One Day Email Subject';

            rosterEmail.emailText = (oneDayEmail && oneDayEmail.emailText)
            ? oneDayEmail.emailText
            : 'One Day Email emailText';

          } else {
            rosterEmail.emailSubject = (sevenDayEmail && sevenDayEmail.emailSubject)
            ? sevenDayEmail.emailSubject
            : 'Seven Day Email Subject';

            rosterEmail.emailText = (sevenDayEmail && sevenDayEmail.emailText)
            ? sevenDayEmail.emailText
            : 'Seven Day Email emailText';

          }
          this.setState({ showModal: true, reminder, rosterEmail });
      }
   
    render() {
        const { roster, group_id } =this.props;
        
        if (!roster.rosterSettings || !roster.rosterSettings.emailAddress) return (
            <div>
                <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                    <Tab eventKey={1} title="Roster Setup">
                        <br />
                        <div>
                          <RosterSetup onSubmit={this.saveForm} openModal={this.openModal} initialValues={roster.rosterSettings} parentList={roster.parentList} />
                        </div>
                    </Tab>
                </Tabs>

                <Modal show={this.state.showModal} bsSize="large" onHide={this.closeModal}>
                    <Modal.Header bsClass="modal-header primary" closeButton>
                        <Modal.Title>{this.state.reminder} Day Reminder Email</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-padding">
                            <RosterEmail onSubmit={this.saveEmail} onEmail={this.onEmailChanged} closeModal={this.closeModal} initialValues={this.state.rosterEmail} />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );

        return (
            <div>
                <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                    <Tab eventKey={1} title="Roster Setup">
                        <br />
                        <div>
                          <RosterSetup onSubmit={this.saveForm} openModal={this.openModal} initialValues={roster.rosterSettings} parentList={roster.parentList} />
                        </div>
                    </Tab>
                    
                    <Tab eventKey={2} title="Term 1" mountOnEnter={true} >
                        <br />
                        <div>
                            <Roster term="1" group_id={group_id} history={this.props.history} />
                        </div>
                    </Tab>
                    
                    <Tab eventKey={3} title="Term 2" mountOnEnter={true} >
                        <br />
                        <div>
                            <Roster term="2" group_id={group_id} history={this.props.history} />
                        </div>
                    </Tab>
                    
                    <Tab eventKey={4} title="Term 3" mountOnEnter={true} >
                        <br />
                        <div>
                            <Roster term="3" group_id={group_id} history={this.props.history} />
                        </div>
                    </Tab>

                    <Tab eventKey={5} title="Term 4" mountOnEnter={true} >
                        <br />
                        <div>
                            <Roster term="4" group_id={group_id} history={this.props.history} />
                        </div>
                    </Tab>
                </Tabs>

                <Modal show={this.state.showModal} bsSize="large" onHide={this.closeModal}>
                    <Modal.Header bsClass="modal-header primary" closeButton>
                        <Modal.Title>{this.state.reminder} Day Reminder Email</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-padding">
                            <RosterEmail onSubmit={this.saveEmail} onEmail={this.onEmailChanged} closeModal={this.closeModal} initialValues={this.state.rosterEmail} />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

RosterPage.propTypes = {
    group_id: PropTypes.string.isRequired,
    roster: PropTypes.object.isRequired,
    getRoster: PropTypes.func.isRequired,
    getParentList: PropTypes.func.isRequired,
    saveRosterSetup: PropTypes.func.isRequired,
    saveRosterEmail: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        roster: state.roster
    };
}

export default connect(mapStateToProps, actions)(RosterPage);


