import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/settings';
import HolidaysForm from './form-holidays';
import moment from 'moment';
import { Modal } from 'react-bootstrap';

class HolidayList extends Component {
  
   constructor(props, context) {
        super(props, context);
        this.state = {
            showModal: false,
            selectedItem: {}
        };

        this.removeItem = this.removeItem.bind(this);
        this.saveList = this.saveList.bind(this);
        this.selectHoliday = this.selectHoliday.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }

 removeItem(item) {
        if (window.confirm(`Remove '${item.label}' from the Holiday list`)) {
            const action = { type: 'remove', list: 'holidays', listName: 'Holidays' };
            this.props.updateList(item, action, this.props.userSetting.schoolId);
        }
    }

  saveList(item) {
    const itemOld = this.state.selectedItem;
    const action = { type: 'add', list: 'holidays', listName: 'Holidays' };

    if (itemOld.label) {
        action.type = 'update';
    }
    this.setState({ showModal: false });
    this.props.updateList(item, action, this.props.userSetting.schoolId, itemOld);
  }

  selectHoliday(holiday) {
      this.setState({ selectedItem: holiday, showModal: true });
  }

  close() {
        this.setState({ showModal: false });
    }

  open() {
        this.setState({ showModal: true });
    }

  renderList() {

    if (!this.props.holidays) {
      return null;
    }

    return this.props.holidays.map((holiday, index) => {
      return (
        <li key={index} className="list-group-item">
            <div className="row">
                <div className="col-sm-4">{moment(holiday.holidayDate).format('D MMM YYYY')}</div>
                <div className="col-sm-6 small grey-text">({holiday.label})</div>
                <div className="col-sm-1" onClick={() => this.selectHoliday(holiday)}><i className="fa fa-pencil-square-o"></i></div>
                <div className="col-sm-1" onClick={() => this.removeItem(holiday)}><i className="fa fa-times"></i></div>
            </div>
        </li>
      );
    });
  }

  render() {
    return (
      <div>
          <div className="text-right">
              <button type="button" onClick={() => this.selectHoliday({})} className="btn btn-primary btn-xs rounded">Add a Holiday</button>
          </div>
  
          <ul className="list-group">
              {this.renderList()}
          </ul>
               

        <Modal show={this.state.showModal} onHide={this.close}>
           <Modal.Header className="modal-header primary" closeButton>
                <Modal.Title>Holiday Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <HolidaysForm onSubmit={this.saveList} initialValues={this.state.selectedItem}  />
            </Modal.Body>
           
        </Modal>
    </div>
    );
  }
}

HolidayList.propTypes = {
    userSetting: PropTypes.object.isRequired,
    holidays: PropTypes.array.isRequired,
    settings: PropTypes.object.isRequired,
    updateList: PropTypes.func.isRequired
};

function mapStateToProps(state) {

  return {
        userSetting: state.usersetting,
        holidays: state.school.selectors.holidays,
        settings: state.selectLists
  };
}

export default connect(mapStateToProps, actions)(HolidayList);
