import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import * as actions from '../../actions/groups';
import GroupForm from './group-form';
import GroupContacts from './group-contact-list';
import GroupRehearsals from './group-rehearsal-list';
import StudentList from './student-list';
import RosterPage from './roster-page';

class GroupPage extends Component {

    constructor(props, context) {
        super(props, context);

        this.saveForm = this.saveForm.bind(this);
        this.deleteGroup = this.deleteGroup.bind(this);
    }

    saveForm(values) {
        
        this.props.updateGroup(values, this.props.userSetting.schoolId);
        }

    deleteGroup() {

        if (this.props.group.noStudent !== 0) {
            alert('All students need to be removed form the group before it can be deleted');
        } else if (window.confirm(`Delete the group: '${this.props.group.name}'`)) { 
            this.props.deleteGroup(this.props.group, this.props.userSetting.schoolId, this.props.history); 
        } 
    }

    render() {

        const { group, selectors, group_id } = this.props;

        
        return (
            <div>
                <div className="pageheader"><h2>Groups</h2></div>
                <section id="main-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel panel-primary">
                                    <div className="panel-heading">{group.name}</div>
                                    <div className="panel-body">
                                        
                                        <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                                            <Tab eventKey={1} title="Students">
                                                <br />
                                                <div>
                                                    <StudentList group_id={group_id} history={this.props.history} />
                                                </div>
                                            
                                            </Tab>
                                            <Tab eventKey={2} title="Group Details">
                                                <br />
                                                <div>
                                                    <GroupForm onSubmit={this.saveForm} initialValues={group} selectors={selectors} deleteGroup={this.deleteGroup} />
                                                  
                                                    <div className="clearfix">
                                                        <div className="col-sm-6">
                                                                <GroupContacts group={group} />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <GroupRehearsals group={group} venues={selectors.venues} /> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>

                                            <Tab eventKey={3} title="Parent Roster" mountOnEnter={true}>
                                                <br />
                                                <RosterPage group_id={group_id} history={this.props.history} />
                                            </Tab>
                                        </Tabs>
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
        );
    }
}

function getGroupById(groups, id) {
  const group = groups.filter(group => group._id == id);
  if (group) return group[0]; //since filter returns an array, have to grab the first.
  return null;
}

GroupPage.propTypes = {
    userSetting: PropTypes.object.isRequired,
    group: PropTypes.object.isRequired,
    selectors: PropTypes.object.isRequired,
    group_id: PropTypes.string.isRequired,
    deleteGroup: PropTypes.func.isRequired,
    updateGroup: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
    // set selected Group
    const groupId = ownProps.match.params.id;
    let group = {};

     if (groupId && state.school.groups.length > 0) {
        group = getGroupById(state.school.groups, groupId);
    }

    return {
        userSetting: state.usersetting,
        group,
        selectors: state.school.selectors,
        group_id: groupId
    };
}

export default connect(mapStateToProps, actions)(GroupPage);


