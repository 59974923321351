import axios from "axios";
import Notifications from "react-notification-system-redux";
import moment from "moment";
import fileDownload from "js-file-download";
import {
  GET_INVOICES,
  UPDATE_INVOICE,
  REMOVE_INVOICES,
  UPDATE_INV,
  SELECTED_INVOICES,
  GET_STUDENT_LIST,
  GET_CONTACTS,
  NO_ACTION,
  CLEAR_EMAIL,
  ROOT_URL,
  ADD_ATTACHMENT,
} from "./types";

export function addAttachment(response) {
  return { 
    type: ADD_ATTACHMENT,
    payload: response.data  
   };
 }

export function getInvoices(
  schoolId,
  dateFrom = moment().add(-3, "months").startOf("day").toDate(),
  dateTo = moment().endOf("day").toDate()
) {
  return function (dispatch) {
    axios
      .post(`${ROOT_URL}/invoices/${schoolId}`, { dateFrom, dateTo }, { headers: { authorization: localStorage.getItem("token") } })
      .then((response) => {
        const invoices = response.data.map((item) => {
          item.invoiceDate = moment(item.invoiceDate).format("D MMM YY");
          item.dueDate = moment(item.dueDate).format("D MMM YY");
          item.amount = item.amount.toFixed(2);
          item.balance = item.balance.toFixed(2);
          return item;
        });
        dispatch({ type: GET_INVOICES, payload: { invoices, dateFrom, dateTo } });
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function deleteInvoices(schoolId, invoices) {
  return function (dispatch) {
    axios
      .post(`${ROOT_URL}/invoice-delete/${schoolId}`, { invoices }, { headers: { authorization: localStorage.getItem("token") } })
      .then((response) => {
        dispatch({ type: REMOVE_INVOICES, payload: response.data });
        dispatch(Notifications.success({ title: "Invoices Deleted" }));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function createInvoices(invoice, students, schoolId) {
  if (!students.length) {
    return function (dispatch) {
      dispatch(Notifications.error({ title: "Select at least one student" }));
    };
  } else {
    return function (dispatch) {
      axios
        .post(
          `${ROOT_URL}/invoice-create/${schoolId}`,
          { invoice, students },
          { headers: { authorization: localStorage.getItem("token") } }
        )
        .then(() => {
          dispatch(Notifications.success({ title: "Invoice Created" }));
        })
        .catch((error) => {
          dispatch(Notifications.error({ title: error.response.data.error }));
        });
    };
  }
}

export function invoiceSetup(invoice, invoiceFooter, invoiceHeader, schoolId) {
  const invoiceDetails = Object.assign({}, invoice, { invoiceHeader, invoiceFooter });

  return function (dispatch) {
    axios
      .post(`${ROOT_URL}/invoice-setup/${schoolId}`, invoiceDetails, { headers: { authorization: localStorage.getItem("token") } })
      .then((response) => {
        dispatch({ type: UPDATE_INVOICE, payload: response.data.invoices });
        dispatch(Notifications.success({ title: "Invoice Template Updated" }));
      })
      .catch((error) => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function uploadImages(blob, schoolId, header) {
  return function (dispatch) {
    const data = new FormData();
    data.append("file", blob);

    axios
      .post(`${ROOT_URL}/invoice-image/${schoolId}/${header}`, data, { headers: { authorization: localStorage.getItem("token") } })
      .then((response) => {
        dispatch({ type: UPDATE_INVOICE, payload: response.data.invoices });
        dispatch(Notifications.success({ title: "Image Uploaded" }));
      })
      .catch((error) => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function removeImage(schoolId, header) {
  return function (dispatch) {
    axios
      .delete(`${ROOT_URL}/invoice-image/${schoolId}/${header}`, { headers: { authorization: localStorage.getItem("token") } })
      .then((response) => {
        dispatch({ type: UPDATE_INVOICE, payload: response.data.invoices });
        dispatch(Notifications.success({ title: "Invoice Image Removed" }));
      })
      .catch((error) => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function addPayment(payment, invoiceId) {
  return function (dispatch) {
    axios
      .post(`${ROOT_URL}/invoice-payment/${invoiceId}`, payment, { headers: { authorization: localStorage.getItem("token") } })
      .then((response) => {
        response.data.invoiceDate = moment(response.data.invoiceDate).format("D MMM YY");
        response.data.dueDate = moment(response.data.dueDate).format("D MMM YY");
        response.data.amount = response.data.amount.toFixed(2);
        response.data.balance = response.data.balance.toFixed(2);

        dispatch({ type: UPDATE_INV, payload: { id: response.data._id, data: response.data } });
        dispatch(Notifications.success({ title: "Payment Applied" }));
      })
      .catch((error) => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function removePayment(paymentId, invoiceId) {
  return function (dispatch) {
    axios
      .delete(`${ROOT_URL}/invoice-payment/${invoiceId}/${paymentId}`, { headers: { authorization: localStorage.getItem("token") } })
      .then((response) => {
        response.data.invoiceDate = moment(response.data.invoiceDate).format("D MMM YY");
        response.data.dueDate = moment(response.data.dueDate).format("D MMM YY");

        dispatch({ type: UPDATE_INV, payload: { id: response.data._id, data: response.data } });
        dispatch(Notifications.success({ title: "Payment Removed" }));
      })
      .catch((error) => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function updateInvoice(invoice, invoiceId) {
  return function (dispatch) {
    axios
      .post(`${ROOT_URL}/invoice-update/${invoiceId}`, invoice, { headers: { authorization: localStorage.getItem("token") } })
      .then((response) => {
        response.data.invoiceDate = moment(response.data.invoiceDate).format("D MMM YY");
        response.data.dueDate = moment(response.data.dueDate).format("D MMM YY");
        response.data.amount = response.data.amount.toFixed(2);
        response.data.balance = response.data.balance.toFixed(2);

        dispatch({ type: UPDATE_INV, payload: { id: response.data._id, data: response.data } });
        dispatch(Notifications.success({ title: "Invoice Updated" }));
      })
      .catch((error) => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function sendInvoice(email, schoolId) {
  return function (dispatch) {
    axios
      .post(`${ROOT_URL}/invoice-send/${schoolId}`, email, { headers: { authorization: localStorage.getItem("token") } })
      .then((response) => {
        dispatch(Notifications.success({ title: "Emails Sent", message: response.data, autoDismiss: 15 }));
      })
      .catch((error) => {
        dispatch(Notifications.error({ title: error.response.data.error }));
      });
  };
}

export function filterInvoices(invoices, dateFrom, dateTo) {
  return {
    type: GET_INVOICES,
    payload: { invoices, dateFrom, dateTo },
  };
}

export function selectedInvoices(selectedInvoices) {
  if (!selectedInvoices.length) {
    return function (dispatch) {
      dispatch(Notifications.error({ title: "Select at least one invoice" }));
    };
  } else {
    return { type: SELECTED_INVOICES, payload: selectedInvoices };
  }
}

export function getNewStudents(schoolId) {
  return function (dispatch) {
    axios.get(`${ROOT_URL}/new-students/${schoolId}`, { headers: { authorization: localStorage.getItem("token") } }).then((response) => {
      const newStudents = [];
      response.data.map((item) => {
        item.regDate = moment(item.registration.registrationDate).format("lll");
        newStudents.push(item);
      });
      dispatch({ type: GET_STUDENT_LIST, payload: newStudents });
    });
  };
}

export function getContacts(schoolId) {
  return function (dispatch) {
    axios.get(`${ROOT_URL}/contacts/${schoolId}`, { headers: { authorization: localStorage.getItem("token") } }).then((response) => {
      dispatch({ type: GET_CONTACTS, payload: response.data });
    });
  };
}

export function exportInvoices(exportList, exportType) {
  axios
    .post(`${ROOT_URL}/utils/export-${exportType}`, exportList, { headers: { authorization: localStorage.getItem("token") } })
    .then((response) => {
      fileDownload(response.data, `Invoice-${exportType}-${moment().format("D-MMM-YYYY")}.csv`);
    });
  return {
    type: NO_ACTION,
    payload: "",
  };
}

export function clearEmail() {
  return { type: CLEAR_EMAIL };
}
