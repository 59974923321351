import { GET_LOGS, GET_LOG } from '../actions/types';
import initialState from './initial-state';

export default function(state = initialState.logs, action) {
    switch(action.type) {
        
        case GET_LOGS:
            return { ...state, logItems: action.payload };

        case GET_LOG:
            return { ...state, emailLog: action.payload };

        default:
            return state;
    }
}