import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';

const renderDropdownList = ({ input, ...rest }) =>
  <DropdownList {...input} {...rest} onBlur={() => input.change} />;

const FileMoveForm = (props) => {
    const { handleSubmit, submitting, closeOption, folders } = props;

    return (
      <div className="basic-form">
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <div className="row">
                    <div className="col-sm-2">
                        <label>Folder</label>
                    </div>
                    <div className="col-sm-10">
                         <Field name="label" type="text" component={renderDropdownList} options={folders} valueKey="label" simpleValue={true} placeholder="Select folder to move document to" />
                    </div>
                </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-8">
                  <button type="submit" className="btn btn-sm btn-primary rounded" disabled={submitting}>Move</button>&nbsp;&nbsp;
                  <button type="button" className="btn btn-sm btn-default rounded" onClick={() => closeOption()}>Close</button>
              </div>
             
            </div>
        </form>
      </div>
    );
};

FileMoveForm.propTypes = {
    ...propTypes
};

renderDropdownList.propTypes = {
    input: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'filleMoveForm'
})(FileMoveForm);