import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/roster';
import { Modal } from 'react-bootstrap';
import ReactDataGrid from 'react-data-grid';
import { Data, Editors } from 'react-data-grid-addons';
import AddItem from './add-roster-item-form';
import ExportRoster from './export-roster-form';

const Selectors = Data.Selectors;
const { AutoComplete: AutoCompleteEditor } = Editors;

class RosterTermPage extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            rows: [],
            parentList: [],
            selectedRows: [],
            showModal: false,
            modalHeading: ''
        };
      
        this.createRoster = this.createRoster.bind(this);
        this.rowGetter = this.rowGetter.bind(this);
        this.getRows = this.getRows.bind(this);
        this.getSize = this.getSize.bind(this);
        this.handleGridRowsUpdated = this.handleGridRowsUpdated.bind(this);
        this.onRowsSelected = this.onRowsSelected.bind(this);
        this.onRowsDeselected = this.onRowsDeselected.bind(this);
        this.removeEvent = this.removeEvent.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.addEvent = this.addEvent.bind(this);
        this.autoFill = this.autoFill.bind(this);
        this.exportRoster = this.exportRoster.bind(this);
    }

    componentWillMount() {
        if (this.props) {
            this.setState( { 
                rows: this.props.roster[`term${this.props.term}`], 
                parentList: this.props.roster.parentList.filter(item => !this.props.roster.rosterSettings.doNotRoster || !this.props.roster.rosterSettings.doNotRoster.includes(item.studentId)).map(item => ({ id: item.studentId, title: item.label })) });
            }
        }
        

    componentWillReceiveProps(nextProps) {
        if (nextProps.roster !== this.props.roster) {
            this.setState({ 
                rows: nextProps.roster[`term${this.props.term}`],
                parentList: nextProps.roster.parentList.filter(item => !this.props.roster.rosterSettings.doNotRoster || !nextProps.roster.rosterSettings.doNotRoster.includes(item.studentId)).map(item => ({ id: item.studentId, title: item.label }))
         });
        }
    }

    openModal(modalHeading) {
        this.setState({ showModal: true, modalHeading });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    onRowsSelected(rows) {
        this.setState({selectedRows: this.state.selectedRows.concat(rows.map(r => r.row))});
    }

    onRowsDeselected(rows) {
        const ids = rows.map(r => r.row._id);
        this.setState({selectedRows: this.state.selectedRows.filter(invoice => !ids.some(id => id === invoice._id) )});
    }

    createRoster() {
        this.props.createRoster(this.props.group_id, this.props.userSetting.schoolId, this.props.term);
        }

    deleteRoster() {
        if (window.confirm(`Confirm that you would like to delete the current roster`)) { 
            this.props.deleteRoster(this.props.group_id, this.props.term);
            } 
        }

    removeEvent() {
        const ids = this.state.selectedRows.map(item => item._id);
        if (ids.length > 1) {
            if (window.confirm(`Confirm that you would like to delete the selected roster items`)) { 
                this.props.removeEvent(this.props.group_id, this.props.term, ids);
                } 
        } else {
            this.props.removeEvent(this.props.group_id, this.props.term, ids);
         }
        this.setState({ selectedRows: [] });
      }

    addEvent(event) {
        this.props.addEvent(this.props.group_id, this.props.term, event);
        this.closeModal();
    }

    autoFill() {
        this.props.autoFill(this.props.group_id, this.props.term);
    }

    emailRoster() {
        this.props.emailList(this.state.selectedRows);
        this.props.history.push(`/send-roster/${this.props.group_id}/${this.state.parentList[0].id}/${this.props.term}`);
    }

    exportRoster(values) {
        const expOption = (values.contactDetails) ? true : false;

        this.props.exportRoster(this.props.group_id, this.props.term, expOption);
        this.closeModal();
    }

    rowGetter(i) {
        const rows = this.getRows();
        return rows[i];
        }

    getRows() {
            return Selectors.getRows(this.state);
        }
   
    getSize() {
           return this.getRows().length;
       }

    handleGridRowsUpdated({ fromRow, updated }) {
        const rows = this.state.rows.slice();
        const updatedRow = Object.assign({}, rows[fromRow], updated);
        const slotKey = Object.keys(updated)[0].replace('_label', '');
        const selectedParent = updated[`${slotKey}_label`];
        const student = this.state.parentList.filter(item => item.title === selectedParent);
        const studentId = (student.length > 0) ? student[0].id : undefined;
        const rosteredParent = { 
            groupId: this.props.group_id,
            eventId: rows[fromRow]._id,
            term:this.props.term,
            slotKey,
            [`${slotKey}_studentId`]: studentId,
            [`${slotKey}_label`]: selectedParent,
        };
        rows[fromRow] = updatedRow;
        
        this.setState({ rows });
        this.props.addRosterParent(rosteredParent);
    }

    render() {
        if ((this.state.rows.length === 0)) return (
            <div className="text-right">
                <button type="button" className="btn btn-xs btn-primary rounded" onClick={() => this.createRoster()}>Create Roster</button>
            </div>
        );

        const columns = [{ key: 'rosterLabel', name: 'Roster Event', width: 250 }];
        for (let i = 1; i <= this.props.roster.rosterSettings.rosterSlots; i++) {
            columns.push({
                key: `slot${i}_label`, 
                name: `Parent Roster ${i}`, 
                width: 200, 
                editor: <AutoCompleteEditor options={this.state.parentList} /> 
            });
        }

        return (
            <div>
                <div className="text-right">
                    <button type="button" className="btn btn-xs btn-success rounded" onClick={() => this.emailRoster()}>Email Roster</button>&nbsp;
                    <button type="button" className="btn btn-xs btn-primary rounded" onClick={() => this.openModal('Add Roster Event')}>Add Event</button>&nbsp;
                    <button type="button" className="btn btn-xs btn-warning rounded" onClick={() => this.removeEvent()}>Remove Event</button>&nbsp;
                    <button type="button" className="btn btn-xs btn-info rounded" onClick={() => this.autoFill()}>Auto Fill</button>&nbsp;
                    <button type="button" className="btn btn-xs btn-danger rounded" onClick={() => this.deleteRoster()}>Delete Roster</button>&nbsp;
                    <button type="button" className="btn btn-xs btn-default rounded" onClick={() => this.openModal('Export Roster')}>Export</button>
                </div>
                
               <ReactDataGrid
                        rowKey="_id"
                        columns={columns}
                        enableCellSelect={true}
                        rowGetter={this.rowGetter}
                        rowsCount={this.getSize()}
                        minHeight={this.getSize() * 35 + 85}
                        onGridRowsUpdated={this.handleGridRowsUpdated}
                        rowSelection={{
                              showCheckbox: true,
                              enableShiftSelect: true,
                              onRowsSelected: this.onRowsSelected,
                              onRowsDeselected: this.onRowsDeselected,
                              selectBy: {
                                  keys: { rowKey: '_id', values: this.state.selectedRows.map(row => row._id)}
                              }
                          }}
                         />
                <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <Modal.Header bsClass="modal-header primary" closeButton>
                        <Modal.Title>{this.state.modalHeading}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-padding">
                            {(this.state.modalHeading === 'Add Roster Event') && <AddItem onSubmit={this.addEvent} onClose={this.closeModal} />}
                            {(this.state.modalHeading === 'Export Roster') && <ExportRoster onSubmit={this.exportRoster} />}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

RosterTermPage.propTypes = {
    group_id: PropTypes.string.isRequired,
    userSetting: PropTypes.object.isRequired,
    roster: PropTypes.object.isRequired,
    term: PropTypes.string.isRequired,
    createRoster: PropTypes.func.isRequired,
    addRosterParent: PropTypes.func.isRequired,
    deleteRoster: PropTypes.func.isRequired,
    removeEvent: PropTypes.func.isRequired,
    addEvent: PropTypes.func.isRequired,
    autoFill: PropTypes.func.isRequired,
    exportRoster: PropTypes.func.isRequired,
    emailList: PropTypes.func.isRequired
};


function mapStateToProps(state) {
    return {
        userSetting: state.usersetting,
        roster: state.roster
    };
}

export default connect(mapStateToProps, actions)(RosterTermPage);