import { GET_EVENTS, SELECT_EVENT } from '../actions/types';
import initialState from './initial-state';

export default function(state = initialState.events, action) {
    switch(action.type) {
        
        case GET_EVENTS:
            return { ...state, all: action.payload };

        case SELECT_EVENT:
            return { ...state, selectedEvent: action.payload };

        default:
            return state;
    }
}