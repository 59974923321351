import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

const UploadAttachment = (props) => {

    const { onDrop } = props;

    return (

            <form>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-10 col-sm-offset-1 ">
                            <label>Select File</label>
                            <Dropzone onDrop={onDrop} className="dropzone" multiple maxSize={10485760} >
                                <div>Drop or Click to attach a file.</div>
                            </Dropzone>
                        </div>
                    </div>
                </div>
            </form>
  
    );
};

UploadAttachment.propTypes = {
  onDrop: PropTypes.func.isRequired
};

export default UploadAttachment;