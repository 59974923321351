import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import { Checkbox } from "@blueprintjs/core";
import { required, email } from '../common/validation';

const renderField = ({ input, label, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type="text" placeholder={label}/>
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
  <div>
      <DropdownList {...input} {...rest} onBlur={() => input.change} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderCheckbox = ({ input }) => (
  <label className="pt-control pt-checkbox pt-large">
      <Checkbox checked={input.value} onChange={input.onChange} />
  </label>
);

const RosterForm = (props) => {
  const { handleSubmit, submitting, openModal, parentList } = props;

  return (
    <div className="basic-form">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
              <div className="row">
                  <div className="col-sm-4 col-md-3 col-lg-2">
                      <label>Parents per rehearsal</label>
                  </div>
                  <div className="col-sm-4 col-md-3 col-lg-2">
                      <Field name="rosterSlots" type="text" clearable={false} simpleValue={true} labelKey="value" component={renderDropdownList} options={[{ value: 1}, { value: 2}, { value: 3}, { value: 4},]}  />
                  </div>
              </div>
          </div>

          <div className="form-group">
              <div className="row">
                  <div className="col-sm-4 col-md-3 col-lg-2">
                      <label>Email From</label>
                  </div>
                  <div className="col-sm-3 col-lg-3">
                      <Field name="emailFrom" type="text" component={renderField} validate={required} label="Email From (Name)" />
                  </div>

                  <div className="col-sm-5 col-lg-4">
                      <Field name="emailAddress" type="text" component={renderField} validate={[required, email]} label="Email Address" />
                  </div>
              </div>
          </div>

          <div className="form-group">
              <div className="row">
                  <div className="col-xs-4 col-md-3 col-lg-2">
                      <label>1 Day Reminder</label>
                  </div>
                  <div className="col-xs-2 col-sm-1">
                      <Field name="oneDayReminder" component={renderCheckbox} />
                  </div>
                  <div className="col-sm-4">
                      <button type="button" className="btn btn-xs btn-info" onClick={() => openModal('1')}>Customise Email</button>
                  </div>
              </div>
          </div>

          <div className="form-group">
              <div className="row">
                  <div className="col-xs-4 col-md-3 col-lg-2">
                      <label>7 Day Reminder</label>
                  </div>
                  <div className="col-xs-2 col-sm-1">
                      <Field name="sevenDayReminder" component={renderCheckbox} />
                  </div>
                  <div className="col-sm-4">
                      <button type="button" className="btn btn-xs btn-info" onClick={() => openModal('7')}>Customise Email</button>
                  </div>
              </div>
          </div>

          <div className="form-group">
              <div className="row">
                  <div className="col-sm-4 col-md-3 col-lg-2">
                      <label>Do not roster</label>
                  </div>
                  <div className="col-sm-8 col-md-9 col-lg-10">
                      <Field name="doNotRoster" type="text" multi={true} simpleValue={true} valueKey="studentId" component={renderDropdownList} options={parentList} />
                  </div>
              </div>
          </div>
          <hr />                
          <div className="row">
              <div className="col-sm-8">
                  <button type="submit" className="btn btn-sm btn-primary rounded" disabled={submitting}>Save</button>&nbsp;&nbsp;
              </div>
          </div>
        </form>
    </div>
  );
};

RosterForm.propTypes = {
    ...propTypes
};

renderField.propTypes = {
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    meta: PropTypes.object.isRequired
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired
};

export default reduxForm({
  form: 'rosterForm',
  enableReinitialize: true
})(RosterForm);
