import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import EmailEditor from '../editor/EmailEditor';
import { required } from '../common/validation';
import { Tag, Checkbox } from '@blueprintjs/core';

const renderEditor = ({ input, onEmailBody }) => (
  <EmailEditor value={input.value} handleOnChange={input.onChange} onBlur={onEmailBody(input.value)} />
);

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
  <div>
    <DropdownList {...input} {...rest} onBlur={() => input.change} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderField = ({ input, label, meta: { touched, error } }) => (
  <div>
    <input className='form-control' {...input} type='text' placeholder={label} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderCheckbox = ({ input, label }) => (
  <Checkbox checked={input.value} className='pt-control pt-checkbox pt-large checkbox-text' onChange={input.onChange}>
    {label}
  </Checkbox>
);

const renderAttachmentList = (list, onRemoveAttachment) => {
  return list.map((attachment, index) => {
    return (
      <Tag className='pt-intent-primary' key={attachment._id} onRemove={() => onRemoveAttachment(index)}>
        {attachment.label}
      </Tag>
    );
  });
};

const renderAttachments = (attachments, onRemoveAttachment) => (
  <div className='form-group'>
    <div className='row'>
      <div className='col-sm-2'>
        <label>Attachments</label>
      </div>
      <div className='col-sm-10'>{renderAttachmentList(attachments, onRemoveAttachment)}</div>
    </div>
  </div>
);

const TuitionEmailForm = (props) => {
  const {
    handleSubmit,
    fromEmails,
    attachments,
    onRemoveAttachment,
    onEmailBody,
    openModal,
    emailNumber,
    emailStatus,
    onClose,
  } = props;

  return (
    <div className='basic-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-2'>
              <label>From Email</label>
            </div>
            <div className='col-sm-5'>
              <Field
                name='fromEmail'
                type='text'
                labelKey='fromEmailAddress'
                clearable={false}
                component={renderDropdownList}
                options={fromEmails}
                validate={[required]}
              />
            </div>
            <div className='col-sm-5 text-right'>
              <a onClick={() => openModal('Attachments')}>
                <i className='fa fa-paperclip' aria-hidden='true'></i> Attachments
              </a>
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-2'>
              <label>Bcc</label>
            </div>
            <div className='col-sm-10'>
              <Field
                name='bccList'
                type='text'
                component={renderDropdownList}
                simpleValue={true}
                multi={true}
                options={props.contactList.map((contact) => ({
                  value: contact.email,
                  label: `${contact.firstName} ${contact.lastName}`,
                }))}
              />
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-2'>
              <label>Subject</label>
            </div>
            <div className='col-sm-10'>
              <Field name='subject' type='text' component={renderField} label='Subject' validate={[required]} />
            </div>
          </div>
        </div>
        {attachments.length ? renderAttachments(attachments, onRemoveAttachment) : ''}
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-12'>
              <Field name='emailBody' type='textarea' onEmailBody={onEmailBody} component={renderEditor} />
              <div className='admin-label margin-top-quarter'>
                <i className='blueBG'>
                  {emailNumber} Student{emailNumber > 1 && 's'} will be emailed
                </i>
              </div>
            </div>
            <div className='clearfix'>&nbsp;</div>
          </div>
        </div>
        <button type='submit' className='btn btn-warning btn-sm rounded' disabled={emailStatus !== 'Send Email'}>
          {emailStatus}
        </button>
        &nbsp;&nbsp;
        <button type='button' className='btn btn-default btn-sm rounded' onClick={() => onClose()}>
          Close
        </button>
      </form>
    </div>
  );
};

TuitionEmailForm.propTypes = {
  ...propTypes,
};

renderField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
};

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
};

renderEditor.propTypes = {
  input: PropTypes.object.isRequired,
  onEmailBody: PropTypes.func.isRequired,
  contacts: PropTypes.bool,
};

export default reduxForm({
  form: 'tuitionEmailForm',
  enableReinitialize: true,
})(TuitionEmailForm);
