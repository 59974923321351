import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const renderDatePicker = ({ input: { onChange, value }}) =>
    <DatePicker 
        className="form-control"
        selected={value ? moment(value) : moment()}
        dateFormat="D MMM YY"
        onChange={onChange}
    />;

const TermDatesForm = (props) => {
    
    const { handleSubmit, submitting } = props;
   
    return (
        <form onSubmit={handleSubmit}>
        <table className="table table-bordered">
          <thead>
          <tr>
              <th width="34%">Term</th>
              <th width="33%">Start Date</th>
              <th width="33%">End Date</th>
          </tr>
          </thead>
        <tbody>
            <tr key="1">
                <td>Term 1</td>
                <td><Field name="term1.start" type="text" component={renderDatePicker} /></td>
                <td><Field name="term1.end" type="text" component={renderDatePicker} /></td>
            </tr>

            <tr key="2">
                <td>Term 2</td>
                <td><Field name="term2.start" type="text" component={renderDatePicker} /></td>
                <td><Field name="term2.end" type="text" component={renderDatePicker} /></td>
            </tr>

            <tr key="3">
                <td>Term 3</td>
                <td><Field name="term3.start" type="text" component={renderDatePicker} /></td>
                <td><Field name="term3.end" type="text" component={renderDatePicker} /></td>
            </tr>

            <tr key="4">
                <td>Term 4</td>
                <td><Field name="term4.start" type="text" component={renderDatePicker} /></td>
                <td><Field name="term4.end" type="text" component={renderDatePicker} /></td>
            </tr>
        </tbody>
        </table>
        <br />
            <button type="submit" className="btn btn-primary rounded" disabled={submitting}>Save</button>
        </form>
    );
};

TermDatesForm.propTypes = {
    ...propTypes
};

renderDatePicker.propTypes = {
    input: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'TermDates'
})(TermDatesForm);