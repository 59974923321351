import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import { Checkbox } from "@blueprintjs/core";

const dropdownData = (data) => {
   let newArry = data.map(function(obj) {
    return { value: obj._id, label: obj.name || obj.instrument, };
    });
    return newArry;
};

const renderCheckbox = ({ input }) => (
    <label className="pt-control pt-checkbox pt-large">
        <Checkbox checked={input.value} onChange={input.onChange} />
    </label>
);

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <label>{label}</label>
    <div className="margin-top-half">
      <input className="form-control" {...input} type={type} placeholder={label}/>
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
  </div>
);


const renderDropdownList = ({ input, ...rest }) =>
  <DropdownList {...input} {...rest} onBlur={() => input.change} />;

const NewRegistrationForm = (props) => {
    const { handleSubmit, submitting, groupList, instrumentList } = props;

    return (
        <div className="advance-form">
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-3">
                        <label className="text-right">Groups</label>
                    </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                            <Field name="ensembleList" type="text" component={renderDropdownList} multi={true} options={dropdownData(groupList)} textField="groupName"  />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-3">
                        <label className="text-right">Instruments</label>
                    </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                            <Field name="instrumentList" type="text" valueKey="label" component={renderDropdownList} multi={true} options={instrumentList} textField="instrument" />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-3">
                        <label className="text-right">Instruments Preferences</label>
                    </div>
                    <div className="col-sm-2">
                        <div className="form-group">
                            <Field name="numberOfPreferences" type="text" simpleValue={true} valueKey="label" clearable={false} component={renderDropdownList} options={[{ label: '0' },{ label: '1' },{ label: '2' },{ label: '3' },{ label: '4' }]} />
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <label className="text-right">Registration Active</label>
                    </div>
                    <div className="col-sm-1">
                        <div className="form-group padding-top-quarter">
                           <Field name="newRegActive" component={renderCheckbox} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12"> 
                        <button type="submit" className="btn btn-primary rounded" disabled={submitting}>Save</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

NewRegistrationForm.propTypes = {
    ...propTypes
};

renderCheckbox.propTypes = {
    input: PropTypes.object.isRequired
};

renderDropdownList.propTypes = {
    input: PropTypes.object.isRequired
};

renderField.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'newRegistration',
    enableReinitialize: true
})(NewRegistrationForm);