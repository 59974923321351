import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { required, email } from '../common/validation';
  
 const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} placeholder={label} type={type}/>
        { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);
 
 const ResetPassword = (props) => {
    
    const { handleSubmit, submitting } = props;

    return (
        <form onSubmit={handleSubmit} className="form-horizontal">
            <div className="form-group">
                <Field name="emailAddress" type="email" component={renderField} label="Email" validate={[required, email]} />
            </div>

            <div className="reset-btn">
                <button type="submit" className="reset-pass-btn rounded" disabled={submitting}>Reset Your Password</button>
            </div>
        </form>
    );
};


renderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired
};

ResetPassword.propTypes = {
  ...propTypes
};

export default reduxForm({
    form: 'resetPassword'
})(ResetPassword);