import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';

const validate = values => {
    const errors = {};
    if (!values.fromName) {
      errors.fromName = 'Sender\'s name is required';
    } 

     if (!values.fromEmailAddress) {
      errors.fromEmailAddress = 'Sender\'s email address is required';
    } 

    return errors;
  };

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <label>{label}</label>
    <div className="margin-top-half">
      <input className="form-control" {...input} type={type} placeholder={label}/>
       {touched && error && <h5><span className="label label-warning pull-right">{error}</span></h5>}
    </div>
  </div>
);

const FromEmails = (props) => {
    
    const { handleSubmit, initialValues } = props;
   
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <Field name="fromName" type="text" component={renderField} label="Name"/>
            </div>
            <div className="form-group">
                <Field name="fromEmailAddress" type="email" component={renderField} label="Email"/>
            </div>
            <br />
            <button type="submit" className="btn btn-primary rounded">{(initialValues._id) ? 'Save' : 'Add'}</button>
        </form>
    );
};

FromEmails.propTypes = {
    ...propTypes
};

renderField.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'fromEmails',
    validate
})(FromEmails);