import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tab, Nav, NavItem, Row, Col } from 'react-bootstrap';
import * as actions from '../../actions/students';
import RegDetails from './re-enrole-reg-details';
import Enrolment from './enrolment-details';
import StudentForm from './new-student-detail-form';
import StudentContactForm from './new-student-contact-form';

class ReEnroleStudentModal extends Component {

    constructor(props, context) {
        super(props, context);

        this.updateStudent = this.updateStudent.bind(this);
        this.updateContact = this.updateContact.bind(this);
        this.saveEnrolment = this.saveEnrolment.bind(this);
    }

    // componentDidMount() {
    //         this.props.getStudent(this.props.student._id);
    // }   

    updateStudent(student) {
        this.props.updateStudent(student, this.props.student._id, true);

    }

    updateContact(contact) {
        this.props.updateStudentContact(contact, this.props.student._id, true);
    }

    saveEnrolment(values) {
        this.props.saveEnrolment(values, this.props.student._id);
        this.props.closeStudent();
    }

    render() {

        const { student, selectors, closeStudent, enrolment, groups } = this.props;
        return (
                                                 
            <Tab.Container id="contact-tabs" defaultActiveKey="registration">
                <Row className="clearfix">
                    <Col sm={12}>
                        <Nav bsStyle="nav nav-tabs">
                             <NavItem eventKey="registration">
                                Registration
                            </NavItem>
                            <NavItem eventKey="student">
                                Student
                            </NavItem>
                            <NavItem eventKey="contacts">
                                Contacts
                            </NavItem>
                        </Nav>

                        <Tab.Content animation>
                            <Tab.Pane eventKey="registration">
                                <br />
                                {(enrolment) ? <Enrolment onSubmit={this.saveEnrolment} initialValues={student.enrolment} selectors={selectors} groupList={groups} closeStudent={closeStudent} /> : <RegDetails regDetails={student.reEnrolment}/>}

                            </Tab.Pane>
                            <Tab.Pane eventKey="student">
                                <br />
                               <StudentForm selectors={selectors} initialValues={student} onSubmit={this.updateStudent} closeStudent={closeStudent} />
                                
                            </Tab.Pane>

                            <Tab.Pane eventKey="contacts">
                                <br />
                                <StudentContactForm initialValues={student.studentContact} onSubmit={this.updateContact} closeStudent={closeStudent}  />
                               
                            </Tab.Pane>

                        </Tab.Content>
                        </Col>
                </Row>
            </Tab.Container>
                                   
        );
    }
}

ReEnroleStudentModal.propTypes = {
  userSetting: PropTypes.object.isRequired,
  student: PropTypes.object.isRequired,
  selectors: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  enrolment: PropTypes.bool.isRequired,
  closeStudent: PropTypes.func.isRequired,
  updateStudentContact: PropTypes.func.isRequired,
  updateStudent: PropTypes.func.isRequired,
  saveEnrolment: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        userSetting: state.usersetting,
        selectors: state.school.selectors,
        groups: state.school.groups,
    };
}

export default connect(mapStateToProps, actions)(ReEnroleStudentModal);