import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import { Data } from 'react-data-grid-addons';
import EmptyToolbar from '../common/grid-toolbar';

const Selectors = Data.Selectors;

class InvoiceList extends Component {
    
     constructor(props) {
        super(props);

        this.state = {
            selectedInvoices: [],
            rows: this.props.invoices, 
            filters : {},
            sortColumn: null, 
            sortDirection: null,
        };

        this.rowGetter = this.rowGetter.bind(this);
        this.onRowsSelected = this.onRowsSelected.bind(this);
        this.onRowsDeselected = this.onRowsDeselected.bind(this);
        this.getRows = this.getRows.bind(this);
        this.getSize = this.getSize.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.onClearFilters = this.onClearFilters.bind(this);
        this.handleGridSort = this.handleGridSort.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.invoices !== this.props.invoices) {
          this.setState({rows: nextProps.invoices.slice(0)});
        }
     }

    rowGetter(i) {
        const rows = this.getRows();
        return rows[i];
    }

    onRowsSelected(rows) {
        this.setState({selectedInvoices: this.state.selectedInvoices.concat(rows.map(r => r.row))});
        this.props.selectedInvoices({rows, status: true});
    }

    onRowsDeselected(rows) {
        const ids = rows.map(r => r.row._id);
        this.setState({selectedInvoices: this.state.selectedInvoices.filter(invoice => !ids.some(id => id === invoice._id) )});
        this.props.selectedInvoices({rows, status: false});

    }

    getRows() {
         return Selectors.getRows(this.state);
     }

    getSize() {
        return this.getRows().length;
    }

    handleFilterChange(filter) {
        const newFilters = Object.assign({}, this.state.filters);
        if (filter.filterTerm) {
        newFilters[filter.column.key] = filter;
        } else {
        delete newFilters[filter.column.key];
        }
        this.setState({filters: newFilters});
    }

    onClearFilters() {
        //all filters removed
        this.setState({filters: {} });
    }

     handleGridSort(sortColumn, sortDirection) {
        const state = Object.assign({}, this.state, {sortColumn: sortColumn, sortDirection: sortDirection});
        this.setState(state);
    }

    render() {

        const columns = [
            {
                key: 'invoiceDate',
                name: 'Date',
                sortable : true,
                filterable : true,
                width: 110
            },
            {
                key: 'invoiceNumber',
                name: 'Invoice No',
                sortable : true,
                filterable : true,
                width: 115
            },
             {
                key: 'invoiceTo',
                name: 'Contact / Student',
                sortable : true,
                filterable : true
            },
            {
               key: 'groups',
               name: 'Student Groups',
               sortable : true,
               filterable : true
           },
             {
                key: 'amount',
                name: 'Amount',
                sortable : true,
                filterable : true,
                width: 100
            },
            {
                key: 'balance',
                name: 'Bal Owing',
                sortable : true,
                filterable : true,
                width: 100
            }
            ];

        return (
            <div>
              <div>
                  <ReactDataGrid
                          rowKey="_id"
                          onGridSort={this.handleGridSort}
                          columns={columns}
                          rowGetter={this.rowGetter}
                          rowsCount={this.getSize()}
                          minHeight={this.getSize() * 35 + 85}
                          onRowClick={this.props.onRowClick}
                          toolbar={<EmptyToolbar />}
                          onAddFilter={this.handleFilterChange}
                          onClearFilters={this.onClearFilters}
                          rowSelection={{
                              showCheckbox: true,
                              enableShiftSelect: true,
                              onRowsSelected: this.onRowsSelected,
                              onRowsDeselected: this.onRowsDeselected,
                              selectBy: {
                                  keys: { rowKey: '_id', values: this.state.selectedInvoices.map(row => row._id)}
                              }
                          }} />
                      </div>
                      <div className="admin-label text-right margin-top-quarter"><i className="blueBG"> { (this.getSize() === this.state.rows.length) ? `${this.state.rows.length} Invoices` : `Showing ${this.getSize()} of ${this.state.rows.length} Invoices`} </i></div>
                  </div>
        );
    }
}

InvoiceList.propTypes = {
  invoices: PropTypes.array.isRequired,
  selectedInvoices: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired
};

export default InvoiceList;
