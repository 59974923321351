import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';

const renderField = ({ input, type, tabIndex, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type={type} tabIndex={tabIndex} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const ServiceCentreForm = (props) => {
    const { handleSubmit, submitting, closeForm, initialValues, removeSC } = props;
    return (
        <div className="basic-form">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Service Centre</label>
                        </div>
                        <div className="col-sm-8">
                            <Field name="name" type="text" component={renderField} />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Contact</label>
                        </div>
                        <div className="col-sm-8">
                            <Field name="contact" type="text" component={renderField} />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Email</label>
                        </div>
                        <div className="col-sm-8">
                            <Field name="email" type="text" component={renderField} />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Tel</label>
                        </div>
                        <div className="col-sm-8">
                            <Field name="telNo" type="text" component={renderField} />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Address</label>
                        </div>
                        <div className="col-sm-8">
                            <Field name="address" type="text" component={renderField} />
                        </div>
                    </div>
                </div>

            
          <hr />
          <div className="row">
                <div className="col-xs-10">
                    <button type="submit" className="btn btn-primary btn-sm rounded" disabled={submitting}>Save</button>&nbsp;&nbsp;
                    <button type="button" className="btn btn-sm btn-default rounded" onClick={() => closeForm()}>Cancel</button>
                </div>
                <div className="col-xs-2 text-right">
                {(initialValues) && <button type="button" className="btn btn-sm btn-danger rounded" onClick={() => removeSC(initialValues._id)}>Delete</button>}
                </div>
            </div>
        </form>
    </div>
    );
};

ServiceCentreForm.propTypes = {
    ...propTypes
};

renderField.propTypes = {
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    tabIndex: PropTypes.string
};

export default reduxForm({
    form: 'serviceCentreForm'
})(ServiceCentreForm);