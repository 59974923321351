import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import EmailForm from './email-form';
import AttachDocument from '../email/document-tabs';
import { ROOT_URL } from '../../actions/types';

class TuitionEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailBody: '',
      emailStatus: 'Send Email',
      showAttachment: false,
      showModal: false,
      email: { emailBody: '' },
    };

    this.sendEmails = this.sendEmails.bind(this);
    this.setEmailBody = this.setEmailBody.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
  }

  componentDidMount() {
    this.props.clearEmail();
    if (!this.props.contacts.length) this.props.getContacts(this.props.userSetting.schoolId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.email.emailStatus !== this.props.email.emailStatus) {
      this.setState({ emailStatus: nextProps.email.emailStatus });
    }
  }

  sendEmails(values) {
    this.setState({ emailStatus: 'Sending...' });
    const email = {
      emailFrom: values.fromEmail,
      emailSubject: values.subject,
      emailBody: this.state.emailBody,
      attachments: this.props.email.attachments,
      emailList: this.props.studentList,
      bccList: values.bccList,
    };
    this.props.SendEmail(email, this.props.userSetting.schoolId);
  }

  setEmailBody(emailBody) {
    this.setState({ emailBody });
  }

  openModal(modalHeading) {
    this.setState({ showModal: true, modalHeading });
  }

  closeModal() {
    this.setState({ showModal: false, modalHeading: '' });
  }

  uploadFile(files, rejected) {
    if (rejected.length > 0) {
      this.props.displayWarning('Maxium size per file is: 10Mb');
    } else {
      const config = {
        headers: { authorization: localStorage.getItem('token'), 'content-type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const uploaded = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          this.setState({ uploaded });
          if (uploaded === 100) {
            setTimeout(() => this.closeModal(), 2000);
          }
        },
      };

      const data = new FormData();
      files.forEach((file) => {
        data.append('file', file);
        data.append('name', this.props.userSetting.schoolId);
        data.append('label', 'Attachments');
      });
      axios.post(`${ROOT_URL}/upload-file/${this.props.userSetting.schoolId}`, data, config).then((response) => {
        this.props.addAttachment(response);
      });
    }
  }

  deleteAttachment(index) {
    this.props.removeAttachment(index);
  }

  render() {
    return (
      <div>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <EmailForm
              onSubmit={this.sendEmails}
              fromEmails={this.props.fromEmails}
              onEmailBody={this.setEmailBody}
              initialValues={this.state.email}
              attachments={this.props.email.attachments}
              onRemoveAttachment={this.deleteAttachment}
              emailStatus={this.state.emailStatus}
              openModal={this.openModal}
              onClose={this.props.onClose}
              emailNumber={this.props.studentList.length}
              contactList={this.props.contacts}
            />
          </div>
        </div>

        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header className='modal-header primary' closeButton>
            <Modal.Title>{this.state.modalHeading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.modalHeading === 'Attachments' && (
              <AttachDocument uploadFile={this.uploadFile} closeModal={this.closeModal} />
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

TuitionEmail.propTypes = {
  userSetting: PropTypes.object.isRequired,
  fromEmails: PropTypes.array.isRequired,
  emailForm: PropTypes.object,
  email: PropTypes.object.isRequired,
  studentList: PropTypes.array.isRequired,
  SendEmail: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  removeAttachment: PropTypes.func.isRequired,
  clearEmail: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  contacts: PropTypes.array.isRequired,
  getContacts: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    userSetting: state.usersetting,
    fromEmails: state.usersetting.profile.fromEmails,
    email: state.email,
    emailForm: state.form.emailForm,
    contacts: state.contacts.all,
  };
}

export default connect(mapStateToProps, actions)(TuitionEmail);
