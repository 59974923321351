import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { Link } from 'react-router-dom';
import DropdownList from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Checkbox } from "@blueprintjs/core";
import { required, number } from '../common/validation';
import 'react-datepicker/dist/react-datepicker.css';

const renderDatePicker = ({ input: { onChange, value }}) =>
    <DatePicker 
        className="form-control"
        selected={value ? moment(value) : moment()}
        dateFormat="D MMM YYYY"
        onChange={onChange}
    />;

const renderCheckbox = ({ input }) => (
    <label className="pt-control pt-checkbox pt-large">
        <Checkbox checked={input.value} onChange={input.onChange} />
    </label>
);

const renderDropdownList = ({ input, checkReserved, meta: { touched, error }, ...rest }) => (
    <div>
        <DropdownList {...input} {...rest} onBlur={() => {
            input.onChange(input.value);
            checkReserved(input.value);
            }} />
        { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
);

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type={type} placeholder={label}/>
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const renderCurrencyField = ({ input, label, type, meta: { touched, error } }) => (
  <div className="input-group">
      <span className="input-group-addon">$</span>
      <input className="form-control" {...input} type={type} placeholder={label}/>
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const notHired = () => (
    <div className="row">
        <div className="col-sm-11">
            <button type="submit" className="btn btn-success rounded">Hire Instrument</button>&nbsp;&nbsp;
        </div>       
    </div>
);

const hired = (studentId, returnInst) => (
    <div className="row">
        <div className="col-sm-8">
            <button type="submit" className="btn btn-primary rounded">Save</button>&nbsp;&nbsp;
            <button type="button" className="btn btn-warning rounded" onClick={() => returnInst('Return Instrument')}>Return Instrument</button>&nbsp;&nbsp;
        </div>  
        <div className="col-sm-4 text-right">
            <Link to={`student/${studentId}`} className="btn btn-primary outlined rounded">Student Details</Link>
        </div>    
    </div>
);

const InstrumentHireForm = (props) => {
    const { handleSubmit, initialValues, isHired, studentList, returnInst, checkReserved } = props;
    return (
        <div className="basic-form">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <div className="col-sm-4">
                                <label>Student</label>
                            </div>
                            <div className="col-sm-8">
                                <Field name="hireDetails.hiredBy" type="text" disabled={(initialValues.isHired)} component={renderDropdownList} checkReserved={checkReserved} options={studentList} validate={required} />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="col-sm-4">
                                <label>Date</label>
                            </div>
                            <div className="col-sm-8">
                                <Field name="hireDetails.dateHired" type="text" component={renderDatePicker} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <div className="col-sm-4">
                                <label>Hire Amt.</label>
                            </div>
                            <div className="col-sm-5">
                                <Field name="hireDetails.hirePrice" type="text" component={renderCurrencyField} label="Amount" validate={[required, number]} />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="col-sm-4">
                                <label>Deposit</label>
                            </div>
                            <div className="col-sm-5">
                                <Field name="hireDetails.deposit" type="text" component={renderCurrencyField} label="Deposit" validate={[required, number]} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="col-sm-1 col-sm-offset-2">
                                <Field name="hireDetails.hireForm" component={renderCheckbox} />
                            </div>
                            <div className="col-sm-9">
                                <div style={{ fontSize: '13px', fontWeight: '600', color: '#333', marginTop: '3px' }}>Hire form received</div>
                            </div>
                        </div>
                    </div>
                </div>

                

                <hr />
                {(isHired) ? hired(initialValues.hireDetails.hiredBy.value, returnInst) : notHired()}     
            </form>
        </div>
    );
};

InstrumentHireForm.propTypes = {
    ...propTypes,
    returnInst: PropTypes.func.isRequired
};

renderField.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
};

renderCurrencyField.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
};

renderDropdownList.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    checkReserved: PropTypes.func.isRequired
};

renderDatePicker.propTypes = {
    input: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'InstrumentHireForm',
    enableReinitialize: true
})(InstrumentHireForm);