import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { Link } from 'react-router-dom';
import FormEditor from '../editor/FormEditor';
import FormEditor1 from '../editor/FormEditor1';
import DropdownList from 'react-select';
import { required, number } from '../common/validation';

const renderDropdownList = ({ input, ...rest }) => <DropdownList {...input} {...rest} onBlur={() => input.change} />;

const renderField = ({ input, label, type, tabIndex, meta: { touched, error } }) => (
  <div>
    <input className='form-control' {...input} type={type} tabIndex={tabIndex} placeholder={label} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderEditor = ({ input, onTextChanged }) => (
  <FormEditor value={input.value} handleOnChange={input.onChange} onBlur={onTextChanged(input.value)} />
);

const renderEditor1 = ({ input, onTextChanged }) => (
  <FormEditor1 value={input.value} handleOnChange={input.onChange} onBlur={onTextChanged(input.value)} />
);

const InvoiceSettingsForm = (props) => {
  const { handleSubmit, submitting, onFooterField, onHeaderField, openModal, openLargeModal, accounts } = props;

  return (
    <div className='basic-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-6'>
              <h4>Invoice Header</h4>
            </div>
            <div className='col-sm-6 text-right'>
              <button type='button' className='btn btn-xs btn-dfault rounded' onClick={() => openModal()}>
                Upload Header Image
              </button>
            </div>
            <div className='col-sm-12'>
              <Field
                name='invoiceHeader'
                type='textarea'
                onTextChanged={onHeaderField}
                component={renderEditor}
                height={120}
              />
            </div>
          </div>
        </div>

        <div className='form-group margin-top-two'>
          <div className='row'>
            <div className='col-sm-6'>
              <h4>Invoice Footer / Payment Details</h4>
            </div>
            <div className='col-sm-6 text-right'>
              <button
                type='button'
                className='btn btn-xs btn-dfault rounded'
                onClick={() => openLargeModal('Upload Invoice Footer')}
              >
                Upload Footer Image
              </button>
            </div>
            <div className='col-sm-12'>
              <Field
                name='invoiceFooter'
                type='textarea'
                onTextChanged={onFooterField}
                component={renderEditor1}
                height={250}
              />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-xs-12 col-sm-7'>
              <div className='col-sm-5'>
                <label>Inst Hire Account</label>
              </div>
              <div className='col-sm-7'>
                <Field
                  name='instrumentHireAccount'
                  type='text'
                  simpleValue={true}
                  component={renderDropdownList}
                  options={accounts}
                />
              </div>
            </div>
            <div className='col-xs-12 col-sm-5'>
              <div className='col-sm-7'>
                <label>Next Invoice No</label>
              </div>
              <div className='col-sm-5'>
                <Field name='nextInvoiceNumber' type='text' component={renderField} validate={[required, number]} />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className='col-xs-12'>
          <button type='submit' disabled={submitting} className='btn btn-primary rounded'>
            Save
          </button>
          &nbsp;&nbsp;
          <button
            type='button'
            className='btn btn-sm btn-info rounded'
            onClick={() => openLargeModal('Invoice Preview')}
          >
            Preview
          </button>
          &nbsp;&nbsp;
          <Link to='accounts' className='btn btn-trans rounded btn-default'>
            Cancel
          </Link>
        </div>
      </form>
    </div>
  );
};

InvoiceSettingsForm.propTypes = {
  ...propTypes,
};

renderEditor.propTypes = {
  input: PropTypes.object.isRequired,
  onTextChanged: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
};

renderEditor1.propTypes = {
  input: PropTypes.object.isRequired,
  onTextChanged: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
};

renderField.propTypes = {
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  tabIndex: PropTypes.string,
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'invoiceSettingForm',
})(InvoiceSettingsForm);
