import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/library';
import { Modal } from 'react-bootstrap';
import { Tree } from '@blueprintjs/core';
import FolderForm from './folder-form';
import TemplateForm from './template-form';
import MoveTemplate from './file-move-form';

class TemplatesPage extends Component {

     constructor(props) {
        super(props);

        this.state = {
            nodes: (this.props.templates) ? JSON.parse(JSON.stringify(this.props.templates)) : [],
            selectedNode: { attachments: [] },
            emailBody: '',
            showModal: false,
            showTemplate: false,
            folderOpen: false,
            isFolder: true
        };

        this.handleInteraction = this.handleInteraction.bind(this);
        this.handleNodeClick = this.handleNodeClick.bind(this);
        this.handleNodeCollapse = this.handleNodeCollapse.bind(this);
        this.handleNodeExpand = this.handleNodeExpand.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.saveFolder = this.saveFolder.bind(this);
        this.removeFolder = this.removeFolder.bind(this);
        this.moveTemplate = this.moveTemplate.bind(this);
        this.openTemplate = this.openTemplate.bind(this);
        this.closeTemplate = this.closeTemplate.bind(this);
        this.saveTemplate = this.saveTemplate.bind(this);
        this.removeTemplate = this.removeTemplate.bind(this);
        this.setEmailBody = this.setEmailBody.bind(this);
        // this.uploadFile = this.uploadFile.bind(this);
    }

    componentDidMount() {
       if (!this.props.documents) this.props.getDocuments(this.props.userSetting.schoolId);
       if (!this.props.templates) this.props.getTemplates(this.props.userSetting.schoolId);
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.templates !== this.props.templates) {
          this.setState({ nodes: JSON.parse(JSON.stringify(nextProps.templates))});
        }
     }

    handleInteraction(state) {
     if (!state) this.setState({ showTemplate: false });
    }

    saveTemplate(values) {
        const template = { 
            label: values.label,
            subject: values.subject,
            emailBody: this.state.emailBody,
            attachments: values.attachments
        };
        if (values.id)  template.id = values.id ;

        this.props.saveTemplate(template, this.state.parentId || this.state.selectedNode.id);
        this.setState({ showTemplate: false, folderOpen: false });
    }

     setEmailBody(emailBody) {
        this.setState({emailBody});
    }

    moveTemplate(values) {
      this.props.moveTemplate(values, this.state.selectedNode);
      this.closeModal();
      this.setState({ showTemplate: false, folderOpen: false });
    }

    removeTemplate() {
      if (window.confirm(`Confirm you would like to delete: ${this.state.selectedNode.label}`)) {
        this.props.removeTemplate(this.state.selectedNode.id);
        }
        this.setState({ showTemplate: false, folderOpen: false });
    }

    handleNodeClick(nodeData) {
       this.forEachNode(this.state.nodes, (n) =>  n.isSelected = false);
       nodeData.isSelected = true;

       if (nodeData.hasCaret) {
         this.forEachNode(this.state.nodes, (n) =>  {
             if (n.id !== nodeData.id) {
                 n.isExpanded = false;
                 if (n.hasCaret) n.iconName = 'folder-close';
             }
        });
         nodeData.isExpanded = !nodeData.isExpanded;
         nodeData.iconName = (nodeData.isExpanded) ? 'folder-open' : 'folder-close';
         this.setState({ 
             nodes: this.state.nodes,
             showTemplate: false,
             folderOpen: nodeData.isExpanded,
             selectedNode: (nodeData.isExpanded) ? nodeData : {},
             parentId: undefined,
             isFolder: true });
     } else {
        let parentId = '';
        this.forEachNode(this.state.nodes, (n) => {
            if (n.hasCaret) {
                if (n.childNodes.find(item => item.id === nodeData.id)) parentId = n.id;
            }
            
        });
       this.setState({ showTemplate: true, selectedNode: nodeData, emailBody: nodeData.emailBody, parentId, isFolder: false });
     }
    }

    handleNodeCollapse(nodeData) {
        nodeData.isExpanded = false;
        nodeData.isSelected = false;
        nodeData.iconName = 'folder-close';
        this.setState({ nodes: this.state.nodes, showTemplate: false, folderOpen: false, selectedNode: {} });
    }

    handleNodeExpand(nodeData) {
        this.forEachNode(this.state.nodes, (n) =>  {
                n.isExpanded = false;
                n.isSelected = false;
                if (n.hasCaret) n.iconName = 'folder-close';
            });
        nodeData.isExpanded = true;
        nodeData.isSelected = true;
        nodeData.iconName = 'folder-open';
        this.setState({ nodes: this.state.nodes, showTemplate: false, folderOpen: true, selectedNode: nodeData });
    }

    forEachNode(nodes, callback) {
        if (nodes == null) {
            return;
        }

        for (const node of nodes) {
            callback(node);
            this.forEachNode(node.childNodes, callback);
        }
    }

    openModal(modal) {
            this.setState({ showModal: true, modal });
        }

    closeModal() {
            this.setState({ showModal: false });
        }

    openTemplate() {
            this.setState({ showTemplate: true });
        }

    closeTemplate() {
            this.setState({ showTemplate: false, selectedNode: {} });
        }

    saveFolder(folderDetails) {
      if (folderDetails.id) {
        this.props.updateTemplateFolder(folderDetails, folderDetails.id);
      } else {
        this.props.addTemplateFolder(folderDetails, this.props.userSetting.schoolId);
      }
      this.closeModal();
    }

    removeFolder(folderId) {
       if (window.confirm(`Confirm you would like delete: ${this.state.selectedNode.label} folder.`)) {
          this.props.removeTemplateFolder(folderId);
          this.setState({ selectedNode: {} });
        }
        this.closeModal();
    }
       
    render() {
        if (!this.props.templates) {
            return <div>loading...</div>;
        }
       // console.log(this.state.folderOpen)
       const folderButton = <button type="button" className="btn btn-primary rounded btn-xs" onClick={() => this.openModal('folder')} >{(this.state.folderOpen) ? 'Edit Folder' : 'Add Folder'}</button>
        
       return (
            <div>
              <div className="pageheader">
                  <h2>Templates</h2>
              </div>
              <section id="main-content">
                  <div className="row">
                      <div className="col-md-12 col-lg-11">
                          <div className="panel panel-primary">
                              <div className="panel-heading">Email Templates List</div>
                              <div className="panel-body">
                                  <div className="text-right">
                                      {this.state.isFolder && folderButton}&nbsp;&nbsp;
                                      {this.state.folderOpen && <button type="button" className="btn btn-xs btn-info rounded" onClick={() => this.openTemplate()} >Add Template</button>}
                                  </div>
                                      <Tree
                                        contents={this.state.nodes}
                                        onNodeClick={this.handleNodeClick}
                                        onNodeCollapse={this.handleNodeCollapse}
                                        onNodeExpand={this.handleNodeExpand}
                                      />
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Modal show={this.state.showModal} onHide={this.closeModal}>
              <Modal.Body>
                      <div className="form-padding">
                        {(this.state.modal === 'folder') && <FolderForm 
                                onSubmit={this.saveFolder}
                                closeFolder={this.closeModal}
                                removeFolder={this.removeFolder}
                                initialValues={this.state.selectedNode} />}
                        {(this.state.modal === 'move') && <MoveTemplate 
                                onSubmit={this.moveTemplate}
                                closeOption={this.closeModal}
                                folders={this.state.nodes} />}
                       
                      </div>
                  </Modal.Body>
               </Modal>

               <Modal show={this.state.showTemplate} bsSize="lg" enforceFocus={false} onHide={this.closeTemplate}>
                  <Modal.Body>
                      <div className="form-padding">
                         <TemplateForm 
                            onSubmit={this.saveTemplate} 
                            closeTemplate={this.closeTemplate}
                            removeTemplate={this.removeTemplate}
                            moveTemplate={() => this.openModal('move')}
                            openModal={this.openModal}
                            onEmailBody={this.setEmailBody}
                            fileList={this.props.fileList}
                            initialValues={(!this.state.selectedNode.hasCaret) ? this.state.selectedNode : {}} />
                      </div>
                  </Modal.Body>
               </Modal>
            </div> 
        );
    }
}

TemplatesPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  templates: PropTypes.array,
  fileList: PropTypes.array,
  getTemplates: PropTypes.func.isRequired,
  getDocuments: PropTypes.func.isRequired,
  updateTemplateFolder: PropTypes.func.isRequired,
  addTemplateFolder: PropTypes.func.isRequired,
  removeTemplateFolder: PropTypes.func.isRequired,
  saveTemplate: PropTypes.func.isRequired,
  removeTemplate: PropTypes.func.isRequired,
  moveTemplate: PropTypes.func.isRequired,

};

function mapStateToProps(state) {
  return { 
      userSetting: state.usersetting,
      templates: state.library.templates,
      fileList: state.library.fileList
    };
}

export default connect(mapStateToProps, actions)(TemplatesPage);
