import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/auth';

class Reload extends Component {

    constructor(props, context) {
        super(props, context);
  }

   componentDidMount() {
      const token = localStorage.getItem('token');

      if (this.props.user.schoolId) {
        this.props.history.push(this.props.user.currentURL);
      } else {
        if (!token) {
          this.props.history.push('signin');
        } else {
          this.props.reload(token, this.props.user.currentURL, this.props.history);
        }
      }
    }

  render() {
      return(<div>Loading</div>);
  }
}

Reload.propTypes = {
  reload: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state) {

   return {
       user: state.usersetting,
       students: state.students.all 
    };
    
}

export default connect(mapStateToProps, actions)(Reload);

