import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { required } from '../common/validation';

const renderField = ({ input, label, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} type="text" placeholder={label}/>
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);

const AddGroupForm = (props) => {
    const { handleSubmit, submitting, onClose } = props;

    return (
        <div className="basic-form">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Group Name</label>
                        </div>
                        <div className="col-sm-8">
                           <Field name="name" type="text" component={renderField} label="Group Name" validate={required} />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Short Name</label>
                        </div>
                        <div className="col-sm-4">
                            <Field name="shortName" type="text" component={renderField} label="Group Short Name" validate={required} />
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <button type="submit" className="btn btn-primary rounded" disabled={submitting} >Add Group</button>&nbsp;&nbsp;
                        <button type="button" className="btn btn-default rounded" onClick={() => onClose()}>Cancel</button>
                    </div>       
                </div>
            </form>
        </div>
    );
};

AddGroupForm.propTypes = {
    ...propTypes,

};

renderField.propTypes = {
    label: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'addGroupForm'
})(AddGroupForm);