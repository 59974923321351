import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from '@blueprintjs/core';
import ShowHTML from '../editor/ShowHTML';
import { ROOT_URL } from '../../actions/types';

const renderAttachments = (attachments, schoolId) => {
  return attachments.map((item) => (
    <a
      key={item._id}
      className='ml-1'
      href={`${ROOT_URL}/email/attachment/${schoolId}/${item.token}`}
      target='_blank'
      rel='noopener noreferrer'
    >
      <Tag className='pt-intent-primary mr5'>{item.fileName}</Tag>
    </a>
  ));
};
const EmailDetails = (props) => {
  const { emailDetails } = props;

  return (
    <div className='email-detail-page'>
      <div className='email-header'>
        <h2>{emailDetails.subject}</h2>
        <div className='email-sender'>
          <div className='sender-name-email'>
            <span className='sender-name'>{emailDetails.from.name}</span>
            <span className='sender-email'>
              <strong>From:</strong> {emailDetails.from.email}
            </span>
          </div>
        </div>
        <div>
          <strong>Attachments:</strong> {renderAttachments(emailDetails.attachments, emailDetails.schoolId)}
        </div>
        <div className='email-text'>
          <ShowHTML value={emailDetails.emailBody} />
        </div>
      </div>
    </div>
  );
};

EmailDetails.propTypes = {
  emailDetails: PropTypes.object.isRequired,
};

export default EmailDetails;
