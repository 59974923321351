import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import ToggleButton from 'react-toggle-button';

const borderRadiusStyle = { borderRadius: 2 };
const renderToggleButton = ({ input }) => (
    <ToggleButton
        value={input.value || false}
        onToggle={(value) => input.onChange(!value)}
        thumbStyle={borderRadiusStyle}
        trackStyle={borderRadiusStyle}
    />
);

const additionalFields = () => (
   <div>
        <ul className="clearfix">
            <li className="pro-name">Instrument hire details</li>
            <li className="project-member"><Field name="instrumentHire" component={renderToggleButton}  /></li>
        </ul>
        <ul className="clearfix">
            <li className="pro-name">Student Medical details</li>
            <li className="project-member"><Field name="studentMedical" component={renderToggleButton} /></li>
        </ul>
   </div> 
);

const ExportStudentForm = (props) => {
    const { handleSubmit, submitting, listType } = props;

    return (
        <div className="clearfix form-padding">
            <form onSubmit={handleSubmit}>
                <h5>Include in student export list:</h5>
                <div className="admin-widgets">
                    <div className="detail-list">
                        <ul className="clearfix">
                            <li className="pro-name">First contact details</li>
                            <li className="project-member"><Field name="firstContact" component={renderToggleButton} /></li>
                        </ul>
                        <ul className="clearfix">
                            <li className="pro-name">Second contact details</li>
                            <li className="project-member"><Field name="secondContact" component={renderToggleButton} /></li>
                        </ul>
                        { (listType === '') && additionalFields()}
                        <ul className="clearfix">
                            <li className="pro-name">Student registration details</li>
                            <li className="project-member"><Field name="studentRegistration" component={renderToggleButton} /></li>
                        </ul>
                        { (listType === 'enrolment') && 
                        <ul className="clearfix">
                            <li className="pro-name">Student enrolment details</li>
                            <li className="project-member"><Field name="studentEnrolment" component={renderToggleButton} /></li>
                        </ul>}
                    </div>
                </div>
                <div className="text-center"><button type="submit" disabled={submitting} className="btn btn-primary">Download Student List</button></div>
        </form>
    </div>
    );
};

ExportStudentForm.propTypes = {
    ...propTypes
};

renderToggleButton.propTypes = {
    input: PropTypes.object.isRequired
};


export default reduxForm({
    form: 'exportStudentForm'
})(ExportStudentForm);