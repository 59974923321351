import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../../actions/library';
import { Modal, ProgressBar } from 'react-bootstrap';
import { Tree, Popover } from '@blueprintjs/core';
import FolderForm from './folder-form';
import FileOptions from './file-options';
import UploadFile from '../common/upload-file';
import ShareFile from './file-share-form';
import MoveFile from './file-move-form';
import { ROOT_URL  } from '../../actions/types';

class DocumentsPage extends Component {

     constructor(props) {
        super(props);

        this.state = {
            nodes: (this.props.documents) ? JSON.parse(JSON.stringify(this.props.documents)) : [],
            selectedNode: {},
            showFolder: false,
            showFile: false,
            showOption: false,
            optionType: '',
            isOpen: false,
            folderOpen: false,
            isFolder: true,
            uploaded: 0
        };

        this.handleInteraction = this.handleInteraction.bind(this);
        this.handleNodeClick = this.handleNodeClick.bind(this);
        this.handleNodeCollapse = this.handleNodeCollapse.bind(this);
        this.handleNodeExpand = this.handleNodeExpand.bind(this);
        this.openFolder = this.openFolder.bind(this);
        this.closeFolder = this.closeFolder.bind(this);
        this.saveFolder = this.saveFolder.bind(this);
        this.removeFolder = this.removeFolder.bind(this);
        this.openFile = this.openFile.bind(this);
        this.closeFile = this.closeFile.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.openOption = this.openOption.bind(this);
        this.closeOption = this.closeOption.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.fileSharing = this.fileSharing.bind(this);
        this.moveFile = this.moveFile.bind(this);
     }

    componentDidMount() {
       if (!this.props.documents) this.props.getDocuments(this.props.userSetting.schoolId);
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.documents !== this.props.documents) {
          this.setState({ nodes: JSON.parse(JSON.stringify(nextProps.documents))});
        }
     }

    shouldComponentUpdate() {
        return true;
    }

    handleInteraction(state) {
     if (!state) this.setState({ isOpen: false });
    }

    downloadFile() {
      this.props.downloadFile(this.state.selectedNode);
    }

    fileSharing(values) {
      this.props.fileSharing(values);
      this.setState({ showOption: false, folderOpen: false });
    }

    moveFile(values) {
      this.props.moveFile(values, this.state.selectedNode);
      this.setState({ showOption: false, folderOpen: false });
    }

    removeFile() {
      if (window.confirm(`Confirm you would ike to delete: ${this.state.selectedNode.label}`)) {
        this.props.removeFile(this.state.selectedNode.id);
        this.setState({ folderOpen: false });
        }
    }

    handleNodeClick(nodeData) {
        this.forEachNode(this.state.nodes, (n) =>  n.isSelected = false);
        nodeData.isSelected = true;
 
        if (nodeData.hasCaret) {
          this.forEachNode(this.state.nodes, (n) =>  {
              if (n.id !== nodeData.id) {
                  n.isExpanded = false;
                  if (n.hasCaret) n.iconName = 'folder-close';
              }
         });
          nodeData.isExpanded = !nodeData.isExpanded;
          nodeData.iconName = (nodeData.isExpanded) ? 'folder-open' : 'folder-close';
          this.setState({ 
              nodes: this.state.nodes,
              isOpen: false,
              folderOpen: nodeData.isExpanded,
              selectedNode: (nodeData.isExpanded) ? nodeData : {},
              isFolder: true,
              parentId: undefined });
      } else {
         let parentId = '';
         this.forEachNode(this.state.nodes, (n) => {
             if (n.hasCaret) {
                 if (n.childNodes.find(item => item.id === nodeData.id)) parentId = n.id;
             }
             
         });
        this.setState({ isOpen: true, selectedNode: nodeData, emailBody: nodeData.emailBody, parentId, isFolder: false });
      }
    }

    handleNodeCollapse(nodeData) {
        nodeData.isExpanded = false;
        nodeData.isSelected = false;
        nodeData.iconName = 'folder-close';
        this.setState({ nodes: this.state.nodes, isOpen: false, folderOpen: false, selectedNode: {} });
    }

    handleNodeExpand(nodeData) {
        this.forEachNode(this.state.nodes, (n) =>  {
            n.isExpanded = false;
            n.isSelected = false;
            if (n.hasCaret) n.iconName = 'folder-close';
            });
        nodeData.isExpanded = true;
        nodeData.isSelected = true;
        nodeData.iconName = 'folder-open';
        this.setState({ nodes: this.state.nodes, isOpen: false, folderOpen: true, selectedNode: nodeData });
    }

    forEachNode(nodes, callback) {
        if (nodes == null) {
            return;
        }

        for (const node of nodes) {
            callback(node);
            this.forEachNode(node.childNodes, callback);
        }
    }

    openFolder() {
            this.setState({ showFolder: true });
        }

    closeFolder() {
            this.setState({ showFolder: false });
        }

    openFile() {
            this.setState({ showFile: true });
        }

    closeFile() {
            this.setState({ showFile: false, uploaded: 0 });
        }
    openOption(optionType) {
             this.setState({ showOption: true, optionType: optionType });
        }

    closeOption() {
            this.setState({ showOption: false });
        }


    saveFolder(folderDetails) {
      if (folderDetails.id) {
        this.props.updateFolder(folderDetails, folderDetails.id);
      } else {
        this.props.addFolder(folderDetails, this.props.userSetting.schoolId);
      }
      this.closeFolder();
    }

    removeFolder(folderId) {
      this.props.removeFolder(folderId);
      this.setState({ selectedNode: {} });
      this.closeFolder();
    }

    uploadFile(files, rejected) {
        if (rejected.length > 0) {
            this.props.displayWarning('Maxium size per file is: 3Mb');
            this.closeFile();
        } else {
            const config = {
                headers: { authorization: localStorage.getItem('token'), 'content-type': 'multipart/form-data' },
                onUploadProgress: (progressEvent) => {
                const uploaded = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                this.setState({ uploaded });
                if (uploaded === 100) {
                    setTimeout(() => this.closeFile(), 2000);
                    }
                }
            };

            const id = this.state.parentId || this.state.selectedNode.id;
            const data = new FormData();
            //console.log(file)
            files.forEach(file => data.append('file', file));
            //data.append('file', file);
            axios.post(`${ROOT_URL}/library/file/${id}`, data, config)
                .then((response) => {
                    this.props.addFile(response.data);
                });
        }
    }

    uploadProgress(value) {
       return (<div><ProgressBar striped bsStyle="success" now={value} /></div>);
    } 

   
    render() {
        if (!this.props.documents) {
            return <div>loading...</div>;
        }

        const folderButton = <button type="button" className="btn btn-primary rounded btn-xs rounded" onClick={() => this.openFolder()} >{(this.state.folderOpen) ? 'Edit Folder' : 'Add Folder'}</button>;

        return (
            <div>
              <div className="pageheader">
                  <h2>Documents</h2>
              </div>
              <section id="main-content">
                  <div className="row">
                      <div className="col-md-12 col-lg-11">
                          <div className="panel panel-primary">
                              <div className="panel-heading">Documents List</div>
                              <div className="panel-body">
                                  <div className="text-right">
                                      {this.state.isFolder && folderButton}&nbsp;&nbsp;
                                      {(this.state.folderOpen) && <button type="button" className="btn btn-xs btn-info rounded" onClick={() => this.openFile()} >Add Document</button>}
                                  </div>
                                  <Popover 
                                    className="pt-popover-dismiss"
                                    content={<FileOptions 
                                      onMove={this.openOption}
                                      onDownload={this.downloadFile}
                                      onShare={this.openOption}
                                      onDelete={this.removeFile} />} 
                                    onInteraction={(state) => this.handleInteraction(state)}
                                    isOpen={this.state.isOpen}>
                                      <Tree
                                        contents={this.state.nodes}
                                        onNodeClick={this.handleNodeClick}
                                        onNodeCollapse={this.handleNodeCollapse}
                                        onNodeExpand={this.handleNodeExpand}
                                      />
                                  </Popover>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Modal show={this.state.showFolder} onHide={this.closeFolder}>
                  <Modal.Body>
                      <div className="form-padding">
                          <FolderForm 
                              onSubmit={this.saveFolder}
                              closeFolder={this.closeFolder}
                              removeFolder={this.removeFolder}
                              initialValues={this.state.selectedNode} />
                      </div>
                  </Modal.Body>
               </Modal>

               <Modal show={this.state.showFile} onHide={this.closeFile}>
                  <Modal.Body>
                      <div className="form-padding">
                          <UploadFile onDrop={this.uploadFile} />
                      </div>
                      <div className="col-sm-12">
                        { (this.state.uploaded > 0) && this.uploadProgress(this.state.uploaded) }
                      </div>
                  </Modal.Body>
               </Modal>

               <Modal show={this.state.showOption} onHide={this.closeOption}>
                  <Modal.Body>
                      <div className="form-padding">
                        {(this.state.optionType) ? <ShareFile onSubmit={this.fileSharing}
                                closeOption={this.closeOption}
                                groups={this.props.groups}
                                initialValues={this.state.selectedNode} />
                            : <MoveFile onSubmit={this.moveFile}
                                closeOption={this.closeOption}
                                folders={this.state.nodes} />}
                      </div>
                  </Modal.Body>
               </Modal>
            </div> 
        );
    }
}

DocumentsPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  documents: PropTypes.array,
  getDocuments: PropTypes.func.isRequired,
  updateFolder: PropTypes.func.isRequired,
  addFolder: PropTypes.func.isRequired,
  removeFolder: PropTypes.func.isRequired,
  addFile: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  fileSharing: PropTypes.func.isRequired,
  moveFile: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  displayWarning: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return { 
      userSetting: state.usersetting,
      documents: state.library.documents,
      uploaded: state.library.uploaded,
      groups: state.school.groups
    };
}

export default connect(mapStateToProps, actions)(DocumentsPage);
