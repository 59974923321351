import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/forms';
import NewRegistration from './new-registration-form';
import Questions from '../common/questions';
import NewRegistrationInfo from './new-registration-info';

class RegistrationPage extends Component {

    constructor(props, context) {
        super(props, context);

         this.state = {
            portalText: ''
        };

        this.saveReg = this.saveReg.bind(this);
        this.saveRegQuestion = this.saveRegQuestion.bind(this);
        this.removeRegQuestion = this.removeRegQuestion.bind(this);
    }

    saveReg(info) {
      this.props.saveNewRegistration(info, this.props.userSetting.schoolId);
    }

    saveRegQuestion(question) {
      this.props.addRegistrationQuestion(question, this.props.userSetting.schoolId);
    }

    removeRegQuestion(question) {
      this.props.removeRegQuestion(question, this.props.userSetting.schoolId);
  }
   
    render() {
      const { groups, selectors, forms } = this.props;

      if (!forms.newReg) return null;

        return (
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-default">
                    <div className="panel-body">
                      <NewRegistration
                          onSubmit={this.saveReg} 
                          groupList={groups} 
                          instrumentList={selectors.instrumentList} 
                          initialValues={forms.newReg} />
                      <hr />
                      <h4 className="blueColor">Registration Information</h4>
                      <NewRegistrationInfo />
                      <h4 className="blueColor">Registration Questions</h4>
                      <Questions 
                          questions={forms.newReg.questions} 
                          saveQuestion={this.saveRegQuestion}
                          removeQuestion={this.removeRegQuestion} />
                    </div>
                  </div>
                </div>
            </div>
        );
    }
}

RegistrationPage.propTypes = {
  userSetting: PropTypes.object.isRequired,
  forms: PropTypes.object.isRequired,
  selectors: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  saveNewRegistration: PropTypes.func.isRequired,
  addRegistrationQuestion: PropTypes.func.isRequired,
  removeRegQuestion: PropTypes.func.isRequired
};



function mapStateToProps(state) {
    return {
      userSetting: state.usersetting,
      groups: state.school.groups,
      selectors: state.school.selectors,
      forms: state.forms
    };
}

export default connect(mapStateToProps, actions)(RegistrationPage);