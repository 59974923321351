import React, { Component } from 'react';
import _ from 'lodash';
import ReactDataGrid from 'react-data-grid';
import { Data } from 'react-data-grid-addons';
import EmptyToolbar from '../common/grid-toolbar';
import { Modal } from 'react-bootstrap';
import Student from '../students/student-page';

const Selectors = Data.Selectors;

class StudentList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedStudents: [],
      rows: _.filter(this.props.students, student => {
        return Boolean(student.groups.find(group => group.tutor === this.props.id));
      }),
      filters : {},
      sortColumn: null, 
      sortDirection: null,
      showModal: false,
      modalHeading: ''
  };

  this.rowGetter = this.rowGetter.bind(this);
  this.onRowClick = this.onRowClick.bind(this);
  this.getRows = this.getRows.bind(this);
  this.getSize = this.getSize.bind(this);
  this.handleFilterChange = this.handleFilterChange.bind(this);
  this.onClearFilters = this.onClearFilters.bind(this);
  this.handleGridSort = this.handleGridSort.bind(this);
  this.closeModal = this.closeModal.bind(this);
  }

  onRowClick(rowIdx, row) {
      if (row) {
          this.setState({ showModal: true, id: row._id })
      }
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  rowGetter(i) {
    const rows = this.getRows();
    return rows[i];
}

  getRows() {
      return Selectors.getRows(this.state);
  }

  getSize() {
      return this.getRows().length;
  }

  handleFilterChange(filter) {
      const newFilters = Object.assign({}, this.state.filters);
      if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
      } else {
      delete newFilters[filter.column.key];
      }
      this.setState({filters: newFilters});
  }

  onClearFilters() {
      //all filters removed
      this.setState({filters: {} });
  }

  handleGridSort(sortColumn, sortDirection) {
      const state = Object.assign({}, this.state, {sortColumn: sortColumn, sortDirection: sortDirection});
      this.setState(state);
  }

  studentTags(tags) {

      if (tags.value.length === 0) return null;
      return (
          <div>
            {tags.value.map((tag, index) => {
                return <span key={index} className="label label-info label-padding">{tag}</span>;
                })}
          </div>
      );
  }

  render() {

    const columns = [
      {
          key: 'firstName',
          name: 'First Name',
          sortable : true,
          filterable : true,
          width: 140
      },
      {
          key: 'lastName',
          name: 'Last Name',
          sortable : true,
          filterable : true,
          width: 150
      },
       {
          key: 'grade',
          name: 'Grade',
          sortable : true,
          filterable : true,
          width: 70
      },
       {
          key: 'class',
          name: 'Class',
          sortable : true,
          filterable : true,
          width: 80
      },
       {
          key: 'groupSummary',
          name: 'Groups',
          sortable : true,
          filterable : true,
      },
      {
          key: 'instruments',
          name: 'Instruments',
          sortable : true,
          filterable : true,
      },
      {
          key: 'studentTags',
          name: 'Tags',
          sortable : true,
          filterable : true,
          formatter: this.studentTags
      },
      {
         width: 18
     }
      ];

    return (
      <div>
        <div className="row">
          <div className="col-xs-12">
            <div>
              <ReactDataGrid
                  rowKey="_id"
                  onGridSort={this.handleGridSort}
                  columns={columns}
                  rowGetter={this.rowGetter}
                  rowsCount={this.getSize()}
                  minHeight={this.getSize() * 35 + 85}
                  onRowClick={this.onRowClick}
                  toolbar={<EmptyToolbar />}
                  onAddFilter={this.handleFilterChange}
                  onClearFilters={this.onClearFilters} />
            </div>
          </div>
        </div>

        <Modal show={this.state.showModal} dialogClassName="fullSize" onHide={this.closeModal}>
        <Modal.Body>
            <Student id={this.state.id} onClose={this.closeModal} />
        </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default StudentList;
