import _ from 'lodash';
import { GET_INVOICES, REMOVE_INVOICES, UPDATE_INV, SELECTED_INVOICES } from '../actions/types';
import initialState from './initial-state';

export default function(state = initialState.invoices, action) {
    switch(action.type) {
        
        case GET_INVOICES:
            return { ...state, 
                invoiceList: _.mapKeys(action.payload.invoices, '_id'), 
                dateFrom: action.payload.dateFrom,
                dateTo: action.payload.dateTo
            };

        case REMOVE_INVOICES:
            return { ...state, invoiceList: _.omit(state.invoiceList, action.payload) };

        case UPDATE_INV:
            return { ...state, invoiceList: { ...state.invoiceList, [action.payload.id]: action.payload.data } };

        case SELECTED_INVOICES:
            return { ...state, selectedInvoices: action.payload };
            
        default:
            return state;
    }
}