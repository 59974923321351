import { TOGGLE_MENU, SIGN_IN, SET_SCHOOL, UPDATE_FROM_EMAIL, LASTUPDATE_STUDENT,
    CURRENT_URL, UPDATE_PROFILE, PWD_TOKEN } from '../actions/types';
import initialState from './initial-state';

export default function(state = initialState.userSettings, action) {
    switch(action.type) {
      
        case TOGGLE_MENU:
             return { ...state, menu: !state.menu };

         case SET_SCHOOL:
             return { ...state, ...action.payload };
        
         case LASTUPDATE_STUDENT:
             return { ...state, lastUpdate: { ...state.lastUpdate, students: action.payload } };
        
        case SIGN_IN:
             return { ...state, signin: true, resetPassword: false, remember: action.remember, profile: action.profile, menu: action.menu, schoolList: action.schoolList };

        case UPDATE_FROM_EMAIL:
             return { ...state, profile: 
                        { ...state.profile, fromEmails: action.payload } };

         case UPDATE_PROFILE:
             return { ...state, profile: action.payload };

         case PWD_TOKEN:
             return { ...state, resetPassword: action.payload };

        case CURRENT_URL:
             return { ...state, currentURL: action.payload };

        default:
            return state;
    }
}
