import { ADD_ATTACHMENT, REMOVE_ATTACHMENT, EMAIL_STATUS, CLEAR_EMAIL, CLEAR_ATTACHMENT } from '../actions/types';
import initialState from './initial-state';

export default function(state = initialState.email, action) {
    switch(action.type) {
        
        case ADD_ATTACHMENT:
            return { ...state, attachments: [ ...state.attachments, action.payload ] };

        case REMOVE_ATTACHMENT:
            return { ...state, attachments: [...state.attachments.slice(0, action.payload), ...state.attachments.slice(action.payload + 1)] };
  
        case CLEAR_ATTACHMENT:
            return { ...state, attachments: [] };

        case EMAIL_STATUS:
            return { ...state, emailStatus: action.payload };

        case CLEAR_EMAIL:
            return { ...state, emailStatus: 'Send Email', attachments: [] };

        default:
            return state;
    }
}